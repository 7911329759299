import { useEffect, useState, useReducer, useContext } from 'react';
import * as con from '../../repository/api_calls';
import TreatmentPlanForm from './TreatmentPlanForm';
import { UserContext } from "../../patient/exp";
import { ToastContainer, toast } from 'react-toastify';
import "./style.css"
import { FaCheck, FaCloudDownloadAlt } from 'react-icons/fa';



const predefinedColors = [
    '#F7C8E0',
    '#FFEEBB',
    '#C4DFDF',
    '#D7C0AE',
    '#D7E9F7',
];



const TreatmentPlanMap = (props) => {

    const [open, setOpen] = useState(false)
    const [treatmentPlan, setTreatmentPlan] = useState([]);
    const [summary, setSummary] = useState([]);
    const [overallDiagnosis, setOverallDiagnosis] = useState([]);
    const contextValue = useContext(UserContext);
    const [firstDropDown, setFirstDropDown] = useState([]);


    const details = (ert) => {
        setOverallDiagnosis(ert)
    }


    useEffect(() => {
        getTreatmentPlan()

    }, [])


    async function getTreatmentPlan() {
        const response = await con.treatmentplanList();
        if (response['status'] === true) {
            setTreatmentPlan(response['data']);
        }
    }

    // Add Treatment Mapping
    async function addToothSpecificMapping(book) {
        const responseadd = await con.AddToothSpecificMapping((JSON.stringify(book)));
        console.log("return = " + responseadd)
        if (responseadd['status'] == true) {
            toast.success(responseadd['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            window.location.reload();
        }
        else {
            console.log("vvvvv")
            console.log(responseadd['status'])
            toast.error(responseadd['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }



    function addTrt(t, index) {
        t['index'] = index;
        getTreatmentPlanName(t)
        console.log(t)
    }


    function update(t, ind) {
        console.log(t)

        contextValue.toothSelection[t['datas']['index']]['data'] = t['datas']['data'];
        // contextValue.toothSelection[t['datas']['index']]['treatmentMethod'] = t.treatmentMethod;
        // contextValue.toothSelection[t['datas']['index']]['treatmentPlan'] = t.treatmentPlan;
        // contextValue.toothSelection[t['datas']['index']]['treatmentPrice'] = t.treatmentPrice;  
        // contextValue.toothSelection[t['datas']['index']]['treatmentProcedure'] = t.treatmentProcedure;
        contextValue.toothSelection[t['datas']['index']]['items'] = t.items;
        contextValue.toothSelection[t['datas']['index']]['itemsP'] = t.itemsP;
        contextValue.toothSelection[t['datas']['index']]['itemsPr'] = t.itemsPr;
        contextValue.toothSelection[t['datas']['index']]['discounts'] = t.discounts;
        contextValue.toothSelection[t['datas']['index']]['discountPrice'] = t.discountPrice;

    }

    function closeForm(cls) {
        setOpen(cls)
    }

    function submit(e) {

        e.preventDefault()
        const pid = props.pid
        // console.log(updatedSelectT)
        // console.log(props.id)
        for (let item of contextValue.toothSelection) {
            item['is_booked'] = false
        }
        (localStorage.setItem('activeTab', 'Summary'))
        console.log(contextValue.toothSelection)
        const book = {
            "patient_treatment": contextValue.toothSelection,
            "patient_id": pid,
        }

        console.log(book)


        addToothSpecificMapping(book)
        // console.log(contextValue.dentalDiagnosis)


    }

    // console.log(contextValue.toothSelection)

    useEffect(() => {
        // getTreatmentPlanName()
        // {contextValue.toothSelection !== undefined?getTreatmentPlanName(contextValue.toothSelection.problem.id):null }
        // getTreatmentPlanName(summary.problem.id)
        // console.log(props.data)

    }, [])


    async function getTreatmentPlanName(t) {
        const response = await con.treatmentplanList();
        // console.log(response['data'])
        console.log(t.problem.id)
        if (response['status'] === true) {
            // const filteredDentalList = response['data']
            const filteredDentalList = response['data'].filter((d) => d.diagnosis_id.toString() === t.problem.id.toString())
            setFirstDropDown(filteredDentalList);
            console.log(filteredDentalList)

            setSummary(t)
            setOpen(true)


        }
    }




    return (
        <>

            <div className='container'>





                {/* Diagnosis Mapping Table */}
                <div className='treatmentPlanMap-container' style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

                    <table className="bordered-table">
                        <thead className="tablehead" >
                            <tr style={{ borderRadius: "10px" }}>
                                <th>S.No</th>
                                <th>Diagnosis Type</th>
                                <th>Tooth No</th>
                                {/* <th>Tooth No</th> */}
                                <th>Diagnosis Name</th>
                                <th>Add Diagnosis Treatment</th>
                                <th>Check</th>



                            </tr>
                        </thead>



                        <tbody>

                            {contextValue.toothSelection.length > 0 ? (
                                contextValue.toothSelection.map((t, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <b>{t.type.toUpperCase()}</b>
                                        </td>
                                        <td>{t.type === 'tooth' ? <p>{t.tooth}</p> : "-"}</td>
                                        <td>{t.problem.name}</td>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-primary my-5"
                                                aria-hidden="true"
                                                onClick={() => {
                                                    addTrt(t, index);
                                                }}
                                                data-toggle="modal"
                                                data-target="#addstaff"
                                                style={{ cursor: "pointer", backgroundColor: "#145BD9" }}
                                            >
                                                <span className="me-1" style={{ fontSize: "20px" }}></span>
                                                Add Treatment
                                            </button>

                                            <div>
                                                {open && (
                                                    <section>
                                                        <div className="container">
                                                            <div className="modal fade" id="addstaff" role="dialog">
                                                                <div className="po_sc_in">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h4
                                                                                className="modal-title"
                                                                                style={{ fontSize: "20px", fontWeight: "600" }}
                                                                            >
                                                                                ADD TREATMENT PLAN
                                                                            </h4>
                                                                            <button
                                                                                type="button"
                                                                                className="close"
                                                                                style={{ marginRight: "-250px", color: "blue", fontSize: "30px" }}
                                                                                data-dismiss="modal"
                                                                            >
                                                                                ×
                                                                            </button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <TreatmentPlanForm
                                                                                data={summary}
                                                                                fun={update}
                                                                                index={index}
                                                                                form={closeForm}
                                                                                patient_id={props.pid}
                                                                                dpValue={firstDropDown}
                                                                            />
                                                                        </div>
                                                                        <div className="modal-footer"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )}
                                            </div>
                                        </td>
                                        <td className='d-flex align-items-center justify-content-center mt-10 pt-5'>
                                                {t.itemsPr? (
                                                    
                                                        <FaCheck className="check" style={{ color: "green" }} />
                                                
                                                ) : (
                                                    <p
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            color: "red",
                                                            borderRadius: "5px",
                                                            padding: "3px",
                                                        }}
                                                    >
                                                        not mapped
                                                    </p>
                                                )}
                                            </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Please select a diagnosis.</td>
                                </tr>
                            )}

                            <hr />
                        </tbody>


                    </table>
                    <div className="submit-btn"
                    // style={{ display: 'flex', justifyContent: 'end' }} 
                    >
                        <button className="btn btn-primary" onClick={submit}
                            style={{ color: "white", padding: "5px 20px", borderRadius: "10px", }}
                        >
                            Submit
                        </button>
                    </div>
                </div>








            </div>
        </>
    )
}

export default TreatmentPlanMap