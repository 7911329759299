import React, { useState, useEffect } from "react";
import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
import * as con from '../../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';



function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            name: state.name,
            id: state.id
        };
    }
}


const MedicalReportType = () => {

    // ------------------------------- add Medical Report type ------------

    const [state, dispatch] = useReducer(reducer, {
        name: '', id: ''
    })

    const submitAddMedicalRepType = (event) => {
        event.preventDefault();
        if (state.id != '') {
            addMedicaReptyp(state, 'edit');
            setDropdownOpen(false)
        } else {
            addMedicaReptyp(state, 'add');
            setDropdownOpen(false)
        }
    }


    async function addMedicaReptyp(MedicalrepTyTypeData, type) {
        if (type == 'add') {
            const response = await con.CreateMedicalReTyAPI((JSON.stringify(MedicalrepTyTypeData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = [...medicalreporttypeList];
                afterAdded.push(response['data']);
                setMedicalreportTypeList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        } else {
            const response = await con.UpdateMedicalRepTyAPI((JSON.stringify(MedicalrepTyTypeData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = medicalreporttypeList.map(item => {
                    if (item.id === state.id) {
                        return response.data;
                    }
                    return item;
                });
                dispatch({ type: 'set_value', value: '', key: 'id' })
                dispatch({ type: 'set_value', value: '', key: 'name' })
                setMedicalreportTypeList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        }
    }


    // -----------------------------  add method for dropdown

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };


    //------------------------for cancel action
    const handleCancel = () => {

        setDropdownOpen(false);


        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };



    // ---------------- for edit function -------

    function handleForEditButtonClick(subCat) {
        setDropdownOpen(true);

        dispatch({ type: 'set_value', value: subCat.id, key: 'id' })
        dispatch({ type: 'set_value', value: subCat.name, key: 'name' })

    };


    // ------------------------------- Medical Report type  list API -----------
    const [medicalreporttypeList, setMedicalreportTypeList] = useState([])


    useEffect(() => {
        getMedcTy();
    }, [])

    async function getMedcTy() {
        const response = await con.MedicalRepoTyListAPI();
        if (response['status'] === true) {
            setMedicalreportTypeList(response['data']);
        }
    }


    // ------------------------- Delete API --------

    async function getMedicalrepoTypeDelete(deletemedicalrepTyp) {
        const response = await con.MedicalRepeTypDeleteAPI(deletemedicalrepTyp);
        console.log(response)
        if (response['status'] === true) {
            let afterAdded = medicalreporttypeList.filter(item => item.id !== deletemedicalrepTyp);
            setMedicalreportTypeList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }

    return (
        <React.Fragment>

            <div>
                < button type="submit"
                    className="btn btn-primary my-5"
                    style={{ width: "auto", backgroundColor: "#5660d9", marginLeft: "18px" }}
                    onClick={handleButtonClick}
                >+ Add Medical Report type
                </button>
                {isDropdownOpen && (

                    <div className="card mb-5" style={{ backgroundColor: "#f5f5f5", borderRadius: "7px", boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)", margin: "10px", padding: "20px" }}>
                        <form onSubmit={submitAddMedicalRepType}>

                            <div className="form-group">
                                <label htmlFor="diagnosisInput">Medical Report type:</label>
                                <input type="text" id="diagnosisInput" placeholder="Enter Your Medical Report type" className="form-control"
                                    value={state.name}
                                    required
                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'name' }); }}
                                />
                            </div>
                            <span> <button type="submit" className="btn btn-primary">Save</button></span>
                            <span className="me-1"> <button type="button" className="btn btn-primary" onClick={handleCancel}>cancel</button></span>
                        </form>
                    </div>

                )}

                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px" }}>
                            <th>S.No</th>
                            <th>Medical Report type</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {medicalreporttypeList.map((medicalrepotype, index) => {
                            return (

                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    {/* <td>{medicalrepotype.name}</td> */}
                                    <td>{medicalrepotype ? medicalrepotype.name : 'N/A'}</td>
                                    <td><RiEdit2Line className="edit_icon" onClick={() => { handleForEditButtonClick(medicalrepotype); }} /></td>
                                    <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                                        onClick={() => getMedicalrepoTypeDelete(medicalrepotype.id)} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>


        </React.Fragment>
    )
}

export default MedicalReportType