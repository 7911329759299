import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import './inventory.css'
import * as con from '../../repository/api_calls';
import { useReducer } from 'react';
import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';
import InventoryAdd from "./InventoryAdd";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { FaEllipsis } from "react-icons/fa6";


function reducer(state, action) {
  if (action.type === 'set_value') {
    state[action.key] = action.value;
    return {
      tab_id: action.value,
      material_name: state.material_name,
      make: state.make,
      category_id: state.category_id,
      condition_status: state.condition_status,
      stock_in: state.stock_in,
      stock_out: state.stock_out,
      backup_stock_in: state.backup_stock_in,
      id: state.id
    };
  }
}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination">
      <ul className="pagination-list">
        {pageNumbers.map((number) => (
          <li key={number} className="pagination-item">
            <a
              href="#"
              onClick={() => paginate(number)}
              className={`pagination-link ${currentPage === number ? 'active' : ''}`}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};



const InventoryList = () => {




  const [inventoryLists, setInventoryList] = useState([])
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const searchedInventory = inventoryLists.filter((c) =>
    c.material_name.toLowerCase().includes(searchTerm.toLowerCase()) || c.make.includes(searchTerm)
  );






  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentInventory = searchedInventory.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);




  useEffect(() => {
    getInventoryCat();
    InventoryList();
  }, [])


  const [state, dispatch] = useReducer(reducer, {
    tab_id: '', material_name: '', make: '', category_id: '', condition_status: 'Good',
    stock_in: '', stock_out: '', id: '', backup_stock_in: ''
  })

  const [InventorycategoidList, setInventoryCateidList] = React.useState([]);

  const handleTab = (e) => {
    e.preventDefault()
    console.log(typeof e.target.name)
    dispatch({ type: 'set_value', value: e.target.name, });
    searchInventory(e.target.name);
  }

  const handleTab2 = (e) => {
    e.preventDefault();
    dispatch({ type: 'set_value', value: e.target.name });
    if (e.target.name === 'all') {
      window.location.reload();
    } else {
      // Handle other tab logic
    }
  };

  async function getInventoryCat() {
    const response = await con.InventoryCategory();
    console.log(response)
    if (response['status'] === true) {
      setInventoryCateidList(response['data']);
    }
  }

  async function searchInventory(id) {
    const response = await con.InventoryList();
    console.log(response)
    if (response['status'] === true) {
      const filteredInventoryList = response['data'].filter(item => item.category_id.toString() === id);
      setInventoryList(filteredInventoryList);
    }
  }


  async function InventoryList() {
    const response = await con.InventoryList();

    console.log(response)
    if (response['status'] === true) {
      setInventoryList(response['data']);
    }
  }

  const submitStaffPop = (event) => {
    event.preventDefault();
    updateInventory(state);
  }

  // ---------------------update api ------------

  async function updateInventory(invent) {
    const response = await con.UpdateInventoryAPI((JSON.stringify(invent)));
    console.log(response)
    if (response['status'] === true) {

      let afterAdded = inventoryLists.map(item => {
        if (item.id === state.id) {
          return response.data;
        }
        return item;
      });
      dispatch({ type: 'set_value', value: '', key: 'id' })
      dispatch({ type: 'set_value', value: '', key: 'material_name' })
      dispatch({ type: 'set_value', value: '', key: 'make' })
      dispatch({ type: 'set_value', value: '', key: 'category_id' })
      dispatch({ type: 'set_value', value: '', key: 'condition_status' })
      dispatch({ type: 'set_value', value: '', key: 'stock_in' })
      dispatch({ type: 'set_value', value: '', key: 'stock_out' })
      setInventoryList(afterAdded);
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      window.location.href = "/Inventory";
    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }

  // ---------------- for edit function -------

  function handleForEditButtonClick(subCat) {
    setDropdownOpen(true);
    dispatch({ type: 'set_value', value: subCat.tab_id, key: 'tab_id' })
    dispatch({ type: 'set_value', value: subCat.material_name, key: 'material_name' })
    dispatch({ type: 'set_value', value: subCat.make, key: 'make' })
    dispatch({ type: 'set_value', value: subCat.category_id, key: 'category_id' })
    dispatch({ type: 'set_value', value: subCat.condition_status, key: 'condition_status' })
    dispatch({ type: 'set_value', value: subCat.stock_in, key: 'stock_in' })
    dispatch({ type: 'set_value', value: subCat.stock_in, key: 'backup_stock_in' })
    dispatch({ type: 'set_value', value: subCat.stock_out, key: 'stock_out' })
    dispatch({ type: 'set_value', value: subCat.id, key: 'id' })
  };

  // ------------------------- Delete API --------

  async function getInvCDelete(deleteInCatag) {
    const response = await con.InventListDeleteAPI(deleteInCatag);
    console.log(response)
    if (response['status'] === true) {
      let afterAdded = inventoryLists.filter(item => item.id !== deleteInCatag);
      setInventoryList(afterAdded);
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
  }

  // ---------------- action dropdown ----
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (

    <div>

      <div>
        <div className="col-md-12 mb-sm-7 mb-4">
          <InventoryAdd />
        </div>
      </div>

      <div>
        <div className=".nav-tab-inventory mt-5" style={{ marginLeft: "50px" }} >
          <input className="form-control searchBar" type="text"
            placeholder="Global Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)} />
          <ul className="nav nav-tabs" style={{ border: 'none' }}>
            <div className="cards mt-5" style={{ gap: "20px", width: '100%' }}>
              {/* <div className={state.tab_id.toString() === 'all' ? `card-tab-invent card` : 'card'} key="all">
                <li className="nav-item">
                  <Link className={state.tab_id === 'all' ? `nav-item inventorytab` : 'nav-item'} name="all" onClick={handleTab2}>
                    Show All
                  </Link>
                </li>
              </div> */}
              {InventorycategoidList.map((tab) => (
                <div className={state.tab_id.toString() === tab.id.toString() ? `card-tab-invent card` : 'card'} key={tab.id}>
                  <li className="nav-item">
                    <Link className={state.tab_id === tab.id ? `nav-item inventorytab` : 'nav-item'} name={tab.id} onClick={handleTab}>
                      {tab.name}
                    </Link>
                  </li>
                </div>
              ))}
            </div>
          </ul>

          <div style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

            <table className="bordered-table">
              <thead className="tablehead" >
                <tr style={{ borderRadius: "10px" }}>
                  <th>S.No</th>
                  <th>Material Name</th>
                  <th>Make</th>
                  <th>Stock In</th>
                  <th>Stock Out</th>
                  <th>Condition</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {currentInventory.map((inventory, index) => {
                  const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
                  return (

                    <tr key={index}>
                      {/* <td>{index + 1}</td> */}
                      <td>{continuousIndex}</td>
                      <td>{inventory.material_name}</td>
                      <td>{inventory.make}</td>
                      <td>{inventory.stock_in}</td>
                      <td>{inventory.stock_out}</td>
                      <td>{inventory.condition_status}</td>
                      <td>
                        <div className="dropdown">
                          <div style={{ display: "flex" }}>
                            <FaEllipsis onClick={toggleDropdown} className="ellipsisicon" />
                          </div>
                          {isOpen && (
                            <div className="dropdown-content" >
                              <div className="lis" aria-hidden="true"
                                data-toggle="modal"
                                data-target="#editinventory"
                                onClick={() => handleForEditButtonClick(inventory)}
                                style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px', width: '100%' }}>
                                <span style={{ display: 'flex', justifyContent: 'center' }}>
                                  <RiEdit2Line className="edit_icon me-2" /><span >Edit</span></span>
                              </div>

                              <div className="lis" style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '20px', width: '100%' }} onClick={() => getInvCDelete(inventory.id)}><span style={{ display: 'flex', justifyContent: 'center' }}><RiDeleteBin2Line className="edit_icon me-2" style={{ color: "BB3504" }} /><span>Delete</span></span></div>

                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
                <hr />
              </tbody>
            </table>



            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={searchedInventory.length}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        </div>
      </div>
      <div>

        <section>
          <div className="container">
            <div className="modal fade" id="editinventory" role="dialog">
              <div className="po_sc_in">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>EDIT INVENTORY</h4>
                    <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                      ×
                    </button>
                  </div>

                  <div className="modal-body">
                    <form onSubmit={submitStaffPop}>
                      <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Material Name:</span>
                          </label>
                          <input name="material_name" type="text" className="form-control"
                            placeholder="Material Name"
                            value={state.material_name}
                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'material_name' }); }}
                            // onChange={(e) => {
                            //   var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                            //   if (sanitizedValue.length > 150) {
                            //     // Handle maximum length violation
                            //     sanitizedValue = sanitizedValue.slice(0, 150);
                            //   }
                            //   // Enforce not allowing same alphabetic character consecutively three times
                            //   const regex = /(.)\1{5,}/g;
                            //   sanitizedValue = sanitizedValue.replace(regex, '');
                            //   dispatch({ type: 'set_value', value: sanitizedValue, key: 'material_name' });
                            // }}
                            required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Make:</span>
                          </label>
                          <input name="make" type="text" className="form-control"
                            placeholder="Make Name"
                            value={state.make}
                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'make' }); }}
                            // onChange={(e) => {
                            //   var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                            //   if (sanitizedValue.length > 150) {
                            //     // Handle maximum length violation
                            //     sanitizedValue = sanitizedValue.slice(0, 150);
                            //   }
                            //   // Enforce not allowing same alphabetic character consecutively three times
                            //   const regex = /(.)\1{5,}/g;
                            //   sanitizedValue = sanitizedValue.replace(regex, '');
                            //   dispatch({ type: 'set_value', value: sanitizedValue, key: 'make' });
                            // }}
                            required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Quantity:</span>
                          </label>
                          {/* <input name="count" type="number" className="form-control"
                            placeholder="No. of Count"
                            // minLength={1} maxLength={5}
                            value={state.stock_in}
                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'stock_in' }); }}
                            required /> */}
                          <input
                            name="count"
                            type="text"
                            className="form-control"
                            placeholder="No. of Count"
                            value={state.stock_in}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              dispatch({ type: 'set_value', value: inputValue, key: 'stock_in' });
                              dispatch({ type: 'set_value', value: inputValue, key: 'backup_stock_in' });
                            }}
                            // onChange={(e) => {
                            //   var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                            //   dispatch({ type: 'set_value', value: sanitizedValue, key: 'stock_in' });
                            //   dispatch({ type: 'set_value', value: inputValue, key: 'backup_stock_in' });
                            // }}
                            required
                          />
                        </div>

                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Stock Out:</span>
                          </label>
                          <input name="count" type="number" className="form-control"
                            placeholder="No. of Count"
                            value={state.stock_out}
                            onChange={(e) => {
                              dispatch({ type: 'set_value', value: e.target.value, key: 'stock_out' });
                              if ((e.target.value !== '' && e.target.value !== undefined) &&
                                (Number(state.backup_stock_in) >= Number(e.target.value))) {
                                state.stock_in = state.backup_stock_in - e.target.value
                              } else {
                                state.stock_in = state.backup_stock_in
                              }
                            }}
                            required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Category:</span>
                          </label>
                          {(InventorycategoidList != null) ?
                            <>
                              <select
                                className="form-select"
                                style={{ fontSize: 14, height: 44, width: "100%" }}
                                required
                                value={state.category_id}
                                onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'category_id' }); }}
                              >
                                {InventorycategoidList.map((list, index) =>
                                  <option value={list['id']}>{list['name']}</option>
                                )}
                              </select>
                              {state.category_id === '3' || state.category_id === 'Equipments' ?
                                <select
                                  className="form-select mt-5"
                                  style={{ fontSize: 14, height: 44, width: "100%" }}
                                  required
                                  value={state.condition_status}
                                  onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'condition_status' }); }}
                                >
                                  <option value="Good">Good</option>
                                  <option value="Fair">Fair</option>
                                  <option value="Poor">Poor</option>
                                  <option value="Defective">Defective</option>
                                </select>
                                : null
                              }
                            </>
                            : null}
                        </div>

                      </div>
                      <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button>
                    </form>
                  </div>
                  <div className="modal-footer">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );

};


export default InventoryList;