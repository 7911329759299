import React, { useState, useEffect } from "react"
import Card from "components/card"
import './style.css'
import Diagnosis from "./diagnosis";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";
import Summary from "./summary";
import Payment from "./payment";
import Prescription from "./prescription";
import Medicalinventory from "./medicalinventory";
import MedicalReport from "./medicalreport";
import { FaPhone } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import TreatmentPlanMap from "./TreatmentPlanMap";
import DiagnosisSummary from "./DiagnosisSummary";

const PatientDetailComponent = (props) => {


  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };


  const userRoleId = localStorage.getItem('role_id');
  const patientuserdata = JSON.parse(localStorage.getItem("userdata"))
  console.log(patientuserdata);

  const location = useLocation();
  // const userData = location.state;
  const userData = {};

  const data = location.state
  console.log(data)
  console.log(location.state)
  // Assign a value based on location.state
  if (location.state) {
    Object.assign(userData, location.state);
    // Object.assign(userData, { 'gender': userData.patient_gender });
  }

  console.log(userData)
  console.log("gender = ", localStorage.getItem('gender'));

  if (userData && userData?.gender) {
    localStorage.setItem("gender", userData?.gender);
  } else {
    // Handle the case where userData or userData.gender is null or undefined
    console.error("userData or userData.gender is null or undefined");
  }



  const { ...rest } = props;
  // const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

  // const [open, setOpen] = React.useState(true);
  // const [actTab, setActTab] = useState('Diagnosis')
  // // const [actTab, setActTab] = useState('')


  // const handleTab = (e) => {
  //   e.preventDefault()
  //   setActTab(e.target.name)
  // }

  const [open, setOpen] = React.useState(true);
  const [actTab, setActTab] = useState(localStorage.getItem('activeTab') || 'Diagnosis');
  // const userData = {}; // Assuming you have userData defined

  useEffect(() => {
    // Save the active tab to localStorage when it changes
    localStorage.setItem('activeTab', actTab);
  }, [actTab]);

  const handleTab = (e) => {
    e.preventDefault();
    setActTab(e.target.name);
  };

  function changeTab(tabName) {
    setActTab(tabName);
  }


  return (
    <React.Fragment>
      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
        (
          <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
            <Sidebar open={open} onClose={() => setOpen(false)} />
            <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">

              <main
                className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
              >
                {/* Routes */}
                <div className="h-full">
                  <Navbar
                    onOpenSidenav={() => setOpen(true)}
                    logoText={"Kirthika Dental Care"}
                    brandText="Patient's Detail"
                    // secondary={getActiveNavbar(routes)}
                    {...rest}
                  />

                  <div className="cards" style={{ backgroundColor: "#FFFFFF" }}>
                    <Card className="card card--1">
                      <ul>
                        <li className="patient_name"> {userData['first_name']} </li>
                        <li className="mb-2">Reg no:<span> {userData['patient_reg_no']}</span></li>
                        <li style={{ display: "flex" }}><FaPhone /><span className="ms-3" style={{ marginTop: "-4px" }}>{userData['phone']}</span></li>
                        <li style={{ display: "flex" }}><AiOutlineMail /><span className="ms-3" style={{ marginTop: "-6px" }}>{userData['email']}</span></li>
                      </ul>
                    </Card>
                    <Card className="card card--2">
                      <ul className="list-flex">
                        <div>
                          <li className="sub-title">DOB</li>
                          <li className="sub-text">
                            {userData['dob'] ? userData['dob'] : '-'}
                          </li>
                        </div>
                        <div>
                          <li className="sub-title">Profession</li>
                          <li className="sub-text">
                            {userData['profession'] ? userData['profession'] : '-'}</li>
                        </div>
                      </ul>
                    </Card>
                    <Card className="card card--3">
                      <ul className="list-flex">
                        <div>
                          <li className="sub-title">Address</li>
                          <li className="sub-text">
                            {userData['address'] ? userData['address'] : '-'}</li>
                        </div>
                        <div>
                          <li className="sub-title">Age</li>
                          <li className="sub-text">
                            {userData['age'] ? userData['age'] : '-'}</li>
                        </div>
                      </ul>
                    </Card>
                    <Card className="card card--4">
                      <ul className="list-flex">
                        <div>
                          <li className="sub-title">Primary Contact Name</li>
                          {/* <li className="sub-text">{userData['primary_contact_name']}</li> */}
                          <li className="sub-text">
                            {userData['primary_contact_name'] ? userData['primary_contact_name'] : '-'}
                          </li>

                        </div>
                        <div>
                          <li className="sub-title">Relationship</li>
                          <li className="sub-text">
                            {userData['relationship'] ? userData['relationship'] : '-'}</li>
                        </div>
                      </ul>
                    </Card>
                    <Card className="card card--5">
                      <ul className="list-flex">
                        <div>
                          <li className="sub-title">Gender</li>
                          <li className="sub-text">
                            {userData['gender'] ? userData['gender'] : '-'}</li>
                        </div>
                        <div>
                          <li className="sub-title">Contact No.</li>
                          <li className="sub-text">
                            {userData['contact_mobile'] ? userData['contact_mobile'] : '-'}</li>
                        </div>
                      </ul>
                    </Card>
                  </div>


                  <div className="nav-tab">
                    <ul className="nav nav-tabs">
                      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2') ?
                        (
                          <li className="nav-item">
                            <a className={actTab === 'Diagnosis' ? `nav-item act` : 'nav-item'} href="#" name='Diagnosis' onClick={handleTab}>Diagnosis</a>
                          </li>
                        ) : null}
                      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2') ?
                        (
                          <li className="nav-item">
                            <a className={actTab === 'Treatment Plan' ? `nav-item act` : 'nav-item'} href="#treatment" name='Treatment Plan' onClick={handleTab}>Treatment Plan</a>
                          </li>
                        ) : null}
                      <li className="nav-item">
                        <a className={actTab === 'Summary' ? `nav-item act` : 'nav-item'} href="#" name='Summary' onClick={handleTab}>Summary</a>
                      </li>
                      <li className="nav-item">
                        <a className={actTab === 'Medical Report' ? `nav-item act` : 'nav-item'} href="#" name='Medical Report' onClick={handleTab}>Medical Report</a>
                      </li>
                      <li className="nav-item">
                        <a className={actTab === 'Prescription' ? `nav-item act` : 'nav-item'} href="#" name='Prescription' onClick={handleTab}>Prescription</a>
                      </li>
                      <li className="nav-item">
                        <a className={actTab === 'Payment' ? `nav-item act` : 'nav-item'} href="#" name='Payment' onClick={handleTab}>Payment</a>
                      </li>
                      {/* <li className="nav-item">
                    <a className={actTab === 'Appoinment' ? `nav-item act` : 'nav-item'} href="#" name='Appoinment' onClick={handleTab}>Appoinment</a>
                  </li> */}
                    </ul>

                  </div>

                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2') ?
                    (
                      <div className={actTab === 'Diagnosis' ? `visible` : 'hidden'}>
                        <Diagnosis tabName={changeTab} pData={userData} />
                      </div>
                    ) : null}

                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2') ?
                    (
                      <div className={actTab === 'Treatment Plan' ? `visible` : 'hidden'}>
                        <TreatmentPlanMap pid={userData.id} />
                      </div>
                    ) : null}

                  <div className={actTab === 'Summary' ? `visible` : 'hidden'}>
                    {/* <SummaryAdmin/> */}
                    <DiagnosisSummary pData={userData} patient_id={userData.id} />
                  </div>

                  <div className={actTab === 'Medical Report' ? `visible` : 'hidden'}>
                    <MedicalReport patient_id={userData.id} />
                  </div>

                  <div className={actTab === 'Prescription' ? `visible` : 'hidden'}>
                    < Prescription patient_id={userData.id} reg={userData.patient_reg_no} pData={userData} />
                  </div>

                  <div className={actTab === 'Payment' ? `visible` : 'hidden'}>
                    <Payment pData={userData} reg={userData.patient_reg_no} patient_id={userData.id} />
                  </div>

                </div>





              </main>
            </div>
          </div>
        ) : null}


      {/* ---------------------------------------------------------------------------------------------------------- */}


      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4') ?
        (

          <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
            {/* <Sidebar open={open} onClose={() => setOpen(false)} /> */}
            <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">

              <main
                className={`h-full flex-none transition-all`}
              // className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
              >
                {/* Routes */}
                <div className="h-full">
                  {/* <Navbar
                    onOpenSidenav={() => setOpen(true)}
                    logoText={"Kirthika Dental Care"}
                    brandText="Patient Detail"
                    // secondary={getActiveNavbar(routes)}
                    {...rest}
                  /> */}
                  <div className="h-full">

                    {/* ------------------------------ for patient login detail card ----------------------------------------- */}

                    <div className="cards" style={{ backgroundColor: "#FFFFFF" }}>
                      <Card className="card card--1">
                        <ul>
                          <li className="patient_name"> {patientuserdata.user.first_name}</li>
                          <li className="mb-2">Reg no:<span> {patientuserdata.user.employee_number}</span></li>
                          <li style={{ display: "flex" }}><FaPhone /><span className="ms-3" style={{ marginTop: "-4px" }}>{patientuserdata.user.phone}</span></li>
                          <li style={{ display: "flex" }}><AiOutlineMail /><span className="ms-3" style={{ marginTop: "-6px" }}>{patientuserdata.user.email}</span></li>
                        </ul>
                      </Card>
                      <Card className="card card--2">
                        <ul className="list-flex">
                          <div>
                            <li className="sub-title">DOB</li>
                            <li className="sub-text">{patientuserdata.user.dob ? patientuserdata.user.dob : '-'}</li>
                            {/* {userData['dob'] ? userData['dob'] : '-'}</li> */}
                          </div>
                          <div>
                            <li className="sub-title">Profession</li>
                            {/* <li className="sub-text">{patientuserdata.user.profession}</li> */}
                            <li className="sub-text">{patientuserdata.user.profession ? patientuserdata.user.profession : '-'}</li>
                          </div>
                        </ul>
                      </Card>
                      <Card className="card card--3">
                        <ul className="list-flex">
                          <div>
                            <li className="sub-title">Address</li>
                            {/* <li className="sub-text">{patientuserdata.user.address}</li> */}
                            <li className="sub-text">{patientuserdata.user.address ? patientuserdata.user.address : '-'}</li>
                          </div>
                          <div>
                            <li className="sub-title">Age</li>
                            {/* <li className="sub-text">{patientuserdata.user.age}</li> */}
                            <li className="sub-text">{patientuserdata.user.age ? patientuserdata.user.age : '-'}</li>
                          </div>
                        </ul>
                      </Card>
                      <Card className="card card--4">
                        <ul className="list-flex">
                          <div>
                            <li className="sub-title">Primary Contact Name</li>
                            {/* <li className="sub-text">{patientuserdata.user.primary_contact_name}</li> */}
                            <li className="sub-text">{patientuserdata.user.primary_contact_name ? patientuserdata.user.primary_contact_name : '-'}</li>
                          </div>
                          <div>
                            <li className="sub-title">Relationship</li>
                            {/* <li className="sub-text">{patientuserdata.user.relationship}</li> */}
                            <li className="sub-text">{patientuserdata.user.relationship ? patientuserdata.user.relationship : '-'}</li>
                          </div>
                        </ul>
                      </Card>
                      <Card className="card card--5">
                        <ul className="list-flex">
                          <div>
                            <li className="sub-title">Gender</li>
                            {/* <li className="sub-text">{patientuserdata.user.gender}</li> */}
                            <li className="sub-text">{patientuserdata.user.gender ? patientuserdata.user.gender : '-'}</li>
                          </div>
                          <div>
                            <li className="sub-title">Contact No.</li>
                            {/* <li className="sub-text">{patientuserdata.user.contact_mobile}</li> */}
                            <li className="sub-text">{patientuserdata.user.contact_mobile ? patientuserdata.user.contact_mobile : '-'}</li>
                          </div>
                        </ul>
                      </Card>
                    </div>

                    <div className="nav-tab">
                      <ul className="nav nav-tabs">


                        <li className="nav-item">
                          <a className={actTab === 'Medical Report' ? `nav-item act` : 'nav-item'} href="#" name='Medical Report' onClick={handleTab}>Medical Report</a>
                        </li>
                        <li className="nav-item">
                          <a className={actTab === 'Summary' ? `nav-item act` : 'nav-item'} href="#" name='Summary' onClick={handleTab}>Summary</a>
                        </li>
                        <li className="nav-item">
                          <a className={actTab === 'Prescription' ? `nav-item act` : 'nav-item'} href="#" name='Prescription' onClick={handleTab}>Prescription</a>
                        </li>
                        <li className="nav-item">
                          <a className={actTab === 'Payment' ? `nav-item act` : 'nav-item'} href="#" name='Payment' onClick={handleTab}>Payment</a>
                        </li>
                      </ul>

                    </div>
                    <div className={actTab === 'Medical Report' ? `visible` : 'hidden'}>
                      <MedicalReport patient_id={patientuserdata.user.id} />
                    </div>
                    <div className={actTab === 'Summary' ? `visible` : 'hidden'}>
                      {/* <SummaryAdmin/> */}
                      <DiagnosisSummary patient_id={patientuserdata.user.id} />
                    </div>
                    <div className={actTab === 'Prescription' ? `visible` : 'hidden'}>
                      < Prescription patient_id={patientuserdata.user.id} />
                    </div>

                    <div className={actTab === 'Payment' ? `visible` : 'hidden'}>
                      <Payment pData={patientuserdata} patient_id={patientuserdata.user.id} />
                    </div>

                  </div>
                </div>





              </main>
            </div>
          </div>
        ) : null}


    </React.Fragment>

  );
};


export default PatientDetailComponent;