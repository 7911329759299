import React, { useEffect, useState } from "react"
import * as con from '../../repository/api_calls';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useReducer } from 'react';
import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';
import { FaEllipsis } from "react-icons/fa6";


function reducer(state, action) {
  if (action.type === 'set_value') {
    state[action.key] = action.value;
    console.log(action.value)
    return {
      // prescription_category_id: state.prescription_category_id,
      category_name: state.category_name,
      category_id: state.category_id,
      sub_name: state.sub_name,
      unit: state.unit,
      available_stock: state.available_stock,
      price: state.price,
      id: state.id
    };
  }
}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination">
      <ul className="pagination-list">
        {pageNumbers.map((number) => (
          <li key={number} className="pagination-item">
            <a
              href="#"
              onClick={() => paginate(number)}
              className={`pagination-link ${currentPage === number ? 'active' : ''}`}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};




const PharmacyList = () => {



  const [medicalInventory, setMedicalInventory] = useState([])
  console.log(medicalInventory)
  console.log(setMedicalInventory)
  const [searchTerm, setSearchTerm] = useState("");

  const searchedPharmacy = medicalInventory.filter((c) =>
    c.sub_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPharmacy = searchedPharmacy.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);



  // ------------------------------- Pharmacylist API -----------
  // ------------------------------- add Sub - Catagory ------------

  const [state, dispatch] = useReducer(reducer, {
    category_name: '', category_id: '', sub_name: '', unit: 'ML', available_stock: '', price: '', id: ''
  })

  // ------------------------------- add Sub - Catagory ------------

  const submitAddMedicalSubCatago = (event) => {
    event.preventDefault();
    if (state.id != '') {
      addSubcat(state, 'edit');
      setDropdownOpen(false)
    } else {
      addSubcat(state, 'add');
      setDropdownOpen(false)
    }
  }

  async function addSubcat(SubcaData, type) {
    if (type == 'add') {
      const response = await con.AddPrescriptSubCata((JSON.stringify(SubcaData)));
      console.log(response)

      if (response['status'] === true) {
        let afterAdded = [...medicalInventory];
        afterAdded.push(response['data']);
        setMedicalInventory(afterAdded);
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        window.location.reload()
      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });
      }

    } else {
      const response = await con.UpdateMedicalInvenAPI((JSON.stringify(SubcaData)));
      console.log(response)
      if (response['status'] === true) {
        let afterAdded = medicalInventory.map(item => {
          if (item.id === state.id) {
            return response.data;
          }
          return item;
        });
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'category_name' })
        dispatch({ type: 'set_value', value: '', key: 'category_id' })
        dispatch({ type: 'set_value', value: '', key: 'sub_name' })
        dispatch({ type: 'set_value', value: '', key: 'unit' })
        dispatch({ type: 'set_value', value: '', key: 'available_stock' })
        dispatch({ type: 'set_value', value: '', key: 'price' })
        setMedicalInventory(afterAdded);
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        window.location.reload()
      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });

      }
    }
  }

  // ------------------ drop down list ------------
  const [pharmacycategoidList, setPharmacyCateidList] = React.useState();

  useEffect(() => {
    getPharmacyCat()
  }, [])

  async function getPharmacyCat() {
    const response = await con.getPharmacyCategoryList();
    console.log(response)
    if (response['status'] === true) {
      setPharmacyCateidList(response['data'])
      dispatch({ type: 'set_value', value: response['data'][0]['id'], key: 'category_id' });
    }
  }

  // ------------------------------- pharmacy  list API -----------

  useEffect(() => {
    getmedicalInventoryLists();
  }, [])

  async function getmedicalInventoryLists() {
    const response = await con.MedicalInventoryListsAPI();
    if (response['status'] === true) {
      setMedicalInventory(response['data'].reverse());

    }
  }

  // ----------- Change Table Heading and Button Dynamically -------------------
  const [heading, setHeading] = useState('Default Heading');
  const [button, setButton] = useState('Default Heading');
  const [addPharm, setaddPharm] = useState(false)
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleButtonClick = (newHeading, newButton) => {
    setHeading(newHeading);
    setButton(newButton)
    setDropdownOpen(!isDropdownOpen);
    setaddPharm(!addPharm)
    dispatch({ type: 'set_value', value: '', key: 'id' })
    dispatch({ type: 'set_value', value: '', key: 'category_name' })
    dispatch({ type: 'set_value', value: '', key: 'category_id' })
    dispatch({ type: 'set_value', value: '', key: 'sub_name' })
    dispatch({ type: 'set_value', value: '', key: 'unit' })
    dispatch({ type: 'set_value', value: '', key: 'available_stock' })
    dispatch({ type: 'set_value', value: '', key: 'price' })
  };

  // ---------------- for edit function -------

  function handleForEditButtonClick(newHeading, newButton, inlist) {

    setHeading(newHeading);
    setButton(newButton)
    setDropdownOpen(true);
    dispatch({ type: 'set_value', value: inlist.id, key: 'id' })
    // dispatch({ type: 'set_value', value: inlist.prescription_category_id, key: 'prescription_category_id' })
    dispatch({ type: 'set_value', value: inlist.category_name, key: 'category_name' })
    dispatch({ type: 'set_value', value: inlist.category_id, key: 'category_id' })
    dispatch({ type: 'set_value', value: inlist.sub_name, key: 'sub_name' })
    dispatch({ type: 'set_value', value: inlist.unit, key: 'unit' })
    dispatch({ type: 'set_value', value: inlist.available_stock, key: 'available_stock' })
    dispatch({ type: 'set_value', value: inlist.price, key: 'price' })
  };

  // ---------------- action dropdown ----
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // ------------------------- Delete API --------

  async function getMedicalInveDelete(deleteMedicalInve) {
    const response = await con.MedicalInventorDeleteAPI(deleteMedicalInve);
    console.log(response)
    if (response['status'] === true) {
      let afterAdded = medicalInventory.filter(item => item.id !== deleteMedicalInve);
      setMedicalInventory(afterAdded);
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
  }


  return (
    <React.Fragment>
      <ToastContainer autoClose={3000} />
      <button type="button" className="btn btn-primary my-5" aria-hidden="true"
        data-toggle="modal"
        data-target="#addpharmacy"
        onClick={() => handleButtonClick('Add A New Pharmacy', 'Submit')}
        style={{ cursor: "pointer", float: "right", marginRight: "40px", backgroundColor: "#145BD9" }}
      ><span className="me-1" style={{ fontSize: "20px" }}>+</span>ADD PHARMACY</button>

      <div style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

        <input className="form-control searchBar" type="text"
          placeholder="Global Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} />

        <table className="bordered-table">
          <thead className="tablehead" >
            <tr style={{ borderRadius: "10px" }}>
              <th>S.No</th>
              <th>Category</th>
              <th>Medicine Name</th>
              <th>Price</th>
              <th>Stock Available</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {currentPharmacy.map((inlist, index) => {
              const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
              return (

                <tr key={index}>
                  {/* <td>{index + 1}</td> */}
                  <td>{continuousIndex}</td>
                  <td>{inlist.category_name}</td>
                  <td>{inlist.sub_name}</td>
                  <td>{inlist.price}</td>
                  <td>{inlist.available_stock}</td>
                  <td><RiEdit2Line className="edit_icon"
                    aria-hidden="true"
                    data-toggle="modal"
                    data-target="#addpharmacy"
                    onClick={() => handleForEditButtonClick('EDIT', 'Update', inlist)}
                  /></td>
                  <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                    onClick={() => getMedicalInveDelete(inlist.id)} /></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={searchedPharmacy.length}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>

      <div>
        <section>
          <div className="container">
            <div className="modal fade" id="addpharmacy" role="dialog">
              <div className="po_sc_in">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>{heading}</h4>
                    <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                      ×
                    </button>
                  </div>

                  <div className="modal-body">
                    <form onSubmit={submitAddMedicalSubCatago}>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="Catagory">Category:</label>

                          {(pharmacycategoidList != null) ?
                            <select
                              className="form-select"
                              id="sel10"
                              style={{ fontSize: 14, height: 44, width: "100%" }}
                              required
                              value={state.category_id}
                              onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'category_id' }); }}
                            >
                              <option>Select Category.......</option>
                              {pharmacycategoidList.map((list, index) =>
                                <option value={list['id']}>{list['name']}</option>
                              )}

                            </select>
                            : null}

                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="CatagoryInput">Medicine Name:</label>
                          <input type="text" id="CatagoryInput" placeholder="Enter Medicine Name" className="form-control"
                            value={state.sub_name}
                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'sub_name' }); }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="stock">Stock:</label>
                          <input type="text" id="stock" placeholder="Enter Stock" className="form-control"
                            value={state.available_stock}
                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'available_stock' }); }}
                            onChange={(e) => {
                              var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                              dispatch({ type: 'set_value', value: sanitizedValue, key: 'available_stock' });
                            }}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="PriceInput">Price:</label>
                          <input type="text" id="PriceInput" placeholder="Enter Price" className="form-control"
                            value={state.price}
                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'price' }); }}
                            // onChange={(e) => {
                            //   var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                            //   dispatch({ type: 'set_value', value: sanitizedValue, key: 'price' });
                            // }}
                            onChange={(e) => {
                              // Replace non-numeric characters and allow decimal point
                              var sanitizedValue = e.target.value.replace(/[^0-9.]/g, '');
                            
                              // Ensure there's only one decimal point
                              sanitizedValue = sanitizedValue.replace(/(\..*)\./g, '$1');
                            
                              // Dispatch the sanitized value
                              dispatch({ type: 'set_value', value: sanitizedValue, key: 'price' });
                            }}
                            
                          />
                        </div>
                      </div>

                      <div >
                        <button type="submit" className="btn btn-primary" style={{ margin: "auto", display: "block" }}>{button}</button>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>


    </React.Fragment>
  )
}

export default PharmacyList