import StaffList from "views/staff/stafflist";

import React from "react";
const StaffListShow = (prop) => {

    return (
        <div>

            <StaffList />

        </div>

    );
};
export default StaffListShow;