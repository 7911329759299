import React, { useState, useEffect } from "react"
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";
import Card from "components/card"
import './doctorcss.css';
import { FaPhone } from 'react-icons/fa';
import { Link, useLocation } from "react-router-dom";
import * as con from '../../repository/api_calls';

const DoctorDetail = (props) => {

  const userRoleId = localStorage.getItem('role_id');
  console.log(userRoleId)

  const location = useLocation();
  const userData = location.state;

  console.log('doc userdata');
  console.log(userData);

  const docId = userData.id

  console.log('doc detail');
  console.log(docId);

  const { ...rest } = props;

  const [open, setOpen] = React.useState(true);

  const [appointmentLists, setAppointmentList] = useState([])

  useEffect(() => {
    getappointmentLists();
  }, [docId])

  async function getappointmentLists() {
    const responselist = await con.appointmentList();
    console.log(responselist)

    if (responselist['status'] === true) {
      const filteredAppointments = responselist['data'].filter(appointment =>
        // new Date(appointment.appointment_date).toDateString() === new Date(props.fdate).toDateString()
        appointment.doctor_id === docId
      );
      setAppointmentList(filteredAppointments);
    }
  }


  return (
    <React.Fragment>
      <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
        <Sidebar open={open} onClose={() => setOpen(false)} />
        <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
          <main
            className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
          >
            {/* Routes */}
            <div className="h-full">
              <Navbar
                onOpenSidenav={() => setOpen(true)}
                logoText={"Kirthika Dental Care"}
                brandText="Doctor Detail's"
                {...rest}
              />

              <div className="cards" style={{ backgroundColor: "#FFFFFF" }}>

                <Card className="card card--1 detialbluec">
                  <ul>
                    <li className="patient_name"> {userData['first_name']}</li>
                    <li > {userData['department_name']}</li>
                    <li style={{ display: "flex" }}><FaPhone /><span className="ms-3" style={{ marginTop: "-4px" }}>{userData['phone']}</span></li>
                    <li style={{ display: "flex", marginLeft: "-22px" }}><span className="ms-3" style={{ marginTop: "-6px", marginLeft: "-10px" }}>{userData['email']}</span></li>

                  </ul>
                </Card>
                <Card className="card card--2">
                  <ul className="list-flex">
                    <div>
                      <li className="sub-title">Full Name</li>
                      <li className="sub-text"><span>{userData['first_name']}</span> <span style={{ marginLeft: '3px' }}>{userData['surname']}</span></li>
                    </div>
                    <div>
                      <li className="sub-title">Profession</li>
                      <li className="sub-text">{userData['department_name']}</li>
                    </div>
                  </ul>
                </Card>
                <Card className="card card--3">
                  <ul className="list-flex">
                    <div>
                      <li className="sub-title">Address</li>
                      <li className="sub-text">{userData['address']}</li>
                    </div>
                    <div>
                      <li className="sub-title">Qualification</li>
                      <li className="sub-text">{userData['qualification']}</li>
                    </div>
                  </ul>
                </Card>
                <Card className="card card--4">
                  <ul className="list-flex">
                    <div>
                      <li className="sub-title">Primary Contact Name</li>
                      <li className="sub-text">{userData['phone']}</li>
                    </div>
                    <div>
                      <li className="sub-title">Date of Birth</li>
                      <li className="sub-text">{userData['dob']}</li>
                    </div>
                  </ul>
                </Card>
                <Card className="card card--5">
                  <ul className="list-flex">
                    <div>
                      <li className="sub-title">Gender</li>
                      <li className="sub-text">{userData['gender']}</li>
                    </div>
                    <div>
                      <li className="sub-title">Contact No.</li>
                      <li className="sub-text">{userData['phone']}</li>
                    </div>
                  </ul>
                </Card>
              </div>


              <section className="mt-5">
                <table className="bordered-table">
                  <thead className="tablehead" >
                    <tr style={{ borderRadius: "10px" }}>

                      <th>Appointment Date</th>
                      <th>Appointment Time</th>
                      <th>Patient Id</th>
                      <th>Appointment Id</th>
                      <th>Patient Name</th>
                      <th>TREATMENT</th>
                      <th>STATUS</th>

                    </tr>
                  </thead>
                  <tbody>

                    {(appointmentLists !== undefined && appointmentLists.length !== 0 && appointmentLists !== null) ?

                      (appointmentLists.map((appointment, index) => {
                        console.log(appointment)
                        return (

                          <tr key={appointment.id}>
                            <td>{new Date(appointment.appointment_date).toLocaleDateString()}</td>
                            <td>{appointment.appointment_time}</td>
                            <td>{appointment.patient_id}</td>
                            <td>{appointment.appointment_id}</td>
                            <td>{appointment.patient_first_name}</td>
                            {/* <td>{appointment.patient_treatment[0].problem !== undefined ? appointment.patient_treatment[0].problem.name : "-"}</td> */}
                            {/* <td>{JSON.parse(appointment.patient_treatment).problem.name}</td> */}
                            <td>
                              {appointment.patient_treatment &&
                                JSON.parse(appointment.patient_treatment).problem &&
                                JSON.parse(appointment.patient_treatment).problem.name
                                ? JSON.parse(appointment.patient_treatment).problem.name
                                : "N/A"}
                            </td>

                            {/* <td>{JSON.parse(appointment.patient_treatment).items.map((t) => t.name)}</td> */}
                            <td>{appointment.appointment_status}</td>

                          </tr>
                        )
                      }))

                      : (
                        <tr>
                          <td colSpan="5">You have no appointments.</td>
                        </tr>
                      )}

                  </tbody>
                </table>

              </section>

            </div>
          </main>
        </div>
      </div >


    </React.Fragment >

  );
};


export default DoctorDetail;