import React, { useState, useEffect } from "react";
import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
import * as con from '../../../repository/api_calls'
import Dropdown from "components/dropdown";
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";
import 'react-toastify/dist/ReactToastify.css';




function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            diagnosis_type_id: state.diagnosis_type_id,
            treatment_plan: state.treatment_plan,
            diagnosis_id: state.diagnosis_id,
            id: state.id,
            diagnosisLists: state.diagnosisLists
        };
    }


}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};


const Treatmentplan = (props) => {

    const { ...rest } = props;

    // ------------------------------- add diagnosis ------------

    const [state, dispatch] = useReducer(reducer, {
        diagnosis_id: '', treatment_plan: '', diagnosis_type_id: '', id: '', diagnosisLists: []
    })
    const [diagnosisLists, setDiagnosisList] = useState([]);
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [diagnosistypeList, setDiagnosiTypeList] = useState([]);
    const [open, setOpen] = useState(true);

    const addTreatplan = (event) => {
        event.preventDefault();
        if (state.id !== '') {
            addtplan(state, 'edit');
            setDropdownOpen(false)
        } else {
            addtplan(state, 'add');
            setDropdownOpen(false)
        }
    }

    async function addtplan(addtreatplaData, type) {
        if (type === 'add') {
            const response = await con.addTreatplan((JSON.stringify(addtreatplaData)));
            console.log(response)
            // let afterAdded = [...treatmentplanList];
            // afterAdded.push(response['data']);
            // console.log(afterAdded)
            // setTreatplanList(afterAdded);
            if (response['status'] === true) {
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        } else {
            const response = await con.UpdateTreatPlanAPI((JSON.stringify(addtreatplaData)));
            console.log(response)
            if (response['status'] === true) {
                // let afterAdded = treatmentplanList.map(item => {
                //     if (item.id === state.id) {
                //         return response.data;
                //     }
                //     return item;
                // });
                dispatch({ type: 'set_value', value: '', key: 'id' })
                dispatch({ type: 'set_value', value: '', key: 'diagnosis_id' })
                dispatch({ type: 'set_value', value: '', key: 'treatment_plan' })
                dispatch({ type: 'set_value', value: '', key: 'diagnosis_type_id' })
                // setTreatplanList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        }
    }


    // -----------------------------  add method button dropdown

    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
        dispatch({ type: 'set_value', value: '', key: 'id' });
        dispatch({ type: 'set_value', value: '', key: 'diagnosis_id' });
        dispatch({ type: 'set_value', value: '', key: 'treatment_plan' });
        dispatch({ type: 'set_value', value: '', key: 'diagnosis_type_id' });
    };

    //------------------------for cancel action
    const handleCancel = () => {

        setDropdownOpen(false);
        dispatch({ type: 'set_value', value: '', key: 'id' });
        dispatch({ type: 'set_value', value: '', key: 'diagnosis_id' });
        dispatch({ type: 'set_value', value: '', key: 'treatment_plan' });
        dispatch({ type: 'set_value', value: '', key: 'diagnosis_type_id' });
    };

    function handleForEditButtonClick(plan) {
        setDropdownOpen(true);
        dispatch({ type: 'set_value', value: plan.id, key: 'id' });
        dispatch({ type: 'set_value', value: plan.diagnosis_id, key: 'diagnosis_id' });
        dispatch({ type: 'set_value', value: plan.treatment_plan, key: 'treatment_plan' });
        dispatch({ type: 'set_value', value: plan.diagnosis_type_id, key: 'diagnosis_type_id' });
    }

    // ------------------ drop down 1 list ------------


    useEffect(() => {
        getDiagTyp()
    }, [])

    async function getDiagTyp() {
        const response = await con.getDiagnosisTypeList();
        console.log(response.data)
        if (response['status'] === true) {
            setDiagnosiTypeList(response['data'])
            dispatch({ type: 'set_value', value: response['data'][0]['id'], key: 'diagnosis_type_id' });
            getDiagnosisLists(response['data'][0]['id'])
        }
    }
    console.log(diagnosistypeList)

    // ------------------ drop down 2 list ------------

    const fun = (id) => {
        getDiagnosisLists(id);
    }

    async function getDiagnosisLists(id) {
        const response = await con.DiagnosisListAPI();
        console.log(response.data)
        if (response['status'] === true) {
            const filteredId = response.data.filter((item) => item.diagnosis_type.toString() === id.toString());
            console.log(filteredId)
            setDiagnosisList(filteredId)
            dispatch({ type: 'set_value', value: filteredId, key: 'diagnosisLists' })
            if (filteredId.length > 0) {
                dispatch({ type: 'set_value', value: filteredId[0].id, key: 'diagnosis_id' });
            } else {
                console.error('filteredId is empty');
            }
        }

    }
    console.log(diagnosisLists)

    // ------------------------------- treatment planlist API -----------


    const [treatmentplanList, setTreatplanList] = useState([])


    useEffect(() => {
        treatmentLists();
    }, [])

    async function treatmentLists() {
        const response = await con.treatmentplanList();
        if (response['status'] === true) {
            setTreatplanList(response['data']);
        }
    }

    // ------------------------- Delete API --------

    async function getTreatmPlanDelete(deleteTreatplan) {
        const response = await con.TrePlanDeleteAPI(deleteTreatplan);
        console.log(response)
        let afterAdded = treatmentplanList.filter(item => item.id !== deleteTreatplan);
        setTreatplanList(afterAdded);
        if (response['status'] === true) {
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });

        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }


    }


    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currenttreatmentplanList = treatmentplanList.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);




    return (
        <React.Fragment>
            <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
                <Sidebar open={open} onClose={() => setOpen(false)} />
                <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                    <main
                        className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
                    >
                        {/* Routes */}
                        <div className="h-full">
                            <Navbar
                                onOpenSidenav={() => setOpen(true)}
                                logoText={"Kirthika Dental Care"}
                                brandText="Treatment Plan"
                                {...rest}
                            />

                            <div>
                                < button type="submit"
                                    className="btn btn-primary my-5"
                                    style={{ height: "auto", width: "auto", backgroundColor: "#5660d9", marginLeft: "18px" }}
                                    onClick={handleButtonClick}
                                >+  Treatment Plan
                                </button>
                                {isDropdownOpen && (

                                    <div className="card mb-5" style={{ backgroundColor: "#f5f5f5", borderRadius: "7px", boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)", margin: "10px", padding: "20px" }}>
                                        <form onSubmit={addTreatplan}>
                                            <div className="form-row">
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="language">Diagnosis Type:</label>
                                                    {(diagnosistypeList !=
                                                        null) ?
                                                        <select
                                                            className="form-select"
                                                            id="sel10"
                                                            style={{ fontSize: 14, height: 44, width: "100%" }}
                                                            required
                                                            value={state.diagnosis_type_id}
                                                            onChange={
                                                                (e) => {
                                                                    dispatch({ type: 'set_value', value: e.target.value, key: 'diagnosis_type_id' });
                                                                    fun(e.target.value)
                                                                }
                                                            }
                                                        >
                                                            <option >Select Diagnosis Type.....</option>
                                                            {diagnosistypeList.map((list, index) => (
                                                                <option value={list['id']}>{list['name']}</option>)
                                                            )}

                                                        </select>
                                                        : null}

                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="language">Diagnosis Name:</label>

                                                    <select
                                                        className="form-select"
                                                        id="sel10"
                                                        style={{ fontSize: 14, height: 44, width: "100%" }}
                                                        required
                                                        value={state.diagnosis_id}
                                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'diagnosis_id' }); }}
                                                    >
                                                        <option>Select Diagnosis Name.....</option>
                                                        {state.diagnosisLists.map((list, index) =>

                                                            <option value={list['id']}>{list['name']}</option>

                                                        )}

                                                    </select>

                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="language">Treatment Plan:</label>

                                                    <input type="text" id="diagnosisInput" placeholder="Enter Your Treatment Plan" className="form-control"
                                                        value={state.treatment_plan}
                                                        required
                                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'treatment_plan' }); }}
                                                    />
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <span> <button type="submit" className="btn btn-primary">Save</button></span>
                                                <span className="me-1"> <button type="button" className="btn btn-primary" onClick={handleCancel}>cancel</button></span>

                                            </div>
                                        </form>
                                    </div>


                                )}
                                <table className="bordered-table">
                                    <thead className="tablehead" >
                                        <tr style={{ borderRadius: "10px" }}>
                                            <th>S.No</th>
                                            <th>Diagnosis Type</th>
                                            <th>Diagnosis Name</th>
                                            <th>Treatment Plan</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currenttreatmentplanList.map((plan, index) => {
                                            return (

                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{plan.diagnosis_type_name}</td>
                                                    <td>{plan.diagnosis_name}</td>
                                                    <td>{plan.treatment_plan}</td>
                                                    <td><RiEdit2Line className="edit_icon" onClick={() => { handleForEditButtonClick(plan); }} /></td>
                                                    <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                                                        onClick={() => getTreatmPlanDelete(plan.id)} /></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={treatmentplanList.length}
                                    currentPage={currentPage}
                                    paginate={paginate}
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Treatmentplan