import PharmacyList from "views/pharmacy/pharmacylist";

import React from "react";
  const PharmacyListShow = (prop) => {
 
  return (

  <div>
  <PharmacyList />
  </div>

  );
};
export default PharmacyListShow;