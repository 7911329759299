import InventoryList from "views/inventory/inventorylist";

import React from "react";
const InventoryListShow = (prop) => {

    return (
        <div>

            <InventoryList />

        </div>

    );
};
export default InventoryListShow;