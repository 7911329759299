import React, { useState } from 'react';
import Logo from "assets/img/logo/logo.png";
import { Link } from "react-router-dom";
import * as api from '../../repository/api_calls.jsx';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useReducer } from 'react';


function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            phone: state.phone,
            otp: state.otp
        };
    }
}

const RegisterVerifyOTP = () => {

    const [state, dispatch] = useReducer(reducer, {
        phone: '', otp: ''
    })

    const navigate = useNavigate();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const phone = localStorage.getItem("phone");


    const goBackToFirstForm = () => {
        setIsFormSubmitted(false);
    };


    // ---------------------------------------------- generate otp api -----------

    const handleGenerateOTP = (event) => {
        event.preventDefault();
        RegisterGenerateOTP(state);

    }

    async function RegisterGenerateOTP(forget) {
        const response = await api.OtpRegistrationAPI((JSON.stringify(forget)));
        console.log(response)
        if (response['status'] === true) {

            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setIsFormSubmitted(true);
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }

    // ---------------------------------------------- verify otp api -----------


    const handleVerifyOTP = (event) => {
        event.preventDefault();
        RegistVerfiyOTP(state);


    }


    async function RegistVerfiyOTP(verify) {
        const response = await api.VerifyOtpAPI((JSON.stringify(verify)));
        console.log(response)
        if (response['status'] === true) {

            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            // setIsFormSubmitted2(true);
            setTimeout(() => {
                window.location.href = "/auth";
            }, 800);

        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }

    return (

        <div className='reg_form_fam' style={{ overflowX: "hidden" }}>
            <ToastContainer autoClose={3000} />

            <div className="mt-5">
                <img
                    className=""
                    style={{ height: "90px", width: "150px", display: "block", margin: "0 auto" }}
                    src={Logo}
                    alt="Logo"
                />
            </div>

            <div className="container">

                <div className="row" style={{ marginTop: "50px" }}>
                    <div className="col-md-1 mb-sm-7 mb-4" > </div>
                    <div className="col-md-6 mb-sm-6 mb-4" >
                        <div style={{ backgroundColor: "white", height: "auto", width: "600px", padding: "20px", borderRadius: "10px", boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>

                            <div>
                                {!isFormSubmitted ? (
                                    <form onSubmit={handleGenerateOTP}>
                                        <div className="row">
                                            <h1 className="text-center mb-7" style={{ fontSize: "24px", paddingTop: "20px" }}>Enter Your Number to verify</h1>
                                            <p></p>
                                            <div className="col-md-2 mb-sm-7 mb-4" >
                                            </div>
                                            <div className="col-md-7 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Mobile No:</span>
                                                </label>
                                                <input type="phone" className="form-control"
                                                    name="phone"
                                                    placeholder="Mobile.No"
                                                    minlength="10"
                                                    maxlength="10"
                                                    value={state.phone}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'phone' }); }}
                                                    // required
                                                    style={{ width: "350px" }} />
                                            </div>
                                            <div className="col-md-2 mb-sm-7 mb-4" >
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 mb-4" ></div>
                                            <div className="col-md-10 mb-sm-7 mb-4" >
                                                <button type="submit" className="btn btn-primary" style={{ width: "350px", backgroundColor: "#5660d9" }}>Submit</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2 mb-4" ></div>
                                            <div className="col-md-3 mb-sm-7 mb-4" >
                                            </div>
                                            <div className="col-md-5 mb-sm-7 mb-4">
                                                <Link to="/auth" className='float-end text-primary'>Login</Link>
                                            </div>

                                        </div>
                                    </form>
                                    // ) : !isFormSubmitted2 ? (
                                ) :
                                    <form onSubmit={handleVerifyOTP}>
                                        <div className="row">
                                            <h1 className="text-center mb-7" style={{ fontSize: "24px", paddingTop: "20px" }}>Enter OTP</h1>
                                            <div className="col-md-2 mb-sm-7 mb-4" >
                                            </div>
                                            <div className="col-md-7 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">OTP:</span>
                                                </label>
                                                <input type="otp" className="form-control"
                                                    name="otp"
                                                    placeholder="OTP"
                                                    minlength="6"
                                                    maxlength="10"
                                                    value={state.otp}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'otp' }); }}
                                                    // required
                                                    style={{ width: "350px" }} />
                                            </div>
                                            <div className="col-md-2 mb-sm-7 mb-4" >
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2 mb-4" ></div>
                                            <div className="col-md-10 mb-sm-7 mb-4" >
                                                <button type="submit" className="btn btn-primary" style={{ width: "350px", backgroundColor: "#5660d9" }}>Submit</button>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-2 mb-4" ></div>
                                            <div className="col-md-3 mb-sm-7 mb-4" >
                                                <button onClick={goBackToFirstForm}>Back</button>
                                            </div>
                                            <div className="col-md-5 mb-sm-7 mb-4">
                                                <Link to="/auth" className='float-end text-primary'>Login</Link>
                                            </div>

                                        </div>

                                    </form>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};
export default RegisterVerifyOTP;