import React, { useEffect, useState } from "react"
import * as con from '../../../repository/api_calls';



const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};



const PharmacyIncome = () => {


    // -------------------------------  Pharmacy income list API -----------

    const [PharmacypaymentLists, setPharmacyPaymentList] = useState([])


    useEffect(() => {
        getPharmacyPaymentLists();
    }, [])

    async function getPharmacyPaymentLists() {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
        const response = await con.PharmacyPaymentList((JSON.stringify({ month: currentMonth })));
        if (response['status'] === true) {
            console.log(response.sum)
            setPharmacyPaymentList(response.sum.reverse());
        }
    }

    const [searchTerm, setSearchTerm] = useState("");

    const searchedstationery = PharmacypaymentLists.filter((c) =>
        c.date.includes(searchTerm) || c.data.some((t) => t.patient_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    // const searchedstationery = PharmacypaymentLists.filter((c) =>
    //     (c.date?.includes(searchTerm) || false) ||
    //     c.data?.some((t) => t.patient_name?.toLowerCase()?.includes(searchTerm.toLowerCase()) || false)
    // );


    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentpharmacyincome = searchedstationery.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);



    return (
        <React.Fragment>


            <div style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>
                <input className="form-control searchBar mb-5" type="text"
                    placeholder="Global Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />

                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px", cursor: "pointer" }}>
                            <th>S.No</th>
                            <th>Year & Month</th>
                            <th>Invoice No</th>
                            <th>Patient Name</th>
                            {/* <th>Quantity</th> */}
                            {/* <th>Duration</th> */}
                            <th>Amount</th>
                        </tr>
                    </thead>
                    {currentpharmacyincome.map((pharincom, index) => {
                        const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
                        return (
                            <tr key={index}>
                                <td>{continuousIndex}</td>
                                <td>{pharincom.date}</td>
                                <td>
                                    {pharincom.data.map((t) => (
                                        <p className="p-2"> {t.invoice} <br /></p>
                                    ))}
                                </td>
                                <td>
                                    {pharincom.data.map((t) => (
                                        <p className="p-2"> {t.patient_name} <br /></p>
                                    ))}
                                </td>
                                <td>
                                    {pharincom.data.map((t) => (
                                        <p className="p-2"> {t.paid_amt} <br /></p>
                                    ))}
                                </td>
                            </tr>
                        )
                    })}
                    <tbody>

                        <hr />
                    </tbody>

                </table>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={searchedstationery.length}
                    currentPage={currentPage}
                    paginate={paginate}
                />
            </div>


        </React.Fragment>
    )
}

export default PharmacyIncome