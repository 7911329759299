import React, { useState, useEffect } from "react";
import './style.css';
import * as con from '../../repository/api_calls';

import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
const Prescription = () => {

  const [selectedOption, setSelectedOption] = useState("");
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const [price, setPrice] = useState("");
  const [data, setData] = useState([]);

  const handleSubmit = () => {
    const newData = { category, name, unit, price };
    setData([...data, newData]);
    clearForm();
  };

  const deleteRow = (index) => {
    setData(data.filter((_, i) => i !== index));
  };

  const clearForm = () => {
    setCategory("");
    setName("");
    setUnit("");
    setPrice("");
  };


  // medical inventory dropdown menu
  const [medimenuList, setMedimenuList] = React.useState();






  // ------------------------------- medical inventory   list API -----------

  const [medicalInventory, setMedicalInventory] = useState([])


  useEffect(() => {
    getmedicalInventoryLists();
  }, [])

  async function getmedicalInventoryLists() {
    const response = await con.MedicalInventoryListsAPI();
    if (response['status'] === true) {
      setMedicalInventory(response['data']);
    }
  }



  return (
    <React.Fragment>





      <div className="medicard">

        {/* <h2 className="text-center" style={{ fontWeight: "bold", marginTop: "20px", fontSize: "30px" }}><b>Medical Inventory</b></h2> */}

        <div className="row medirow" >
          <div className="col-md-3" >
            <label>Category</label>



            <select
              className="pres_select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option>Select...</option>
              <option>First Category</option>
              <option>Second Category</option>
            </select>




          </div>
          <div className="col-md-3">
            <label>Name</label>
            <input
              type="text"
              className="pres_select"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <label>Unit</label>
            <select
              className="pres_select"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            >
              <option>ml</option>
              <option>mg</option>
            </select>
          </div>
          <div className="col-md-3">
            <label>Price</label>
            <input
              type="number"
              className="pres_select"

              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>
        <button className="btn btn-primary medibtn" onClick={handleSubmit} >

          Submit
        </button>
      </div>
      <table className="bordered-table mt20">
        <thead className="tablehead">
          <tr>
            <th>Category</th>
            <th>Name</th>
            <th>Unit</th>
            <th>Price</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {medicalInventory.map((inlist, index) => (
            <tr key={index}>
              <td>{inlist.id}</td>
              <td>{inlist.unit}</td>
              <td>{inlist.price}</td>
              <td>{inlist.name}</td>
              <td>
                <RiEdit2Line
                  className="edit_icon"
                  onClick={() => {
                    handleForEditButtonClick(diagnosis);
                  }}
                />
              </td>
              <td>
                <RiDeleteBin2Line
                  className="delete_icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => getDiagnosisDelete(diagnosis.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>





    </React.Fragment >
  )
}

export default Prescription