import React from 'react';
import { useEffect, useState, useReducer, useContext } from 'react';
import * as con from '../../repository/api_calls';

function GeneralDetails(props) {

    const patient = props.pData
    const [detailId, setDetailId] = useState([])

    useEffect(() => {
        MedicalRecordListsAPI(patient.id)

    }, [])

    //  MedicalRecordListsAPI 
    async function MedicalRecordListsAPI(id) {
        const response = await con.MedicalRecordListsAPI();
        console.log('genaral detail');
        console.log(response['data'])
        if (response['status'] === true) {

            const filteredId = response.data.filter((item) => item.patient_id === id);
            console.log(filteredId)
            setDetailId(filteredId)
        }
    }

    const gender = localStorage.getItem('gender');

    return (
        <>
            <div style={{ backgroundColor: 'white' }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F5F6FA',
                    color: '#1C1C22',
                    font: 'Gilroy-Bold',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    borderRadius: '10px',

                }}>
                    GENERAL DETAILS
                </div>
                {/* {detailId.map((t, index) => { */}
                {detailId.slice(-1).map((t, index) => {

                    return (
                        <div>
                            <div className='row' style={{ marginLeft: '3px', marginTop: '50px' }}>
                                <div className='col-3'>
                                    <p style={{ fontWeight: 'bold', color: '#808080' }}>SUFFERED</p>
                                    <div style={{ display: 'flex' }}>
                                        <span>
                                            <span>{t.astjma == "yes" ? <p>Asthuma</p> : ""}</span>
                                            <span>{t.epilepsy == "yes" ? <p>Epilepsy</p> : ""}</span>
                                            <span>{t.diabets == "yes" ? <p>Diabetes</p> : ""}</span>
                                            <span>{t.heart_problem == "yes" ? <p>Heart Problem </p> : ""}</span>
                                            <span>{t.blood_disease == "yes" ? <p>Blood Disease</p> : ""}</span>
                                            <span>{t.jaundice == "yes" ? <p>Jaundice</p> : ""}</span>
                                            <span>{t.others == "yes" ? <p>Others</p> : ""}</span>
                                        </span>
                                    </div>
                                </div>
                                {gender === 'Male'
                                    ? null
                                    :
                                    <div className='col-3'>
                                        <p style={{ fontWeight: 'bold', color: '#808080' }}>PREGNANT</p>
                                        <div style={{ display: 'flex' }}>
                                            <span style={{ color: '#1C1C22', fontSize: '14px', font: 'Gilroy-Medium' }}>Due date : </span>
                                            <span style={{ color: '#BB3504', fontSize: '14px', fontWeight: 'bold', marginLeft: '3px' }}>{t.due_date}</span></div>
                                    </div>
                                }
                                <div className='col-3'>
                                    <p style={{ fontWeight: 'bold', color: '#808080' }}>HABITS</p>
                                    <div style={{ display: 'flex' }}>
                                        <span style={{
                                            color: '#FF6C2E', fontWeight: 'bold', font: 'Gilroy-Bold', fontSize: '14px'
                                        }}>
                                            <span>{t.alcohol == "yes" ? <p>Alcohol</p> : ""}</span>
                                            <span>{t.paan == "yes" ? <p>Paan</p> : ""}</span>
                                            <span>{t.tobacco == "yes" ? <p>Tobacco</p> : ""}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <p style={{ fontWeight: 'bold', color: '#808080' }}>ALLERGIC</p>
                                    <div style={{ display: 'flex' }}>
                                        <span>
                                            <span>{t.aspirin == "yes" ? <p>Aspirin</p> : ""}</span>
                                            <span>{t.sulfa == "yes" ? <p>Sulfa </p> : ""}</span>
                                            <span>{t.local_aneshthetic == "yes" ? <p>LocalAneshthetic</p> : ""}</span>
                                            <span>{t.ibuprofen == "yes" ? <p>Ibuprofen</p> : ""}</span>
                                            <span>{t.mention_others == null ? "" : <p>{t.mention_others}</p>}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div style={{ marginTop: '80px', color: '#808080', fontSize: '14px', font: 'Gilroy-Bold', fontWeight: 'bold' }}><p>MEDICATION</p></div>
                            <div style={{ color: '#1C1C22', font: 'Gilroy-Medium', marginTop: '15px', fontSize: '16px' }}>
                                <p> {t.medication}</p></div>
                        </div>
                    )
                })}
            </div>
        </>
    )

}

export default GeneralDetails;