// import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react"
import * as con from '../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            material_name: state.material_name,
            make: state.make,
            category_id: state.category_id,
            condition_status: state.condition_status,
            stock_in: state.stock_in,
            stock_out: state.stock_out,
            id: state.id
        };
    }
}


const InventoryAdd = (props) => {

    useEffect(() => {
        getInventoryCat();
    }, [])

    const [InventorycategoidList, setInventoryCateidList] = React.useState([]);

    async function getInventoryCat() {
        const response = await con.InventoryCategory();
        console.log(response)
        if (response['status'] === true) {
            setInventoryCateidList(response['data']);
            dispatch({ type: 'set_value', value: response['data'][0]['id'], key: 'category_id' });
        }
    }

    // ---------------------------- popup form api ---------------

    const [state, dispatch] = useReducer(reducer, {
        material_name: '', make: '', category_id: '', condition_status: 'Good',
        stock_in: '', stock_out: '', id: ''
    })


    const submitStaffPop = (event) => {
        event.preventDefault();
        addInventory(state);
    }

    async function addInventory(invent) {
        const response = await con.AddInventoryList((JSON.stringify(invent)));
        console.log(response)
        if (response['status'] === true) {

            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            window.location.href = "/Inventory";
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }


    // ---------------- action dropdown ----
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    return (
        <React.Fragment>

            <ToastContainer autoClose={3000} />
            <button type="button" className="btn btn-primary my-5" aria-hidden="true"
                data-toggle="modal"
                data-target="#addstaff" style={{ cursor: "pointer", float: "right", marginRight: "40px", backgroundColor: "#145BD9" }}
            ><span className="me-1" style={{ fontSize: "20px" }}>+</span>ADD INVENTORY</button>

            <div>
                <section>
                    <div className="container">

                        <div className="modal fade" id="addstaff" role="dialog">
                            <div className="po_sc_in">

                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>ADD A NEW INVENTORY</h4>
                                        <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                            ×
                                        </button>

                                    </div>
                                    <div className="modal-body">
                                        <form onSubmit={submitStaffPop}>
                                            <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">Material Name:</span>
                                                    </label>
                                                    <input name="material_name" type="text" className="form-control"
                                                        placeholder="Material Name"
                                                        value={state.material_name}
                                                        // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'material_name' }); }}
                                                        onChange={(e) => {
                                                            var sanitizedValue = e.target.value.replace(/[^A-Za-z0-9\s-,/.()!@#$%^&*/+-<>'"]/g, '');
                                                            // if (sanitizedValue.length > 30) {
                                                            //     // Handle maximum length violation
                                                            //     sanitizedValue = sanitizedValue.slice(0, 30);
                                                            // }
                                                            // Enforce not allowing same alphabetic character consecutively three times
                                                            const regex = /(.)\1{5,}/g;
                                                            sanitizedValue = sanitizedValue.replace(regex, '');
                                                            dispatch({ type: 'set_value', value: sanitizedValue, key: 'material_name' });
                                                        }}
                                                        required />
                                                </div>
                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">Make:</span>
                                                    </label>
                                                    <input name="make" type="text" className="form-control"
                                                        placeholder="Make Name"
                                                        value={state.make}
                                                        // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'make' }); }}
                                                        onChange={(e) => {
                                                            var sanitizedValue = e.target.value.replace(/[^A-Za-z0-9\s-,/.()!@#$%^&*/+-<>'"]/g, '');
                                                            // if (sanitizedValue.length > 30) {
                                                            //     // Handle maximum length violation
                                                            //     sanitizedValue = sanitizedValue.slice(0, 30);
                                                            // }
                                                            // Enforce not allowing same alphabetic character consecutively three times
                                                            const regex = /(.)\1{5,}/g;
                                                            sanitizedValue = sanitizedValue.replace(regex, '');
                                                            dispatch({ type: 'set_value', value: sanitizedValue, key: 'make' });
                                                        }}
                                                        required />
                                                </div>
                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">Stock:</span>
                                                    </label>
                                                    <input name="count" type="text" className="form-control"
                                                        placeholder="No. of Count"
                                                        value={state.stock_in}
                                                        // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'stock_in' }); }}
                                                        onChange={(e) => {
                                                            var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                            dispatch({ type: 'set_value', value: sanitizedValue, key: 'stock_in' });
                                                        }}
                                                        required />
                                                </div>
                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">Category:</span>
                                                    </label>

                                                    {/* {(InventorycategoidList != null) ?
                                                        <select
                                                            className="form-select"
                                                            style={{ fontSize: 14, height: 44, width: "100%" }}
                                                            required
                                                            value={state.category_id}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'category_id' }); }}
                                                        >

                                                            {InventorycategoidList.map((list, index) =>
                                                                <option value={list['id']}>{list['name']}</option>
                                                            )}

                                                        </select>

                                                        : null} */}


                                                    {(InventorycategoidList != null) ?
                                                        <>
                                                            <select
                                                                className="form-select"
                                                                style={{ fontSize: 14, height: 44, width: "100%" }}
                                                                required
                                                                value={state.category_id}
                                                                onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'category_id' }); }}
                                                            >
                                                                {InventorycategoidList.map((list, index) =>
                                                                    <option value={list['id']}>{list['name']}</option>
                                                                )}
                                                            </select>
                                                            {state.category_id === '3' || state.category_id === 'Equipments' ?
                                                                <select
                                                                    className="form-select mt-5"
                                                                    style={{ fontSize: 14, height: 44, width: "100%" }}
                                                                    required
                                                                    value={state.condition_status}
                                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'condition_status' }); }}
                                                                >
                                                                    <option value="Good">Good</option>
                                                                    <option value="Fair">Fair</option>
                                                                    <option value="Poor">Poor</option>
                                                                    <option value="Defective">Defective</option>
                                                                </select>
                                                                : null
                                                            }
                                                        </>
                                                        : null}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </React.Fragment>
    )
}

export default InventoryAdd