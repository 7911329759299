import React, { useState, useEffect } from "react";
import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
import * as con from '../../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";

function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        state.selectedPlanId = action.value;
        return {

            treatment_plan_id: state.treatment_plan_id,
            treatment_procedure: state.treatment_procedure,
            selectedPlanId: state.selectedPlanId,
            id: state.id
        };
    }
}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};


const Treatmentprocedure = (props) => {

    const { ...rest } = props;

    // ------------------------------- add diagnosis ------------

    const [state, dispatch] = useReducer(reducer, {
        treatment_plan_id: '', treatment_procedure: '', price: '', selectedPlanId: '', id: ''
    })

    const [open, setOpen] = useState(true);

    const addTreatProcedur = (event) => {
        event.preventDefault();
        if (state.id != '') {
            AddtProced(state, 'edit');
            setDropdownOpen(false)
        } else {
            AddtProced(state, 'add');
            setDropdownOpen(false)
        }
    }

    async function AddtProced(ProcedData, type) {
        if (type == 'add') {
            const response = await con.CreateTreatProcedAPI((JSON.stringify(ProcedData)));
            let afterAdded = [...treatmentProcedList];
            afterAdded.push(response['data']);
            setTreatmentProcedList(afterAdded);
            if (response['status'] === true) {
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        } else {
            const response = await con.UpdateTreatmeProceduAPI((JSON.stringify(ProcedData)));
            if (response['status'] === true) {
                let afterAdded = treatmentProcedList.map(item => {
                    if (item.id === state.id) {
                        return response.data;
                    }
                    return item;
                });
                dispatch({ type: 'set_value', value: '', key: 'id' })
                dispatch({ type: 'set_value', value: '', key: 'treatment_plan_id' })
                dispatch({ type: 'set_value', value: '', key: 'treatment_procedure' })
                dispatch({ type: 'set_value', value: '', key: 'selectedPlanId' })
                setTreatmentProcedList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        }
    }

    // -----------------------------  add method button dropdown

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'treatment_plan_id' })
        dispatch({ type: 'set_value', value: '', key: 'treatment_procedure' })
    };

    //------------------------for cancel action
    const handleCancel = () => {

        setDropdownOpen(false);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'treatment_plan_id' })
        dispatch({ type: 'set_value', value: '', key: 'treatment_procedure' })
    };


    // -----------------------------  for edit method button dropdown---------

    function handleForEditButtonClick(procedure) {
        setDropdownOpen(true);
        dispatch({ type: 'set_value', value: procedure.id, key: 'id' })
        dispatch({ type: 'set_value', value: procedure.treatment_plan_id, key: 'treatment_plan_id' })
        dispatch({ type: 'set_value', value: procedure.treatment_procedure, key: 'treatment_procedure' })
    };


    //----------------------------------- api 1nd Dropdownlist

    const [treatmentplanList, setTreatplanList] = React.useState();
    useEffect(() => {
        treatmentLists();
    }, [])

    async function treatmentLists() {
        const response = await con.treatmentplanList();
        if (response['status'] === true) {
            setTreatplanList(response['data']);
            dispatch({ type: 'set_value', value: response['data'][0]['id'], key: 'treatment_plan_id' });
        }
    }

    // -------------------------------Procedure list API -----------


    const [treatmentProcedList, setTreatmentProcedList] = useState([])


    useEffect(() => {
        TreatmProcedureLists();
    }, [])

    async function TreatmProcedureLists() {
        const response = await con.TreatmPrcedList();
        if (response['status'] === true) {
            console.log(response['data'])
            setTreatmentProcedList(response['data']);
        }
    }


    // ------------------------- Delete API --------

    async function getTreatProDelete(deleteProced) {
        const response = await con.TreProcedDeleteAPI(deleteProced);
        let afterAdded = treatmentProcedList.filter(item => item.id !== deleteProced);
        setTreatmentProcedList(afterAdded);
        if (response['status'] === true) {
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }



    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currenttreatmentProcedList = treatmentProcedList.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <React.Fragment>

            <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
                <Sidebar open={open} onClose={() => setOpen(false)} />
                <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                    <main
                        className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
                    >
                        {/* Routes */}
                        <div className="h-full">
                            <Navbar
                                onOpenSidenav={() => setOpen(true)}
                                logoText={"Kirthika Dental Care"}
                                brandText="Treatment Procedure"
                                {...rest}
                            />
                            <div>
                                < button type="submit"
                                    className="btn btn-primary my-5"
                                    style={{ width: "auto", backgroundColor: "#5660d9", marginLeft: "18px" }}
                                    onClick={handleButtonClick}
                                >+  Treatment Procedure
                                </button>
                                {isDropdownOpen && (

                                    <div className="card mb-5" style={{ backgroundColor: "#f5f5f5", borderRadius: "7px", boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)", margin: "10px", padding: "20px" }}>
                                        <form onSubmit={addTreatProcedur}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="language">Treatment Plan:</label>
                                                    {(treatmentplanList != null) ?
                                                        <select
                                                            className="form-select"
                                                            id="sel10"
                                                            style={{ fontSize: 14, height: 44, width: "100%" }}
                                                            required
                                                            value={state.treatment_plan_id}
                                                            onChange={(e) => {
                                                                dispatch({ type: 'set_value', value: e.target.value, key: 'treatment_plan_id' });
                                                            }}
                                                        >
                                                            <option>Select Treatment Plan.....</option>
                                                            {treatmentplanList.map((list, index) =>
                                                                <option value={list['id']}>{list['treatment_plan']}</option>
                                                            )}

                                                        </select>
                                                        : null}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="country">Treatment Procedure:</label>


                                                    <input type="text" id="diagnosisInput" placeholder="Enter Your Treatment Plan" className="form-control"
                                                        value={state.treatment_procedure}
                                                        required
                                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'treatment_procedure' }); }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12 ">
                                                <span> <button type="submit" className="btn btn-primary">Save</button></span>
                                                <span className="me-1"> <button type="button" className="btn btn-primary" onClick={handleCancel}>cancel</button></span>

                                            </div>
                                        </form>
                                    </div>


                                )}
                                <table className="bordered-table">
                                    <thead className="tablehead" >
                                        <tr style={{ borderRadius: "10px" }}>
                                            <th>S.No</th>
                                            <th>Treatment Plan</th>
                                            <th>Treatment Procedure</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currenttreatmentProcedList.map((procedure, index) => {
                                            const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
                                            return (

                                                <tr key={index}>
                                                    <td>{continuousIndex}</td>
                                                    <td>{procedure.treatment_plan_name}</td>
                                                    <td>{procedure.treatment_procedure}</td>
                                                    <td><RiEdit2Line className="edit_icon" onClick={() => { handleForEditButtonClick(procedure); }} /></td>
                                                    <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                                                        onClick={() => getTreatProDelete(procedure.id)} /></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>

                                </table>
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={treatmentProcedList.length}
                                    currentPage={currentPage}
                                    paginate={paginate}
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Treatmentprocedure