import { background } from "@chakra-ui/system";
import React, { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useReducer, } from "react";
import './style.css';
import * as con from '../../repository/api_calls';
import General from "./General";
import TreatmentPlanMap from "./TreatmentPlanMap";
import { ToastContainer, toast } from 'react-toastify';
import { UserContext } from "../../patient/exp"



const predefinedColors = [
  '#F7C8E0',
  '#FFEEBB',
  '#C4DFDF',
  '#D7C0AE',
  '#D7E9F7',
  '#BFACE2',
  // Add more colors as needed
];

const style = {
  fontSize: "17px",
  backgroundColor: "#D3DCEB",
  color: '#145BD9',
  padding: " 1px 20px",
  borderRadius: "10px",
};



function treatmentReducer(treatmentState, action) {
  switch (action.type) {
    case 'UPDATE_TREATMENT_PLAN':
      return {
        ...treatmentState,
        summary: {
          ...treatmentState.summary,
          treatmentPlan: action.payload,
        },
      };
    case 'UPDATE_TREATMENT_PROCEDURE':
      return {
        ...treatmentState,
        summary: {
          ...treatmentState.summary,
          treatmentProcedure: action.payload,
        },
      };
    case 'UPDATE_TREATMENT_METHOD':
      return {
        ...treatmentState,
        summary: {
          ...treatmentState.summary,
          treatmentMethod: action.payload,
        },
      };
    case 'UPDATE_TREATMENT_PRICE':
      return {
        ...treatmentState,
        summary: {
          ...treatmentState.summary,
          treatmentPrice: action.payload,
        },
      };
    default:
      return treatmentState;
  }
}


const Diagnosis = (props) => {
  const [updatedSelectT, setupdatedSelectT] = useState([]);
  const [isOpen, setIsOpen] = useState(false)
  const [selectedGeneral, setSelectedGeneral] = useState([]);
  const [selectedTeeth, setSelectedTeeth] = useState([]);
  const [backupForSelectedTeeth, setbackupForSelectedTeeth] = useState([])
  const [activeTab, setActiveTab] = useState([]);
  const [activePlan, setActivePlan] = useState(false);
  const [activeTab1, setActiveTab1] = useState([]);
  const [problem, setProblem] = useState([]);
  const [toothNo, setToothNo] = useState([]);
  const [selectedActiveTooth, setselectedActiveTooth] = useState(0);
  const initialState = {
    tooth_data: selectedTeeth,
    summary: {
      treatmentPlan: '',
      treatmentProcedure: '',
      treatmentMethod: '',
      treatmentPrice: '',
    },
  };
  const [treatmentState, dispatcher] = useReducer(treatmentReducer, initialState);
  const [treatmentPlan, setTreatmentPlan] = useState(false)
  const [dianosisType, setDianosisType] = useState([]);
  const [emptyList, setEmptyList] = useState([])
  const contextValue = useContext(UserContext);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [actTab2, setActTab2] = useState('')
  const [specificPlan, setSpecificPlan] = useState([])


  const [showMessage, setShowMessage] = useState(false);

  const message = showMessage ? (
    <div className="message-card">
      Diagnosis has been saved
    </div>
  ) : null;

  const handleTab = (e) => {
    e.preventDefault()
    setActTab2(e.target.name)
    getDiagnosisName()
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submited')
  }
  function a(e) {
    // setSpecificPlan(e)
    if (localStorage.getItem("teethData") !== undefined || localStorage.getItem("teethData") !== null) {
      let data = JSON.parse(localStorage.getItem("teethData"));
      setSpecificPlan(data)
    }


  }




  const teethValues = [
    [{
      'teeth_id': "1",
      'teeth_no': 18,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "2",
      'teeth_no': 17,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "3",
      'teeth_no': 16,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "4",
      'teeth_no': "15/55",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "5",
      'teeth_no': "14/54",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "6",
      'teeth_no': "13/53",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "7",
      'teeth_no': "12/52",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "8",
      'teeth_no': "11/51",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "9",
      'teeth_no': "21/61",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "10",
      'teeth_no': "22/62",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "11",
      'teeth_no': "23/63",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "12",
      'teeth_no': "24/64",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "13",
      'teeth_no': "25/65",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "14",
      'teeth_no': 26,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "15",
      'teeth_no': 27,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "16",
      'teeth_no': 28,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },],
    [{
      'teeth_id': "17",
      'teeth_no': 48,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "18",
      'teeth_no': 47,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "19",
      'teeth_no': 46,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "20",
      'teeth_no': "45/85",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "21",
      'teeth_no': "44/84",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "22",
      'teeth_no': "43/83",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "23",
      'teeth_no': "42/82",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "24",
      'teeth_no': '41/81',
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "25",
      'teeth_no': "31/71",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "26",
      'teeth_no': "32/72",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "27",
      'teeth_no': "33/73",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "28",
      'teeth_no': "34/74",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "29",
      'teeth_no': "35/75",
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "30",
      'teeth_no': 36,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "31",
      'teeth_no': 37,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },
    {
      'teeth_id': "32",
      'teeth_no': 38,
      'teeth_stroke': 'none',
      'selected_teeth': []
    },]
  ]


  const childTeethValues = [
    [
      {
        'teeth_id': "1",
        'teeth_no': "55",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "2",
        'teeth_no': "54",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "3",
        'teeth_no': "53",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "4",
        'teeth_no': "52",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "5",
        'teeth_no': "51",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "6",
        'teeth_no': "61",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "7",
        'teeth_no': "62",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "8",
        'teeth_no': "63",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "9",
        'teeth_no': "64",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "10",
        'teeth_no': "65",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
    ], [
      {
        'teeth_id': "11",
        'teeth_no': "85",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "12",
        'teeth_no': "84",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "13",
        'teeth_no': "83",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "14",
        'teeth_no': "82",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "15",
        'teeth_no': '81',
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "16",
        'teeth_no': "71",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "17",
        'teeth_no': "72",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "18",
        'teeth_no': "73",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "19",
        'teeth_no': "74",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
      {
        'teeth_id': "20",
        'teeth_no': "75",
        'teeth_stroke': 'none',
        'selected_teeth': []
      },
    ]
  ]

  useEffect(() => {
    getDiagnosisType();
  }, []);



  async function getDiagnosisType() {
    const response = await con.getDiagnosisTypeList();
    console.log(response)
    if (response['status'] === true) {
      setDianosisType(response['data']);
    }
  }
  // Function to handle input changes
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    switch (field) {
      case 'treatmentPlan':
        dispatcher({ type: 'UPDATE_TREATMENT_PLAN', payload: value });
        break;
      case 'treatmentProcedure':
        dispatcher({ type: 'UPDATE_TREATMENT_PROCEDURE', payload: value });
        break;
      case 'treatmentMethod':
        dispatcher({ type: 'UPDATE_TREATMENT_METHOD', payload: value });
        break;
      case 'treatmentPrice':
        dispatcher({ type: 'UPDATE_TREATMENT_PRICE', payload: +value });
        break;
      default:
        break;
    }
  };

  async function getDiagnosisName(id) {
    const response = await con.DiagnosisListAPI();
    // console.log(response['data'])
    if (response['status'] === true) {
      // const filteredDentalList = response['data'].filter(item => item.diagnosis_type.toString() === id.toString())
      const filteredDentalList = response['data']
      setEmptyList(filteredDentalList);
      // console.log(filteredDentalList)
    }
  }

  function diseasesG(name, t) {
    const isSelected = selectedGeneral.some((problemItem) => problemItem.id === t.id);
    console.log(isSelected)
    if (isSelected) {
      setSelectedGeneral((prevSelected) => prevSelected.filter((item) => item.id !== t.id));
    } else {

      setSelectedGeneral([...selectedGeneral, t]);
    }
  }

  function deselectGeneral(name) {
    setSelectedGeneral((prevSelected) => prevSelected.filter((item) => item.id !== name.id));
  }

  function nextGeneral(e) {
    e.preventDefault()
    setTreatmentPlan(true)
  }
  const [teethPlan, setTeethPlan] = useState([])

  const diseases = (selectedDisease, t) => {
    console.log(t)
    setSelectedTeeth((prevTeeth) =>
      prevTeeth.map((tooth) => {
        // if (toothNo.includes(tooth.tooth)) {
        //   const idExists = tooth.problem.some((problemItem) => problemItem.name === t.name);
        //   if (!idExists) {

        //     tooth.problem.push(t);
        //   } else {
        //     tooth.problem = tooth.problem.filter((problemItem) => problemItem.name !== t.name);
        //   }
        // }

        if (tooth.tooth == selectedActiveTooth) {
          const idExists = tooth.problem.some((problemItem) => problemItem.id === t.id);
          if (!idExists) {
            tooth.problem.push(t);
          } else {
            tooth.problem = tooth.problem.filter((problemItem) => problemItem.id !== t.id);
          }
        }

        return tooth;
      })
    );
  };


  const handleClick = (tooth) => {
    const newTooth = {
      tooth: tooth,
      problem: [],
    };

    const idList = selectedTeeth.map(teeth => teeth.tooth);
    if (!idList.includes(tooth)) {
      setSelectedTeeth([...selectedTeeth, newTooth]);
      setToothNo([...toothNo, tooth]);
      // setbackupForSelectedTeeth([...backupForSelectedTeeth, newTooth]);
    }
    setselectedActiveTooth(tooth)
    // }
  };


  const handleRemove = (index, prob) => {
    let updatedTeeth = [...selectedTeeth];
    const updatedList = updatedTeeth[index].problem.filter((id) => id !== prob);
    updatedTeeth[index].problem = updatedList
    // updatedTeeth.reduce(index, 1);
    console.log(updatedTeeth)
    setSelectedTeeth(updatedTeeth);
  };


  const handleRemoveList = (index) => {
    const updatedTeeth = [...selectedTeeth];
    updatedTeeth.splice(index, 1);
    setSelectedTeeth(updatedTeeth);
    const tno = [...toothNo];
    tno.splice(index, 1);
    setToothNo(tno)
  };


  const handleTabClick2 = (num) => {
    setSelectedTeeth([])
    const ides = backupForSelectedTeeth.map(item => item['tooth']);
    setToothNo(ides);
    setActiveTab1(num)
  }


  function next() {
    setbackupForSelectedTeeth([...backupForSelectedTeeth, selectedTeeth[0]])
    if (activeTab === "Tooth Specific") {
      // setupdatedSelectT([])
      setTreatmentPlan(true)
    }
    const { treatmentPlan, treatmentProcedure, treatmentMethod, treatmentPrice } = treatmentState.summary;
    const updatedSelect = selectedTeeth.map((t) => {
      return {
        ...t,
        treatmentPlan,
        treatmentProcedure,
        treatmentMethod,
        treatmentPrice,
      };
    });

    setActivePlan(true)
  }

  console.log(emptyList)
  const gen = emptyList.filter((t) => t.diagnosis_type.toString() === "16")
  const toothSpecific = emptyList.filter((t) => t.diagnosis_type.toString() === "27")

  function save(e) {
    e.preventDefault()

    setShowMessage(true);

    // setTimeout(() => {
    //   setShowMessage(false);
    // }, 2000);

    props.tabName('Treatment Plan');

    toast.success(' Diagnosis has been saved', {
      position: 'top-right',
      autoClose: 2000, // Adjust as needed
    });
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
    // contextValue = selectedGeneral selectedTeeth
    contextValue.toothSelection = [];
    console.log(selectedGeneral)
    if (selectedGeneral.length !== 0) {
      for (let i of selectedGeneral) {
        contextValue.toothSelection.push({ 'type': "general", "problem": i })
      }
    }
    if (selectedTeeth.length !== 0) {
      let data = contextValue.toothSelection;
      for (let i = 0; i < selectedTeeth.length; i++) {
        for (let j = 0; j < selectedTeeth[i]['problem'].length; j++) {
          let dataa = { 'type': "tooth", "problem": selectedTeeth[i]['problem'][j], 'tooth': selectedTeeth[i]['tooth'] }
          data.push(dataa)
        }

      }
      contextValue.toothSelection = data
    }
    console.log(contextValue.toothSelection)
    // navigate("treatmentm")
  }

  return (
    <React.Fragment>
      <>
        <ul className="nav nav-tabs tab--2" >
          <li className="nav-item">
            <a className={actTab2 == 'General' ? `tab--2 act` : 'tab--2'} href="#" name='General' onClick={handleTab}>General Medical Record</a>
          </li>
          <li className="nav-item">
            <a className={actTab2 == 'Dental' ? `tab--2 act` : 'tab--2'} href="#" name='Dental' onClick={handleTab}>Dental</a>
          </li>
        </ul>
        <div className={actTab2 == 'General' ? `visible` : 'hidden'}>
          <General p={props.pData} />
        </div>

        <div className={actTab2 == 'Dental' ? `visible treatment-tab` : 'hidden'}>
          <>
            {/* <div className="container-fluid generalDentistry"> */}
            <div className="container-fluid my-5">
              <p className="diagnosisType"> <b>GENERAL DENTISTRY</b></p>
              <div className="container-fluid mt-5">
                {/* <div className="tabtablediagno generaldens" > */}
                <div style={{ display: 'flex', flexWrap: 'wrap' }} >
                  {gen.map((t, index) => {
                    const backgroundColor = predefinedColors[index % predefinedColors.length];
                    const style = {

                      height: 'auto',
                      width: 'auto',
                      borderRadius: '7px',
                      marginRight: '5px',
                      padding: "3px 10px",
                      backgroundColor: backgroundColor,
                      cursor: 'pointer'
                    };
                    return (
                      <span className="my-3">
                        <a
                          onClick={() => diseasesG(t.name, t)}
                          className={`diagnosisNameList${selectedGeneral.includes(t.name) ? ' selected' : ''}`}
                          style={style}
                        >
                          {t.name}
                        </a>
                      </span>
                    );
                  })}
                </div>
              </div>

            </div>

          </>


          <>
            {/* <div className="container-fluid toothSpecific tabtablediagno"> */}
            <div className="container-fluid tabtablediagno mb-5">
              <p className="diagnosisType "> <b>TOOTH SPECIFIC</b></p>
              <table className="container-fluid " style={{ width: "100%", padding: "8px" }}>
                <tbody>
                  {props.pData.age > 6 ? teethValues.map((row, rowIndex) => (
                    <tr key={rowIndex} >
                      {row.map((cell, cellIndex) => (
                        <td
                          key={cellIndex}
                          style={{
                            backgroundColor: `${(toothNo.includes(cell.teeth_no) && (selectedActiveTooth != cell.teeth_no)) ? "#4E4C67" :
                              (toothNo.includes(cell.teeth_no) && (selectedActiveTooth == cell.teeth_no)) ? "#B4869F" : "white"
                              }`,
                            fontSize: "16px",
                            // color: "black",
                            color: `${(toothNo.includes(cell.teeth_no) && (selectedActiveTooth != cell.teeth_no)) ? "#fff" : "#111"}`,
                            border: "1px solid black",
                            cursor: "pointer",
                            textAlign: "center",
                            verticalAlign: "middle",
                            padding: "10px"
                          }}
                          onClick={() => {
                            handleClick(cell.teeth_no);
                          }}
                        >
                          {cell.teeth_no}
                        </td>
                      ))}
                    </tr>
                  )) :
                    childTeethValues.map((row, rowIndex) => (
                      <tr key={rowIndex} >
                        {row.map((cell, cellIndex) => (
                          <td
                            key={cellIndex}
                            style={{
                              backgroundColor: `${(toothNo.includes(cell.teeth_no) && (selectedActiveTooth != cell.teeth_no)) ? "#4E4C67" :
                                (toothNo.includes(cell.teeth_no) && (selectedActiveTooth == cell.teeth_no)) ? "#B4869F" : "white"
                                }`,
                              fontSize: "16px",
                              // color: "black",
                              color: `${(toothNo.includes(cell.teeth_no) && (selectedActiveTooth != cell.teeth_no)) ? "#fff" : "#111"}`,
                              border: "1px solid black",
                              cursor: "pointer",
                              textAlign: "center",
                              verticalAlign: "middle",
                              padding: "10px"
                            }}
                            onClick={() => {
                              handleClick(cell.teeth_no);
                            }}
                          >
                            {cell.teeth_no}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="container-fluid toothSpecificName tabtablediagno" style={{ display: 'flex', flexWrap: 'wrap' , marginLeft: '1.5rem'}}>
              {toothSpecific.map((t, index) => {

                const backgroundColor = predefinedColors[index % predefinedColors.length];

                const styles = {
                  height: 'auto',
                  width: 'auto',
                  borderRadius: '7px',
                  marginRight: '5px',
                  padding: "3px 10px",
                  backgroundColor: backgroundColor,
                  cursor: 'pointer'
                };
                return (
                  <a onClick={() => diseases(t.name, t)}
                    // className={`diagnosisNameList${selectedTeeth.includes(t.name) ? ' selected' : ''}`} 
                    className="diagnosisNameList"
                    style={styles} >{t.name}</a>

                )
              })}

            </div>

            {/* <div className="container-fluid diagnosisSelList"> */}
            <div className="container-fluid my-5 ">

              <>
                {/* <hr style={{ border: '1px solid #1C1C22', fontWeight: 'bold', width: '96%' }} /> */}
                {/* <hr style={{ border: '1px solid #1C1C22', fontWeight: 'bold', width: '96%', marginTop: '15px' }} /> */}
                <div >
                  <h5 style={{ marginTop: '25px', font: 'Gilroy-Bold', fontSize: '16px', fontWeight: 'bold', color: '#1C1C22' }}>SELECTED DIAGNOSIS</h5>

                  <table style={{ border: '2px solid #EAECF0', backgroundColor: '#ffffff', width: '100%' }}>
                    <thead>

                    </thead>
                    <tbody>

                      {selectedTeeth.length > 0 && (

                        <>

                          {selectedTeeth.map((tooth, index) => (
                            <tr style={{ height: '50px' }}>
                              <td style={{ border: '2px solid #EAECF0', paddingLeft: '30px', width: '10px' }}> <span className="tablevalue_round">{tooth.tooth}</span></td>
                              <td className="selectedDiagnosis"
                              //  style={{ border: '2px solid #EAECF0', width: '550px', padding: '10px, 20px' }}
                              >
                                <span>
                                  {tooth.problem !== null ? (
                                    tooth.problem.map((tee, ind) => (

                                      <span
                                        // className="selectedDiagnosis"
                                        style={{
                                          color: '#BB3504',
                                          backgroundColor: "#F5F6F9",
                                          // padding: '10px, 20px',
                                          borderRadius: '3px',
                                          margin: '10px',
                                          display: 'inline-block',
                                          font: 'Gilroy-Bold', gap: '10px',
                                          lineHeight: '16.41px',
                                          font: 'Gilroy-Bold',
                                          fontWeight: 'bold'

                                        }}
                                      >
                                        <span
                                        // style={{ padding: '40px 8px 40px 5px' }}
                                        >
                                          {tee.name}
                                          <button
                                            onClick={() => handleRemove(index, tee)}
                                            style={{ color: '#808080', fontWeight: 'bold', marginLeft: '10px', fontSize: '18px', cursor: 'pointer' }}
                                          >
                                            x
                                          </button>
                                        </span>
                                      </span>
                                    ))
                                  ) : null}
                                </span>
                                {/* <span className="teethClose"> */}
                                <span>
                                  <button
                                    onClick={() => handleRemoveList(index)}
                                    style={{ color: "#BB3504", margin: '10px', fontWeight: 'bold', fontSize: '20px' }}
                                  >
                                    x
                                    {/* <FaDeleteLeft /> */}
                                  </button>
                                </span>
                              </td>
                            </tr>
                          )
                          )}
                        </>
                      )}
                    </tbody>
                    {selectedGeneral.length > 0 && (
                      <tfoot>
                        <tr style={{ height: '50px' }}>
                          <td style={{ border: '2px solid #EAECF0', fontSize: '15px', font: 'Gilroy-Bold', fontWeight: 'bold', color: '#1C1C22', width: '30px', paddingLeft: '30px' }} >
                            General</td>

                          <td style={{ border: '2px solid #EAECF0', width: '250px', padding: '10px, 20px' }}>
                            {selectedGeneral.map((value, index) => (
                              <span
                                key={index}
                                style={{
                                  color: '#10902C',
                                  backgroundColor: "#F5F6F9",
                                  padding: '10px, 20px',
                                  borderRadius: '3px',
                                  margin: '10px',
                                  font: 'Gilroy-Bold',
                                  gap: '10px',
                                  lineHeight: '20.41px',
                                  fontWeight: 'bold'

                                }}
                              >
                                <span style={{ padding: '40px 8px 40px 5px' }}>
                                  {value.name}{' '}
                                  <button
                                    onClick={() => deselectGeneral(value)}
                                    style={{
                                      backgroundColor: 'transparent',
                                      border: 'none',
                                      cursor: 'pointer',
                                      color: '#808080',
                                      fontWeight: 'bold',
                                      marginLeft: '10px',
                                      fontSize: '18px'

                                    }}
                                  >
                                    x
                                  </button>
                                </span>
                              </span>
                            ))}
                          </td>
                        </tr>
                      </tfoot>
                    )}
                  </table>
                </div>
              </>
              <div className='nxt-btn' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className="btn btn-primary" onClick={save}
                  style={{ color: "white", padding: "5px 20px", borderRadius: "10px", marginTop: '8px', marginRight: '11vh', marginBottom: '5vh' }}
                >
                  Save
                </button></div>

            </div>

          </>
          {/* <div className='nxt-btn' style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <button className="btn btn-primary" onClick={save}
              style={{ color: "white", padding: "5px 20px", borderRadius: "10px", marginTop: '20px' }}
            >
              Save
            </button></div> */}



        </div>
        {/* {message} */}
      </>

    </React.Fragment>
  );



};










export default Diagnosis;