import React, { useState, useEffect } from "react";
import { useReducer } from 'react';
import * as con from '../../../repository/api_calls';
import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';
import { Link } from "react-router-dom";
import DoctorSalary from "./doctorsalary";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { FaEllipsis } from "react-icons/fa6";

function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            id: state.id,
            staff_id: state.staff_id,
            doctor_id: state.doctor_id,
            salary: state.salary,
            allowance: state.allowance,
            income_tax: state.income_tax,
            otc: state.otc,
            epf: state.epf,
            employee_type: state.employee_type,
            employee_id: state.employee_id,
        };
    }
}



const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};


const EmployeSalary = () => {
    const [SalaryLists, setSalaryList] = useState([])
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const userRoleId = localStorage.getItem('role_id');
  const staff_category_name = localStorage.getItem('staff_category_name');

    const [searchTerm, setSearchTerm] = useState("");

    // const searchedSalary = SalaryLists.filter((c) =>
    //     // c.staff_name.toLowerCase().includes(searchTerm.toLowerCase()) || c.staff_id.includes(searchTerm)
    //     c.staff_name.includes(searchTerm) || c.doctor_name.includes(searchTerm).includes(searchTerm.toLowerCase())
    // );
    // const searchedSalary = SalaryLists.filter((c) =>
    //     c.staff_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     (c.doctor_name?.includes(searchTerm) || "").toLowerCase().includes(searchTerm.toLowerCase())
    // );
    // const [searchTerm, setSearchTerm] = useState("");

    const searchedSalary = SalaryLists.filter((c) =>
        c.date.includes(searchTerm)
    );


    // ---------------------------- popup form api ---------------

    const [state, dispatch] = useReducer(reducer, {

        id: '',
        staff_id: '',
        doctor_id: '',
        salary: '',
        allowance: '',
        income_tax: '',
        otc: '',
        epf: '',
        employee_type: '',
        employee_id: ''


    }
    );

    const submitStaffPop = (event) => {
        event.preventDefault();
        updateSalary(state);
    }


    // ---------------------update api ------------

    async function updateSalary(invent) {
        const response = await con.UpdateSalaryAPI((JSON.stringify(invent)));
        console.log(response)
        if (response['status'] === true) {

            // let afterAdded = SalaryLists.map(item => {
            //     if (item.id === state.id) {
            //         return response.data;
            //     }
            //     return item;
            // });
            dispatch({ type: 'set_value', value: '', key: 'id' })
            dispatch({ type: 'set_value', value: '', key: 'employee_id' })
            dispatch({ type: 'set_value', value: '', key: 'doctor_id' })
            dispatch({ type: 'set_value', value: '', key: 'salary' })
            dispatch({ type: 'set_value', value: '', key: 'allowance' })
            dispatch({ type: 'set_value', value: '', key: 'income_tax' })
            dispatch({ type: 'set_value', value: '', key: 'otc' })
            dispatch({ type: 'set_value', value: '', key: 'epf' })
            dispatch({ type: 'set_value', value: '', key: 'employee_type' })
            // setSalaryList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                window.location.reload();
            }, 800);
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }

    // ---------------- for edit function -------

    function handleForEditButtonClick(subCat) {
        console.log(subCat)
        setDropdownOpen(true);
        dispatch({
            type: 'set_value',
            value: [subCat.staff_salary_id, subCat.doctor_salary_id]
                .filter(id => id !== null && id !== undefined)
                .join(', '), // Join the non-null values with a separator
            key: 'id'
        });

        dispatch({
            type: 'set_value',
            value: subCat.staff_id !== null && subCat.staff_id !== undefined ? subCat.staff_id.toString() : subCat.doctor_id.toString(),
            key: 'employee_id'
        });

        dispatch({ type: 'set_value', value: subCat.salary, key: 'salary' })
        dispatch({ type: 'set_value', value: subCat.allowance, key: 'allowance' })
        dispatch({ type: 'set_value', value: subCat.income_tax, key: 'income_tax' })
        dispatch({ type: 'set_value', value: subCat.otc, key: 'otc' })
        dispatch({ type: 'set_value', value: subCat.epf, key: 'epf' })
        dispatch({ type: 'set_value', value: subCat.employee_type, key: 'employee_type' })

    };




    const [actTab, setActTab] = useState('tab1')

    const handleTab = (e) => {
        e.preventDefault()
        setActTab(e.target.name)
    }

    const submitSalary = (event) => {
        event.preventDefault();
        addSalary(state);
    }

    // ---------------- action dropdown ----
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    async function addSalary(salary) {
        const response = await con.StaffSalary((JSON.stringify(salary)));
        if (response['status'] === true) {
            let afterAdded = [...SalaryLists];
            afterAdded.push(response['data']);
            setSalaryList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                window.location.reload();
            }, 800);
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            })
        }
    }

    // -------------------------------  SalaryLists list API -----------



    useEffect(() => {
        SalaryListing();
    }, [])

    async function SalaryListing() {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
        const response = await con.SalaryList((JSON.stringify({ month: currentMonth })));
        console.log(response)
        console.log('lllllllllllllllllllllll')
        if (response['status'] === true) {
            // setSalaryList(response['salaries']);
            setSalaryList(response.data);
            console.log(response.data)
            console.log('salaries')
            // setSalaryList(response['salaries'].reverse());
        }
    }

    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentSalary = searchedSalary.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    // ------------------------- Delete API --------

    async function getInvCDelete(deletesalary) {
        console.log(deletesalary)
        console.log('deletesalary')
        const response = await con.SalaryDeleteAPI(deletesalary);
        if (response['status'] === true) {
            // let afterAdded = SalaryLists.filter(item => item.id !== deletesalary);
            // setSalaryList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                window.location.reload();
            }, 800);
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }


    return (
        <React.Fragment>
           {userRoleId === '3' && staff_category_name === "Auditor"? "" : <button type="button" className="btn btn-primary my-5" aria-hidden="true"
                data-toggle="modal"
                data-target="#addsalary"
                style={{ cursor: "pointer", float: "right", marginRight: "40px", backgroundColor: "#145BD9" }}
            ><span className="me-1" style={{ fontSize: "20px" }}>+</span>Add Salary</button>}


            <div className="tabtable" style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

                <input className="form-control searchBar mb-5" type="text"
                    placeholder="Global Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />

                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px", cursor: "pointer" }}>
                            <th>S.No</th>
                            <th>Year & Month</th>
                            <th>Employee Id</th>
                            <th>Employee Name</th>
                            <th>Employee Category</th>
                            <th>Net Salary</th>
                            <th>Allowance</th>
                            <th>Income tax</th>
                            <th>OTC</th>
                            <th>EPF</th>
                            <th>Gross Salary</th>
                            {userRoleId === '3' && staff_category_name === "Auditor"? "" :  <th>Action</th>}
                        </tr>
                    </thead>

                    <tbody>
                        {currentSalary.map((Salary, index) => {
                            const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;

                            return (
                                <tr key={index}>
                                    <td>{continuousIndex}</td>
                                    <td>{Salary.date}</td>
                                    <td>
                                        {Salary.salary_list && Salary.salary_list.map((t) => (
                                            <p key={t.staff_id + '-' + t.doctor_id} className="p-2">
                                                <span>{t.staff_id}</span>
                                                <span>{t.doctor_id}</span>
                                                <br />
                                            </p>
                                        ))}
                                    </td>

                                    <td>
                                        {Salary.salary_list.map((t) => (
                                            <p className="p-2"> {t.staff_name} <br /></p>
                                        ))}
                                    </td>
                                    <td>
                                        {Salary.salary_list && Salary.salary_list.map((t) => (
                                            <p key={t.staff_category_name + '-' + t.doctor_department_name} className="p-2">
                                                <span>{t.staff_category_name}</span>
                                                <span>{t.doctor_department_name}</span>
                                                <br />
                                            </p>
                                        ))}
                                    </td>
                                    <td>
                                        {Salary.salary_list.map((t) => (
                                            <p className="p-2"> {t.salary} <br /></p>
                                        ))}
                                    </td>
                                    <td>
                                        {Salary.salary_list.map((t) => (
                                            <p className="p-2"> {t.allowance} <br /></p>
                                        ))}
                                    </td>
                                    <td>
                                        {Salary.salary_list.map((t) => (
                                            <p className="p-2"> {t.income_tax} <br /></p>
                                        ))}
                                    </td>
                                    <td>
                                        {Salary.salary_list.map((t) => (
                                            <p className="p-2"> {t.otc} <br /></p>
                                        ))}
                                    </td>
                                    <td>
                                        {Salary.salary_list.map((t) => (
                                            <p className="p-2"> {t.epf} <br /></p>
                                        ))}
                                    </td>
                                    <td>
                                        {Salary.salary_list.map((t) => (
                                            <p className="p-2"> {t.gross_salary} <br /></p>
                                        ))}
                                    </td>

                                    {/* <td>
                                        {Salary.salary_list.map((t) => (
                                            <p className="p-2"> <RiEdit2Line className="edit_icon" data-toggle="modal"
                                                data-target="#editsalary" onClick={() => handleForEditButtonClick(t)} /></p>
                                        ))}
                                    </td>

                                    <td>
                                        {Salary.salary_list.map((t) => (
                                            <p className="my-3">   <RiDeleteBin2Line
                                                className="delete_icon"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => getInvCDelete(t)} /></p>
                                        ))}
                                    </td> */}

                                    {/* <td>{Salary.staff_name}</td> */}
                                    {/* <td>{Salary.staff_category_name}</td>
                                    <td>{Salary.salary}</td>
                                    <td>{Salary.allowance}</td>
                                    <td>{Salary.income_tax}</td>
                                    <td>{Salary.otc}</td>
                                    <td>{Salary.epf}</td>
                                    <td>{Salary.gross_salary}</td> */}
                                 { userRoleId === '3' && staff_category_name === "Auditor"? "" :  <td>
                                        {Salary.salary_list.map((t) => (
                                            <span>
                                                <div className="dropdown">
                                                    <div style={{ display: "flex" }}>
                                                        <FaEllipsis onClick={toggleDropdown} className="ellipsisicon" />
                                                    </div>
                                                    {isOpen && (
                                                        <div className="dropdown-content" >
                                                            <div className="lis" aria-hidden="true"
                                                                data-toggle="modal"
                                                                data-target="#editsalary"
                                                                onClick={() => handleForEditButtonClick(t)}
                                                                style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px', width: '100%' }}>
                                                                <span style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <RiEdit2Line className="edit_icon me-2" /><span >Edit</span></span>
                                                            </div>


                                                            <div className="lis" style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '20px', width: '100%' }} onClick={() => getInvCDelete(t)}
                                                            ><span style={{ display: 'flex', justifyContent: 'center' }}><RiDeleteBin2Line className="edit_icon me-2" style={{ color: "BB3504" }} /><span>Delete</span></span></div>

                                                        </div>
                                                    )}
                                                </div>
                                                <br />
                                            </span>
                                        ))}
                                    </td>}
                                </tr>
                            )
                        })}
                        <hr />
                    </tbody>
                </table>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={searchedSalary.length}
                    currentPage={currentPage}
                    paginate={paginate}
                />
            </div>


            <section>
                <div className="container">

                    <div className="modal fade" id="addsalary" role="dialog">
                        <div className="po_sc_in">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Add Salary</h4>
                                    <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                        ×
                                    </button>

                                </div>
                                <div className=".nav-tab-inventory mt-5" style={{ marginLeft: "50px" }} >
                                    <ul className="nav nav-tabs" style={{ border: 'none', justifyContent: "center" }}>
                                        <div className="cards tabwid" >
                                            <div className={actTab === 'tab1' ? ` card card-tab-invent` : 'card'} style={{ width: "60%", padding: "8px", textAlign: "center", lineHeight: 1, backgroundColor: "gainsboro" }}  >
                                                <li className="nav-item">
                                                    <Link className={actTab === 'tab1' ? `nav-item actm ` : 'nav-item'} name='tab1' onClick={handleTab}>Staff</Link>
                                                </li>
                                            </div>
                                            <div className={actTab === 'tab2' ? ` card card-tab-invent` : 'card'} style={{ width: "60%", padding: "8px", textAlign: "center", lineHeight: 1, backgroundColor: "gainsboro" }} >
                                                <li className="nav-item">
                                                    <Link className={actTab === 'tab2' ? `nav-item actm ` : 'nav-item'} name='tab2' onClick={handleTab}>Doctor</Link>
                                                </li>
                                            </div>
                                        </div>
                                    </ul>
                                    <div className={actTab === 'tab1' ? `visible` : 'hidden'}>
                                        <div className="modal-body">
                                            <form onSubmit={submitSalary}>

                                                <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px", marginTop: '-10px' }}>

                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Employee Id:</span>
                                                        </label>

                                                        <input name="Employee Id" type="text" className="form-control"
                                                            placeholder="Employee Id"
                                                            value={state.staff_id}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'staff_id' }); }}
                                                            required />

                                                    </div>

                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Basic Salary:</span>
                                                        </label>
                                                        <input name="Salary" type="text" className="form-control"
                                                            placeholder="Salary"
                                                            value={state.salary}
                                                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'salary' }); }}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'salary' });
                                                            }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Allowance:</span>
                                                        </label>
                                                        <input name="Allowance" type="text" className="form-control"
                                                            placeholder="Allowance"
                                                            value={state.allowance}
                                                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'allowance' }); }}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'allowance' });
                                                            }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">OTC:</span>
                                                        </label>
                                                        <input name="otc" type="text" className="form-control"
                                                            placeholder="OTC"
                                                            value={state.otc}
                                                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'otc' }); }}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'otc' });
                                                            }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Income tax:</span>
                                                        </label>
                                                        <input name="incometax" type="text" className="form-control"
                                                            placeholder="Income tax"
                                                            value={state.income_tax}
                                                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'income_tax' }); }}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'income_tax' });
                                                            }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">EPF</span>
                                                        </label>
                                                        <input name="epf" type="text" className="form-control"
                                                            placeholder="EPF"
                                                            value={state.epf}
                                                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'epf' }); }}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'epf' });
                                                            }}
                                                            required />
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button>
                                            </form>
                                        </div>
                                    </div>

                                    <div className={actTab === 'tab2' ? `visible` : 'hidden'}>

                                        <div className="modal-body">
                                            <DoctorSalary />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section>
                <div className="container">

                    <div className="modal fade" id="editsalary" role="dialog">
                        <div className="po_sc_in">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Edit Salary</h4>
                                    <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                        ×
                                    </button>

                                </div>
                                <div className=".nav-tab-inventory mt-5" style={{ marginLeft: "50px" }} >


                                    <div className="modal-body">
                                        <form onSubmit={submitStaffPop}>

                                            <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px", marginTop: '-10px' }}>

                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">Employee Id:</span>
                                                    </label>

                                                    <input name="Employee Id" type="text" className="form-control"
                                                        placeholder="Employee Id"
                                                        value={state.employee_id}
                                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'employee_id' }); }}
                                                        required />

                                                </div>

                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">Basic Salary:</span>
                                                    </label>
                                                    <input name="Salary" type="text" className="form-control"
                                                        placeholder="Salary"
                                                        value={state.salary}
                                                        // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'salary' }); }}
                                                        onChange={(e) => {
                                                            var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                            dispatch({ type: 'set_value', value: sanitizedValue, key: 'salary' });
                                                        }}
                                                        required />
                                                </div>
                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">Allowance:</span>
                                                    </label>
                                                    <input name="Allowance" type="text" className="form-control"
                                                        placeholder="Allowance"
                                                        value={state.allowance}
                                                        // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'allowance' }); }}
                                                        onChange={(e) => {
                                                            var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                            dispatch({ type: 'set_value', value: sanitizedValue, key: 'allowance' });
                                                        }}
                                                        required />
                                                </div>
                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">OTC:</span>
                                                    </label>
                                                    <input name="otc" type="text" className="form-control"
                                                        placeholder="OTC"
                                                        value={state.otc}
                                                        // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'otc' }); }}
                                                        onChange={(e) => {
                                                            var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                            dispatch({ type: 'set_value', value: sanitizedValue, key: 'otc' });
                                                        }}
                                                        required />
                                                </div>
                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">Income tax:</span>
                                                    </label>
                                                    <input name="incometax" type="text" className="form-control"
                                                        placeholder="Income tax"
                                                        value={state.income_tax}
                                                        // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'income_tax' }); }}
                                                        onChange={(e) => {
                                                            var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                            dispatch({ type: 'set_value', value: sanitizedValue, key: 'income_tax' });
                                                        }}
                                                        required />
                                                </div>

                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">EPF</span>
                                                    </label>
                                                    <input name="epf" type="text" className="form-control"
                                                        placeholder="EPF"
                                                        value={state.epf}
                                                        // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'epf' }); }}
                                                        onChange={(e) => {
                                                            var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                            dispatch({ type: 'set_value', value: sanitizedValue, key: 'epf' });
                                                        }}
                                                        required />
                                                </div>


                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                    <label className="form-label">
                                                        <span className="required">Employee Type:</span>
                                                    </label>
                                                    <select
                                                        className="form-select mt-5"
                                                        style={{ fontSize: 14, height: 44, width: "100%" }}
                                                        required
                                                        value={state.employee_type}
                                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'employee_type' }); }}
                                                    >
                                                        <option disabled hidden ></option>
                                                        <option value="staff">Staff</option>
                                                        <option value="doctor">Doctor</option>

                                                    </select>




                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button>
                                        </form>
                                    </div>




                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}

export default EmployeSalary