// import PatientView from "views/patientview";
import PatientDetial from "views/patient/patientdetial";

const Patient = () => {

  return (
    <div>
      <PatientDetial />
    </div>

  );
};
export default Patient;