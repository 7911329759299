import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import * as con from '../../repository/api_calls';
import * as url from '../../repository/urls.jsx';
import { useReducer } from 'react';
import { RiDeleteBin2Line } from 'react-icons/ri';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { FaCheck, FaCloudDownloadAlt } from 'react-icons/fa';
import { FaXRay, FaTeethOpen } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";
import { HiOutlinePhoto } from "react-icons/hi2";
import { ImDroplet, ImImages } from "react-icons/im";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';

const predefinedIcons = [{
    id: 1,
    name: 'Location A',
    type: 'icon', // 'icon' or 'image'
    icon: 'FaCheck', // Icon class name or image URL

},
{
    id: 2,
    name: 'Location B',
    type: 'image',
    icon: 'FaXmark ',

},
    // Add more map items here
]
const userRoleId = localStorage.getItem('role_id');
const cid = localStorage.getItem("categoryId");
const style = {
    fontSize: "13px",
    backgroundColor: "white",

    padding: " 10px 100px 10px 20px",
    borderRadius: "10px",
    border: '0.5px light lightgray',
    margin: "5px",
    cursor: "pointer",
    display: 'flex',
    justifyContent: 'start'
};

const handleDownload = (image) => {
    const downloadUrl = URL.createObjectURL(image);
    saveAs(downloadUrl, 'image.png');
};

function reducer(state, action) {
    if (action.type === 'set_value') {
        return {
            tab_id: action.value,
            patient_id: state.patient_id,
            image: state.image,
            image_type: state.image_type,
            medical_document: state.medical_document,
        };
    }
    if (action.type === 'set_value1') {
        return {
            tab_id: state.tab_id,
            patient_id: state.patient_id,
            image: action.value,
            image_type: state.image_type,
            medical_document: state.medical_document,
        };
    }
    if (action.type === 'set_value2') {
        return {
            tab_id: state.tab_id,
            patient_id: state.patient_id,
            image: state.image,
            image_type: action.value,
            medical_document: action.medical_document,
        };
    }
}




const MedicalReport = (props) => {
    const [typeName, setTypeName] = useState("");
    const [medicaldocument, setMedicalDocument] = useState("");
    const [selectedCard, setSelectedCard] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [activeTab, setActiveTab] = useState([]);
    const [cardName, setCardName] = useState([]);
    const [catId, setCatId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [copiedImageUrl, setCopiedImageUrl] = useState('');
    const [imageUrl, setImageUrl] = useState(''); // Make sure to declare imageUrl in the state


    const [state, dispatch] = useReducer(reducer, {
        tab_id: '',
        patient_id: (props.patient_id),
        'image': "",
        image_type: "",
        medical_document: ""
    })


    const togglePopup = () => {
        setIsOpen(!isOpen);
    };





    const handleImageChange = (event) => {
        setSelectedImage(event.target.files[0]);
    };


    const handleAddToFileList = () => {


        const newItem = {
            type: 'network', // or 'local' based on the source
            document: selectedImage,
            medical_document: imageUrl


        };

        console.log(imageUrl)

        setReportList([...reportList, newItem]);
        // Clear the selectedImage and imageUrl for the next input
        setSelectedImage(null);
        setImageUrl('');



        if (selectedImage !== null) {
            let data = {
                "name": activeTab,
                "type": cardName,
                "document": selectedImage,
                "medical_document": imageUrl,
                'success': false

            };
            let dataList = [...reportList];
            dataList.unshift(data);
            setReportList(dataList);
            console.log("mydata are");
            console.log(dataList)

        }


        // getReportLists();
    };

    // const handleRemoveFromFileList = (index) => {
    //     const updatedFileList = [...fileList];
    //     updatedFileList.splice(index, 1);
    //     setFileList(updatedFileList);
    // };

    const CardClick = (cardName, id) => {
        setActiveTab(cardName);
        setCatId(id);
        setSelectedCard(cardName);
        localStorage.setItem("typeName", cardName);
        console.log("localStorage:", localStorage.getItem("typeName"));
        const typeName = localStorage.getItem("typeName");
        const medical_document = localStorage.getItem("medical_document");
        setTypeName(typeName);
        setMedicalDocument(medical_document);
        getReportLists(id)

    };



    useEffect(() => {
        getreport()
    }, [])

    // ------------------ drop down list ------------
    const [reportType, setReportType] = React.useState([]);

    async function getreport() {
        const response = await con.getMedicalReportList();
        console.log(response)
        if (response['status'] === true) {
            setReportType(response['data'])
            const categoryId = response.data[0].id;
            localStorage.setItem("categoryId", categoryId);
            console.log("category id = " + localStorage.getItem("categoryId"))
            console.log("cid= " + localStorage.getItem("categoryId"))
        }
    }

    // ------------------------- Delete API --------

    async function getMedicalReportDelete(deleteMedicRepo) {
        const response = await con.MedicalReportDeleteAPI(deleteMedicRepo);
        console.log(response)
        if (response['status'] === true) {
            let afterAdded = reportList.filter(item => item.id !== deleteMedicRepo);
            setReportList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }

    function deleteFromLocal(index) {
        let data = [...reportList]
        data.splice(index, 1)
        setReportList(data)
    }

    // ------------------------------- report   list API -----------

    const [reportList, setReportList] = useState([])


    // useEffect(() => {
    //     getReportLists();
    // }, [])



    async function getReportLists(id) {
        const response = await con.medirepoListAPI({
            "patient_id": props.patient_id
        });


        if (response['status'] === true) {
            console.log(response['data'].map((t) => t.category_id))
            // let apiData = [];
            // for (let i = 0; i < response['data'].length; i++) {
            //     response['data'][i]['type'] = 'network';
            //     response['data'][i]['success'] = true;
            //     apiData.push(response['data'][i]);
            // }
            // const filteredmedicalreportList = response['data'].filter(item => item.category_id.toString() === id.toString());
            // setReportList(apiData, filteredmedicalreportList);
            let apiData = [];
            for (let i = 0; i < response['data'].length; i++) {
                response['data'][i]['type'] = 'network';
                response['data'][i]['success'] = true;
                apiData.push(response['data'][i]);
            }
            const filteredmedicalreportList = apiData.filter(item => item.category_id.toString() === id.toString()).reverse();
            setReportList(filteredmedicalreportList);
            // setReportList(filteredmedicalreportList);
            console.log(response)
        }
        // if (response['status'] === true) {
        //     const apiData = response['data'].map(item => ({
        //         ...item,
        //         type: 'network',
        //         success: true
        //     }));

        //     setReportList(apiData);
        //     console.log(response);
        // }
    }

    // ==============================================================================================================================


    function openNetWorkImage(data) {
        dispatch({ type: "set_value1", value: data, });
        dispatch({ type: "set_value2", value: 'network', });
        setIsOpen(true);
        // window.open(data, '_target')
    }

    function openLocalImage(data) {
        dispatch({ type: "set_value1", value: data, });
        dispatch({ type: "set_value2", value: 'local', });
        setIsOpen(true);
        // window.open(data, '_target')
    }

    const convertImageToBase64 = (imageUrl) => {
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result;
                    console.log(base64String);
                };
                reader.readAsDataURL(blob);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    function downloadImage() {
        if (state.image_type !== 'network') {
            const downloadLink = document.createElement('a');
            const imageURL = URL.createObjectURL(state.image);
            downloadLink.href = imageURL;
            downloadLink.download = state.image.name;
            downloadLink.click();
            URL.revokeObjectURL(imageURL);
        } else {
            convertImageToBase64(state.image);
            console.log(state.image)
            const downloadLink = document.createElement('a');
            downloadLink.href = state.image;
            downloadLink.download = 'locally.jpg';
            downloadLink.target = "_blank";
            downloadLink.rel = 'noopener noreferrer';
            downloadLink.click();
        }
    }




    const handleSuccessClick = (index, repolist) => {

        console.log(repolist)

        const meeting = reportList[index].document;
        try {

            const formData = new FormData();
            formData.append("category_id", catId);
            formData.append("patient_id", (props.patient_id));
            formData.append("document", repolist.document ?? "");
            // formData.append("medical_document", "https://media.istockphoto.com/id/1319031310/photo/doctor-writing-a-medical-prescription.jpg?s=612x612&w=0&k=20&c=DWZGM8lBb5Bun7cbxhKT1ruVxRC_itvFzA9jxgoA0N8=");
            formData.append("medical_document", repolist.medical_document);
            console.log(imageUrl)

            // const token = localStorage.getItem("token");
            const tokenStr = localStorage.getItem("token");

            const token =  JSON.parse(tokenStr)
            console.log(token)
            const response = fetch("https://api.kirthikadentalcare.in/api/savePatientMedicalReport",
                // const response = fetch("http://192.168.29.197:8000/api/savePatientMedicalReport",
                {
                    method: "POST",
                    body: formData,

                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                }).then(async response => {


                    var res = await response.json();
                    console.log(res)
                    if (res['status'] === true) {
                        // let afterAdded = [...reportList];
                        // afterAdded.push(response['data']);
                        // setReportList(afterAdded);
                        toast.success(res["message"], {
                            position: toast.POSITION.TOP_RIGHT,
                            className: 'foo_bar_log_Success'
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 800);
                    } else {
                        toast.error(res["message"], {
                            position: toast.POSITION.TOP_RIGHT,
                            className: 'foo_bar_log_error'
                        });
                    }
                });

        } catch (error) {
            console.log("Error occurred while making the API call:", error);
        }
    };


    return (

        <div>
            <div>
                <div className="typecard" >
                    {reportType && reportType.map((item, index) => (




                        <div
                            key={index}
                            className={activeTab === item.name ? 'active card-tab-medical cardt' : 'cardt'}
                            onClick={() => CardClick(item.name, item.id)}
                            style={{
                                fontSize: '13px',
                                display: 'flex',
                                padding: '10px 0',
                                border: '1px solid #E0E0E0',
                                cursor: 'pointer',
                                alignItems: "center",
                                justifyContent: 'center',
                                borderRadius: '10px',
                                color: 'black'
                            }}

                        >
                            <span className='medical-icons'>
                                {item.name === 'Photos' && <ImImages className="photo-icon" />}
                                {item.name === 'X-Ray' && < FaXRay className="xray-icon" />}
                                {item.name === 'Blood Report' && < ImDroplet className="blood-icon" />}
                                {item.name === 'OPG/CBCT' && <FaTeethOpen className="opg-icon" />}
                            </span>
                            <h5
                            // style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}
                            >{item.name}</h5>
                            {/* <p>{item.id}</p> */}

                        </div>
                    ))}
                </div>

                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                    (
                        <div>

                            {selectedCard && (

                                <div className="typedisplay">
                                    <label className="form-label me-5">
                                        <span className="required">File upload: (pdf, jpg, png, svg, documents) </span>
                                        <p>File size must be less than 5 MB.</p>

                                    </label>
                                    <br />
                                    <input type="file"
                                        onClick={handleImageChange}
                                        onChange={(e) => {
                                            setSelectedImage(e.target.files[0]);

                                        }}
                                    />
                                    <div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Image URL"
                                            style={{ height: "30px" }}
                                            onChange={(e) => setImageUrl(e.target.value)}


                                        />
                                    </div>
                                    <button className="btn btn-primary"

                                        onClick={() => {

                                            handleAddToFileList(imageUrl);
                                            setCopiedImageUrl(imageUrl);
                                            console.log(copiedImageUrl); // Set the copied URL
                                        }}

                                        style={{ height: "30px", marginLeft: "30px" }}>Add to List</button>
                                </div>
                            )}
                        </div>
                    ) : null}


                {/* typename = {typeName} */}

                <table className="bordered-table">
                    <thead className="tablehead">
                        <tr style={{ borderRadius: "10px" }}>
                            <th>S.No</th>
                            <th>File Type</th>
                            <th>Thumbnail</th>
                            <th>URL</th>
                            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                                (
                                    <th>Send</th>
                                ) : null}
                            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                                (
                                    <th>Delete</th>
                                ) : null}
                        </tr>
                    </thead>
                    <tbody>
                        {reportList.map((repolist, index) => (



                            <tr key={index}>
                                <td>{index + 1}</td>

                                <td>{repolist.category_name ? repolist.category_name : typeName}</td>
                                {/* <td>{repolist.document}</td> */}
                                {/* <td>
                                    <span style={{ display: 'flex', justifyContent: 'center' }}>

                                        {
                                            repolist.type === 'network' &&
                                            <img src={repolist.document} alt="Image"
                                                style={{ width: "50px", height: "auto" }}
                                                data-toggle="modal"
                                                data-target="#addstaff"
                                                onClick={() => { openNetWorkImage(repolist.document); }} />
                                        }

                                        {
                                            repolist.type !== 'network' &&
                                            <img
                                                src={repolist.type === 'network' ? repolist.document : URL.createObjectURL(repolist.document)}
                                                alt="Thumbnail"
                                                style={{ width: "50px", height: "auto" }}
                                                data-toggle="modal"
                                                data-target="#addstaff"
                                                onClick={() => { }}
                                            />
                                        }


                                    </span>
                                </td> */}
                                <td>
                                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                                        {repolist.document ? (
                                            <>
                                                {repolist.type === 'network' && (
                                                    <img
                                                        src={repolist.document}
                                                        alt="Image"
                                                        style={{ width: "50px", height: "auto" }}
                                                        data-toggle="modal"
                                                        data-target="#addstaff"
                                                        onClick={() => { openNetWorkImage(repolist.document); }}
                                                    />
                                                )}

                                                {repolist.type !== 'network' && (
                                                    <img
                                                        src={
                                                            repolist.type === 'network'
                                                                ? repolist.document
                                                                : URL.createObjectURL(repolist.document)
                                                        }
                                                        alt="Thumbnail"
                                                        style={{ width: "50px", height: "auto" }}
                                                        data-toggle="modal"
                                                        data-target="#addstaff"
                                                        onClick={() => { /* handle click if needed */ }}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <p>There is no file.</p>
                                        )}
                                    </span>
                                </td>

                                {/* <td>{repolist.medical_document}</td> */}
                                <td>
                                    {repolist.medical_document ? (
                                        <a href={repolist.medical_document} target="_blank" rel="noopener noreferrer">
                                            {repolist.medical_document}
                                        </a>
                                    ) : (
                                        <p>There is no url.</p>
                                    )}
                                </td>

                                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                                    (

                                        <td>
                                            <td style={{ display: "flex", justifyContent: "center" }}>
                                                {!repolist.success ? (
                                                    <span
                                                        style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
                                                        onClick={() => handleSuccessClick(index, repolist)}
                                                    >
                                                        <FaCheck className="check" style={{ color: "green" }} />
                                                    </span>
                                                ) : (
                                                    <span
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            color: "green",
                                                            borderRadius: "5px",
                                                            padding: "3px",
                                                        }}
                                                    >
                                                        Saved
                                                    </span>
                                                )}
                                            </td>
                                        </td>
                                    ) : null}
                                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                                    (
                                        <td>
                                            {
                                                repolist.type === 'network' &&
                                                <RiDeleteBin2Line
                                                    className="delete_icon"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => getMedicalReportDelete(repolist.id)}
                                                />
                                            }
                                            {
                                                repolist.type !== 'network' &&
                                                <FaXmark
                                                    className="delete_icon"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => deleteFromLocal(index,)}
                                                />
                                            }
                                        </td>
                                    ) : null}
                            </tr>

                        ))}
                    </tbody>
                </table>
            </div>



            {isOpen && (


                <div className="container">
                    <div className="modal fade" id="addstaff" role="dialog">
                        <div className="po_sc_in">

                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600", }} >DOWNLOAD <span className="ms-4" style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faDownload} onClick={() => { downloadImage(); }} />
                                    </span></h4>
                                    <button type="button" className="close" style={{ marginRight: "-250px", color: "blue", fontSize: "30px" }} data-dismiss="modal" onClick={togglePopup} >
                                        ×
                                    </button>

                                </div>
                                <div className="modal-body">


                                    {
                                        state.image_type === 'network' &&
                                        <img src={state.image} alt="Image"
                                        />
                                    }

                                    {
                                        state.image_type !== 'network' &&
                                        <img
                                            src={URL.createObjectURL(state.image)}
                                            alt="Thumbnail"

                                        />
                                    }




                                </div>
                                <div className="modal-footer">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            )}





        </div >
    );
};


export default MedicalReport;