import React, { useEffect, useState } from "react"
import * as con from '../../../repository/api_calls';



const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};




const NetProfit = () => {

    const [AccList, setAccList] = useState([]);


    const [searchTerm, setSearchTerm] = useState("");
    const searchedAccList = AccList.filter((c) =>
        c.date.includes(searchTerm)
    );


    useEffect(() => {
        AccountList();
    }, [])

    async function AccountList() {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
        const response = await con.listNetProfit((JSON.stringify({ month: currentMonth })));
        console.log(response)
        console.log('iiiiimmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm')

        if (response['status'] === true) {
            setAccList(response.data);
            console.log(response.data)
            console.log(setAccList)


        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentsearchedAccList = searchedAccList.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <React.Fragment>

            <div style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>
                <input className="form-control searchBar mb-5" type="text"
                    placeholder="Global Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />


                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px", cursor: "pointer" }}>
                            <th>S. No</th>
                            <th>Year & Month</th>
                            <th>Credit</th>
                            <th>Debit</th>
                            <th>Net Value</th>
                        </tr>
                    </thead>

                    <tbody>
                        {currentsearchedAccList.map((payment, index) => {
                            const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;

                            console.log(currentsearchedAccList)
                            console.log(payment)
                            const income = ((payment.patient_clinic_income ? payment.patient_clinic_income : 0) + (payment.pharmacy_income ? payment.pharmacy_income : 0))
                            const debit = ((payment.pharmacy_account ? payment.pharmacy_account : 0) + (payment.inventory_accounts ? payment.inventory_accounts : 0) + (payment.lab_accounts ? payment.lab_accounts : 0) + (payment.consultant_payment ? payment.consultant_payment : 0) + (payment.doctor_salary ? payment.doctor_salary : 0) + (payment.staff_salary ? payment.staff_salary : 0) + (payment.stationery_accounts ? payment.stationery_accounts : 0))
                            const netvalue = ((income - debit))
                            return (
                                <tr key={index}>
                                    <td>{continuousIndex}</td>
                                    <td>{payment.date}</td>
                                    {/* <td>{((payment.patient_clinic_income ? payment.patient_clinic_income : 0) + (payment.pharmacy_income ? payment.pharmacy_income : 0))}</td> */}
                                    <td>{income}</td>
                                    {/* <td>{((payment.pharmacy_account ? payment.pharmacy_account : 0) + (payment.inventory_accounts ? payment.inventory_accounts : 0) + (payment.lab_accounts ? payment.lab_accounts : 0) + (payment.consultant_payment ? payment.consultant_payment : 0) + (payment.doctor_salary ? payment.doctor_salary : 0) + (payment.staff_salary ? payment.staff_salary : 0) + (payment.stationery_accounts ? payment.stationery_accounts : 0))}</td> */}
                                    <td>{debit}</td>
                                    <td>{netvalue}</td>
                                </tr>
                            );
                        })
                        }
                    </tbody>
                </table>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={searchedAccList.length}
                    currentPage={currentPage}
                    paginate={paginate}
                />
            </div>

        </React.Fragment>
    )
}

export default NetProfit