import React, { useState } from "react";
import Calendar from "react-calendar";
import Card from "components/card";
import "react-calendar/dist/Calendar.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "assets/css/MiniCalendar.css";

const MiniCalendar = (props) => {
  // const token = localStorage.getItem("token");
  const [value, onChange] = useState(new Date());
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const fdate = `${year}-${month}-${day}`;
  const Sdate = `${day}-${month}-${year}`;


  // props.date= fdate
  props.date(fdate)
  // props.date(fdate)



  return (
    <div style={{ marginLeft: "20px" }}>
      <Card extra="flex w-full h-full flex-col px-3 py-3" >
        <Calendar style={{ with: "auto" }}
          onChange={onChange}
          value={value}
          prevLabel={<MdChevronLeft className="ml-1 h-6 w-6 " />}
          nextLabel={<MdChevronRight className="ml-1 h-6 w-6 " />}
          view={"month"}

        />
      </Card>
    </div>
  );
};

export default MiniCalendar;
