import React, { useState } from "react"
import * as con from '../../../repository/api_calls';
import { useReducer, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Doctor from "assets/img/profile/doctor.png"
import './myprofile.css'
import { FiEdit2 } from "react-icons/fi";

function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            image: state.image,
            first_name: state.first_name,
            surname: state.surname,
            phone: state.phone,
            address: state.address,
            email: state.email,
            age: state.age,
            dob: state.dob,
            gender: state.gender,
            qualification: state.qualification,
            id: state.id,
            new_password: state.new_password,
            confirm_password: state.confirm_password

        };
    }
    if (action.type === 'set_base64') {
        return {
            image: action.value,
            first_name: state.first_name,
            surname: state.surname,
            phone: state.phone,
            address: state.address,
            email: state.email,
            age: state.age,
            dob: state.dob,
            gender: state.gender,
            qualification: state.qualification,
            id: state.id,
            new_password: state.new_password,
            confirm_password: state.confirm_password
        }
    }

}

function Adminprofile(props) {
    const userRoleId = localStorage.getItem('role_id');

    const userdata = JSON.parse(localStorage.getItem("userdata"));
    console.log(userdata)
    const [open, setOpen] = React.useState(true);


    // ------------------ staff category drop down list ------------

    useEffect(() => {
        dispatch({ type: 'set_value', value: userdata?.user?.id, key: 'id' })
        dispatch({ type: 'set_value', value: userdata?.user?.image, key: 'image' })
        dispatch({ type: 'set_value', value: userdata?.user?.first_name, key: 'first_name' })
        dispatch({ type: 'set_value', value: userdata?.user?.surname, key: 'surname' })
        dispatch({ type: 'set_value', value: userdata?.user?.phone, key: 'phone' })
        dispatch({ type: 'set_value', value: userdata?.user?.address, key: 'address' })
        dispatch({ type: 'set_value', value: userdata?.user?.email, key: 'email' })
        dispatch({ type: 'set_value', value: userdata?.user?.dob, key: 'dob' })
        dispatch({ type: 'set_value', value: userdata?.user?.age, key: 'age' })
        dispatch({ type: 'set_value', value: userdata?.user?.gender, key: 'gender' })
        dispatch({ type: 'set_value', value: userdata?.user?.status, key: 'status' })
        dispatch({ type: 'set_value', value: userdata?.user?.qualification, key: 'qualification' })
        dispatch({ type: 'set_value', value: userdata?.user?.new_password, key: 'new_password' })
        dispatch({ type: 'set_value', value: userdata?.user?.confirm_password, key: 'confirm_password' })

    }, [])


    const [state, dispatch] = useReducer(reducer, {
        image: '', first_name: '', suranme: '', qualification: "",
        phone: '', address: '', email: '', dob: '', age: '', gender: '', id: ''
    })

    const submitEditAdminprofile = (event) => {
        event.preventDefault();
        UpdateAdmin(state);
    }

    async function UpdateAdmin(adminbodyData) {
        const response = await con.UpdateAdminAPI((JSON.stringify(adminbodyData)));
        console.log(response)
        if (response['status'] === true) {
            localStorage.setItem('userdata', JSON.stringify({ "user": response['data'] }))
            dispatch({ type: 'set_value', value: '', key: 'id' })
            dispatch({ type: 'set_value', value: '', key: 'image' })
            dispatch({ type: 'set_value', value: '', key: 'first_name' })
            dispatch({ type: 'set_value', value: '', key: 'surname' })
            dispatch({ type: 'set_value', value: '', key: 'qualification' })
            dispatch({ type: 'set_value', value: '', key: 'phone' })
            dispatch({ type: 'set_value', value: '', key: 'email' })
            dispatch({ type: 'set_value', value: '', key: 'address' })
            dispatch({ type: 'set_value', value: '', key: 'dob' })
            dispatch({ type: 'set_value', value: '', key: 'age' })
            dispatch({ type: 'set_value', value: '', key: 'gender' })
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                window.location.href = "/profile";
            }, 800);

        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }

    // ------------- profile image --------

    const [image, setImage] = useState(null);
    const [isImageSelected, setIsImageSelected] = useState(false);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        let base64String = '';
        reader.onloadend = () => {
            base64String = reader.result;
            setImage(reader.result);
            setIsImageSelected(true);
            setImageValue(base64String);
            console.log(base64String)
        };
        reader.readAsDataURL(file);
    };
    function handleChangePassword(e) {
        e.preventDefault()
        changePassword()
    }
    console.log(state.confirm_password, userdata)
    async function changePassword() {
        const responseadd = await con.changePassword({
            "patient_id": userdata.user.id,
            "id": userdata.user.id,
            "password": state.new_password,
            "confirm_password": state.confirm_password,
        });
        if (responseadd['status'] == true) {
            console.log(responseadd['status'])
            toast.success(responseadd['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
        else {
            console.log(responseadd)
            toast.error(responseadd['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }

    function setImageValue(result) {
        const splitUps = result.split(',')
        dispatch({ type: "set_base64", value: splitUps[1] });
    }

    const handleProfileClick = () => {
        document.getElementById('profileImageInput').click();
    };

    const handleRemoveProfile = () => {
        setImage(null);
        setIsImageSelected(false);
    };

    return (

        <div className="profilediv" >
            <ToastContainer autoClose={3000} />
            <div className="row justify-content-around user-info" >

                {/* ----------------------------------- Admin Profile----------------------------- */}

                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                    (
                        <div className='row ms-4 ' >

                            <div className="mb-5 mt-4 col-2">
                                <img src={state.image === "" ? Doctor : state.image}
                                    alt="Profile" className="profileImage  " style={{ width: "140px", height: "140px", borderRadius: "50%", marginLeft: "35px" }} />

                            </div>
                            <div className="col-7 ms-5">

                                <p className="fullname"><span>{userdata?.user?.first_name}</span><span className="surname" >{userdata?.user?.surname}</span></p>
                                <p className="address" >{userdata?.user?.address}</p>
                            </div>
                            <div className="col-2 ms-5" >

                                <button
                                    className="edit"
                                    aria-hidden="true"
                                    data-toggle="modal"
                                    data-target="#adminprofile"
                                >
                                    <span className="edit-span" > Edit</span>
                                    <span className="edit-icons" >< FiEdit2 /></span>

                                </button>

                            </div>
                            <div className="row">
                                <div className="col-2 " >

                                    <button
                                        className="change"
                                        aria-hidden="true"
                                        data-toggle="modal"
                                        data-target="#passwordchange"
                                    >
                                        <span className="change-span" >Change </span>
                                        <span className="change-span">Password</span>
                                        <span className="change-icons" >< FiEdit2 /></span>

                                    </button>

                                </div>
                            </div>
                            <hr className="line" />
                            <div className='row ms-5'>
                                <div className='col-12'>
                                    <p className="personal" >Personal Information</p>
                                </div>

                            </div>
                            <div className='row ms-5 info' >
                                <div className='col-3'>
                                    <div className="field" ><h3>First Name</h3></div>
                                    <div className="ans"><p>{userdata?.user?.first_name}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Surname</h3></div>
                                    <div className="ans"><p>{userdata?.user?.surname}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Phone Number</h3></div>
                                    <div className="ans"><p>{userdata?.user?.phone}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Qualification</h3></div>
                                    <div className="ans"><p>{userdata?.user?.qualification}</p></div>
                                </div>

                            </div>
                            <div className='row ms-5 info'>

                                <div className='col-3'>
                                    <div className="field"><h3>D.O.B</h3></div>
                                    <div className="ans"><p>{userdata?.user?.dob}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Address</h3></div>
                                    <div className="ans"><p>{userdata?.user?.address}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Age</h3></div>
                                    <div className="ans"><p>{userdata?.user?.age}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Gender</h3></div>
                                    <div className="ans"><p>{userdata?.user?.gender}</p></div>
                                </div>
                            </div>
                            <div className='row ms-5 low-row'>

                                <div className='col-3'>
                                    <div className="field"><h3>Email</h3></div>
                                    <div className="ans"><p>{userdata?.user?.email}</p></div>
                                </div>

                            </div>
                        </div >

                    ) : null}
            </div>


            {/* ---------------------------------- update Docter Profile-------------------------------------- */}

            <section>
                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                    (
                        <div className="container">


                            <div className="modal fade" id="adminprofile" role="dialog">
                                <div className="po_sc_in">

                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Update My profile</h4>
                                            <button type="button" className="close" style={{ marginRight: "-250px", color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                                ×
                                            </button>

                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={submitEditAdminprofile}>

                                                <div className="mb-5 mt-3">
                                                    <p className="text-center"><b>Profile Image</b></p>
                                                    <input
                                                        id="profileImageInput"
                                                        type="file"
                                                        onChange={handleImageUpload}
                                                        style={{ display: 'none' }}

                                                    />
                                                    <div>
                                                        <div className="profileContainer" onClick={handleProfileClick} style={{ backgroundColor: "#F4F4F4", width: "100px", height: "100px", borderRadius: "50%", cursor: "pointer", margin: "auto", display: "block" }}>
                                                            {isImageSelected ? (
                                                                <div>
                                                                    <img src={image} alt="Profile" className="profileImage" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                                                                    <button onClick={handleRemoveProfile} style={{ marginTop: "8px", marginLeft: "25px", color: "red" }}>Remove</button>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <img src={state.image === "" ? Doctor : state.image} alt="Profile" className="profileImage" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />

                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Name:</span>
                                                        </label>
                                                        <input name="first_name" type="text" className="form-control"
                                                            placeholder=" Name"
                                                            value={state.first_name}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                                if (sanitizedValue.length > 150) {
                                                                    sanitizedValue = sanitizedValue.slice(0, 150);
                                                                }
                                                                const regex = /(.)\1{4,}/g;
                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'first_name' });
                                                            }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Surname:</span>
                                                        </label>
                                                        <input name="surname" type="text" className="form-control"
                                                            placeholder="Surname"
                                                            value={state.surname}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                                if (sanitizedValue.length > 100) {
                                                                    sanitizedValue = sanitizedValue.slice(0, 100);
                                                                }
                                                                const regex = /(.)\1{4,}/g;
                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'surname' });
                                                            }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Phone Number</span>
                                                        </label>
                                                        <input name="phone" type="text" className="form-control"
                                                            placeholder="Phone Number"
                                                            minLength={10} maxLength={10}
                                                            value={state.phone}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'phone' });
                                                            }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Qualification</span>
                                                        </label>
                                                        <input name="qualification" type="text" className="form-control"
                                                            placeholder="Qualification"
                                                            value={state.qualification}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                                if (sanitizedValue.length > 150) {
                                                                    sanitizedValue = sanitizedValue.slice(0, 150);
                                                                }
                                                                const regex = /(.)\1{4,}/g;
                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'qualification' });
                                                            }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Email</span>
                                                        </label>
                                                        <input name="email" type="email" className="form-control"
                                                            placeholder="Email"
                                                            value={state.email}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'email' }); }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Address</span>
                                                        </label>
                                                        <input name="address" type="address" className="form-control"
                                                            placeholder="Address"
                                                            value={state.address}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'address' }); }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Age</span>
                                                        </label>
                                                        <input name="age" type="text" className="form-control"
                                                            placeholder="Age"
                                                            maxLength={3}
                                                            value={state.age}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'age' });
                                                            }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">D.O.B</span>
                                                        </label>
                                                        <input name="date" type="date" className="form-control"
                                                            placeholder="Date"
                                                            value={state.dob}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'dob' }); }}
                                                            required />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mt-3">
                                                        <div className="form-group mb-5">
                                                            <label className="form-label">
                                                                <span className="required"></span>Gender
                                                            </label>
                                                            <br />
                                                            <div className="d-flex align-items-center">
                                                                <div className="form-check me-5">
                                                                    <label className="container-radio" htmlFor="male">  Male
                                                                        <input type="radio" id="male" name="gender"
                                                                            required
                                                                            value={state.gender}
                                                                            checked={state.gender === 'Male'}
                                                                            onChange={(e) => { dispatch({ type: 'set_value', value: 'Male', key: 'gender' }); }}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="form-check me-10">
                                                                    <label className="container-radio" htmlFor="female">       Female
                                                                        <input type="radio" id="female" name="gender"
                                                                            value={state.gender}
                                                                            checked={state.gender === 'Female'}
                                                                            onChange={(e) => { dispatch({ type: 'set_value', value: 'Female', key: 'gender' }); }}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Update</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
            </section>

            {/* -------------------------password change------------------ */}
            <section >
                <div className="container">


                    <div className="modal fade" id="passwordchange" role="dialog">
                        <div className="po_sc_in">

                            <div className="modal-content" style={{ width: '65%', marginLeft: '200px' }}>
                                <div className="modal-header">
                                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Change Password</h4>
                                    <button type="button" className="close" style={{ marginRight: "-150px", color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                        ×
                                    </button>

                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleChangePassword}>

                                        <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                            <div className="col-md-12 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">New Password</span>
                                                </label>
                                                <input name="first_name" type="password" className="form-control"
                                                    placeholder="Enter New Password"
                                                    value={state.new_password}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: "new_password" }); }}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-12 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required"> Confirm New Password</span>
                                                </label>
                                                <input name="last_name" type="password" className="form-control"
                                                    placeholder="Cofirm New Password"
                                                    value={state.confirm_password}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'confirm_password' }); }}
                                                    required />
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Change </button>
                                    </form>
                                </div>
                                <div className="modal-footer">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Adminprofile