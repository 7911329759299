import { useEffect, useState, useReducer, useContext } from 'react';
import * as con from '../../repository/api_calls';
import { UserContext } from "../../patient/exp"
import { Multiselect } from "multiselect-react-dropdown";
import "./style.css"
import { ToastContainer, toast } from 'react-toastify';
import { FaCaretDown } from 'react-icons/fa';
import { data } from 'autoprefixer';


// function treatmentReducer(treatmentState, action) {
//   switch (action.type) {
//     case 'UPDATE_TREATMENT_PLAN':
//       return {
//         ...treatmentState,
//         summary: {
//           ...treatmentState.summary,
//           treatmentPlan: action.payload,
//         },
//       };
//     case 'UPDATE_TREATMENT_PROCEDURE':
//       return {
//         ...treatmentState,
//         summary: {
//           ...treatmentState.summary,
//           treatmentProcedure: action.payload,
//         },
//       };
//     case 'UPDATE_TREATMENT_METHOD':
//       return {
//         ...treatmentState,
//         summary: {
//           ...treatmentState.summary,
//           treatmentMethod: action.payload,
//         },
//       };
//     case 'UPDATE_TREATMENT_PRICE':
//       return {
//         ...treatmentState,
//         summary: {
//           ...treatmentState.summary,
//           treatmentPrice: action.payload,
//         },
//       };
//       case 'set_value':
//         return {
//           ...treatmentState,
//         summary: {
//           ...treatmentState.summary,
//           tPlanList: action.payload,
//         },
//         }
//     default:
//       return treatmentState;
//   }
// }

const TreatmentPlanForm = (props) => {

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [treatmentPlanList, setTreatmentPlanList] = useState([]);
  const [treatmentProcedureList, setTreatmentProcedureList] = useState([]);
  const [treatmentMethodList, setTreatmentMethodList] = useState([]);


  const [items, setItems] = useState([]);
  const [itemsP, setItemsP] = useState([]);
  const [itemsPr, setItemsPr] = useState([]);
  const [itemsA, setItemsA] = useState([]);
  const [formOpen, setFormOpen] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  // const [discounts, setDiscounts] = useState([0]);
  const [discountPrice, setDiscountPrice] = useState([])

  const patient_id = props.patient

  // console.log(patient_id.id)


  useEffect(() => {
    setTreatmentPlanList()
  }, [])

  // const message = showMessage ? (
  //   <div className="message-card">
  //     Treatment has been saved
  //   </div>
  // ) : null;



  const result = props.dpValue.map((t, index) => ({
    name: t.treatment_plan,
    index: index,
    id: t.id,
  }));
  console.log(props.dpValue);
  // console.log(result.map((t)=>t.name));


  const result1 = treatmentProcedureList.map((t, index) => ({
    sname: t.treatment_procedure,
    index: index,
    id: t.id
  }));
  console.log(result1)

  const result2 = treatmentMethodList.map((t, index) => ({
    mname: t.treatment_method,
    index: index,
    id: t.id,
    price: t.method_price
  }));
  console.log(result2)



  const handleSubmit = (e) => {
    e.preventDefault();
  };

  console.log(itemsPr);

  const handleSelect = (selectedList) => {
    console.log(selectedList);
    setItems(selectedList);
    // // getTreatmentProcedureName(selectedList)
    getTreatmentProcedureName(selectedList.map((t) => t.id))
  };
  const handleSelectP = (selectedList) => {
    getTreatmentMethodName(selectedList.map((t) => t.id))
    setItemsP(selectedList);
  };
  const handleSelectPr = (selectedList) => {
    setItemsPr(selectedList);
  };
  const handleSelectA = (selectedList) => {
    setItemsA(selectedList);
  };



  const handleRemove = (selectedList) => {
    setItems(selectedList);
  };


  const [overallDiagnosis, setOverallDiagnosis] = useState([])

  const contextValue = useContext(UserContext);
  const datas = props.data;

  const selectedTreatment = props.treatment;

  const { type, problem, tooth, index } = datas


  // useEffect(()=>{
  //   setDatas(props.data)
  // },[props.data])

  console.log(props.data)
  console.log(datas)
  console.log(selectedTreatment)

  console.log(type, problem, tooth, index)



  //////////////////////////// TreatmentPlan Input Form
  // const initialState = {
  //   tooth_data: [datas],
  //   summary: {
  //     treatmentPlan: '',
  //     treatmentProcedure: '',
  //     treatmentMethod: '',
  //     treatmentPrice: '',
  //     tPlanList:''
  //   },
  // };

  // const [treatmentState, dispatcher] = useReducer(treatmentReducer, initialState);




  function save(e) {

    // console.log(datas.data.problem.map((t)=>t.id))
    // console.log(treatmentPlanList)
    // console.log(treatmentState.summary)
    // setShowMessage(true);

    // setTimeout(() => {
    //   setShowMessage(false);
    // }, 5000);

    // "type": "tooth",
    // "problem": {
    //     "id": 72,
    //     "diagnosis_type": "27",
    //     "name": "Missing",
    //     "status": 1,
    //     "created_at": "2023-09-25 19:36:29",
    //     "updated_at": null,
    //     "dname": "Tooth Specific"
    // },
    // "tooth": "54",
    // "index": 1,


    console.log(datas)
    // const { treatmentPlan, treatmentProcedure, treatmentMethod, treatmentPrice, tPlanList } = treatmentState.summary;
    const updatedSelect = {
      datas,
      // treatmentPlan,
      // treatmentProcedure,
      // treatmentMethod,
      // treatmentPrice,
      // tPlanList,
      items,
      itemsP,
      itemsPr,
      // discounts,
      discountPrice,

    }
    const updatedSelectEdit = {
      type,
      problem,
      tooth,
      index,
      // treatmentPlan,
      // treatmentProcedure,
      // treatmentMethod,
      // treatmentPrice,
      // tPlanList,
      items,
      itemsP,
      itemsPr,
      // discounts,
      discountPrice,

    }
    e.preventDefault()

    if (props.edit) {
      updateSummary({ 'id': props.data.id, "patient_id": patient_id.id, 'patient_treatment': updatedSelectEdit })
    }

    (props.fun(updatedSelect, props.index),

      setOverallDiagnosis([...overallDiagnosis, updatedSelect]))


    console.log(updatedSelect)
    setItems([]);
    setItemsP([]);
    setItemsPr([]);
    toast.success('Treatment has been saved', {
      position: toast.POSITION.TOP_RIGHT,
      className: 'foo_bar_log_Success'
    });

    // props.form(false)

    // setFormOpen(false)
  }

  contextValue.dentalDiagnosis.push(overallDiagnosis);
  ///////////////////////////////////////////////////////////////////
  console.log(overallDiagnosis)
  // useEffect(()=>{
  //   // getTreatmentPlanName()
  //   getTreatmentPlanName(datas.problem.id)
  //   console.log(props.data)

  // },[])


  // async function getTreatmentPlanName(id) {
  //   const response = await con.treatmentplanList();
  //   console.log(response['data'])
  //   if (response['status'] === true) {
  //     // const filteredDentalList = response['data']
  //     const filteredDentalList = response['data'].filter((t)=> t.diagnosis_id.toString() === id.toString())
  //     setTreatmentPlanList(filteredDentalList);
  //     console.log(filteredDentalList)

  //   }
  // }
  async function getTreatmentProcedureName(id) {
    console.log("jaga", id)
    const response = await con.TreatmPrcedList();
    console.log(response['data'])
    if (response['status'] === true) {

      // const filteredDentalList = response['data']
      for (let i = 0; i < id.length; i++) {
        const filteredDentalList = response['data'].filter((t) => t.treatment_plan_id.toString() === id[i].toString())
        setTreatmentProcedureList(filteredDentalList);
        console.log(filteredDentalList)
      }



    }
  }
  async function getTreatmentMethodName(id) {
    const response = await con.treatmentmethodList();
    console.log(response['data'])
    if (response['status'] === true) {
      for (let i = 0; i < id.length; i++) {
        const filteredDentalList = response['data'].filter((t) => t.treatment_procedure_id.toString() === id[i].toString())
        setTreatmentMethodList(filteredDentalList);
        console.log(filteredDentalList)
      }


      // const filteredDentalList = response['data'].filter((t) => t.treatment_procedure_id.toString() === id.toString())
      // setTreatmentMethodList(filteredDentalList);
      // console.log(filteredDentalList)
      // console.log(filteredDentalList.map((t) => t.method_name))
    }
  }

  const calculateTotalCost = (prices, discount) => {
    const total = prices.reduce((acc, price) => acc + price, 0);
    console.log(total)
    setDiscountPrice(total)
    return total;
  };
  console.log(discountPrice)


  useEffect(() => {
    calculateTotalCost(
      itemsPr !== undefined
        ? itemsPr.map((t) => parseFloat(t.price))
        : [],
    )
  }, [itemsPr])


  async function updateSummary(summary) {
    const response = await con.UpdateTreatmentPlanMappingAPI((JSON.stringify(summary)));
    if (response['status'] === true) {
      setTimeout(() => {
        window.location.reload();
      }, 800);

    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }




  return (
    <>
      {formOpen &&
        <div className='container-fluid treatmentPlanForm'>
          <div style={{ height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

            <table className="bordered-table">
              <tr>
                <th>Diagnosis Type :</th>
                <td>{datas.type}</td>
                {/* <td style={{ textTransform: "uppercase" }}><b></b></td> */}
                {datas.type === 'tooth' && (<><th>Tooth No:</th><td>{datas.tooth}</td></>)}
                <th>Diagnosis:</th>

                <td>
                  {/* {datas.problem.name} */}
                  {datas.problem !== undefined ? datas.problem.name : "-"}

                </td>


              </tr>
            </table>
          </div>
          {props.edit ? <div>
            <p>Previously Selected Treament</p>
            <p> <b> Treatment Plan:</b> {datas.items !== undefined ? datas.items.map((t) => t.name).join(", ") : ''}</p>
            <p><b>Treatment Procedure: </b> {datas.itemsP !== undefined ? datas.itemsP.map((t) => t.sname).join(", ") : ""}</p>
            <p><b>Treatment Method: </b> {datas.itemsPr !== undefined ? datas.itemsPr.map((t) => t.mname).join(", ") : ""}</p>
          </div> : ''}



          <>

            <div className="mb-sm-7 mb-4 dropdownMenu">
              <label className="form-label">
                <span className="required">Treatment Plan:</span>
              </label>

              <div className='form-dropdown' onSubmit={handleSubmit}>
                <Multiselect
                  options={result}
                  selectedValues={items}
                  onSelect={handleSelect}
                  onRemove={handleRemove}
                  displayValue="name"
                  caretIcon={<FaCaretDown />}
                />
              </div>


            </div>
            <div className="mb-sm-7 mb-4 dropdownMenu">
              <label className="form-label">
                <span className="required">Treatment Procedure:</span>
              </label>

              <form className='form-dropdown' onSubmit={handleSubmit}>
                <Multiselect
                  options={result1}
                  selectedValues={itemsP}
                  onSelect={handleSelectP}
                  onRemove={handleRemove}
                  displayValue="sname"
                  caretIcon={<FaCaretDown />}
                />
              </form>


            </div>
            <div className="mb-sm-7 mb-4 dropdownMenu">
              <label className="form-label">
                <span className="required">Treatment Method:</span>
              </label>


              <form className='form-dropdown' onSubmit={handleSubmit}>
                <Multiselect
                  options={result2}
                  selectedValues={itemsPr}
                  onSelect={handleSelectPr}
                  onRemove={handleRemove}
                  displayValue="mname"
                  caretIcon={<FaCaretDown />}
                />
              </form>



            </div>
            <div className='dropdownMenu'>
              <label className="form-label">
                <span className="required">Treatment Method Price:</span>
              </label>

              <div>
                {itemsPr !== undefined ? itemsPr.map((t) => t.price).join(", ") : "-"}
              </div>

            </div>
            {/* <div>
              <label className="form-label">
                <span className="required">Discount:</span>
              </label>

              <div>
                <input type="text" value={discounts !== undefined ? discounts : ""} onChange={(e) => {
                  let updatedDiscounts = [...discounts];
                  updatedDiscounts = e.target.value;
                  setDiscounts(updatedDiscounts);
                }} maxLength="3" />
              </div>

            </div> */}
            <div className='dropdownMenu price'>
              <label className="form-label">
                <span className="required">Total:</span>
              </label>

              <div>
                {/* {calculateTotalCost(
                  itemsPr !== undefined
                    ? itemsPr.map((t) => parseFloat(t.price))
                    : []
                )} */}
                {discountPrice}
              </div>

            </div>
          </>


          <button className="btn btn-primary formSave" onClick={save}
            style={{ color: "white", padding: "5px 20px", borderRadius: "10px", }}>
            Save
          </button>
        </div>}
      {/* {message} */}
    </>
  )
}

export default TreatmentPlanForm