import React, { useState, useEffect } from "react";
import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
// import './setting.css';
import * as con from '../../../repository/api_calls'
import Dropdown from "components/dropdown";
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";
import 'react-toastify/dist/ReactToastify.css';




function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            treatment_procedure_id: state.treatment_procedure_id,
            treatment_method: state.treatment_method,
            method_price: state.method_price,
            id: state.id,
            doctor_price: state.doctor_price
        };
    }


}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};


const Treatmentmethod = (props) => {

    const { ...rest } = props;

    // ------------------------------- add diagnosis ------------

    const [state, dispatch] = useReducer(reducer, {
        treatment_procedure_id: '', treatment_method: '', method_price: '', id: '', doctor_price: ''
    })
    const [open, setOpen] = useState(true);

    // const submitAddTreatMethType = (event) => {
    //     event.preventDefault();
    //     addtreMethtyp(state);
    // }


    const submitAddTreatMethType = (event) => {
        event.preventDefault();
        // addtreMethtyp(state);
        if (state.id != '') {
            addtreMethtyp(state, 'edit');
            setDropdownOpen(false)
        } else {
            addtreMethtyp(state, 'add');
            setDropdownOpen(false)
        }
    }


    async function addtreMethtyp(addtreatMethData, type) {
        if (type == 'add') {
            const response = await con.CreateTreatMethTypeAPI((JSON.stringify(addtreatMethData)));
            console.log(response)
            // let afterAdded = [...treatmentmethodList];
            // afterAdded.push(response['data']);
            // console.log()
            // setTreatmethodList(afterAdded);
            if (response['status'] === true) {
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        } else {
            const response = await con.UpdateTreatmenMethodAPI((JSON.stringify(addtreatMethData)));
            console.log(response)
            if (response['status'] === true) {
                // let afterAdded = treatmentmethodList.map(item => {
                //     if (item.id === state.id) {
                //         return response.data;
                //     }
                //     return item;
                // });
                dispatch({ type: 'set_value', value: '', key: 'id' })
                dispatch({ type: 'set_value', value: '', key: 'treatment_procedure_id' })
                dispatch({ type: 'set_value', value: '', key: 'method_price' })
                dispatch({ type: 'set_value', value: '', key: 'treatment_method' })
                dispatch({ type: 'set_value', value: '', key: 'doctor_price' })
                // setTreatmethodList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        }
    }

    // -----------------------------  add method button dropdown
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'treatment_procedure_id' })
        dispatch({ type: 'set_value', value: '', key: 'treatment_method' })
        dispatch({ type: 'set_value', value: '', key: 'method_price' })
        dispatch({ type: 'set_value', value: '', key: 'doctor_price' })

    };
    //------------------------for cancel action
    const handleCancel = () => {

        setDropdownOpen(false);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'treatment_procedure_id' })
        dispatch({ type: 'set_value', value: '', key: 'treatment_method' })
        dispatch({ type: 'set_value', value: '', key: 'method_price' })
        dispatch({ type: 'set_value', value: '', key: 'doctor_price' })
    };
    // -----------------------------  for edit method button dropdown---------

    function handleForEditButtonClick(treamethod) {
        setDropdownOpen(true);
        console.log(treamethod)

        dispatch({ type: 'set_value', value: treamethod.id, key: 'id' })
        dispatch({ type: 'set_value', value: treamethod.treatment_procedure_id, key: 'treatment_procedure_id' })
        dispatch({ type: 'set_value', value: treamethod.treatment_method, key: 'treatment_method' })
        dispatch({ type: 'set_value', value: treamethod.method_price, key: 'method_price' })
        dispatch({ type: 'set_value', value: treamethod.doctor_price, key: 'doctor_price' })

    };

    // ------------------ drop down 1 list ------------

    const [treatmentProcedList, setTreatmentProcedList] = React.useState();
    useEffect(() => {
        TreatmProcedureLists()
    }, [])

    async function TreatmProcedureLists() {
        const response = await con.TreatmPrcedList();
        console.log(response)
        if (response['status'] === true) {
            setTreatmentProcedList(response['data'])
            dispatch({ type: 'set_value', value: response['data'][0]['id'], key: 'treatment_procedure_id' });
        }
    }

    // ------------------------------- Method list API -----------


    const [treatmentmethodList, setTreatmethodList] = useState([])


    useEffect(() => {
        treatmentMethod();
    }, [])

    async function treatmentMethod() {
        const response = await con.treatmentmethodList();
        if (response['status'] === true) {
            setTreatmethodList(response['data']);
        }
    }



    // ------------------------- Delete API --------

    async function getTreatMethDelete(deleteTreatmeth) {
        const response = await con.TretmentMethDeleteAPI(deleteTreatmeth);
        console.log(response)
        let afterAdded = treatmentmethodList.filter(item => item.id !== deleteTreatmeth);
        setTreatmethodList(afterAdded);
        if (response['status'] === true) {
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }


    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currenttreatmentmethod = treatmentmethodList.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <React.Fragment>
            <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
                <Sidebar open={open} onClose={() => setOpen(false)} />
                <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                    <main
                        className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
                    >
                        {/* Routes */}
                        <div className="h-full">
                            <Navbar
                                onOpenSidenav={() => setOpen(true)}
                                logoText={"Kirthika Dental Care"}
                                brandText="Treatment Method"
                                {...rest}
                            />

                            <div>
                                < button type="submit"
                                    className="btn btn-primary my-5"
                                    style={{ height: "auto", width: "auto", backgroundColor: "#5660d9", marginLeft: "18px" }}
                                    onClick={handleButtonClick}
                                >+  Treatment Method
                                </button>
                                {isDropdownOpen && (

                                    <div className="card mb-5" style={{ backgroundColor: "#f5f5f5", borderRadius: "7px", boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)", margin: "10px", padding: "20px" }}>
                                        <form onSubmit={submitAddTreatMethType}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="language">Treatment Procedure:</label>

                                                    {(treatmentProcedList != null) ?
                                                        <select
                                                            className="form-select"
                                                            id="sel10"
                                                            style={{ fontSize: 14, height: 44, width: "100%" }}
                                                            required
                                                            value={state.treatment_procedure_id}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'treatment_procedure_id' }); }}
                                                        >
                                                            <option>Select Treatment Procedure.....</option>
                                                            {treatmentProcedList.map((list, index) =>
                                                                <option value={list['id']}>{list['treatment_procedure']}</option>
                                                            )}

                                                        </select>
                                                        : null}
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="language">Treatment Method:</label>
                                                    <input type="text" id="diagnosisInput" placeholder="Enter Your Treatment Plan" className="form-control"
                                                        value={state.treatment_method}
                                                        required
                                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'treatment_method' }); }}
                                                    />

                                                </div>
                                                <div className="form-group col-md-12 ">
                                                    <label htmlFor="Input">Price:</label>
                                                    <input type="text" id="Input" placeholder="Price" className="form-control"
                                                        value={state.method_price}
                                                        required
                                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'method_price' }); }}
                                                    // onChange={(e) => {
                                                    //     var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                    //     dispatch({ type: 'set_value', value: sanitizedValue, key: 'method_price' });
                                                    // }} 
                                                    />
                                                </div>
                                                <div className="form-group col-md-12 ">
                                                    <label htmlFor="Input">Consultant Fee:</label>
                                                    <input type="text" id="Input" placeholder="Fee" className="form-control"
                                                        value={state.doctor_price}
                                                        required
                                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'doctor_price' }); }}
                                                    />
                                                </div>

                                            </div>
                                            <div className=" col-md-6">
                                                <span> <button type="submit" className="btn btn-primary">Save</button></span>
                                                <span className="me-1"> <button type="button" className="btn btn-primary" onClick={handleCancel}>cancel</button></span>

                                            </div>
                                        </form>
                                    </div>


                                )}
                                <table className="bordered-table">
                                    <thead className="tablehead" >
                                        <tr style={{ borderRadius: "10px" }}>
                                            <th>S.No</th>
                                            <th>Treatment Procedure</th>
                                            <th>Treatment Method</th>
                                            <th>Price</th>
                                            <th>Consultant Fee</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currenttreatmentmethod.map((treamethod, index) => {
                                            const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
                                            return (

                                                <tr key={index}>
                                                    <td>{continuousIndex}</td>
                                                    <td>{treamethod.treatment_procedure}</td>
                                                    <td>{treamethod.treatment_method}</td>
                                                    <td>{treamethod.method_price}</td>
                                                    <td>{treamethod.doctor_price}</td>
                                                    <td><RiEdit2Line className="edit_icon" onClick={() => { handleForEditButtonClick(treamethod); }} /></td>
                                                    <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                                                        onClick={() => getTreatMethDelete(treamethod.id)} /></td>
                                                </tr>


                                            )
                                        })}
                                    </tbody>
                                </table>
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={treatmentmethodList.length}
                                    currentPage={currentPage}
                                    paginate={paginate}
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </div>

        </React.Fragment>
    )
}

export default Treatmentmethod