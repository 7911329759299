import { useEffect, useState, useReducer, useContext } from 'react';
import * as con from '../../repository/api_calls';
import { UserContext } from "../../patient/exp"
import { ToastContainer, toast } from 'react-toastify';





const Book = (props) => {

  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [time, setTime] = useState('12:00');
  const patientData = props.pData
  const doctorData = props.doc
  const summaryData = props.sum
  const info = props.info
  const currentDate = new Date().toISOString().split('T')[0];


  // console.log(doctorData)
  // console.log(summaryData.service_id)
  // console.log(summaryData.map((t)=>t.service_id))
  // console.log(info)

  // function formatTime(inputTime) {
  //   const [hour, minute] = inputTime.split(':');
  //   const selectedTime = new Date();
  //   selectedTime.setHours(hour);
  //   selectedTime.setMinutes(minute);
  //   return selectedTime.toLocaleTimeString('en-US', {
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     hour12: true,
  //   });
  // }

  function book(e) {
    e.preventDefault();


    const dateObj = new Date(selectedDate);

    // Format the date and time in the desired format
    const formattedDate = dateObj.getFullYear() + '-' +
      String(dateObj.getMonth() + 1).padStart(2, '0') + '-' +
      String(dateObj.getDate()).padStart(2, '0') + ' ' +
      '10:10:00';

      const [hours, minutes] = time.split(':').map(str => parseInt(str, 10));

// Convert hours to 12-hour format
const formattedHours = hours % 12 || 12;

// Determine whether it's AM or PM
const period = hours >= 12 ? 'pm' : 'am';

// Format the time string
const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;

// console.log('Formatted time:', formattedTime);
      

    // const formattedTime = time + " " + selectedTime;

    const book = {
      "patient_id": String(patientData.id),
      "doctor_id": selectedDoctor,
      "treatment_id": String(summaryData.treatment_id),
      // "treatment_name":"",
      "service_id": summaryData.service_id,
      "appointment_date": formattedDate,
      "appointment_time": formattedTime
    }


    // console.log(formattedTime)
    bookAppoinment(book)

    // setSelectedDoctor([])
    // setSelectedTime([])
    // setSelectedDate([])
    // setTime([])
  }

  // bookAppoinment
  async function bookAppoinment(book) {
    const responseadd = await con.bookAppoinment(book);
    // console.log("return = " + responseadd)
    if (responseadd['status'] == true) {
      // console.log(responseadd['status'])
      props.setStatus(summaryData)
      // console.log(responseadd['data'])
      toast.success(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      window.location.reload();
    }
    else {
      // console.log(responseadd['status'])
      toast.error(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }


  return (
    <>

      {/* <form onSubmit={book} className="book-component"> */}
      <form onSubmit={book} >
        <div className='row mb-4'>
          <div className="col-4">
            <label >Select a Date:</label>
            <input
              className=" form-control"
              type="date"
              min={currentDate}
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </div>

          <div className="col-4">

            {/* <div> */}
            <label style={{ textAlign: "left" }}>Select a Doctor:</label>
            <select
              className=" form-control"
              value={selectedDoctor}
              onChange={(e) => setSelectedDoctor(e.target.value)}
            >
              <option value="">Select a doctor</option>
              {doctorData.map((doctor) => (
                <option key={doctor.id} value={doctor.id}>
                  {doctor.first_name}
                </option>
              ))}
            </select>
            {/* </div> */}
          </div>
          <div className="col-4">
            <div>
              {/* <label className='me-5'>Select Time:</label>
              <input
                type="radio"
                id="am"
                name="time"
                value="am"
                className="date"
                checked={selectedTime === 'am'}
                onChange={() => setSelectedTime('am')}
                required
              />
              <label htmlFor="am" className='me-3'>AM</label>
              <input
                type="radio"
                id="pm"
                name="time"
                value="pm"
                className="date"
                checked={selectedTime === 'pm'}
                onChange={() => setSelectedTime('pm')}
              />
              <label htmlFor="pm">PM</label> */}
              <label htmlFor="time">Time</label>
              <input
                // type="text"
                type='time'
                name="time"
                value={time}
                className="form-control"
                onChange={(e) =>{
                  setTime(e.target.value)}}
              />
              {/* <input
  type="time"
  name="time"
  value={time}
  className="form-control"
  onChange={(e) => {
    const selectedTime = e.target.value;

    // Create a new Date object with the selected time
    const timeObject = new Date(`2000-01-01T${selectedTime}`);

    // Format the time in 12-hour format with AM/PM
    const formattedTime = timeObject.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });

    // Update the state with the formatted time
    setTime(formattedTime);
  }}
/> */}
              
            </div>
          </div>
        </div>




        <button
          className="btn btn-primary"
          type="submit"
          style={{ color: 'white', padding: '5px 20px', borderRadius: '10px' }}
        >
          Book
        </button>
      </form>
    </>
  )
}

export default Book