import React, { useState, useEffect } from "react"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck } from "@fortawesome/free-solid-svg-icons";
import * as con from '../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


function reducer(state, action, props) {
  if (action.type === 'set_value') {
    state[action.key] = action.value;
    return {
      id: state.id,
      // patient_id: props.p.id,
      patient_id: state.patient_id,
      astjma: state.astjma,
      epilepsy: state.epilepsy,
      diabets: state.diabets,
      heart_problem: state.heart_problem,
      blood_disease: state.blood_disease,
      jaundice: state.jaundice,
      others: state.others,
      pregnant: state.pregnant,
      due_date: state.due_date,
      alcohol: state.alcohol,
      paan: state.paan,
      tobacco: state.tobacco,
      medication: state.medication,
      aspirin: state.aspirin,
      sulfa: state.sulfa,
      local_aneshthetic: state.local_aneshthetic,
      penicilin: state.penicilin,
      ibuprofen: state.ibuprofen,
      mention_others: state.mention_others,
    };
  }
}



const General = (props) => {

  const [state, dispatch] = useReducer(reducer, {
    id: '', patient_id: (props.p.id), astjma: '', epilepsy: '', diabets: '', heart_problem: '',
    blood_disease: '', jaundice: '', others: '', pregnant: '', due_date: '', alcohol: '', paan: '', tobacco: '', medication: ''
    , aspirin: '', sulfa: '', local_aneshthetic: '', penicilin: '', ibuprofen: '', mention_others: ''
  })

  // useEffect(() => {
  //   // Check local storage for the flag indicating form submission
  //   const hasSubmitted = localStorage.getItem('formSubmitted');
  //   if (hasSubmitted) {
  //     setSubmitted(true);
  //   }
  // }, []);



  function generalMedicalRecord(event) {

    // console.log(state)
    event.preventDefault();
    AddMedicalRecord(state);

    setSubmitted(true);
  }

  async function AddMedicalRecord(medical) {
    // medical['category_id'] = props.cat_id;
    // console.log(medical)
    const response = await con.MedicalRecord((JSON.stringify(medical)));
    console.log(response)
    if (response['status'] === true) {

      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      window.location.reload();
      // window.location.href = "/Inventory";
    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }

  const [submitted, setSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [selectedOption, setSelectedOption] = useState('');

  const gender = localStorage.getItem('gender');
  console.log("my gender", gender)


  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [sCards, setSCards] = useState([]);


  // const hClick = (cardId) => {

  //   if (sCards.includes(cardId)) {
  //     setSCards(sCards.filter((id) => id !== cardId));
  //     // for (let i of sCards) {
  //     //   if (i == 11) {
  //     //     dispatch({ type: 'set_value', value: "yes", key: 'alcohol' });
  //     //   } else {
  //     //     dispatch({ type: 'set_value', value: "no", key: 'alcohol' });
  //     //   }
  //     // }
  //     // dispatch({ type: 'set_value', value: state.alcohol == "11" ? 'yes' : 'no', key: 'alcohol' });
  //   } else {
  //     setSCards([...sCards, cardId]);
  //   }

  // };

  const hClick = (cardId) => {
    const cardIdToKeyMap = {
      1: 'aspirin',
      2: 'sulfa',
      3: 'local_aneshthetic',
      4: 'penicilin',
      5: 'ibuprofen',
      11: 'alcohol',
      22: 'paan',
      33: 'tobacco',
    };
    const isSelected = sCards.includes(cardId);

    if (isSelected) {
      setSCards(sCards.filter((id) => id !== cardId));
    } else {
      setSCards([...sCards, cardId]);
    }
    const stateKey = cardIdToKeyMap[cardId];

    dispatch({
      type: 'set_value',
      value: isSelected ? 'no' : 'yes',
      key: stateKey,
    });
  };


  // console.log("patiend_id", props.p.id);

  const currentDate = new Date().toISOString().split('T')[0];

  return (
    <div className="card" style={{ backgroundColor: "#F4F4F4" }}>
      <div style={{ padding: "10px" }}>
        {submitted ? (
          <p style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', fontSize: '20px', color: 'black' }}>Thank you for submitting the form.</p>
        ) : (
          <form onSubmit={generalMedicalRecord}>
            <p>HAVE YOU EVER SUFFERED FROM ANY OF THE FOLLOWING? (PLEASE TIC)</p>
            <div className="row mt-4">
              <div className="col-md-3">
                <label className="container-checkbox">Asthma
                  <input type="checkbox"
                    value={state.astjma}
                    onChange={(e) => { dispatch({ type: 'set_value', value: state.astjma == "" ? 'yes' : '', key: 'astjma' }); console.log(state.astjma) }} />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="col-md-3">
                <label className="container-checkbox">Epilepsy
                  <input type="checkbox"
                    value={state.epilepsy}
                    onChange={(e) => { dispatch({ type: 'set_value', value: state.epilepsy == "" ? 'yes' : '', key: 'epilepsy' }); console.log(state.epilepsy) }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="col-md-3">
                <label className="container-checkbox">Diabetes
                  <input type="checkbox"
                    value={state.diabets}
                    onChange={(e) => { dispatch({ type: 'set_value', value: state.diabets == "" ? 'yes' : '', key: 'diabets' }); console.log(state.diabets) }} />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="col-md-3">
                <label className="container-checkbox"> Heart Problem
                  <input type="checkbox"
                    value={state.heart_problem}
                    onChange={(e) => { dispatch({ type: 'set_value', value: state.heart_problem == "" ? 'yes' : '', key: 'heart_problem' }); console.log(state.heart_problem) }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>

            </div>

            <div className="row mt-3">
              <div className="col-md-3">
                <label className="container-checkbox"> Blood Disease
                  <input type="checkbox"
                    value={state.blood_disease}
                    onChange={(e) => { dispatch({ type: 'set_value', value: state.blood_disease == "" ? 'yes' : '', key: 'blood_disease' }); console.log(state.blood_disease) }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="col-md-3">
                <label className="container-checkbox">Jaundice
                  <input type="checkbox"
                    value={state.jaundice}
                    onChange={(e) => { dispatch({ type: 'set_value', value: state.jaundice == "" ? 'yes' : '', key: 'jaundice' }); console.log(state.jaundice) }}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
              {/* <div className="col-md-3">
              <label class="container-checkbox">Epilepsy
                <input type="checkbox"
                  value={state.jaundice}
                  onChange={(e) => { dispatch({ type: 'set_value', value: state.jaundice == "" ? 'yes' : '', key: 'jaundice' }); console.log(state.jaundice) }}
                />
                <span className="checkmark"></span>
              </label>
            </div> */}
              <div className="col-md-3">


                <label className="container-checkbox"> Other
                  <input type="checkbox"
                    id="myCheck"
                    // checked={isChecked}
                    // onChange={handleCheckboxChange}
                    value={state.others}
                    onChange={(e) => { dispatch({ type: 'set_value', value: state.others == "" ? 'yes' : '', key: 'others' }); console.log(state.others) }}

                  />
                  <span className="checkmark"></span>
                </label>

              </div>
              {state.others == 'yes' &&
                <div style={{ display: "flex" }}>
                  <p className="ms-3" id="text">If any other, Mention it </p>
                  <input type="text"
                    id="text" className="ms-3 input_under" style={{ borderBottom: "1px solid black", background: "transparent", width: "41%" }} />
                </div>

              }
            </div>

            <hr
              style={{
                background: '#9e9c9c',
                color: '#9e9c9c',
                borderColor: '#9e9c9c',
                height: '1px',
                marginBottom: "25px",
                marginTop: '8px'
              }}
            />

            {gender === 'Male'
              ? null
              :
              <div>


                <p>WOMENS - (PLEASE TIC)</p>


                <label className="container-checkbox my-4" >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    value={state.pregnant}
                    onChange={(e) => { dispatch({ type: 'set_value', value: state.pregnant == "" ? 'yes' : '', key: 'pregnant' }); console.log(state.pregnant) }}
                  // checked={selectedOption === 'option1'}
                  // onChange={handleRadioChange}
                  />

                  ARE YOU A PREGANANT?
                  <span className="checkmark"></span>
                </label>
                {state.pregnant === 'yes' && <div style={{ color: "red" }}>If yes, your due date?<input type="date"
                  min={currentDate}
                  value={state.due_date}
                  onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'due_date' }); }}

                  className="cal input_under"></input>
                  <span style={{ border: "2px solid #F5F5F5" }}></span></div>}
                {selectedOption === 'option2' && <div></div>}

                <hr
                  style={{
                    background: '#9e9c9c',
                    color: '#9e9c9c',
                    borderColor: '#9e9c9c',
                    height: '1px',
                    marginBottom: "25px",
                    marginTop: '12px'
                  }}
                />


              </div>
            }





            <p>HABITS</p>

            <div className="multiselect" style={{ width: "550px" }}>
              <div
                className={`acard ${sCards.includes(11) ? 'active' : ''}`}
                onClick={() => hClick(11)}

              >
                <div className="card-body">

                  <p className="probname">Alcohol</p>
                </div>
                <div className="xclose">{sCards.includes(11) && <p>X</p>}</div>
              </div>
              <div
                className={`acard ${sCards.includes(22) ? 'active' : ''}`}
                onClick={() => hClick(22)}
              >
                <div className="card-body">
                  <p className="probname">Paan</p>
                </div>
                <div className="xclose">{sCards.includes(22) && <p>X</p>}</div>
              </div>
              <div
                className={`acard ${sCards.includes(33) ? 'active' : ''}`}
                onClick={() => hClick(33)}
              >
                <div className="card-body">
                  <p className="probname">Tobacco </p>
                </div>
                <div className="xclose">{sCards.includes(33) && <p>X</p>}</div>
              </div>
            </div>

            <div>


            </div>
            <hr
              style={{
                background: '#9e9c9c',
                color: '#9e9c9c',
                borderColor: '#9e9c9c',
                height: '1px',
                marginBottom: "25px",
                marginTop: '12px'
              }}
            />
            <p>MEDICATION</p>
            <input type="textarea"
              value={state.medication}
              onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'medication' }); }}
              className="mt-4 input_under" style={{ height: "100px", width: "100%", borderBottom: "1px solid  #d3d3d3" }} />
            <hr
              style={{
                background: '#9e9c9c',
                color: '#9e9c9c',
                borderColor: '#9e9c9c',
                height: '1px',
                marginBottom: "25px",
                marginTop: '12px'
              }}
            />
            <p className="mt-3">ARE YOU ALLERGIC TO ANY OF THE FOLLOWING?</p>
            <div className="multiselect" style={{ width: "850px" }}>
              <div
                className={`acard ${sCards.includes(1) ? 'active' : ''}`}
                onClick={() => hClick(1)}
              >
                <div className="card-body">
                  <p className="probname">Aspirin</p>
                </div>
                <div className="xclose">{sCards.includes(1) && <p>X</p>}</div>
              </div>
              <div
                className={`acard ${sCards.includes(2) ? 'active' : ''}`}
                onClick={() => hClick(2)}
              >
                <div className="card-body">
                  <p className="probname">Sulfa</p>
                </div>
                <div className="xclose">{sCards.includes(2) && <p>X</p>}</div>
              </div>
              <div
                className={`acard ${sCards.includes(3) ? 'active' : ''}`}
                onClick={() => hClick(3)}
              >
                <div className="card-body">
                  <p className="probname">Local Anaesthetic </p>
                </div>
                <div className="xclose">{sCards.includes(3) && <p>X</p>}</div>
              </div>

              <div
                className={`acard ${sCards.includes(4) ? 'active' : ''}`}
                onClick={() => hClick(4)}
              >
                <div className="card-body">
                  <p className="probname">Penicillin </p>
                </div>
                <div className="xclose">{sCards.includes(4) && <p>X</p>}</div>
              </div>

              <div
                className={`acard ${sCards.includes(5) ? 'active' : ''}`}
                onClick={() => hClick(5)}
              >
                <div className="card-body">
                  <p className="probname">Ibuprofen </p>
                </div>
                <div className="xclose">{sCards.includes(5) && <p>X</p>}</div>
              </div>
            </div>
            <p style={{ marginLeft: "30px", marginTop: "30px" }}>If any other, Mention it
              <input type="text"
                value={state.mention_others}
                onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'mention_others' }); }}
                className="ms-3 input_under" style={{ borderBottom: "1px solid black", background: "transparent", width: "41%" }} />

            </p>




            <button type="submit" className="btn btn-primary my-5" style={{ float: "right", width: "150px" }}>Submit</button>

            <hr
              style={{
                background: '#9e9c9c',
                color: '#9e9c9c',
                borderColor: '#9e9c9c',
                height: '1px',
                marginBottom: "25px",
                marginTop: '12px'
              }}
            />
          </form>
        )}
      </div>




    </div>
  )
}
export default General