import MiniCalendar from "components/calendar/MiniCalendar";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import TotalSpent from "views/admin/default/components/TotalSpent";
// import PieChartCard from "views/admin/default/components/PieChartCard";
// import { IoMdHome } from "react-icons/io";
// import { IoDocuments } from "react-icons/io5";
// import { MdBarChart, MdDashboard } from "react-icons/md";
// import { columnsDataCheck} from "./variables/columnsData";
// import {columnsDataComplex } from "./variables/columnsData";

// import Widget from "components/widget/Widget";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import MonthlySummary from "./components/MonthlySummary";
import { useState } from "react";
import { role, userdata } from "patient/exp";
// import tableDataCheck from "./variables/tableDataCheck.json";
// import tableDataComplex from "./variables/tableDataComplex.json";


const Dashboard = (props) => {
  // const [datePicker, setdatePicker] = useState([])
  const [datePicker1, setdatePicker1] = useState([])
  const datec = (date) => {
    setdatePicker1(date)
    // change(date)
    // setdatePicker(datec)
  }
  // const token = localStorage.getItem("token");


  // const tokenPrt = JSON.parse(token)
  // console.log(role, "hjbfsdagdkjgasjgfjkh", userdata)
  return (
    // <div>
    //   <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
    //     <div className="grid grid-cols-1 rounded-[20px]">
    //       <MiniCalendar date={datec} />
    //     </div>

    //     <div className="grid grid-cols-1 gap-5 rounded-[20px]">
    //       <TaskCard />
    //     </div>
    //   </div>

    //   <div className="mt-5" style={{ marginLeft: "20px" }}>
    //     <MonthlySummary fdate={datePicker1} />
    //   </div>
    // </div>
    <div>
      <div className="row">
        <div className="col-md-6 mt-5">
          <MiniCalendar date={datec} />
        </div>
        <div className="col-md-6 mt-5">
          <TaskCard />
        </div>

      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <MonthlySummary fdate={datePicker1} />

        </div>

      </div>
    </div>
  );
};

export default Dashboard;
