import PatientList from "views/patient/patientlist";

import React from "react";
const PatientListShow = (prop) => {

  return (

    <div>
      <PatientList />
    </div>

  );
};
export default PatientListShow;