import AccountList from "views/accounts/accountlist";

import React from "react";
  const AccountListShow = (prop) => {
 
  return (

  <div>
  <AccountList />
  </div>

  );
};
export default AccountListShow;