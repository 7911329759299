import React, { useState, useEffect } from "react";
import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
import * as con from '../../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";


function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            name: state.name,
            id: state.id
        };
    }
}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};


const DiagnosisType = (props) => {

    // ------------------------------- add diagnosis ------------
    const { ...rest } = props;

    const [state, dispatch] = useReducer(reducer, {
        name: '', id: ''
    })

    const [open, setOpen] = useState(true);
    
    function submitAddDiagnosisType(event) {
        event.preventDefault();
        if (state.id !== '') {
            addDiagnosistyp(state, 'edit');
            setDropdownOpen(false)
        } else {
            addDiagnosistyp(state, 'add');
            setDropdownOpen(false)
        }
    }

    async function addDiagnosistyp(diagnosisTypeData, type) {
        if (type == 'add') {
            const response = await con.CreateDiagnosisTypeAPI((JSON.stringify(diagnosisTypeData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = [...diagnosistypeList];
                afterAdded.push(response['data']);
                setDiagnosiTypeList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success',
                    onClose: () => {
                        toast.dismiss();
                    }
                });
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        } else {
            const response = await con.UpdateDiagnoTypeAPI((JSON.stringify(diagnosisTypeData)));
            if (response['status'] === true) {
                let afterAdded = diagnosistypeList.map(item => {
                    if (item.id === state.id) {
                        return response.data;
                    }
                    return item;
                });
                dispatch({ type: 'set_value', value: '', key: 'id' })
                dispatch({ type: 'set_value', value: '', key: 'name' })
                setDiagnosiTypeList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        }
    }


    // -----------------------------  add method for dropdown

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };
    // ---------------- for edit function -------
    const handleCancel = () => {

        setDropdownOpen(false);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };


    function handleForEditButtonClick(subCat) {
        setDropdownOpen(true);

        dispatch({ type: 'set_value', value: subCat.id, key: 'id' })
        dispatch({ type: 'set_value', value: subCat.name, key: 'name' })
    };

    useEffect(() => {
        if (!isDropdownOpen) {
            dispatch({ type: 'set_value', value: '', key: 'id' });
            dispatch({ type: 'set_value', value: '', key: 'name' });
        }
    }, [isDropdownOpen]);

    // ------------------------------- Diagnosis type  list API -----------

    const [diagnosistypeList, setDiagnosiTypeList] = useState([])


    useEffect(() => {
        getDiagTyp();
    }, [])

    async function getDiagTyp() {
        const response = await con.getDiagnosisTypeList();
        if (response['status'] === true) {
            setDiagnosiTypeList(response['data']);
        }
    }


    // ------------------------- Delete API --------

    async function getDiagnosisTypeDelete(deletediagnosisTyp) {
        const response = await con.DiagnoTypDeleteAPI(deletediagnosisTyp);
        console.log(response)
        if (response['status'] === true) {
            let afterAdded = diagnosistypeList.filter(item => item.id !== deletediagnosisTyp);
            setDiagnosiTypeList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success',
                autoClose: 1000,
                onClose: () => {
                    toast.dismiss();
                }
            });
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }



    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentdiagnosistypeList = diagnosistypeList.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <React.Fragment>
            <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
                <Sidebar open={open} onClose={() => setOpen(false)} />
                <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                    <main
                        className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
                    >
                        {/* Routes */}
                        <div className="h-full">
                            <Navbar
                                onOpenSidenav={() => setOpen(true)}
                                logoText={"Kirthika Dental Care"}
                                brandText="Diagnosis Type"
                                {...rest}
                            />
                            <div>
                                < button type="submit"
                                    className="btn btn-primary my-5"
                                    style={{ width: "auto", backgroundColor: "#5660d9", marginLeft: "18px" }}
                                    onClick={handleButtonClick}
                                >+ Add Diagnosis Type
                                </button>
                                {isDropdownOpen && (

                                    <div className="card mb-5" style={{ backgroundColor: "#f5f5f5", borderRadius: "7px", boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)", margin: "10px", padding: "20px" }}>
                                        <form onSubmit={submitAddDiagnosisType}>

                                            <div className="form-group">
                                                <label htmlFor="diagnosisInput">Diagnosis Type:</label>
                                                <input type="text" id="diagnosisInput" placeholder="Enter Your diagnosis Type" className="form-control"
                                                    value={state.name}
                                                    required
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'name' }); }}
                                                />
                                            </div>
                                            <span> <button type="submit" className="btn btn-primary">Save</button></span>
                                            <span className="me-1"> <button type="button" className="btn btn-primary" onClick={handleCancel}>cancel</button></span>

                                        </form>
                                    </div>

                                )}

                                <table className="bordered-table">
                                    <thead className="tablehead" >
                                        <tr style={{ borderRadius: "10px" }}>
                                            <th>S.No</th>
                                            <th>Diagnosis type</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentdiagnosistypeList.map((diagnosistype, index) => {
                                            const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
                                            return (

                                                <tr key={index}>
                                                    <td>{continuousIndex}</td>
                                                    <td>{diagnosistype.name}</td>
                                                    <td><RiEdit2Line className="edit_icon" onClick={() => { handleForEditButtonClick(diagnosistype); }} /></td>
                                                    <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                                                        onClick={() => getDiagnosisTypeDelete(diagnosistype.id)} /></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={diagnosistypeList.length}
                                    currentPage={currentPage}
                                    paginate={paginate}
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DiagnosisType