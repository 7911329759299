import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import './account.css'
import PharmacyExpense from "./accounttab.jsx/pharmacyexpense";
import InventoryExpenses from "./accounttab.jsx/inventoryexpense";
import ConsultantExpenses from "./accounttab.jsx/consultantexpense";
import PharmacyIncome from "./accounttab.jsx/pharmacyincome";
import LabPayment from "./accounttab.jsx/labpayment";
import OthersAccount from "./accounttab.jsx/others";
import EmployeSalary from "./accounttab.jsx/salary";
import PatientClinicIncome from "./accounttab.jsx/patientclinicincome";
import NetProfit from "./accounttab.jsx/netprofit";

const AccountList = () => {


  // const [actTab, setActTab] = useState('tab1')

  const userRoleId = localStorage.getItem('role_id');
  const staff_category_name = localStorage.getItem('staff_category_name');
  // const handleTab = (e) => {
  //   e.preventDefault()
  //   setActTab(e.target.name)
  // }

  // console.log(staff_category_name,userRoleId )

  const [actTab, setActTab] = useState(localStorage.getItem('activeTab') || 'tab1');

  const handleTab = (event) => {
    const newTab = event.target.name;
    setActTab(newTab);
    localStorage.setItem('activeTab', newTab);
  };

  useEffect(() => {
    // Your existing useEffect logic here
  }, [actTab]);

  return (

    <div className=".nav-tab-inventory mt-5" style={{ marginLeft: "50px" }} >


      {/* ------------------------------------------------------------- row 1 ------------------------------------------------------------------ */}

      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1') || (userRoleId === '3' &&  staff_category_name === 'Auditor') ?
        (
          <div>
            <h2 className="my-3 tabhead">Income</h2>
            <ul className="nav nav-tabs" style={{ border: 'none' }}>

              <div className="cards" style={{ gap: "20px", width: '100%' }}>

                <div className={actTab === 'tab5' ? ` card card-tab-invent` : 'card'} >
                  <li className="nav-item">
                    <Link className={actTab === 'tab5' ? `nav-item actm ` : 'nav-item'} name='tab5' onClick={handleTab}>Patient Clinic Income</Link>
                  </li>
                </div>
                <div className={actTab === 'tab4' ? ` card card-tab-invent` : 'card'} >
                  <li className="nav-item">
                    <Link className={actTab === 'tab4' ? `nav-item actm ` : 'nav-item'} name='tab4' onClick={handleTab}>Pharmacy Income</Link>
                  </li>
                </div>
                <div className="cards" style={{ gap: "20px", width: '100%' }}>
                  <div className={actTab === 'tab9' ? ` card card-tab-invent` : 'card'}  >
                    <li className="nav-item">
                      <Link className={actTab === 'tab9' ? `nav-item actm ` : 'nav-item'} name='tab9' onClick={handleTab}>Net Profit</Link>
                    </li>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        ) : null}
      {/* ------------------------------------------------------------- row 2 ------------------------------------------------------------------ */}

      <h2 className="mt-5 tabhead">Expenses</h2>
      <ul className="nav nav-tabs mt-3" style={{ border: 'none' }}>
        <div className="cards" style={{ gap: "20px", width: '100%' }}>

          <div className={actTab === 'tab1' ? ` card card-tab-invent` : 'card'}  >
            <li className="nav-item">
              <Link className={actTab === 'tab1' ? `nav-item actm ` : 'nav-item'} name='tab1' onClick={handleTab}>Pharmacy Expenses</Link>
            </li>
          </div>
          <div className={actTab === 'tab2' ? ` card card-tab-invent` : 'card'} >
            <li className="nav-item">
              <Link className={actTab === 'tab2' ? `nav-item actm ` : 'nav-item'} name='tab2' onClick={handleTab}>Inventory Expenses</Link>
            </li>
          </div>
          <div className={actTab === 'tab6' ? ` card card-tab-invent` : 'card'} >
            <li className="nav-item">
              <Link className={actTab === 'tab6' ? `nav-item actm ` : 'nav-item'} name='tab6' onClick={handleTab}>Lab Payment</Link>
            </li>
          </div>


        </div>
      </ul>

      {/* ------------------------------------------------------------- row 3 ------------------------------------------------------------------ */}

      <ul className="nav nav-tabs mt-5" style={{ border: 'none' }}>
        <div className="cards" style={{ gap: "20px", width: '100%' }}>
          {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1') || (userRoleId === '3' &&  staff_category_name === 'Auditor') ?
            (
              <div className={actTab === 'tab3' ? ` card card-tab-invent` : 'card'} >
                <li className="nav-item">
                  <Link className={actTab === 'tab3' ? `nav-item actm ` : 'nav-item'} name='tab3' onClick={handleTab}>Consultant Expenses</Link>
                </li>
              </div>
            ) : null}
          {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1') || (userRoleId === '3' &&  staff_category_name === 'Auditor') ?
            (
              <div className={actTab === 'tab8' ? ` card card-tab-invent` : 'card'} >
                <li className="nav-item">
                  <Link className={actTab === 'tab8' ? `nav-item actm ` : 'nav-item'} name='tab8' onClick={handleTab}>Salary</Link>
                </li>
              </div>
            ) : null}
          {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '3' || userRoleId === '1') ? (
            <div className={actTab === 'tab7' ? `card card-tab-invent` : 'card'} style={{ width: userRoleId === '2' || userRoleId === '3' ? '32%' : '100%' }}>
              <li className="nav-item">
                <Link className={actTab === 'tab7' ? `nav-item actm` : 'nav-item'} name='tab7' onClick={handleTab}>Others</Link>
              </li>
            </div>
          ) : null}

        </div>
      </ul>


      <div className={actTab === 'tab1' ? `visible` : 'hidden'}>
        <PharmacyExpense />
      </div>

      <div className={actTab === 'tab2' ? `visible` : 'hidden'}>
        < InventoryExpenses />
      </div>

      <div className={actTab === 'tab3' ? `visible` : 'hidden'}>
        < ConsultantExpenses />
      </div>

      <div className={actTab === 'tab4' ? `visible` : 'hidden'}>
        < PharmacyIncome />
      </div>

      <div className={actTab === 'tab5' ? `visible` : 'hidden'}>
        < PatientClinicIncome />
      </div>

      <div className={actTab === 'tab6' ? `visible` : 'hidden'}>
        <LabPayment />
      </div>

      <div className={actTab === 'tab7' ? `visible` : 'hidden'}>
        <OthersAccount />
      </div>

      <div className={actTab === 'tab8' ? `visible` : 'hidden'}>
        <EmployeSalary />
      </div>
      <div className={actTab === 'tab9' ? `visible` : 'hidden'}>
        <NetProfit />
      </div>

    </div>

  );
};


export default AccountList;
