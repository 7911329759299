import React, { useEffect } from "react"
import { useReducer } from 'react';
import './style.css'

function reducer(state, action) {
  if (action.type === 'change_appointment_selection') {
    const listData = state.appointments_list.map(item => {
      if (item.id === action.ind) {
        return { ...item, appointment: action.value };
      }
      return item;
    });
    return {
      appointments_list: listData
    }
  }
  if (action.type === 'change_status_selection') {
    const listData = state.appointments_list.map(item => {
      if (item.id === action.ind) {
        return { ...item, status: action.value };
      }
      return item;
    });
    return {
      appointments_list: listData
    }
  }

  if (action.type === 'set_value') {
    return {
      appointments_list: action.value
    }
  }
}
const SummaryAdmin = () => {


  const [state, dispatch] = useReducer(reducer, { appointments_list: [] })


  useEffect(() => {
    let appointmenntslist = [
      {
        "id": "1",
        "appointment": "make",
        "status": "inprogress"
      },
      {
        "id": "2",
        "appointment": "cancel",
        "status": "completed"
      },
      {
        "id": "3",
        "appointment": "make",
        "status": "yettostart"
      },
    ];
    dispatch({ type: "set_value", value: appointmenntslist });
  }, []);
  return (
    <React.Fragment>

      <div className="card sum_card">
        <h3><b>GENERAL DETIALS</b></h3>

      </div>

      <div className=" su_card mt-5">
        {/* <div className=" card su_card"> */}
        <div className="row" >
          <div className="col-md-3">
            <h4>SUFFERED</h4>
            <div className="mt-4" style={{ display: "flex" }}>
              <p><span className="badge_blue">Diabetes</span></p>
              <p className="ms-2"><span className="badge_blue">Asthma</span></p>
            </div>
          </div>

          <div className="col-md-3">
            <h4> PREGNANT</h4>
            <div className="mt-4">
              <p>Due date: <span style={{ color: "red" }}>dd/mm/yyyy</span></p>
            </div>
          </div>

          <div className="col-md-3">
            <h4> HABITS</h4>
            <div className="mt-4" style={{ display: "flex" }}>
              <p><span className="badge_orange">Alcohol</span></p>
            </div>
          </div>

          <div className="col-md-3">
            <h4> ALLERGIC</h4>
            <div className="mt-4" style={{ display: "flex" }}>
              <p><span className="badge_vilot">Local Anaesthetic</span></p>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h4> MEDICATION</h4>
          <p className="mt-4">Medicines, often referred to as drugs, are used to prevent or treat diseases and other health conditions.</p>
        </div>
      </div>

      <div style={{ width: "100%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>
        <h3 style={{ fontWeight: "bold", marginTop: "20px" }}>Appointment Details</h3>

        <table className="bordered-table">
          <thead className="tablehead" >
            <tr style={{ borderRadius: "10px" }}>

              <th>Date</th>
              <th>TYPE</th>
              <th>DIAGNOSIS</th>
              <th>TREATMENT PLAN</th>
              <th>APPONIMENT CHARGE</th>
              <th>DOCTOR</th>
              <th>APPONIMENT</th>
              <th>STATUS</th>

            </tr>
          </thead>
          {state.appointments_list !== undefined ?
            (state.appointments_list.map((appointmentData) => {
              return (
                <tbody>
                  <tr>

                    <td>Index {appointmentData.id}</td>
                    <td >General</td>
                    <td className="tablevalue" >Cavities</td>
                    <td>Complete Edentulism</td>
                    <td >5,000</td>
                    <td className="tablevalue">Doctor</td>
                    <td>
                      <select className={appointmentData.appointment === 'make' ? 'make' : 'cancel'} onChange={(e) => dispatch({ type: 'change_appointment_selection', value: e.target.value, ind: appointmentData.id })} value={appointmentData.appointment}>
                        <option className="makeap" value="make" >Make an Appointment</option>
                        <option className="canap" value="cancel">Cancel Appointment</option>
                        <option className="resap" value="reschedule">Reschedule Appointment </option>
                      </select>
                    </td>
                    <td>
                      <select className={appointmentData.status === 'inprogress' ? 'inprogressC' :
                        appointmentData.status === 'completed' ? 'completedC' : 'badge_vilot'}
                        value={appointmentData.status}
                        onChange={(e) => dispatch({ type: 'change_status_selection', value: e.target.value, ind: appointmentData.id })}>
                        <option className="inprogressC" value="inprogress" >In-Progress</option>
                        <option className="completedC" value="completed">Completed</option>
                        <option className="badge_vilot" value="yettostart">Yet-to-Start </option>
                      </select>
                    </td>

                  </tr>

                </tbody>
              );
            })
            ) : null}

        </table>

        <div style={{ height: "100px" }}></div>
      </div>

    </React.Fragment>
  )
}

export default SummaryAdmin