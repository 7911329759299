// import Footer from "components/footer/FooterAuthDefault";
// import { Link, Routes, Route, Navigate } from "react-router-dom";
// import routes from "routes.js";
// import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import SignIn from "views/auth/SignIn";

export default function Auth() {
  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/auth") {
  //       return (
  //         <Route path={`/${prop.path}`} element={prop.component} key={key} />
  //       );
  //     } else {
  //       return null;
  //     }
  //   });
  // };
  // document.documentElement.dir = "ltr";
  return (
    <div>
  <SignIn />
    </div>
  );
}