import * as con from '../repository/urls.jsx'

const tokenStr = localStorage.getItem("token");

const token = JSON.parse(tokenStr)

console.log(token)

export const loginAPICall = async (phone, password) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.login}`, {
            method: 'post',
            body: JSON.stringify({
                phone,
                password
            }),
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'strict-origin-when-cross-origin'

            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------------- Logout API ----------

export const logoutAPI = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.Logout}`, {
            method: 'post',
            body: token,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin',
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- doctor list API ------------

export const doctorsListAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.DoctorList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- staff list API ------------

export const staffListAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StaffList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- UpcomingAppointment API ------------

export const UpcomingAppointmentAPI = async (date) => {
    try {

        // export const UpcomingAppointmentList = "upcoming_appointments_v2"
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpcomingAppointmentList}`, {
            method: 'POST',
            body: JSON.stringify(date),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}
// ----------------------------------------------- UpcomingAppointment API ------------

export const AppointmentAPI = async (date) => {
    try {

        // export const UpcomingAppointmentList = "upcoming_appointments_v2"
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AppointmentDate}`, {
            method: 'POST',
            body: JSON.stringify(date),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- patient list API ------------

export const PatientListAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.PatientList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Add Doctor API ------------

export const CreateDoctorAPI = async (doctorDetails) => {
    console.log(doctorDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddDoctor}`, {
            method: 'post',
            body: doctorDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Doctor Department id list API ----------
export const getDoctorCompanyList = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DoctorDepartment}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- staff category id list API ----------
export const getStaffCategoryList = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.StaffCategory}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- Add Staff API ------------

export const CreateStaffAPI = async (staffDetails) => {
    console.log(staffDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddStaff}`, {
            method: 'post',
            body: staffDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------------- Doctor Delete API ----------
export const DoctorDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteDoctor}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Staff Delete API ----------
export const StaffDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteStaff}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Staff Delete API ----------
export const PatientDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.Deletepatient}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}
// ----------------------------------------------- inventory list API ------------

export const InventoryList = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.InventoryList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- inventory list API ------------

export const InventoryCategory = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.InventoryCategoryList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}



// ----------------------------------------------- pharma list API ------------

export const PharmaList = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.pharmaList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Active Inactive API ------------

export const ActiveInactiveAPI = async (toogle) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.ActiveInactive}`, {
            method: 'post',
            body: toogle,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- pharmacy category id list API ----------
export const getPharmacyCategoryList = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.getPharmacyCategory}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- Pharmacy Doctor API ------------

export const CreatePharmacyAPI = async (PharamacyDetial) => {
    console.log(PharamacyDetial)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddPharmacy}`, {
            method: 'post',
            body: PharamacyDetial,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------- staff edit api ------------------


export const EditStaffAPI = async (editstaffDetails) => {
    console.log(editstaffDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateStaff}`, {
            method: 'put',
            body: editstaffDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}



// ----------------------------- Doctor edit api ------------------


export const UpdateDoctorAPI = async (editstaffDetails) => {
    console.log(editstaffDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateDoctor}`, {
            method: 'put',
            body: editstaffDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------------- Pharmacy Delete API ----------
export const PharmacyDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeletePharmacy}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -----------------------------------------------Setting Diagnosis list API ------------

export const DiagnosisListAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.SettDiagnosList}`, {
            method: 'get',

            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'

            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------------- DiagnosisType id list API ----------
export const getDiagnosisTypeList = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DiagnosisType}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- Add treatment API ------------

export const addTreatplan = async (TreatmentDetails) => {
    console.log(TreatmentDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.Addtreatment}`, {
            method: 'post',
            body: TreatmentDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}



// ----------------------------------------------- Add Diagnosis API ------------

export const CreateDiagnosisAPI = async (DiagnosisDetails) => {
    console.log(DiagnosisDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddDiagnosis}`, {
            method: 'post',
            body: DiagnosisDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}



// -------------------------- Diagnosis Delete API ----------
export const DiagnosisDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteDiagnosis}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- treatmentplan list API ----------
export const treatmentplanList = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.treatmentplanList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }

        }
        const data = await res.json();
        console.log(data)
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- treatmentprocedure list API ----------
export const TreatmPrcedList = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.Treaprocedure}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }

        }
        const data = await res.json();
        console.log(data)
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Add Treat Procedure API ------------

export const CreateTreatProcedAPI = async (ProcedureDetails) => {
    console.log(ProcedureDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddTreProced}`, {
            method: 'post',
            body: ProcedureDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Diagnosis Delete API ----------
export const TreProcedDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteTreProce}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}




// -------------------------- treatmentmethod list API ----------
export const treatmentmethodList = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.treatmentmethodList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }

        }
        const data = await res.json();
        console.log(data)
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Add Diagnosis Type -------------

export const CreateDiagnosisTypeAPI = async (DiagnosisType) => {
    console.log(DiagnosisType)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddDiagnosisType}`, {
            method: 'post',
            body: DiagnosisType,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------------- DiagnosisType Delete API ----------
export const DiagnoTypDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteDiagnosisType}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Treatment plan Delete API ----------
export const TrePlanDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteTreatplan}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Add Treatment Method Type -------------

export const CreateTreatMethTypeAPI = async (AddTreatMethType) => {
    console.log(AddTreatMethType)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreTreatMethod}`, {
            method: 'post',
            body: AddTreatMethType,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Add inventory list API ------------

export const AddInventoryList = async (inventory) => {
    console.log(inventory)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddInventory}`, {
            method: 'post',
            body: inventory,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Treatment method Delete API ----------
export const TretmentMethDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteTretMeth}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Update Diagnosis Type -------------

export const UpdateDiagnoTypeAPI = async (DiagnosisType) => {
    console.log(DiagnosisType)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateDiagnType}`, {
            method: 'PUT',
            body: DiagnosisType,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Update Diagnosis  -------------

export const UpdateDiagnoAPI = async (UpDiagnosis) => {
    console.log(UpDiagnosis)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateDiagnosis}`, {
            method: 'PUT',
            body: UpDiagnosis,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Update Treatment plan  -------------

export const UpdateTreatPlanAPI = async (UpdTreatplan) => {
    console.log(UpdTreatplan)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.Updatetreatmeplan}`, {
            method: 'PUT',
            body: UpdTreatplan,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update Treatment Method  -------------

export const UpdateTreatmenMethodAPI = async (tremethod) => {
    console.log(tremethod)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateTreatMethod}`, {
            method: 'PUT',
            body: tremethod,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update Treatment Procedure  -------------

export const UpdateTreatmeProceduAPI = async (tremethod) => {
    console.log(tremethod)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateTreatProced}`, {
            method: 'PUT',
            body: tremethod,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Add Inventory Catagory  -------------

export const CreateInvent_cataAPI = async (AddInventoryCata) => {
    console.log(AddInventoryCata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreInventoryCatagory}`, {
            method: 'post',
            body: AddInventoryCata,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update Inventory Catagory  -------------

export const UpdateInvenCataAPI = async (invencata) => {
    console.log(invencata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateInventoryCatag}`, {
            method: 'PUT',
            body: invencata,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Inventory Catagory list API ------------

export const InvenCatagAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.InventoryCatagList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Inventory Catagory Delete API ----------
export const InventCataDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteInventoryCata}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// -------------------------- Inventory List Delete API ----------
export const InventListDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.InventoryListDelete}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// -------------------  Update Inventory   -------------

export const UpdateInventoryAPI = async (invencata) => {
    console.log(invencata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateInventory}`, {
            method: 'PUT',
            body: invencata,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}




// -------------------  Update Salary   -------------

export const UpdateSalaryAPI = async (invencata) => {
    console.log(invencata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateSalary}`, {
            method: 'PUT',
            body: invencata,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}



// -------------------------- Salary Delete API ----------

export const SalaryDeleteAPI = async ({ doctor_salary_id, staff_salary_id, type }) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteSalary}`, {
            method: 'DELETE',
            body: JSON.stringify({
                "id": doctor_salary_id ? doctor_salary_id.toString() : staff_salary_id.toString(),
                "type": type
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });

        if (!res.ok) {
            return { "status": false }
        }

        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}



// ----------------------------------------------- Pharamcy Catagory list API ------------

export const PharamaCatagAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.ListPharamacyCata}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Add Pharamacy Catagory  -------------

export const CreatePharamCataAPI = async (AddPharamacyCata) => {
    console.log(AddPharamacyCata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StorePharamacyCata}`, {
            method: 'post',
            body: AddPharamacyCata,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update Pharmacy Catagory   -------------

export const UpdatPharaCataAPI = async (pharacata) => {
    console.log(pharacata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdatePharamaCata}`, {
            method: 'PUT',
            body: pharacata,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Pharamacy Catagory Delete API ----------
export const PharaCataDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeletePharaCata}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Staff Catagory list API ------------

export const StaffCatagAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.ListStaffCategory}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// ------------------- Add Staff Catagory  -------------

export const CreateStaffCataAPI = async (AddStaffCata) => {
    console.log(AddStaffCata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.Addstaffcat}`, {
            method: 'post',
            body: AddStaffCata,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------  Update Staff Catagory   -------------

export const UpdateStaffCataAPI = async (Staffcata) => {
    console.log(Staffcata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateStaffCata}`, {
            method: 'PUT',
            body: Staffcata,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Staff Catagory Delete API ----------

export const StaffCataDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteStaffCata}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -----------------------------------------------  Doctor Department list API ------------

export const DoctorDepartAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.ListDoctorDepart}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Add Doctor Department  -------------

export const CreateDoctorDepaAPI = async (AddDoctoDepar) => {
    console.log(AddDoctoDepar)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddDoctorDepart}`, {
            method: 'post',
            body: AddDoctoDepar,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update  Doctor Department  -------------

export const UpdateDoctorDepaAPI = async (UpDocDe) => {
    console.log(UpDocDe)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateDoctoDep}`, {
            method: 'PUT',
            body: UpDocDe,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// --------------------------  Doctor Department Delete API ----------

export const DoctorDepaDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteDoctorDepar}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update   Pharmacy  -------------

export const UpdatePharmacyAPI = async (UpDocDe) => {
    console.log(UpDocDe)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdatePharamacy}`, {
            method: 'PUT',
            body: UpDocDe,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Add Patient API ------------

export const AddPatient = async (StorePatientDetial) => {
    console.log(StorePatientDetial)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddPatient}`, {
            method: 'post',
            body: StorePatientDetial,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------  Update   Patient  -------------

export const UpdatePatientAPI = async (UpdaPatient) => {
    console.log(UpdaPatient)
    console.log(token)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdatePatient}`, {
            method: 'PUT',
            body: UpdaPatient,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------------------------ Add ToothSpecific Mapping --------

export const AddToothSpecificMapping = async (ToothSpecific) => {
    console.log(";;;;;;" + ToothSpecific)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.ToothSpecificMapping}`, {
            method: 'post',
            body: ToothSpecific,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });

        if (!response.ok) {
            console.log("nottttt respoinseeeee");
            return { "status": false };
        } else {
            console.log("respoinseeeee");
            console.log(response);
            const responseText = await response.text();
            const data = JSON.parse(responseText);
            console.log(data);
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false };
    }
}

// -----------------------------------------------prescription page list API ------------

export const PrescriptionListsAPI = async (id) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.PrescriptionList}`, {
            method: 'post',
            body: JSON.stringify(id),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'

            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            console.log("-----------------------------------")
            console.log(data)
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -----------------------------------------------Medical Inventory page list API ------------

export const MedicalInventoryListsAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.MedicalInventoryList}`, {
            method: 'get',

            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'

            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- Add Prescription Sub - Catagory API ------------

export const AddPrescriptSubCata = async (PrescriptionSubCatagory) => {
    console.log(PrescriptionSubCatagory)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreprescriptioSub}`, {
            method: 'post',
            body: PrescriptionSubCatagory,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- Add Prescription Sub - Catagory API ------------

export const AddPrescription = async (Prescription) => {
    console.log(Prescription)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StorePrescrition}`, {
            method: 'post',
            body: Prescription,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------------- Medical report dropdwon list API ----------
export const getMedicalReportList = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.medicalCategoryList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// -----------------------------------------------Medical Report list API ------------

export const medirepoListAPI = async (id) => {
    console.log(id)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.PatientMedicalReport}`, {
            method: 'post',
            body: JSON.stringify(id),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'

            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- Add Medical Report Type API ------------

export const CreateMedicalReTyAPI = async (doctorDetails) => {
    console.log(doctorDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AddMedicalReportType}`, {
            method: 'post',
            body: doctorDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Medical Report Type list API ------------

export const MedicalRepoTyListAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.ListMedicalReportType}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------- Medical Report Type edit api ------------------


export const UpdateMedicalRepTyAPI = async (editstaffDetails) => {
    console.log(editstaffDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateMedicalReportType}`, {
            method: 'put',
            body: editstaffDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------------- Medical Report Type Delete API ----------
export const MedicalRepeTypDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteMedicalReportType}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------- Medical Inventory edit api ------------------


export const UpdateMedicalInvenAPI = async (editstaffDetails) => {
    console.log(editstaffDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateMedicalInven}`, {
            method: 'put',
            body: editstaffDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Medical Inventory Delete API ----------
export const MedicalInventorDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteMedicalInven}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------- Patient Prscription edit api ------------------


export const UpdatePatientPrescriptAPI = async (editstaffDetails) => {
    console.log(editstaffDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdatePatientPrescr}`, {
            method: 'put',
            body: editstaffDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Patient Prescription Delete API ----------
export const PatientPrescription = async (id) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeletePatientPrescri}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Medical Report Delete API ----------

export const MedicalReportDeleteAPI = async (id) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteMedicalreport}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// --------------------------- book appointment --------------
export const bookAppoinment = async (id) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.appointment}`, {
            method: 'post',
            body: JSON.stringify(id),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'

            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// --------------------------- Resudule appointment --------------
export const RescuduleAppoinment = async (id) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreResuduleAppoin}`, {
            method: 'post',
            body: JSON.stringify(id),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'

            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}
// --------------------------- Next appointment --------------
export const NextAppoinment = async (id) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.NextAppointment}`, {
            method: 'post',
            body: JSON.stringify(id),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'

            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

//------------------------------- summaryList -----------------

export const summaryList = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.summaryList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// ------------------- Add Medical record API   -------------

export const MedicalRecord = async (generalmedicalrecord) => {
    console.log(generalmedicalrecord)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.GeneralMedicalRecord}`, {
            method: 'post',
            body: generalmedicalrecord,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -----------------------------------------------Medical Record list API ------------

export const MedicalRecordListsAPI = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.GeneralMedicalRecordList}`, {
            method: 'get',

            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'

            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// ------------------- Generate OTP  API   -------------

export const ForgetPasswordOTP = async (Forget) => {
    console.log(Forget)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.ForgetPassword}`, {
            method: 'post',
            body: Forget,
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Forget Verfiy OTP  API   -------------

export const ForgetPasswordVerfiyOtp = async (ForgetVerfiy) => {
    console.log(ForgetVerfiy)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.ForgetVerfiyOtp}`, {
            method: 'post',
            body: ForgetVerfiy,
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Forget Change Password API   -------------

export const ForgetChangePass = async (ForgetChange) => {
    console.log(ForgetChange)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.ForgetChangePassword}`, {
            method: 'post',
            body: ForgetChange,
            headers: {
                'Content-Type': 'application/json',
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Appointment List  API   -------------

export const appointmentList = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.appointmentList}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Add PharmacyExpense API ------------

export const AddPharmacyExpens = async (formData) => {
    console.log(formData)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StorePharmacyExpenses}`, {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  List PharmacyExpense   API   -------------

export const PharmacyExpenseList = async (month) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.ListPharmacyExpenses}`, {
            method: 'post',
            body: month,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- Add InventoryExpense API ------------

export const AddInventoryExpens = async (formData) => {
    console.log(formData)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreInventoryExpenses}`, {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  List InventoryExpense   API   -------------

export const InventoryExpenseList = async (month) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.ListInventoryExpenses}`, {
            method: 'post',
            body: month,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Delete PharmacyExpenses   API  ----------

export const PharmacyExpensesDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeletePharmacyExpenses}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Delete InventoryExpense   API  ----------

export const InventoryExpenseDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteInventoryExpenses}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- Add Lab Payment API ------------

export const AddLabPayment = async (formData) => {
    console.log(formData)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreLabPayment}`, {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  List Lab Payment  API   -------------

export const LabPaymentList = async (month) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.ListLabPayment}`, {
            method: 'post',
            body: month,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------------- Delete Lab Payment  API  ----------

export const LabPaymentDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteLabPayment}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Add Stationery or Others API ------------

export const AddStationery = async (formData) => {
    console.log(formData)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreStationery}`, {
            method: 'post',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  List Stationery or Others  API   -------------

export const StationeryList = async (month) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.ListStationery}`, {
            method: 'post',
            body: month,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------------- Delete Stationery or Others  API  ----------

export const StationeryDeleteAPI = async (id) => {
    try {

        console.log(token)
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteStationery}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- Appoinment status Update API ------------

export const StatusUpdateAppoinment = async (statusUpdate) => {
    console.log(statusUpdate)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.AppoinmentStatusUpdate}`, {
            method: 'put',
            body: statusUpdate,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  List Pharmacy Payment  API   -------------

export const PharmacyPaymentList = async (month) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.ListPharmacyIncome}`, {
            method: 'post',
            body: month,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  List Consultant Expenses  API   -------------

// export const ConsultantExpensesList = async (month) => {
//     try {
//         
//         const res = await fetch(`${process.env.REACT_APP_API_URL}${con.ListConsultantExpenses}`, {
//             method: 'post',
//             body: month,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${token}`,
//             }
//         });
//         if (!res.ok) {
//             return { "status": false }
//         }
//         const data = await res.json();
//         return data;
//     } catch (error) {
//         console.error(error);
//         return { "status": false }
//     }
// }

// -------------------  ListPatient Clinic Income  API   -------------

// export const PatientClinicIncomeList = async (month) => {
//     try {
//         
//         const res = await fetch(`${process.env.REACT_APP_API_URL}${con.ListPatientClinicIncome}`, {
//             method: 'post',
//             body: month,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${token}`,
//             }
//         });
//         if (!res.ok) {
//             return { "status": false }
//         }
//         const data = await res.json();
//         return data;
//     } catch (error) {
//         console.error(error);
//         return { "status": false }
//     }
// }

// -------------------  List Staff/doctor salary   API   -------------

export const SalaryList = async (month) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreSalary}`, {
            method: 'post',
            body: month,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  List Treatment Plan API   -------------



export const ConsultantExpensesList = async (month) => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.ListConsultantExpenses}`, {
            method: 'post',
            body: month,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}



// -------------------  AccountList  Plan API   -------------



// export const AccountList = async (month) => {
//     try {
//         
//         const res = await fetch(`${process.env.REACT_APP_API_URL}${con.AccountsList}`, {
//             method: 'post',
//             body: month,
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${token}`,
//             }
//         });
//         if (!res.ok) {
//             return { "status": false }
//         }
//         const data = await res.json();
//         return data;
//     } catch (error) {
//         console.error(error);
//         return { "status": false }
//     }
// }














// ----------------------------------------------- Add staff Salary API ------------

export const StaffSalary = async (staffsalary) => {
    console.log(staffsalary)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.staffSalary}`, {
            method: 'post',
            body: staffsalary,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}



// ----------------------------------------------- Add Doctor Salary API ------------

export const DoctorSalary = async (docsalary) => {
    console.log(docsalary)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.doctorSalary}`, {
            method: 'post',
            body: docsalary,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}

// ------------------- upcomingAppointments List  API   -------------

export const upcomingAppointments = async () => {
    try {

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.upcomingAppointments}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- treatment list to be paid API ------------

export const listToPay = async (treatment) => {
    console.log(treatment)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.listToPay}`, {
            method: 'post',
            body: JSON.stringify(treatment),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}


// ----------------------------------------------- storePayment API ------------

export const storePayment = async (treatment) => {
    // console.log(treatment)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.storePayment}`, {
            method: 'post',
            body: JSON.stringify(treatment),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}

// ----------------------------------------------- listClinicPayment API ------------

export const listClinicPayment = async (treatment) => {
    // console.log(treatment)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.listClinicPayment}`, {
            method: 'post',
            body: JSON.stringify(treatment),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}

// ----------------------------------------------- Notification list API ------------

export const NotificationListAPI = async (notification) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.NotificationList}`, {
            method: 'post',
            body: JSON.stringify(notification),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- listClinicPayment API ------------

export const listNetProfit = async (accountlist) => {
    // console.log(accountlist)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.NetProfitList}`, {
            method: 'post',
            body: JSON.stringify(accountlist),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}
// ----------------------------------------------- change password API ------------

export const changePassword = async (password) => {
    console.log(password)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.changePassword}`, {
            method: 'post',
            body: JSON.stringify(password),
            // body: password,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}
// ----------------------------------------------- change password API ------------

export const changeStatus = async (status) => {
    console.log(status)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.changeStatus}`, {
            method: 'post',
            body: JSON.stringify(status),
            // body: status,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}
// ----------------------------------------------- change op status API ------------

export const changeStatusop = async (status) => {
    console.log(status)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.changeStatusop}`, {
            method: 'post',
            body: JSON.stringify(status),
            // body: status,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}

// -------------------------- Appoinment  Delete API ----------

export const AppoinmentDeleteAPI = async (id) => {
    try {

        console.log(token);

        // Check if id is defined before calling toString()
        const idString = id !== undefined ? id.toString() : "";

        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteAppoinment}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": idString
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });

        if (!res.ok) {
            return { "status": false };
        }

        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false };
    }
};


// ----------------------------- Admin edit api ------------------


export const UpdateAdminAPI = async (editadminDetails) => {
    console.log(editadminDetails)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateAdmin}`, {
            method: 'post',
            body: editadminDetails,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------  Update Patient payment   -------------

export const UpdatePatientpaymentAPI = async (invencata) => {
    console.log(invencata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdatePayment}`, {
            method: 'POST',
            body: invencata,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------  Update Update Empoloyee id   -------------

export const UpdateEmpoloyeeIdAPI = async (employeid) => {
    console.log(employeid)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateEmpoloyee}`, {
            method: 'POST',
            body: employeid,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Store Presceiption Payment   -------------

export const StorePrescriptionPayAPI = async (storeprespay) => {
    console.log(storeprespay)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StorePrescriptionPay}`, {
            method: 'POST',
            body: storeprespay,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------  Update Presceiption Payment  -------------

export const UpdatePrescriptionPayAPI = async (prescripayedit) => {
    console.log(prescripayedit)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdatePrescriptionPay}`, {
            method: 'POST',
            body: prescripayedit,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update Pharmacy Account  -------------

export const UpdatePharmacyAccountAPI = async (pharmacyaccoutedit) => {
    console.log(pharmacyaccoutedit)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdatePharmacyAccount}`, {
            method: 'post',
            body: pharmacyaccoutedit,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update Inventory Account  -------------

export const UpdateInventoryAccountAPI = async (bodydatainve) => {
    console.log(bodydatainve)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateInventoryAccount}`, {
            method: 'post',
            body: bodydatainve,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update Lab Account  -------------

export const UpdateLabAccountAPI = async (bodydatalab) => {
    console.log(bodydatalab)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateLabAccount}`, {
            method: 'post',
            body: bodydatalab,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update Stationary Account  -------------

export const UpdateStationaryAccountAPI = async (bodydata) => {
    console.log(bodydata)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateStationaryAccount}`, {
            method: 'post',
            body: bodydata,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Update TreatmentPlan Mapping  -------------

export const UpdateTreatmentPlanMappingAPI = async (prescripayedit) => {
    console.log(prescripayedit)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateTreatmentPlanMapping}`, {
            method: 'put',
            body: prescripayedit,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// -------------------  Store Consultant Payment   -------------

export const StoreConsultantPaymentAPI = async (storeconsultpay) => {
    console.log(storeconsultpay)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreConsultantPayment}`, {
            method: 'POST',
            body: storeconsultpay,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// -------------------  Update TreatmentPlan Mapping  -------------

export const UpdateConsultantPaymentAPI = async (consultpayedit) => {
    console.log(consultpayedit)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateConsultantPayment}`, {
            method: 'post',
            body: consultpayedit,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Add Pharamacy Catagory  -------------

export const StoreEnquiryAPI = async (Addenquiry) => {
    console.log(Addenquiry)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.StoreEnquiry}`, {
            method: 'post',
            body: Addenquiry,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ----------------------------------------------- Patient Enquiry Notification list API ------------

export const EnquiryListAPI = async (notification) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.EnquiryList}`, {
            method: 'post',
            body: JSON.stringify(notification),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}
// ----------------------------------------------- Out -Patient Enquiry Notification list API ------------

export const OpEnquiryListAPI = async (notification) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.OpEnquiry}`, {
            method: 'post',
            body: JSON.stringify(notification),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}


// ----------------------------------------------- change Enquiry status  ------------

export const changeEnquirStatus = async (status) => {
    console.log(status)
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.changeEnStatus}`, {
            method: 'post',
            body: JSON.stringify(status),
            // body: status,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }

}

// ------------------- Otp Registration API   -------------

export const OtpRegistrationAPI = async (RegisterOtp) => {
    console.log(RegisterOtp)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.OtpRegistration}`, {
            method: 'post',
            body: RegisterOtp,
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// ------------------- Verify Otp API   -------------

export const VerifyOtpAPI = async (Verfiyotp) => {
    console.log(Verfiyotp)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.VerifyOtp}`, {
            method: 'post',
            body: Verfiyotp,
            headers: {
                'Content-Type': 'application/json',
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// Create Event API

export const CreateEvent = async (event) => {
    console.log(event)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.CreateEvent}`, {
            method: 'post',
            body: event,
            headers: {
                'Referrer-Policy': 'strict-origin-when-cross-origin',
                'Authorization': `Bearer ${token}`
            }
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            return { "status": false }
        }
    } catch (error) {
        console.error(error);
        return {
            "status": false,
            "mesaage": error
        }
    }
}

// Event List API 

export const GetEvent = async () => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.GetEvent}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}



// Update Event API
export const UpdateEvent = async (event) => {
    try {

        const response = await fetch(`${process.env.REACT_APP_API_URL}${con.UpdateEvent}`, {
            method: 'post',
            body: event,
            headers: {
                // 'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!response.ok) {
            return { "status": false }
        } else {
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error(error);
        return { "status": false }
    }
}

// Delete Event API

export const DeleteEvent = async (id) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}${con.DeleteEvent}`, {
            method: 'delete',
            body: JSON.stringify({
                "id": id.toString()
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Referrer-Policy': 'strict-origin-when-cross-origin'
            }
        });
        if (!res.ok) {
            return { "status": false }
        }
        const data = await res.json();
        return data;
    } catch (error) {
        // Handle the error
        console.error(error);
        return { "status": false }
    }
}