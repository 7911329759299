import { useEffect, useState, useReducer, useContext } from 'react';
import * as con from '../../repository/api_calls';
import { UserContext } from "../../patient/exp"
import { ToastContainer, toast } from 'react-toastify';





const RescheduleAppoi = (props) => {

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [time, setTime] = useState('12:00');
    const patientData = props.pData
    const doctorData = props.doc
    const summaryData = props.sum
    const info = props.info



    console.log(props.apStatus)
    // console.log(summaryData.service_id)
    // console.log(summaryData.map((t)=>t.service_id))
    // console.log(info)

    const currentDate = new Date().toISOString().split('T')[0];

    function Rescudbook(e) {
        e.preventDefault();


        const dateObj = new Date(selectedDate);

        // Format the date and time in the desired format
        const formattedDate = dateObj.getFullYear() + '-' +
            String(dateObj.getMonth() + 1).padStart(2, '0') + '-' +
            String(dateObj.getDate()).padStart(2, '0') + ' ' +
            '10:10:00';

            const [hours, minutes] = time.split(':').map(str => parseInt(str, 10));

// Convert hours to 12-hour format
const formattedHours = hours % 12 || 12;

// Determine whether it's AM or PM
const period = hours >= 12 ? 'pm' : 'am';

// Format the time string
const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;



        // const formattedTime = time + " " + selectedTime;
        const bookrescud = {
            "patient_id": String(patientData.id),
            "doctor_id": selectedDoctor,
            "treatment_id": String(summaryData.treatment_id),
            // "treatment_name":"",
            "service_id": summaryData.service_id,
            "appointment_date": formattedDate,
            "appointment_time": formattedTime,
            "id": summaryData.id
        }

        console.log(bookrescud)
        ResuduAppoinment(bookrescud)

        setSelectedDoctor([])
        setSelectedTime([])
        setSelectedDate([])
        setTime([])
    }

    // ResuduAppoinment
    // async function ResuduAppoinment(bookrescud) {
    //     const response = await con.RescuduleAppoinment(bookrescud);
    //     console.log(response);
    //     console.log('fwefwef');
    //     // console.log("return = " + response)
    //     if (response['status'] == true) {
    //         // console.log(response['status'])
    //         props.setStatus(summaryData)
    //         // console.log(response['data'])
    //         toast.success(response['message'], {
    //             position: toast.POSITION.TOP_RIGHT,
    //             className: 'foo_bar_log_Success'
    //         });
    //     }
    //     else {
    //         // console.log(response['status'])
    //         toast.error(response['message'], {
    //             position: toast.POSITION.TOP_RIGHT,
    //             className: 'foo_bar_log_error'
    //         });

    //     }
    // }


    async function ResuduAppoinment(bookrescud, id) {
        // Update the function signature to include the id parameter

        if(props.apStatus === "Inprogress"){
            const response = await con.NextAppoinment(bookrescud, id);
            if (response['status'] == true) {
                // console.log(response['status'])
                // props.setStatus(summaryData)
                // console.log(response['data'])
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                console.log(response['message'])
                window.location.reload();
            } else {
                // console.log(response['status'])
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        }
        else{
        const response = await con.RescuduleAppoinment(bookrescud, id);
        console.log(response);
        console.log('fwefwef');
        // console.log("return = " + response)
        if (response['status'] == true) {
            // console.log(response['status'])
            props.setStatus(summaryData)
            // console.log(response['data'])
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            window.location.reload();
        } else {
            // console.log(response['status'])
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }
    }

    useEffect(() => {
        appointmentListApi()
    }, [])

    const [appointmentList, setAppointmentList] = useState([]);

    async function appointmentListApi(id) {
        const response = await con.appointmentList();
        // console.log(response);
        if (response['status'] === true) {
            // Call the ResuduAppoinment function and pass the id
            // await ResuduAppoinment(id);
        }
    }

    // const [appointmentList, setAppointmentList] = useState([]);

    // async function appointmentListApi(id) {
    //     const response = await con.appointmentList();
    //     console.log(response);
    //     if (response['status'] === true) {

    //         // const filteredId = response.data.filter((item) => item.patient_id === id);
    //         // console.log(filteredId)

    //         // setAppointmentList(filteredId);

    //         // let localList1 = [];
    //         // for (let i1 of filteredId) {
    //         //     let li1 = JSON.parse(i1['patient_treatment']);


    //         //     if (li1 !== null) {
    //         //         for (let item of li1) {

    //         //             item['created_at_1'] = i1['created_at'];
    //         //             item['treatment_id'] = i1['treatment_id']
    //         //             item['service_id'] = i1['id']
    //         //             item['status'] = i1['status']
    //         //             item['doctor_first_name'] = i1['doctor_first_name']


    //         //         }
    //         //         localList1.push(...li1);
    //         //     }

    //         // }
    //         // setAppointmentList(localList1);

    //     } else {
    //         console.log("error")
    //     }
    // }


    return (
        <>

            <form onSubmit={Rescudbook}>
                {/* <div>
                    <label>Select a Date:</label>
                    <input
                        type="date"
                        value={selectedDate} required
                        onChange={(e) => setSelectedDate(e.target.value)}
                    />
                </div>

                <div>
                    <label>Select a Doctor:</label>
                    <select
                        value={selectedDoctor} required
                        onChange={(e) => setSelectedDoctor(e.target.value)}
                    >
                        <option value="">Select a doctor</option>
                        {doctorData.map((doctor) => (
                            <option key={doctor.id} value={doctor.id}>
                                {doctor.first_name}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label>Select Time:</label>
                    <input
                        type="radio"
                        id="am"
                        name="time"
                        value="am"
                        checked={selectedTime === 'am'}
                        onChange={() => setSelectedTime('am')}
                    />
                    <label htmlFor="am">AM</label>
                    <input
                        type="radio"
                        id="pm"
                        name="time"
                        value="pm"
                        checked={selectedTime === 'pm'}
                        onChange={() => setSelectedTime('pm')}
                    />
                    <label htmlFor="pm">PM</label>
                    <input
                        type="text"
                        name="time"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                    />
                </div>

                <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ color: 'white', padding: '5px 20px', borderRadius: '10px' }}
                >
                    Book
                </button> */}
                <div className='row mb-4'>
                    <div className="col-4">
                        <label >Select a Date:</label>
                        <input
                            type="date"
                            className=" form-control"
                            // min={new Date()}
                            min={currentDate}
                            value={selectedDate}
                            required
                            onChange={(e) => setSelectedDate(e.target.value)}
                        />
                    </div>

                    <div className="col-4">

                        {/* <div> */}
                        <label style={{ textAlign: "left" }}>Select a Doctor:</label>
                        <select
                            className=" form-control"
                            value={selectedDoctor} required
                            onChange={(e) => setSelectedDoctor(e.target.value)}
                        >
                            <option value="">Select a doctor</option>
                            {doctorData.map((doctor) => (
                                <option key={doctor.id} value={doctor.id}>
                                    {doctor.first_name}
                                </option>
                            ))}
                        </select>
                        {/* </div> */}
                    </div>
                    <div className="col-4">
                        <div>
                            {/* <label className='me-5'>Select Time:</label>
                            <input
                                type="radio"
                                id="am"
                                name="time"
                                value="am"
                                checked={selectedTime === 'am'}
                                onChange={() => setSelectedTime('am')}
                            />
                            <label htmlFor="am" className='me-3'>AM</label>
                            <input
                                type="radio"
                                id="pm"
                                name="time"
                                value="pm"
                                checked={selectedTime === 'pm'}
                                onChange={() => setSelectedTime('pm')}
                                required
                            /> */}
                            <label htmlFor="pm">Time</label>
                            <input
                                className=" form-control"
                                type="time"
                                // type="text"
                                name="time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                            />
                        </div>
                    </div>
                </div>




                <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ color: 'white', padding: '5px 20px', borderRadius: '10px' }}
                >
                    Book
                </button>
            </form>
        </>
    )
}

export default RescheduleAppoi