import Logo from 'assets/img/logo/logo.png';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


export default function Register() {


    const role_id = localStorage.getItem('role_id');

    const [checkbox2Checked, setCheckbox2Checked] = useState(false);
    const [checkbox22Checked, setCheckbox22Checked] = useState(false);
    const [checkbox3Checked, setCheckbox3Checked] = useState(false);
    const [checkbox33Checked, setCheckbox33Checked] = useState(false);
    const [checkbox4Checked, setCheckbox4Checked] = useState(false);
    const [checkbox44Checked, setCheckbox44Checked] = useState(false);
    const [checkbox5Checked, setCheckbox5Checked] = useState(false);
    const [checkbox55Checked, setCheckbox55Checked] = useState(false);
    const [checkbox6Checked, setCheckbox6Checked] = useState(false);
    const [checkbox66Checked, setCheckbox66Checked] = useState(false);
    const handleCheckboxChange2 = (checkboxNumber) => {
        if (checkboxNumber === 2) {
            setCheckbox2Checked(!checkbox2Checked);
            setCheckbox22Checked(!checkbox2Checked);
        } else if (checkboxNumber === 22) {
            setCheckbox22Checked(!checkbox22Checked);
            setCheckbox2Checked(!checkbox22Checked);
        }
    };

    const handleCheckboxChange3 = (checkboxNumber) => {
        if (checkboxNumber === 3) {
            setCheckbox3Checked(!checkbox3Checked);
            setCheckbox33Checked(!checkbox3Checked);
        } else if (checkboxNumber === 33) {
            setCheckbox33Checked(!checkbox33Checked);
            setCheckbox3Checked(!checkbox33Checked);
        }
    };
    const handleCheckboxChange4 = (checkboxNumber) => {
        if (checkboxNumber === 4) {
            setCheckbox4Checked(!checkbox4Checked);
            setCheckbox44Checked(!checkbox4Checked);
        } else if (checkboxNumber === 44) {
            setCheckbox44Checked(!checkbox44Checked);
            setCheckbox4Checked(!checkbox44Checked);
        }
    };
    const handleCheckboxChange5 = (checkboxNumber) => {
        if (checkboxNumber === 5) {
            setCheckbox5Checked(!checkbox5Checked);
            setCheckbox55Checked(!checkbox5Checked);
        } else if (checkboxNumber === 55) {
            setCheckbox55Checked(!checkbox55Checked);
            setCheckbox5Checked(!checkbox55Checked);
        }
    };
    const handleCheckboxChange6 = (checkboxNumber) => {
        if (checkboxNumber === 6) {
            setCheckbox6Checked(!checkbox6Checked);
            setCheckbox66Checked(!checkbox6Checked);
        } else if (checkboxNumber === 66) {
            setCheckbox66Checked(!checkbox66Checked);
            setCheckbox6Checked(!checkbox66Checked);
        }
    };

    console.log(checkbox2Checked)
    console.log(checkbox3Checked)
    console.log(checkbox4Checked)
    console.log(checkbox5Checked)
    console.log(checkbox6Checked)

    const allCheck = (checkbox6Checked && checkbox2Checked && checkbox5Checked)
    // const allCheck = (checkbox6Checked && checkbox2Checked &&
    //     checkbox3Checked && checkbox4Checked &&
    //     checkbox5Checked)



    // console.log('asfgjasgkljasklfgjklasfjgkljasfklgjkasflj')
    const nameRef = React.useRef();
    const lastnameRef = React.useRef();
    const phoneRef = React.useRef();
    const dobRef = React.useRef();
    const ageRef = React.useRef();
    const emailRef = React.useRef();
    const genderRef = React.useRef();
    const professionRef = React.useRef();
    const addressRef = React.useRef();
    const primarycontactRef = React.useRef();
    const relationRef = React.useRef();
    const cphoneRef = React.useRef();

    const check2Ref = React.useRef();
    const check3Ref = React.useRef();
    const check4Ref = React.useRef();
    const check5Ref = React.useRef();
    const check6Ref = React.useRef();


    const handleRadioClick = (value) => {
        genderRef.current = value;
        console.log(genderRef.current);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const first_name = nameRef.current.value;
        const surname = lastnameRef.current.value;
        const email = emailRef.current.value;
        const phone = phoneRef.current.value;
        const dob = dobRef.current.value;
        const age = ageRef.current.value;
        // const gender = genderRef.current.value;
        const gender = genderRef.current;
        console.log('gender =', gender);
        const profession = professionRef.current.value;
        const address = addressRef.current.value;
        const primary_contact_name = primarycontactRef.current.value;
        const relationship = relationRef.current.value;
        const contact_mobile = cphoneRef.current.value;

        const check2 = check2Ref.current.value;
        const check3 = check3Ref.current.value;
        const check4 = check4Ref.current.value;
        const check5 = check5Ref.current.value;
        const check6 = check6Ref.current.value;


        fetch('https://api.kirthikadentalcare.in/api/patient_registration', {
            // fetch('http://192.168.29.197:8000/api/patient_registration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'role_id': role_id,
                'first_name': first_name,
                'surname': surname,
                'email': email,
                'phone': phone,
                'dob': dob,
                'age': age,
                'gender': gender,
                'image': '',
                'profession': profession,
                'primary_contact_name': primary_contact_name,
                'relationship': relationship,
                'contact_mobile': contact_mobile,
                'address': address,
                'check1': checkbox6Checked === true? 'yes' : "",
                'check2': checkbox2Checked === true? 'yes' : "",
                'check3': checkbox3Checked === true? 'yes' : "",
                'check4': checkbox4Checked === true? 'yes' : "",
                'check5': checkbox5Checked === true? 'yes' : ""
            }),
        }
        ).then(async response => {
            const result = await response.json();


            if (result['status'] === true) {
                toast.success(result['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });

                setTimeout(() => {
                    // window.location.href = '/auth';
                    window.location.href = '/verify';
                }, 800);

            }
            else {
                toast.error(result['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }

        }).catch(error => {
            console.error('There was an error!', error);
        });
    };
    const handleInputChange = () => {
        const input = nameRef.current;
        // input.value = input.value.replace(/[^A-Za-z]/g, '');
        const inputS = lastnameRef.current;
        // inputS.value = inputS.value.replace(/[^A-Za-z]/g, '');
        const inputP = professionRef.current;
        // inputP.value = inputP.value.replace(/[^A-Za-z]/g, '');
        const inputR = relationRef.current;
        // inputR.value = inputR.value.replace(/[^A-Za-z]/g, '');
        const inputC = primarycontactRef.current;
        // inputC.value = inputC.value.replace(/[^A-Za-z]/g, '');

        const numericValue = phoneRef.current;
        numericValue.value = numericValue.value.replace(/[^0-9]/g, '');
        const ageValue = ageRef.current;
        ageValue.value = ageValue.value.replace(/[^0-9]/g, '');
        const cphoneValue = cphoneRef.current;
        cphoneValue.value = cphoneValue.value.replace(/[^0-9]/g, '');
    };

    const currentDate = new Date().toISOString().split('T')[0];

    return (

        <div className="reg_form_fam">
            <div>

                <div className="mt-5">
                    <img
                        className=""
                        style={{ height: '90px', width: '150px', display: 'block', margin: '0 auto' }}
                        src={Logo}
                        alt="Logo"
                    />
                </div>
                <div style={{ margin: '30px' }}>
                    <ToastContainer autoClose={3000} />
                    {/*------------------------------------------ form */}
                    <form onSubmit={handleSubmit} >

                        <div style={{ backgroundColor: 'white', height: 'auto', width: 'auto', padding: '20px', borderRadius: '10px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>

                            <h1 className="text-center mb-5" style={{ fontSize: '24px', paddingTop: '20px' }}> <b>Patient Registration</b></h1>

                            <div className="row">

                                <div className="col-md-4 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        <span className="required"  >First Name/முதல் பெயர்:</span>

                                    </label>
                                    <input
                                        name="first_name"
                                        id="first_name"
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                        ref={nameRef}
                                        required
                                        onInput={handleInputChange}
                                    />
                                    {/* <input name="first_name" id="first_name" type="text" className="form-control"
                                        placeholder="First Name"
                                        ref={nameRef}
                                        required /> */}
                                </div>
                                <div className="col-md-4 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        <span className="required">surname/குடும்ப பெயர்:</span>
                                    </label>
                                    <input name="last_name" id="last_name" type="text" className="form-control" onInput={handleInputChange}
                                        placeholder="Last Name"
                                        ref={lastnameRef}
                                    />
                                </div>

                                <div className="col-md-4 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        <span className="required">Phone Number/தொலைபேசி எண்: </span>
                                    </label>
                                    {/* <input name="phone" id="phone" type="text" className="form-control" onInput={handleInputChange}
                                        placeholder="Phone.No"
                                        minLength="10"
                                        maxLength="10"
                                        ref={phoneRef}
                                        required /> */}
                                    <input
                                        name="phone"
                                        id="phone"
                                        type="text"
                                        className="form-control"
                                        onInput={handleInputChange}
                                        placeholder="Phone.No"
                                        minLength="10"
                                        maxLength="10"
                                        ref={phoneRef}
                                        required
                                    />

                                </div>
                            </div>


                            <div className="row">

                                <div className="col-md-3 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        <span className="required">Dob/பிறந்த தேதி</span>
                                    </label>
                                    <div className="mb-3 position-relative">
                                        <input name="dob" id="datepicker" type="date" className="form-control" ref={dobRef} max={currentDate} required />
                                    </div>
                                </div>

                                <div className="col-md-3 mb-sm-7 mb-4">
                                    <label className="form-label">Age/வயது
                                    </label>
                                    <div className="mb-3 position-relative">
                                        <input name="age" id="age" type="text" className="form-control" onInput={handleInputChange}
                                            placeholder="Age"
                                            ref={ageRef}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3 mb-sm-7 mb-4">
                                    <div className="form-group mb-5">
                                        <label className="form-label">
                                            <span className="required"></span>Gender/பாலினம் &nbsp;<br />
                                        </label>
                                        <br />
                                        <div className="flexdis align-items-center">

                                            <div className="form-check ">
                                                <label className="container-radio" htmlFor="male">  Male<br />ஆண்
                                                    <input type="radio" className="form-check-input" id="male" name="gender" onClick={() => handleRadioClick('Male')} value="Male" required />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                            <div className="form-check ">
                                                <label className="container-radio" htmlFor="female">       Female <br />பெண்
                                                    <input type="radio" className="form-check-input" id="female" name="gender" onClick={() => handleRadioClick('Female')} value="Female" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 mb-sm-7 mb-4">
                                    <label className="form-label">Profession/தொழில்
                                    </label>
                                    <div className="mb-3 position-relative">
                                        <input name="Profession" id="Profession" type="text" className="form-control" onInput={handleInputChange}
                                            placeholder="Profession"
                                            ref={professionRef}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-8">
                                    <label className="form-label">
                                        Address/ முகவரி:
                                    </label>
                                    <div className="mb-3 position-relative">
                                        <input className="form-control" name="address" placeholder="Address" ref={addressRef} />
                                    </div>
                                </div>
                                <div className="col-md-4 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        Email/மின்னஞ்சல்:
                                    </label>
                                    <input name="email" id="email" type="email" className="form-control"
                                        placeholder="Email"

                                        pattern="[a-zA-Z0-9.]{5,}@[a-z]+\.[a-z]{2,3}"
                                        // pattern="[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]{5,}@[a-z]+\.[a-z]{2,3}"
                                        ref={emailRef} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        <span className="required">Relation Name/உறவினர் பெயர்:</span>
                                    </label>
                                    <div className="mb-3 position-relative">
                                        <input name="primary_contact_name" type="text" className="form-control" onInput={handleInputChange}
                                            placeholder="Primary contact name" ref={primarycontactRef}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        <span className="required">Relation/உறவு:</span>
                                    </label>
                                    <div className="mb-3 position-relative">
                                        <input name="relationship" type="text" className="form-control"
                                            placeholder="Relationship" ref={relationRef} onInput={handleInputChange}
                                            minLength={1} maxLength={15} />
                                    </div>
                                </div>
                                <div className="col-md-4 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        <span className="required">Contact Mobile/மொபைல் தொடர்பு :</span>
                                    </label>
                                    <div className="mb-3 position-relative">
                                        <input name="contact_mobile" type="phone" className="form-control" onInput={handleInputChange}
                                            placeholder="Contact mobile"
                                            minLength={1} maxLength={10}
                                            ref={cphoneRef}
                                        />
                                    </div>
                                </div>
                            </div>



                            {/* check box list */}
                            <div className="row">
                                <div className="col-md-12 mb-sm-7 mb-4">

                                    <div >
                                        <label className="container-checkbox" style={{ fontWeight: '400' }}> I, authorize Kirthika Dental Care to perform the necessary diagnosis and treatment planing for me/ dependent (for Parents/Guardian).
                                            <input type="checkbox" name="check_field" id="checkbox6" checked={checkbox6Checked}
                                                onChange={() => handleCheckboxChange6(6)} value="" ref={check6Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="container-checkbox" style={{ fontWeight: '400' }}>எனக்கு/சார்ந்தவருக்கு (பெற்றோர்/பாதுகாவலருக்கு ) தேவையான  நோயறிதல் மற்றும் சிகிச்சைத் திட்டத்தைச்  செய்ய கிர்த்திகா பல் மருத்துவமனைக்கு அனுமதி  அளிக்கிறேன்
                                            <input type="checkbox" name="check_field" id="checkbox55" checked={checkbox66Checked}
                                                onChange={() => handleCheckboxChange6(66)} ref={check6Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="mt-5">

                                        <label className="container-checkbox" style={{ fontWeight: '400' }}>I authorize the use of anesthesia if necessary for the diagnosis and treatment and I understand the effects of using it on me(dependant).

                                            <input type="checkbox" name="check_field" id="checkbox2" checked={checkbox2Checked}
                                                onChange={() => handleCheckboxChange2(2)} value="" ref={check2Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="container-checkbox" style={{ fontWeight: '400' }}> நோய்  கண்டறிதல்  மற்றும்  சிகிச்சைக்கு  தேவைப்பட்டால், மயக்க மருத்து  பயன்படுத்துவதற்கு நான் அனுமதி அளிக்கறேன். மேலும் அதைப் பயன்படுத்துவதால் ஏற்படும் விளைவுகளை நான் புரிந்து கொள்கிறேன்
                                            <input type="checkbox" name="check_field" id="checkbox22" checked={checkbox22Checked}
                                                onChange={() => handleCheckboxChange2(22)} ref={check2Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="mt-5">
                                        <label className="container-checkbox" style={{ fontWeight: '400' }}>I confirmed that doctor explained the possible treatement options available for my conditions and I authorise the doctor to perform the treatment selected by me.
                                            <input type="checkbox" name="check_field" id="checkbox3" checked={checkbox3Checked}
                                                onChange={() => handleCheckboxChange3(3)} value="" ref={check3Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="container-checkbox" style={{ fontWeight: '400' }}>எனது  நிலைக்கான  சாத்தியமான சிகிச்சை  முறைகளை மருத்துவர் விளக்கியதை நான்  உறுதிப்படுத்துகிறேன், மேலும்  நான்  தேர்ந்தெடுத்த சிகிச்சையை  மேற்கொள்ள  நான்  அனுமதிக்கிறேன்
                                            <input type="checkbox" name="check_field" id="checkbox33" checked={checkbox33Checked}
                                                onChange={() => handleCheckboxChange3(33)} ref={check3Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="mt-5">
                                        <label className="container-checkbox" style={{ fontWeight: '400' }}>I acknowledge that the risk benefits and complications of the treatment selected by me was clearly explained to me.
                                            <input type="checkbox" name="check_field" id="checkbox4" checked={checkbox4Checked}
                                                onChange={() => handleCheckboxChange4(4)} value="" ref={check4Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="container-checkbox" style={{ fontWeight: '400' }}>நான் தேர்ந்தெடுத்த சிகிச்சையின் ஆபத்து நன்மைகள் மற்றும்  சிக்கல்கள் எனக்கு  தெளிவாக விளக்கப்பட்டது என்பதை நான் ஒப்புக்கொள்கிறேன்
                                            <input type="checkbox" name="check_field" id="checkbox44" checked={checkbox44Checked}
                                                onChange={() => handleCheckboxChange4(44)} ref={check4Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="mt-5">
                                        <label className="container-checkbox" style={{ fontWeight: '400' }}> I acknowledge that all information I provided in this form is true and accurate.
                                            <input type="checkbox" name="check_field" id="checkbox5" checked={checkbox5Checked}
                                                onChange={() => handleCheckboxChange5(5)} value="" ref={check5Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                        <label className="container-checkbox" style={{ fontWeight: '400' }}>இந்த  படிவத்தில் நான் வழங்கிய  அனைத்து தகவல்களும் உண்மை மற்றும் துல்லியமானவை என்பதை நான் ஒப்புக்கொள்கிறேன்
                                            <input type="checkbox" name="check_field" id="checkbox55" checked={checkbox55Checked}
                                                onChange={() => handleCheckboxChange5(55)} ref={check5Ref} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>



                                </div>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <p>Already have an account ?
                                    <Link to="sign-in" className="text-center" style={{ color: 'blue' }}>  Sign In
                                    </Link></p>


                                {/* <button type="submit" id="submit" className="btn btn-primary " style={{ width: "150px", marginLeft: "500px" }}>Submit</button> */}
                                {allCheck ? (
                                    <div style={{ display: "flex" }}>
                                        <button type="submit" id="submit" className="btn btn-primary " style={{ width: '150px', marginLeft: "30px" }}>Submit</button>
                                        <p className='ms-5' style={{ fontSize: "22px", fontWeight: 600 }}> Enter this password after verification: <span style={{ color: "#145BD9" }}>patient@kdc</span></p>
                                    </div>
                                ) : (
                                    <button type="submit" id="submit" className="btn btn-primary ms-4" style={{ display: 'none' }}>Submit</button>
                                )}
                                {/* </form> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    );
}