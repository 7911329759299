import React, { useEffect, useState } from "react"
import * as con from '../../../repository/api_calls';
import { useNavigate } from "react-router-dom";
import { useReducer } from 'react';
import { FaEllipsis } from "react-icons/fa6";
import { RiEdit2Line, RiMailAddLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';


function reducer(state, action) {
  if (action.type === 'set_value') {
    state[action.key] = action.value;
    return {
      id: state.id,
      doctor_id: state.doctor_id,
      total_amount: state.total_amount,
      paid_amt: state.paid_amt,
      month: state.month,
      status: state.status
    };
  }
}


const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];


  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination">
      <ul className="pagination-list">
        {pageNumbers.map((number) => (
          <li key={number} className="pagination-item">
            <a
              href="#"
              onClick={() => paginate(number)}
              className={`pagination-link ${currentPage === number ? 'active' : ''}`}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};



const ConsultantExpenses = () => {

  const userRoleId = localStorage.getItem('role_id');
  // const userRoleId = localStorage.getItem('role_id');
  const staff_category_name = localStorage.getItem('staff_category_name');

  const [SalaryLists, setSalaryList] = useState([]);
  const [TreatmentPlanMethodList, setTreatmentPlanMethodList] = useState([]);
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };


  const [state, dispatch] = useReducer(reducer, {
    id: '', doctor_id: '', total_amount: '', paid_amt: '', month: '', status: ''
  })

  const [isOpen, setIsOpen] = useState(false);
  const [presIds, setPresIds] = useState([]);

  function toggleDropdown(ids, date) {
    // let idsValue = ids.doctor_wise.map((i) => i.id);
    let idsValue = ids.doctor_id;
    dispatch({ type: 'set_value', value: idsValue, key: 'doctor_id' });
    dispatch({ type: 'set_value', value: ids.total, key: 'total_amount' });
    dispatch({ type: 'set_value', value: date.date, key: 'month' });
    setPresIds(idsValue);
    setIsOpen(!isOpen);
  }

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };


  const [searchTerm, setSearchTerm] = useState("");


  // const searchedSalary = SalaryLists.filter((c) =>
  //   c.staff_name.toLowerCase().includes(searchTerm.toLowerCase()) || c.staff_id.includes(searchTerm)
  // );

  // const searchedSalary = SalaryLists.filter((c) =>
  //   c.staff_name.toLowerCase().includes(searchTerm.toLowerCase()) || c.staff_id.includes(searchTerm)
  // );
  // const searchedSalary = SalaryLists.filter((c) =>
  //   c.staff_name.toLowerCase().includes(searchTerm.toLowerCase()) || (c.staff_id && c.staff_id.includes(searchTerm))
  // );
  // const searchedTreatmentPlanMethod = TreatmentPlanMethodList.filter((c) =>
  //   c.date.includes(searchTerm) || c.data.some((t) => t.company_name.toLowerCase().includes(searchTerm.toLowerCase()))
  // );

  const searchedTreatmentPlanMethod = TreatmentPlanMethodList.filter((c) =>
    c.date.includes(searchTerm) || (c.data && c.data.some((t) => t.company_name && t.company_name.toLowerCase().includes(searchTerm.toLowerCase())))
  );


  // -------------------------------  Consultant Expenses list API -----------

  const [consultantexpensesLists, setConsultantExpensesList] = useState([])




  useEffect(() => {
    getConsultantExpensesLists();
  }, [])

  async function getConsultantExpensesLists() {
    // const currentDate = new Date();
    // const currentMonth = currentDate.getMonth() + 1; 
    const response = await con.ConsultantExpensesList();
    console.log(response)
    if (response['status'] === true) {
      setConsultantExpensesList(response['data']);
    }
  }


  // const searchedPatients = consultantexpensesLists.filter((c) =>
  //   c.first_name.includes(searchTerm.toLowerCase()) || c.phone.includes(searchTerm)
  // );

  // ------------ pagenation ------------

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentConsultexpe = searchedTreatmentPlanMethod.slice(indexOfFirstItem, indexOfLastItem);
  // const currentconsultantexpenses = consultantexpensesLists.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const submitSalary = (event) => {
    event.preventDefault();
    console.log(state)
    // addSalary(state);
  }


  async function addSalary(salary) {
    const response = await con.StaffSalary((JSON.stringify(salary)));
    if (response['status'] === true) {
      let afterAdded = [...SalaryLists];
      afterAdded.push(response['data']);
      setSalaryList(afterAdded);
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        window.location.reload();
      }, 800);

    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      })
    }
  }




  // -------------------------------  SalaryLists list API -----------TreatmentPlanMethod



  useEffect(() => {
    SalaryListing();
  }, [])

  async function SalaryListing() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
    const response = await con.SalaryList((JSON.stringify({ month: currentMonth })));

    if (response['status'] === true) {
      setSalaryList(response['salaries']);
    }
  }

  useEffect(() => {
    TreatmentPlanMethod();
  }, [])

  async function TreatmentPlanMethod() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
    const response = await con.ConsultantExpensesList((JSON.stringify({ month: currentMonth })));

    if (response['status'] === true) {
      setTreatmentPlanMethodList(response['monthwiseSum'].reverse());
    }
  }


  const navigate = useNavigate();
  function moveToConsultantExopeDetial(data, doctor_id) {

    for (let i of data.doctor_wise) {
      if (i.doctor_id === doctor_id) {
        navigate('/consultantdetial', { state: i });
      }
    }
  }

  const [editmode, setEditmode] = useState(false);

  // ----------- Change Table Heading and Button Dynamically -------------------

  const [heading, setHeading] = useState('Default Heading');
  const [button, setButton] = useState('Default Heading');
  const [addPharm, setaddPharm] = useState(false)
  const [isDropdownOpenforpay, setDropdownOpenpay] = useState(false);
  const handleForAddButtonClickpay = (newHeading, newButton) => {
    setHeading(newHeading);
    setButton(newButton)
    setEditmode(true)
    setDropdownOpenpay(!isDropdownOpenforpay);
    setaddPharm(!addPharm);

    // dispatch({ type: 'set_value', value: '', key: 'id' })
    // dispatch({ type: 'set_value', value: '', key: 'sum' })

  };

  // ---------------- for edit function -------

  function handleForEditButtonClickpay(newHeading, newButton, method) {
    setHeading(newHeading);
    setButton(newButton)
    setDropdownOpenpay(true);
    setEditmode(false)
    // dispatch({ type: 'set_value', value: method.prescription.map((t) => (t.id)), key: 'id' })
    dispatch({ type: 'set_value', value: method.total, key: 'total_amount' })
    dispatch({ type: 'set_value', value: method.paid_amt, key: 'paid_amt' })
    dispatch({ type: 'set_value', value: method.status, key: 'status' })
    dispatch({ type: 'set_value', value: method.date, key: 'month' })
    dispatch({ type: 'set_value', value: method.payment, key: 'payment' })
    dispatch({ type: 'set_value', value: method.payment[0].status, key: 'status' })
    dispatch({ type: 'set_value', value: method.payment[0].paid_amount, key: 'paid_amt' })
    dispatch({ type: 'set_value', value: method.payment[0].payment_id, key: 'id' })
    dispatch({ type: 'set_value', value: method.payment[0].month, key: 'month' })



  };


  const submitConsultantExpenPay = (event) => {
    event.preventDefault();
    if (state.id !== '') {

      consultantexpenpay(state, 'edit');
    } else {
      consultantexpenpay(state, 'add');
    }
  }


  async function consultantexpenpay(consultantexpenpayData, type) {

    if (type === 'add') {
      const response = await con.StoreConsultantPaymentAPI((JSON.stringify(consultantexpenpayData)));

      if (response['status'] === true) {
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });

        setTimeout(() => {
          window.location.reload();
        }, 800);

      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        })
      }
    } else {
      const response = await con.UpdateConsultantPaymentAPI((JSON.stringify(consultantexpenpayData)));

      if (response['status'] === true) {
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          window.location.reload();
        }, 800);
      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });
      }
    }
  }

  return (
    <React.Fragment>

      <div style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>
        <input className="form-control searchBar mb-5" type="text"
          placeholder="Search by name or id"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} />

        <table className="bordered-table">
          <thead className="tablehead">
            <tr style={{ borderRadius: "10px" }}>
              <th>ID</th>
              <th>Year & Month</th>
              <th>Doctor Name</th>
              {/* <th>Patient Name</th> */}
              {/* <th>Patient Method</th> */}
              <th>Doctor's Fee</th>

              {userRoleId === '3' && staff_category_name === "Auditor"? "" : <th>Pay</th>}
              {/* <th>Paid Amount</th>/ */}

              <th>Balance Amount</th>
              <th>Status</th>
              <th>Month Total</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>

          <tbody>
            {currentConsultexpe.map((method, index) => {
              const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;

              // Calculate total fee for all doctors in the method
              // const totalDoctorFee = method.data.reduce(
              //   (total, t) => total + parseFloat(t.treatments[0].doctor_price),
              //   0
              // );

              // Calculate balance amount
              // const balanceAmount = userInput ? totalDoctorFee - parseFloat(userInput) : '';

              return (
                <tr key={index}>
                  <td>{continuousIndex}</td>
                  <td>{method.date}</td>

                  <td style={{ cursor: "pointer", color: "#145BD9" }}>
                    {method.doctor_wise.map((t) => (
                      <p className="p-2" onClick={() => { moveToConsultantExopeDetial(method, t.doctor_id); }}> {t.doctor_name} <br /></p>
                    ))}
                  </td>
                  {/* <td>
                    {method.doctor_wise.map((t) => (
                      // <p className="p-2"> {t.treatments[0].doctor_price} <br /></p>
                      <p className="p-2"> {t.doctor_name} <br /></p>
                    ))}
                  </td> */}
                  {/* <td style={{ cursor: "pointer", color: "#145BD9" }}>
                    {method.doctor_wise.map((t) => (
                      <p className="p-2" onClick={() => { moveToConsultantExopeDetial(method, t.doctor_id); }}> {t.doctor_name} <br /></p>
                    ))}
                  </td> */}
                  <td>
                    {method.doctor_wise.map((t) => (
                      // <p className="p-2"> {t.treatments[0].doctor_price} <br /></p>
                      <p className="p-2"> {t.total} <br /></p>
                    ))}
                  </td>

                  {/* <td>
                    {method.doctor_wise.map((t) => (
                      <p className="p-2"> <br />  <input type="number" value={userInput} onChange={handleInputChange} /></p>

                    ))}
                  </td> */}


                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                    (
                      // <td>
                      //   <form >
                      //     <input type="number" required style={{ border: '2px solid #F5F5F5' }} />
                      //     < button type="submit" className="btn btn-primary ms-3" style={{ width: "auto" }}>Add</button>
                      //   </form>
                      // </td>
                      userRoleId === '3' && staff_category_name === "Auditor"? "" : <td>
                        {method.doctor_wise.map((t, ind) => (
                          <span>
                            <div className="dropdown">
                              <FaEllipsis onClick={() => toggleDropdown(t, method)} className="ellipsisicon" />
                              {(isOpen) && (
                                <div className="dropdown-content" >
                                  <div className="lis" aria-hidden="true"
                                    data-toggle="modal"
                                    data-target="#consultanpayment"
                                    onClick={() => handleForAddButtonClickpay('Add Consultant Expense Payment')}
                                    style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px', width: '100%' }}>
                                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                                      < RiMailAddLine className="edit_icon me-2" /><span >Add Pay</span></span>
                                  </div>
                                  <div className="lis" aria-hidden="true"
                                    data-toggle="modal"
                                    data-target="#consultanpayment"
                                    onClick={() => handleForEditButtonClickpay('Edit Consultant Expense Payment', 'Update', t)}
                                    style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px', width: '100%' }}>
                                    <span style={{ display: 'flex', justifyContent: 'center' }}>
                                      <RiEdit2Line className="edit_icon me-2" /><span >Edit Pay</span></span>
                                  </div>



                                </div>
                              )}
                            </div>
                            <span>
                              <br />
                            </span>
                          </span>

                        ))}
                      </td>
                    ) : null}


                  {/* <td>
                    <input type="number" value={userInput} onChange={handleInputChange} />
                  </td> */}

                  {/* <td>{balanceAmount}</td> */}
                  <td>
                    {method.doctor_wise.map((t) => (
                      // <p className="p-2"> {t.payment[0].balance} <br /></p>
                      <p className="p-2"> {t.payment.map((r) => (r.balance))} <br /></p>
                    ))}
                  </td>
                  <td>
                    {method.doctor_wise.map((t) => (
                      // <p className="p-2"> {t.payment[0].balance} <br /></p>
                      <p className="p-2"> {t.payment.map((r) => (r.status))} <br /></p>
                      // <p className="p-2">{t.payment[0].status}<br /></p>
                      // <p className="p-2">{t.payment[0] && t.payment[0].status}<br /></p>


                    ))}
                  </td>
                  {/* <td>
                    {method.payment.map((t) => (
                      <p className="p-2"> {t.status} <br /></p>
                    ))}
                  </td> */}

                  <td>{method.treatment_plan_method}</td>
                  {/* <td><button type="button" class="btn btn-primary my-5" aria-hidden="true"
                    style={{ cursor: "pointer", backgroundColor: "#145BD9" }}
                  >Submit</button></td> */}
                </tr>
              );
            })}
            <hr />
          </tbody>
        </table>


        <Pagination
          itemsPerPage={itemsPerPage}
          // totalItems={consultantexpensesLists.length}
          totalItems={searchedTreatmentPlanMethod ? searchedTreatmentPlanMethod.length : 0}
          currentPage={currentPage}
          paginate={paginate}
        />
      </div>



      {/* ========================================================================================================================= */}

      <section>
        <div className="container">
          <div className="modal fade" id="consultanpayment" role="dialog">
            <div className="po_sc_in">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>{heading}</h4>
                  <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <form onSubmit={submitConsultantExpenPay}>
                    <div className="row">
                      {/* <div className="col-md-3">

                      </div> */}
                      <div className="col-md-6 mb-sm-7 mb-4">
                        <label className="form-label">
                          <span className="required">Consultant Payment</span>
                        </label>
                        <input name="paid_amt" type="number" className="form-control"
                          placeholder="Payment"
                          value={state.paid_amt}
                          onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'paid_amt' }); }}
                          required />
                      </div>
                      <div className="col-md-6 mb-sm-7 mb-4">
                        <label className="form-label">
                          <span className="required">Status</span>
                        </label>
                        <input name="status" type="text" className="form-control"
                          placeholder="Status"
                          value={state.status}
                          onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'status' }); }}
                          required />
                      </div>

                    </div>
                    <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button>
                  </form>
                </div>
                <div className="modal-footer">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default ConsultantExpenses