import React from "react";

import MainDashboard from "views/admin/default";
import PatientListShow from "layouts/patients/patientlist";
import DoctorListShow from "layouts/doctor/doctorlistshow";
import StaffListShow from "layouts/staff/stafflistshow";
import InventoryListShow from "layouts/inventory/inventorylistshow";
import SettingShow from "layouts/setting/settingshow";
import AccountListShow from "layouts/account/accountlistshow";
import PharmacyListShow from "layouts/pharmacy/pharmacylistshow";
import MedicalInventory from 'views/patient/medicalinventory';
import PatientDetailComponent from "views/patient/patientdetial";
import PatientEnquiry from "views/patient/enquiry";
import AddPatientMember from "views/patient/addmenber";


// Icon Imports
import { MdHome } from "react-icons/md";
import { AiOutlineDatabase } from "react-icons/ai";
import { FaUser, FaClinicMedical, FaUserCircle, FaQuestionCircle } from 'react-icons/fa';
import { IoMdMedkit, IoMdPeople, IoMdPhotos, IoMdSettings } from 'react-icons/io';
import EventList from "layouts/event/Event";
import Inquiry from "views/inquiry/inquiry";

const userRoleId = localStorage.getItem('role_id');
const staff_category_name = localStorage.getItem('staff_category_name');


const routes = [
  {
    name: "Dashboard",
    layout: "/",
    path: "default",
    icon: <MdHome className="h-8 w-8" />,
    component: <MainDashboard />,
    roll_id: ['1', '2', '3', '4', '5']
  },
  {
    name: "Inquiry",
    layout: "/",
    path: "inquiries",
    icon: <MdHome className="h-8 w-8" />,
    component: <Inquiry />,
    roll_id: ['1', '2', '3', '5']
  },
  {
    name: "Patient",
    layout: "/",
    path: "patient",
    icon: <FaUser className="h-8 w-8" />,
    component: <PatientListShow />,
    secondary: true,
    roll_id: ['1', '2', '3', '5']
  },
  {
    name: "Patient",
    layout: "/",
    path: "patient-detail",
    icon: <FaUser className="h-8 w-8" />,
    component: <PatientDetailComponent />,
    roll_id: ['4']
  },
  {
    name: "Enquiry",
    layout: "/",
    path: "enquiry",
    icon: <FaQuestionCircle className="h-8 w-8" />,
    component: <PatientEnquiry />,
    roll_id: ['4']
  },
  {
    name: "Family Member",
    layout: "/",
    path: "patientmenber",
    icon: <FaUser className="h-8 w-8" />,
    component: <AddPatientMember />,
    roll_id: ['4']
  },
  {
    name: "Doctors",
    layout: "/",
    icon: <IoMdMedkit className="h-8 w-8" />,
    path: "doctor-List",
    component: <DoctorListShow />,
    roll_id: ['1', '2', '3', '5']
  },
  {
    name: "Staff",
    layout: "/",
    path: "staff-list",
    icon: <IoMdPeople className="h-8 w-8" />,
    component: <StaffListShow />,
    roll_id: ['1', '2', '3', '5']
  },
  userRoleId === '3' && staff_category_name === "Auditor" ? "" : {
    name: "Inventory",
    layout: "/",
    path: "Inventory",
    icon: <AiOutlineDatabase className="h-8 w-8" />,
    component: <InventoryListShow />,
    roll_id: ['1', '3', '5']
  },
  userRoleId === '3' && staff_category_name === "Auditor" ? "" : {
    name: "Pharmacy",
    layout: "/",
    path: "Pharmacy",
    icon: <FaClinicMedical className="h-8 w-8" />,
    component: <PharmacyListShow />,
    roll_id: ['1', '3', '5']
  },
  {
    name: "Accounts",
    layout: "/",
    path: "Account",
    icon: <FaUserCircle className="h-8 w-8" />,
    component: <AccountListShow />,
    roll_id: ['1', '5', '3']
  },
  {
    name: "Setting",
    layout: "/",
    path: "Setting",
    icon: <IoMdSettings className="h-8 w-8" />,
    component: <SettingShow />,
    roll_id: ['1', '5']
  },
  {
    name: "Event",
    layout: "/",
    path: "events",
    icon: <IoMdPhotos className="h-8 w-8" />,
    component: <EventList />,
    roll_id: ['1', '2', '3', '5']
  },
];
export default routes;
