import React, { useState } from 'react';
import Logo from "assets/img/logo/logo.png";
import { Link } from "react-router-dom";
import * as api from '../../repository/api_calls.jsx';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
// import { Rings } from 'react-loader-spinner';

const SignIn = () => {

  const [phone, setphone] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false); // New state variable

  const handleLogin = async (e) => {
    e.preventDefault();
    if (isLoggingIn) {
      // If login is already in progress, do nothing
      return;
    }
    setIsLoggingIn(true);
    try {
      await loginFunction();
    } finally {
      // Reset the state variable whether login is successful or not
      setIsLoggingIn(false);
    }
  };

  // async function loginFunction() {
  //   var response = await api.loginAPICall(phone, password);
  //   if (response['status'] === true) {
  //     console.log(response['data'])
  //     localStorage.setItem('role_id', response['data']['user']['role_id']);
  //     localStorage.setItem('token', response['data']['token']);
  //     localStorage.setItem('userdata', JSON.stringify(response['data']))

  //     toast.success(response['message'], {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: 'foo_bar_log_Success'
  //     });
  //     setTimeout(() => {
  //       window.location.href = "/default";
  //     }, 350);

  //   } else {
  //     toast.error(response['message'], {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: 'foo_bar_log_error'
  //     });
  //   }
  // }
  async function loginFunction() {
    const response = await api.loginAPICall(phone, password);
    console.log(response)
    const { status, data, message, members_list, token } = response;

    if (status === true) {
      console.log(data);
      console.log(token);
      console.log(members_list);
      // localStorage.setItem('role_id', data.user.role_id);
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('tokenTimestamp', Date.now());
      localStorage.setItem('role_id', data.role_id);
      localStorage.setItem('staff_category_name', data.staff_category_name);

      // localStorage.setItem('token', response);
      if (data.role_id === 4 || data.role_id === '4') {
        localStorage.setItem('userdata', JSON.stringify({ "user": members_list[0] }));
        localStorage.setItem('members', JSON.stringify(members_list));
      } else {
        localStorage.setItem('userdata', JSON.stringify({ "user": data }));
        members_list.push(data);
        localStorage.setItem('members', JSON.stringify(members_list));
      }

      // console.log(typeof members_list)
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });

      function redirectToDefaultPage() {
        window.location.href = '/default';
      }

      // ...

      setTimeout(redirectToDefaultPage, 50);


    } else {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
  }


  return (

    <div className='reg_form_fam' style={{ overflowX: "hidden" }}>
      <ToastContainer autoClose={3000} />
      {/* <Rings
        height="80"
        width="80"
        radius="9"
        color="green"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      /> */}
      <div className="mt-5">
        <img
          className=""
          style={{ height: "90px", width: "150px", display: "block", margin: "0 auto" }}
          src={Logo}
          alt="Logo"
        />
      </div>

      <div className="container logincard1">

        <div className="row" style={{ marginTop: "50px" }}>
          <div className="col-md-1 mb-sm-7 mb-4" > </div>
          <div className="col-md-6 mb-sm-6 mb-4" >
            <div style={{ backgroundColor: "white", height: "auto", width: "600px", padding: "20px", borderRadius: "10px", boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>

              <form onSubmit={handleLogin} method="post">
                <div className="row">
                  <h1 className="text-center mb-7" style={{ fontSize: "24px", paddingTop: "20px" }}>Sign In</h1>
                  <div className="col-md-2 mb-sm-7 mb-4" >
                  </div>
                  <div className="col-md-7 mb-sm-7 mb-4">
                    <label className="form-label">
                      <span className="required">Mobile No:</span>
                    </label>
                    <input type="phone" className="form-control"
                      name="phone"
                      placeholder="Mobile.No"
                      minlength="10"
                      maxlength="10"
                      value={phone}
                      onChange={(e) => setphone(e.target.value)}
                      required
                      style={{ width: "350px" }} />
                  </div>
                  <div className="col-md-2 mb-sm-7 mb-4" >
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-2 mb-sm-7 mb-4" >
                  </div>
                  <div className="col-md-7 mb-sm-7 mb-4">
                    <label className="form-label">
                      <span className="required">Password:</span>
                    </label>
                    <input type="password" className="form-control"
                      name="password"
                      placeholder=""
                      minlength="6"
                      maxlength="50"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      style={{ width: "350px" }} />
                  </div>
                  <div className="col-md-2 mb-sm-7 mb-4" >
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-2 mb-sm-7 mb-4" ></div>
                  <div className="col-md-7 mb-sm-7 mb-4 form-check">
                    <input type="checkbox" className="form-check-input" style={{ marginLeft: "3px" }} id="remember_me" name="remember" />
                    <label className="form-check-label" for="remember_me" style={{ marginLeft: "3px" }}>Remember Me</label>
                    {/* <Link to="/forget" className='float-end text-primary'>Forgot Password</Link> */}
                    {!isLoggingIn ? (
                      <Link to="/forget" className='float-end text-primary'>Forgot Password</Link>
                    ) : (
                      <span className='float-end text-primary' style={{ color: 'gray', cursor: 'not-allowed' }}>Forgot Password</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 mb-4" ></div>
                  <div className="col-md-10 mb-sm-7 mb-4" >
                    <button type="submit" className="btn btn-primary" style={{ width: "350px", backgroundColor: "#5660d9" }}>Login</button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 mb-4" ></div>
                  <div className="col-md-8 mb-4" >
                    {/* <span className="text-gray-700 me-2" >If you don't have an account ? </span>
                    <Link to="register" className="link-info text-decoration-none" style={{ fontSize: "12px" }}>
                      Register an Account
                    </Link> */}
                    {isLoggingIn ? (
                      <span className="text-gray-700 me-2" style={{ fontSize: "12px", color: 'gray', cursor: 'not-allowed' }}>
                        <span style={{ color: "black" }}>If you don't have an account? </span> Register an Account
                      </span>
                    ) : (
                      <Link to="register" className="link-info text-decoration-none" style={{ fontSize: "12px" }}>
                        <span style={{ color: "black" }}>If you don't have an account? </span> Register an Account
                      </Link>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------------- */}

      <div className="logincard2" style={{ marginTop: "50px", marginLeft: "30px", marginRight: "30px" }} >
        <div className='row'>
          <div className='col-lg-4'>

          </div>
          <div className='col-lg-4'>
            <div className='card' style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
              <form onSubmit={handleLogin} method="post" >
                <h1 className="text-center mb-7" style={{ fontSize: "24px", paddingTop: "20px" }}>Sign In</h1>
                <div className="">
                  <label className="form-label">
                    <span className="required">Mobile No:</span>
                  </label>
                  <input type="phone" className="form-control"
                    name="phone"
                    placeholder="Mobile.No"
                    minlength="10"
                    maxlength="10"
                    value={phone}
                    onChange={(e) => setphone(e.target.value)}
                    required
                    style={{ width: "100%" }} />
                </div>

                <div className="">
                  <label className="form-label">
                    <span className="required">Password:</span>
                  </label>
                  <input type="password" className="form-control"
                    name="password"
                    placeholder=""
                    minlength="6"
                    maxlength="50"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{ width: "100%" }} />
                </div>
                <div className="form-check mt-3">
                  <input type="checkbox" className="form-check-input" id="remember_me" name="remember" checked />
                  <label className="form-check-label" for="remember_me" style={{ marginLeft: "3px" }}>Remember Me</label>
                  {/* <Link to="/forget" className='float-end text-primary'>Forget Password</Link> */}
                  {!isLoggingIn ? (
                    <Link to="/forget" className='float-end text-primary'>Forgot Password</Link>
                  ) : (
                    <span className='float-end text-primary' style={{ color: 'gray', cursor: 'not-allowed' }}>Forgot Password</span>
                  )}
                </div>
                <div className="" >
                  <button type="submit" className="btn btn-primary" style={{ width: "100%", backgroundColor: "#5660d9" }}>Login</button>
                </div>
                {/* <div className="text-center" >
                  <span className="text-gray-700 me-2" >If you don't have an account ? </span>

                </div>
                <div className="text-center">
                  <span>
                    <Link to="register" className="link-info text-decoration-none" style={{ fontSize: "12px" }}>
                      Register an Account
                    </Link>
                  </span>
                </div> */}
                {isLoggingIn ? (
                  <span className="text-gray-700 me-2" style={{ fontSize: "12px", color: 'gray', cursor: 'not-allowed' }}>
                    <span style={{ color: "black" }}>If you don't have an account? </span> Register an Account
                  </span>
                ) : (
                  <Link to="register" className="link-info text-decoration-none" style={{ fontSize: "12px" }}>
                    <span style={{ color: "black" }}>If you don't have an account? </span> Register an Account
                  </Link>
                )}
              </form>

            </div>

          </div>
          <div className='col-lg-4'>

          </div>

        </div>

      </div>
      {/* -------------------------------------- */}
    </div>


  );
};
export default SignIn;