import React, { useEffect, useState, useReducer } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import * as con from '../../repository/api_calls';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Logo from "assets/img/logo/logo.png"
import Doctor from "assets/img/profile/doctor.png"
import { FaUser} from 'react-icons/fa';

function reducer(state, action) {
  if (action.type === 'set_value') {
    state[action.key] = action.value;
    return {
      image: state.image,

    };
  }
  if (action.type === 'set_base64') {
    return {
      image: action.value,
    }
  }

}

const Navbar = (props) => {

  const [state, dispatch] = useReducer(reducer, {
    image: '',
  })

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userRoleId = localStorage.getItem('role_id');

  const userdata = JSON.parse(localStorage.getItem("userdata")??"{}");
  const membersdata = localStorage.getItem("members")??"[]";


  const memData = JSON.parse(membersdata);
  console.log(memData)
  
  async function logout() {
    try {
      const response = await con.logoutAPI();
      const { status, message } = response;


      if (status) {
        toast.success(message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success',
        });
        setTimeout(() => {
          navigate('/auth');
          localStorage.clear();
          window.location.reload();
        }, 5);
      } else {
        toast.error(message, {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error',
        });
      }
    } catch (error) {
      console.error('Logout failed:', error);
      // Handle error appropriately, e.g., display an error toast.
    }
  }


  // -------------------------------  Notification list API -----------

  const [notificationList, setNotificationList] = useState([])

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const responseadd = await con.NotificationListAPI({
          "role": "staff_status",
          "id": userdata?.user?.id
        });

        if (isMounted) {
          setNotificationList(responseadd['notifications']);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const [enquirynotificationList, setEnquiryNotificationList] = useState([])
  const [opEnquirynotificationList, setOpEnquiryNotificationList] = useState([])

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const responseadd = await con.EnquiryListAPI({
          "role": "doctor_status",
          "id": userdata?.user?.id
        });
        console.log(responseadd)
        console.log(responseadd.notifications)
        console.log('yyyyyyyyyyyyyyyyy')

        if (isMounted) {
          setEnquiryNotificationList(responseadd['notifications']);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchData();
    const fetchOpData = async () => {
      try {
        const responseadd = await con.OpEnquiryListAPI({
          "role": "staff_status",
          "id": userdata?.user?.id
        });
        console.log(responseadd)
        console.log(responseadd.notifications)
        // console.log('yyyyyyyyyyyyyyyyy')

        if (isMounted) {
          setOpEnquiryNotificationList(responseadd['notifications']);
        }
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchOpData()

    return () => {
      isMounted = false;
    };
  }, []);


  function handleStatus(e) {
    e.preventDefault()
    changeStatus()
  }

  function handleStatusop() {
    // e.preventDefault()
    changeStatusop()
  }

  async function changeStatus() {
    const responseadd = await con.changeStatus({
      "role": "staff_status",
      "id": userdata?.user?.id
    });
    if (responseadd['status'] == true) {
      toast.success(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        window.location.reload();
      }, 800);
    }
    else {
      toast.error(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }
  async function changeStatusop() {
    const responseadd = await con.changeStatusop( {
      "role":"staff_status",
      "id": userdata?.user?.id
  });
    if (responseadd['status'] == true) {


      console.log("hkdsjhkashdkljhasdlkjfhasdlkdjfhlksadflkjasdhgfkjahgsdfkhasdkfjhaskdjjhlkajsjdhgflkashgdflkjhgasdi")
      toast.success(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        // window.location.reload();
      }, 50);
    }
    else {
      toast.error(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }
  function handleEneuiryStatus(e) {
    e.preventDefault()
    changeEnquirStatus()
  }

  async function changeEnquirStatus() {
    const responseadd = await con.changeEnquirStatus({
      "role": "doctor_status",
      "id": userdata?.user?.id
    });
    if (responseadd['status'] == true) {
      toast.success(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        window.location.reload();
      }, 800);
    }
    else {
      toast.error(responseadd['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }


  const { onOpenSidenav, brandText } = props;

  const handleChangeMem = (mem) => {
    console.log(mem)
    localStorage.setItem('userdata', JSON.stringify({ "user": mem }));
    localStorage.setItem('role_id', mem.role_id);

    function redirectToDefaultPage() {
      window.location.href = '/default';
    }

    // ...

    setTimeout(redirectToDefaultPage, 50);
  }

  return (
    <div>
      <div>
        <ToastContainer autoClose={3000} />
      </div>
      <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
        <div className="ml-[6px]" style={{ marginTop: "17px" }}>
          <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white" style={{ marginLeft: "17px" }}>
            <Link
              to="#"
              className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
            >
              {brandText}
            </Link>
          </p>
        </div>

        <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full  px-2 py-2  dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2" >
          <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
            <p className="pl-3 pr-2 text-xl">
            </p>
          </div>
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={onOpenSidenav}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span>
          {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
            (
              <Dropdown
                button={
                  <p className="cursor-pointer">
                    <IoMdNotificationsOutline className="h-9 w-9 text-gray-600 dark:text-white" />
                    {/* <span className="notification-dot" style={{ display: notificationList.length > 0 ? "block" : "none" }}></span> */}
                    <span className="notification-dot" style={{ display: notificationList && notificationList.length > 0 || enquirynotificationList && enquirynotificationList.length > 0 || opEnquirynotificationList && opEnquirynotificationList.length > 0? "block" : "none" }}></span>

                  </p>
                }
                animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                children={
                  <div className="flex w-[360px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
                    <div className="flex items-center justify-between">
                      <p className="font-bold text-navy-700 dark:text-white">
                        Notification
                      </p>
                      <p onClick={handleStatus} className="text-md font-bold text-navy-700 dark:text-white cursor-pointer" style={{ display: notificationList && notificationList.length > 0 ? "block" : "none" }} >
                        Mark as read
                      </p>
                    </div>

                    {notificationList && notificationList.map((notifica, index) => {
                      return (
                        <p> {index + 1} . A new patient has been added - <span><b>{notifica.first_name}  {notifica.surname} </b> (<b>{notifica.phone}</b>)</span></p>
                      )
                    })}
                    <div className="flex items-center justify-between mt-3">
                      <p className="font-bold text-navy-700 dark:text-white" style={{ display: enquirynotificationList && enquirynotificationList.length > 0 ? "block" : "none" }}>
                        Enquiry
                      </p>
                      <p onClick={handleEneuiryStatus} className="text-md font-bold text-navy-700 dark:text-white cursor-pointer" style={{ display: enquirynotificationList && enquirynotificationList.length > 0 ? "block" : "none" }} >
                        Mark as read
                      </p>
                    </div>
                    {enquirynotificationList && enquirynotificationList.map((enquinotifica, index) => {
                      return (
                        <p> {index + 1} . Patient Enquiry - <span><b>{enquinotifica.date} </b> <b>{enquinotifica.first_name} {enquinotifica.surname} </b> (<b>{enquinotifica.phone}</b>)</span></p>
                      )
                    })}
                    <div className="flex items-center justify-between mt-3">
                      <p className="font-bold text-navy-700 dark:text-white" style={{ display: opEnquirynotificationList && opEnquirynotificationList.length > 0 ? "block" : "none" }}>
                       Out Patient Enquiry
                      </p>
                      <p onClick={handleStatusop} className="text-md font-bold text-navy-700 dark:text-white cursor-pointer" style={{ display: opEnquirynotificationList && opEnquirynotificationList.length > 0 ? "block" : "none" }} >
                        Mark as read
                      </p>
                    </div>
                    {opEnquirynotificationList && opEnquirynotificationList.length > 0 && opEnquirynotificationList.map((enquinotifica, index) => {
                      return (
                        <p> {index + 1} . Patient Enquiry - <span><b>{enquinotifica.date} </b> <b>{enquinotifica.patient_name} </b> (<b>{enquinotifica.mobile_number}</b>)</span></p>
                      )
                    })}
                  </div>
                }
                classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
              />
            ) : null}

          <Dropdown
            button={
              // <img
              //   className="h-12 w-12 rounded-full"
              //   src={Logo}
              //   alt="Elon Musk"
              // />
              <img src={userdata?.user?.image === "" ? Doctor : userdata?.user?.image} alt="Profile" style={{ width: "55px", height: "55px", borderRadius: "50%", cursor: 'pointer' }} />
            }
            children={
              <div className="flex w-60 p-1 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <p className="text-md font-bold text-navy-700 dark:text-white">
                      👋 Hey, {userdata?.user?.first_name}
                    </p>{" "}
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-4">
                  {/* {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '3' || userRoleId === '4') ?
                    ( */}
                  <a
                    href="/profile"
                    className="text-md text-gray-800 dark:text-white hover:dark:text-white"
                  >
                    Profile Settings
                  </a>
                  {memData?.map((mem)=>
                  <a
                  href="#"
                  onClick={()=>handleChangeMem(mem)}
                  className="text  hover:dark:text-white mt-2 d-flex align-items-center gap-2"
                >
                  {userdata?.user?.id!== mem.id?  <><span> <FaUser className="h-4 w-4" /></span>  <span>{mem.first_name}</span></>: ""}
              
                </a>)}
                  
                  {/* ) : null} */}
                  <span
                    onClick={logout}
                    className="mt-3 text-md font-medium text-red-500 hover:text-red-500 cursor-pointer"
                  >

                    Log Out
                  </span>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
