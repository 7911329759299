import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom";
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";

const ConsiltantExDetial = (props) => {


    const userRoleId = localStorage.getItem('role_id');
    const patientuserdata = JSON.parse(localStorage.getItem("userdata"))

    const location = useLocation();
    // const userData = location.state;
    const userData = {};

    // Assign a value based on location.state
    if (location.state) {
        Object.assign(userData, location.state);
    }

    const { ...rest } = props;

    return (
        <React.Fragment>
            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                (
                    <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
                        <Sidebar open={open} onClose={() => setOpen(false)} />
                        <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">

                            <main
                                className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
                            >
                                {/* Routes */}
                                <div className="h-full">
                                    <Navbar
                                        onOpenSidenav={() => setOpen(true)}
                                        logoText={"Kirthika Dental Care"}
                                        brandText="Consultant Detail"
                                        // secondary={getActiveNavbar(routes)}
                                        {...rest}
                                    />

                                    <table className="bordered-table">
                                        <thead className="tablehead">
                                            <tr style={{ borderRadius: "10px" }}>
                                                <th>S.No</th>
                                                <th>Doctor Name</th>
                                                <th>Patient Name</th>
                                                <th>Doctor's Fee</th>
                                                <th>Treatment Method</th>
                                                {/* <th>Method Price</th> */}
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {userData.data.map((t, index) => (
                                                <tr key={index} >
                                                    <td>{index + 1}</td>
                                                    <td> {t['doctor_name'] ? t['doctor_name'] : '-'}</td>
                                                    <td> {t['patient_name'] ? t['patient_name'] : '-'}</td>
                                                    <td> {t.treatments[0]['doctor_price'] ? t.treatments[0]['doctor_price'] : '-'}</td>
                                                    <td> {t.treatments[0]['treatment_method'] ? t.treatments[0]['treatment_method'] : '-'}</td>
                                                    {/* <td> {t.treatments[0]['method_price'] ? t.treatments[0]['method_price'] : '-'}</td> */}
                                                </tr>
                                            ))}
                                            <hr />
                                        </tbody>
                                    </table>
                                </div>

                            </main>
                        </div>
                    </div>
                ) : null}

        </React.Fragment>

    );
};


export default ConsiltantExDetial;