import React, { useEffect, useState } from "react"
import * as con from '../../../repository/api_calls';



const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};


const PatientClinicIncome = () => {

    // -------------------------------  Patient clinic income list API -----------

    const [patientclinicLists, setPatientclinicincoList] = useState([])

    useEffect(() => {
        getPatientClinicLists();
    }, [])

    async function getPatientClinicLists() {
        const response = await con.listClinicPayment();
        console.log(response)
        // console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee')
        if (response['status'] === true) {
            setPatientclinicincoList(response.sum.reverse());
        }
    }
    const [searchTerm, setSearchTerm] = useState("");

    const searchedpatientclinicLists = patientclinicLists.filter((c) =>
        c.date.includes(searchTerm) ||
        c.data.some((t) => t.doctor_name && t.doctor_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentpatientclinic = searchedpatientclinicLists.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    return (
        <React.Fragment>

            <div style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>
                <input className="form-control searchBar mb-5" type="text"
                    placeholder="Global Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />

                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px", cursor: "pointer" }}>
                            <th>S.No</th>
                            <th>Year & Month</th>
                            <th>Invoice</th>
                            <th>Patient Name</th>
                            {/* <th>Treatment</th> */}
                            <th>Paid Amount</th>
                        </tr>
                    </thead>

                    {currentpatientclinic.map((patientclinic, index) => {
                        const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
                        console.log(patientclinic.data)
                        return (
                            <tr key={index}>
                                <td>{continuousIndex}</td>
                                <td>{patientclinic.date}</td>
                                <td>
                                    {patientclinic.data.map((t) => (
                                        <p className="p-2"> {t.invoice} <br /></p>
                                    ))}
                                </td>
                                <td>
                                    {patientclinic.data.map((t) => (
                                        <p className="p-2"> {t.patient_name} <br /></p>
                                    ))}
                                </td>
                               
                                {/* <td>
                                    {patientclinic.data.map((t) => (t.doctor_id?.map((f) =>
                                        <p className="p-2"> {f.doctor_name} <br /></p>

                                    )))}
                                </td> */}

                                {/* <td>
                                    {patientclinic.data.map((t) => (t.
                                        doctor_id?.map((p) =>
                                            <p className="p-2">{JSON.parse(p.service).problem.name}<br /></p>

                                        )))}
                                </td> */}
                                <td>
                                    {patientclinic.data.map((t) => (
                                        <p className="p-2"> {t.paid_amt} <br /></p>
                                    ))}
                                </td>
                            </tr>
                        )
                    })}
                    <tbody>
                        <hr />
                    </tbody>

                </table>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={patientclinicLists.length}
                    currentPage={currentPage}
                    paginate={paginate}
                />
            </div>

        </React.Fragment>
    )
}

export default PatientClinicIncome