import React, { useState, useEffect } from "react"
import "./monthly.css"
import * as con from '../../../../repository/api_calls';

const MonthlySummary = (props) => {

  const dateP = props.fdate === undefined ? new Date() : props.fdate;

  const [actTab, setActTab] = useState()

  const userdata = JSON.parse(localStorage.getItem("userdata"));

  const userId = userdata.user.id

  const userRoleId = localStorage.getItem('role_id');

  const [appointmentLists, setAppointmentList] = useState([])
  const [appointmentList, setList] = useState(true)


  useEffect(() => {
    const appointDate = {
     "date": props.fdate
    }
    console.log(appointDate)
    console.log(appointmentList)
    // UpcomingAppointments(appointDate)
    getappointmentLists();
  }, [props.fdate, userId, userRoleId]);

//   async function UpcomingAppointments(date) {
    
    

//     const response = await con.AppointmentAPI(date);
//     // const response = await con.UpcomingAppointmentAPI((JSON.stringify(date)));
//     console.log(response);
//     if (response['status'] === true) {
//       setAppointmentList(response['data'])
//       // toast.success(response['message'], {
//       //   position: toast.POSITION.TOP_RIGHT,
//       //   className: 'foo_bar_log_Success'
//       // });

//       // setTimeout(() => {
//       //   window.location.reload();
//       // }, 800);
//       setList(true)

//     }
//     else {
    
//       setList(false)
//       console.log("Error:",  response.message)
//       // toast.error(response['message'], {
//       //   position: toast.POSITION.TOP_RIGHT,
//       //   className: 'foo_bar_log_error'
//       // })
//     }
  
// }

  async function getappointmentLists() {
    const responselist = await con.appointmentList();

    if (responselist['status'] === true) {
      let filteredAppointments;

      if (userRoleId === '2') {
        filteredAppointments = responselist['data'].filter(appointment =>
          new Date(appointment.appointment_date).toDateString() === new Date(props.fdate).toDateString() &&
          appointment.doctor_id === userId
        );
      } else if (userRoleId === '4') {
        filteredAppointments = responselist['data'].filter(appointment =>
          new Date(appointment.appointment_date).toDateString() === new Date(props.fdate).toDateString() &&
          appointment.patient_id === userId
        );
      } else if (userRoleId === '1' || userRoleId === '5') {
        filteredAppointments = responselist['data'].filter(appointment =>
          new Date(appointment.appointment_date).toDateString() === new Date(props.fdate).toDateString()
          // &&
          // appointment.patient_id === userId
        );
      } else {

      }

      setAppointmentList(filteredAppointments);
    }
  }
  const handleTab = (e) => {
    e.preventDefault()
    setActTab(e.target.name)
  }

  return (
    <>
      {/* {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2') ?
        (
          <div className="nav-tab ">

            <div className={actTab ? 'hidden' : 'visible'}>

              <h1 className="mt-5" style={{ marginLeft: "10px", fontSize: "18px" }}><b>DATE: <em>{dateP}</em></b></h1>

              <table className="bordered-table">
                <thead className="tablehead" >
                  <tr style={{ borderRadius: "10px" }}>
                    <th>Id</th>
                    <th>Patient Name</th>
                    <th>Appointment Date</th>
                    <th>Appointment Time</th>
                    <th>Price</th>
                    <th>Status</th>

                  </tr>
                </thead>

                <tbody>

                  {(appointmentLists !== undefined && appointmentLists.length !== 0 && appointmentLists !== null) ?

                    (appointmentLists.map((appointment, index) => {
                      return (

                        <tr key={appointment.id}>
                          <td>{appointment.appointment_id}</td>
                          <td>{appointment.patient_first_name}</td>
                          <td>{new Date(appointment.appointment_date).toLocaleDateString()}</td>
                          <td>{appointment.appointment_time}</td>
                          <td>{appointment.treatment_plan_price}</td>
                          <td>{appointment.appointment_status}</td>
                        </tr>
                      )
                    }))

                    : (
                      <tr>
                        <td colSpan="5">There are no appointments for the selected date.</td>
                      </tr>
                    )}


                </tbody>
              </table>
            </div>

          </div>
        ) : null}

      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4') ?
        (
          <div className="nav-tab ">

            <div className={actTab ? 'hidden' : 'visible'}>

              <h1 className="mt-5" style={{ marginLeft: "10px", fontSize: "18px" }}><b>DATE: <em>{dateP}</em></b></h1>

              <table className="bordered-table">
                <thead className="tablehead" >
                  <tr style={{ borderRadius: "10px" }}>

                    <th>Id</th>
                    <th>Doctor Name</th>
                    <th>Appointment Date</th>
                    <th>Appointment Time</th>
                    <th>Price</th>
                    <th>Status</th>

                  </tr>
                </thead>

                <tbody>

                  {(appointmentLists !== undefined && appointmentLists.length !== 0 && appointmentLists !== null) ?

                    (appointmentLists.map((appointment, index) => {
                      return (

                        <tr key={appointment.id}>
                          <td>{appointment.appointment_id}</td>
                          <td>{appointment.doctor_first_name}</td>
                          <td>{new Date(appointment.appointment_date).toLocaleDateString()}</td>
                          <td>{appointment.appointment_time}</td>
                          <td>{appointment.treatment_plan_price}</td>
                          <td>{appointment.appointment_status}</td>

                        </tr>
                      )
                    }))

                    : (
                      <tr>
                        <td colSpan="5">There are no appointments for the selected date.</td>
                      </tr>
                    )}


                </tbody>
              </table>
            </div>

          </div>
        ) : null} */}

      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') || (userRoleId === '2')  ?
        (
          <div className="nav-tab ">

            <div className={actTab ? 'hidden' : 'visible'}>


              <h1 style={{ fontSize: "18px", marginLeft: "30px" }}><b>Day Appoinment:</b> <span style={{ marginLeft: "10px", fontSize: "17px" }}><em>{dateP}</em></span></h1>

              <table className="bordered-table" style={{ marginLeft: "20px", marginTop: "20px" }}>

                <thead className="tablehead" >

                  {/* <p className="mt-5" style={{ marginLeft: "10px", fontSize: "18px" }}><span>Date: <em>({dateP})</em></span></p> */}
                  <tr style={{ borderRadius: "10px" }}>


                    <th>S.no</th>
                    <th>Doctor Name</th>
                    <th>Patient Name</th>
                    <th>Appointment Date</th>
                    <th>Appointment Time</th>
                    {/* <th>Price</th> */}
                    <th>Status</th>

                  </tr>
                </thead>

                <tbody>

                  {(appointmentLists !== undefined && appointmentLists.length !== 0 && appointmentLists !== null && appointmentList === true ) ?

                    (appointmentLists.map((appointment, index) => {
                      return (

                        <tr key={index}>
                          <td>{index+1}</td>
                          {/* <td>{appointment.appointment_id}</td> */}
                          <td>{appointment.doctor_first_name}</td>
                          {/* <td>{appointment.first_name}</td> */}
                          <td>{appointment.patient_first_name}</td>
                          <td>{new Date(appointment.appointment_date).toLocaleDateString()}</td>
                          <td>{appointment.appointment_time}</td>
                          {/* <td>{appointment.appointments.map((d)=> d.appointment_time)}</td> */}
                          {/* <td>{appointment.appointments.map((d) => d.appointment_time.split("").join("") + " , ")}</td> */}
                          {/* <td>{appointment.treatment_plan_price}</td> */}
                          <td>{appointment.appointment_status}</td>

                        </tr>
                      )
                    }))

                    : appointmentList === false && (
                      <tr>
                        <td colSpan="5">There are no appointments for the selected date.</td>
                      </tr>
                    )}


                </tbody>
              </table>
            </div>

          </div>
        ) : null}
    </>
  );

};


export default MonthlySummary;