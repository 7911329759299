import React from "react";
import Event from "views/event/event";
const EventList = (prop) => {

    return (
        <div>

            <Event />

        </div>

    );
};
export default EventList;