import DoctorList from "views/doctor/doctorlist";

import React from "react";
const DoctorListShow = (prop) => {

    return (
        <div>

            <DoctorList />

        </div>

    );
};
export default DoctorListShow;