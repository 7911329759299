import React, {useState, useEffect} from "react";
import * as con from '../../repository/api_calls';
import { toast } from 'react-toastify';


const Inquiry = () => {

    const userdata = JSON.parse(localStorage.getItem("userdata")??"{}");

    // const [enquirynotificationList, setEnquiryNotificationList] = useState([])
  const [opEnquirynotificationList, setOpEnquiryNotificationList] = useState([])

    useEffect(() => {
        let isMounted = true;
    
        // const fetchData = async () => {
        //   try {
        //     const responseadd = await con.EnquiryListAPI({
        //       "role": "doctor_status",
        //       "id": userdata?.user?.id
        //     });
        //     console.log(responseadd)
        //     console.log(responseadd.notifications)
        //     console.log('yyyyyyyyyyyyyyyyy')
    
        //     if (isMounted) {
        //       setEnquiryNotificationList(responseadd['notifications']);
        //     }
        //   } catch (error) {
        //     console.error("Error fetching notifications:", error);
        //   }
        // };
    
        // fetchData();
        const fetchOpData = async () => {
          try {
            const responseadd = await con.OpEnquiryListAPI({
              "role": "staff_status",
              "id": userdata?.user?.id
            });
            console.log(responseadd)
            console.log(responseadd.notifications)
            // console.log('yyyyyyyyyyyyyyyyy')
    
            if (isMounted) {
              setOpEnquiryNotificationList(responseadd['notifications']);
            }
          } catch (error) {
            console.error("Error fetching notifications:", error);
          }
        };
    
        fetchOpData()
    
        return () => {
          isMounted = false;
        };
      }, []);

      function handleStatusop() {
        // e.preventDefault()
        changeStatusop()
      }
      async function changeStatusop() {
        const responseadd = await con.changeStatusop( {
          "role":"staff_status",
          "id": userdata?.user?.id
      });
        if (responseadd['status'] == true) {

          toast.success(responseadd['message'], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_Success'
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
        else {
          toast.error(responseadd['message'], {
            position: toast.POSITION.TOP_RIGHT,
            className: 'foo_bar_log_error'
          });
    
        }
      }
    //   function handleEneuiryStatus(e) {
    //     e.preventDefault()
    //     changeEnquirStatus()
    //   }
    
    //   async function changeEnquirStatus() {
    //     const responseadd = await con.changeEnquirStatus({
    //       "role": "doctor_status",
    //       "id": userdata?.user?.id
    //     });
    //     if (responseadd['status'] == true) {
    //       toast.success(responseadd['message'], {
    //         position: toast.POSITION.TOP_RIGHT,
    //         className: 'foo_bar_log_Success'
    //       });
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 800);
    //     }
    //     else {
    //       toast.error(responseadd['message'], {
    //         position: toast.POSITION.TOP_RIGHT,
    //         className: 'foo_bar_log_error'
    //       });
    
    //     }
    //   }

    //   console.warn(enquirynotificationList)
      console.error(opEnquirynotificationList)

      return(
        <>
         <div className='tabtablediagno' style={{ width: "100%", height: "auto", backgroundColor: "white", marginTop: "30px", marginBottom: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <h2>Out patient Inquiry Notifications</h2>
                    <button onClick={handleStatusop} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007bff', color: 'white', border: 'none', cursor: 'pointer' }}>
                        Mark as Read
                    </button>
                </div>
         <table className="bordered-table prescription-tablescroll">
         <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Patient Name</th>
                            <th>Mobile Number</th>
                            <th>Appointment Date</th>
                            <th>Service Name</th>
                            <th>Appointment Time</th>
                            <th>Enquiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        {opEnquirynotificationList.map((item, index) => (
                            <tr key={item.enquiry_id}>
                                <td>{index + 1}</td>
                                <td>{item.patient_name}</td>
                                <td>{item.mobile_number}</td>
                                <td>{item.appointment_date}</td>
                                <td>{item.service_name}</td>
                                <td>{item.appointment_time}</td>
                                <td>{item.enquiry}</td>
                            </tr>
                        ))}
                    </tbody>
            </table>
            </div>
            </>
      )

}

export default Inquiry