import React, { useState, useEffect } from "react";
import { useReducer } from 'react';
import * as con from '../../../repository/api_calls';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';

function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            invoice_no: state.invoice_no,
            company_name: state.company_name,
            amount: state.amount,
            status: state.status,
            bill_copy: state.bill_copy,
            id: state.id
        };
    }
    if (action.type === 'set_base64') {
        return {
            invoice_no: state.invoice_no,
            company_name: state.company_name,
            amount: state.amount,
            status: state.status,
            bill_copy: state.value,
            id: state.id
        }
    }
}



const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};


// ----------------- for file view --------------

const openDocument = (url) => {
    window.open(url, '_blank');
};

const LabPayment = () => {


    const userRoleId = localStorage.getItem('role_id');
  const staff_category_name = localStorage.getItem('staff_category_name');
    // ---------------------------- popup form api ---------------

    const [state, dispatch] = useReducer(reducer, {
        invoice_no: '',
        company_name: '',
        amount: '',
        status: '',
        bill_copy: '',
        id: ''
    });

    // const submitLabPayment = (event) => {
    //     event.preventDefault();
    //     addLabPayme(state);
    // }
    const submitLabPayment = (event) => {
        event.preventDefault();
        if (state.id != '') {
            addLabPayme(state, 'edit');
        } else {
            addLabPayme(state, 'add');
        }
    }


    async function addLabPayme(LabPaymentdata, type) {
        const formData = new FormData();
        formData.append('invoice_no', LabPaymentdata.invoice_no);
        formData.append('company_name', LabPaymentdata.company_name);
        formData.append('amount', LabPaymentdata.amount);
        formData.append('status', LabPaymentdata.status);
        formData.append('bill_copy', LabPaymentdata.bill_copy);
        formData.append('id', LabPaymentdata.id);

        for (const [key, value] of formData.entries()) {
            console.log(key, value);
        }


        // Send API request
        if (type == 'add') {
            const response = await con.AddLabPayment(formData);

            if (response['status'] === true) {
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            } else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        }

        else {
            const response = await con.UpdateLabAccountAPI(formData);
            if (response['status'] === true) {

                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }

        }
    }



    const [editmode, setEditmode] = useState(false);

    // ----------- Change Table Heading and Button Dynamically -------------------

    const [heading, setHeading] = useState('Default Heading');
    const [button, setButton] = useState('Default Heading');
    const [addPharm, setaddPharm] = useState(false)
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = (newHeading, newButton) => {
        setHeading(newHeading);
        setButton(newButton)
        setEditmode(true)
        setDropdownOpen(!isDropdownOpen);
        setaddPharm(!addPharm);

        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'company_name' })
        dispatch({ type: 'set_value', value: '', key: 'bill_copy' })
        dispatch({ type: 'set_value', value: '', key: 'invoice_no' })
        dispatch({ type: 'set_value', value: '', key: 'amount' })
        dispatch({ type: 'set_value', value: '', key: 'status' })

    };


    // ---------------- for edit function -------

    function handleForEditButtonClick(newHeading, newButton, PharExpan) {
        setHeading(newHeading);
        setButton(newButton)
        setDropdownOpen(true);
        setEditmode(false)


        dispatch({ type: 'set_value', value: PharExpan.id, key: 'id' })
        dispatch({ type: 'set_value', value: PharExpan.company_name, key: 'company_name' })
        dispatch({ type: 'set_value', value: PharExpan.bill_copy, key: 'bill_copy' })
        dispatch({ type: 'set_value', value: PharExpan.invoice_no, key: 'invoice_no' })
        dispatch({ type: 'set_value', value: PharExpan.amount, key: 'amount' })
        dispatch({ type: 'set_value', value: PharExpan.status, key: 'status' })


    };


    // -------------------------------  Lab Payment list API -----------

    const [labpaymentLists, setLabPaymentList] = useState([])


    useEffect(() => {
        getLapPaymentLists();
    }, [])

    async function getLapPaymentLists() {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
        const response = await con.LabPaymentList((JSON.stringify({ month: currentMonth })));
        if (response['status'] === true) {
            setLabPaymentList(response.sum.reverse());
        }
    }

    // ------------------------- Delete API --------

    async function getLabPaymentDelete(deleteLabP) {
        const response = await con.LabPaymentDeleteAPI(deleteLabP);
        if (response['status'] === true) {
            let afterAdded = labpaymentLists.filter(item => item.id !== deleteLabP);
            setLabPaymentList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                window.location.reload();
            }, 800);
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }

    const [searchTerm, setSearchTerm] = useState("");

    const searchedInventory = labpaymentLists.filter((c) =>
        c.date.includes(searchTerm) || c.data.some((t) => t.company_name.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    // const searchedInventory = labpaymentLists.filter((c) =>
    //     (c.date && c.date.includes(searchTerm)) ||
    //     (c.data && c.data.some((t) => t.company_name && t.company_name.toLowerCase().includes(searchTerm.toLowerCase())))
    // );


    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentlabpayment = searchedInventory.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);



    return (
        <React.Fragment>
            {userRoleId === '3' && staff_category_name === "Auditor"? "" :<button type="button" className="btn btn-primary my-5" aria-hidden="true"
                data-toggle="modal"
                data-target="#addlabpayment"
                onClick={() => handleButtonClick('Add Lab Payment', 'Submit')}
                style={{ cursor: "pointer", float: "right", marginRight: "40px", backgroundColor: "#145BD9" }}
            ><span className="me-1" style={{ fontSize: "20px" }}>+</span>Add Lab Payment</button>}


            <div style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

                <input className="form-control searchBar mb-5" type="text"
                    placeholder="Global Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} />

                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px", cursor: "pointer" }}>
                            <th>S.No</th>
                            <th>Year & Month</th>
                            <th>Company</th>
                            <th>Bill Copy</th>
                            <th>Invoice NO	</th>
                            <th>Amount</th>
                            <th>Status</th>
                            {userRoleId === '3' && staff_category_name === "Auditor"? "" : <th>Edit</th>}
                            {userRoleId === '3' && staff_category_name === "Auditor"? "" : <th>Delete</th>}
                        </tr>
                    </thead>

                    <tbody>
                        {currentlabpayment.map((LabPay, index) => {
                            const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
                            return (

                                <tr key={index}>
                                    <td>{continuousIndex}</td>
                                    <td>{LabPay.date}</td>

                                    <td>
                                        {LabPay.data.map((t) => (
                                            <p className="p-2"> {t.company_name} <br /></p>
                                        ))}
                                    </td>
                                    <td>
                                        {LabPay.data.map((bill) => (
                                            <p className="cursor-pointer p-2" onClick={() => openDocument(bill.bill_copy)}>
                                                View File
                                            </p>
                                        ))}
                                    </td>
                                    <td>
                                        {LabPay.data.map((t) => (
                                            <p className="p-2"> {t.invoice_no} <br /></p>
                                        ))}
                                    </td>
                                    <td>
                                        {LabPay.data.map((t) => (
                                            <p className="p-2"> {t.amount} <br /></p>
                                        ))}
                                    </td>
                                    <td>
                                        {LabPay.data.map((t) => (
                                            <p className="p-2"> {t.status} <br /></p>
                                        ))}
                                    </td>
                                    {userRoleId === '3' && staff_category_name === "Auditor"? "" :  <td>
                                        {LabPay.data.map((t) => (
                                            <p className="p-2"> <RiEdit2Line className="edit_icon" data-toggle="modal"
                                                data-target="#addlabpayment" onClick={() => handleForEditButtonClick('Edit Lab Payment', 'Update', t)} /></p>
                                        ))}
                                    </td>}
                                    {userRoleId === '3' && staff_category_name === "Auditor"? "" :<td>
                                        {LabPay.data.map((t) => (
                                            <p className="my-3">   <RiDeleteBin2Line
                                                className="delete_icon"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => getLabPaymentDelete(t.id)} /></p>
                                        ))}
                                    </td>}  
                                    {/* <td>{LabPay.company_name}</td> */}
                                    {/* <td>{LabPay.bill_copy}</td> */}
                                    {/* <td className="cursor-pointer" onClick={() => { openDocument(LabPay['bill_copy']) }} >View File</td> */}
                                    {/* <td>{LabPay.invoice_no}</td> */}
                                    {/* <td>{LabPay.amount}</td> */}
                                    {/* <td>{LabPay.status}</td> */}
                                    {/* <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                                        onClick={() => getLabPaymentDelete(LabPay.id)} /></td> */}
                                </tr>
                            )
                        })}
                        <hr />
                    </tbody>

                </table>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={searchedInventory.length}
                    currentPage={currentPage}
                    paginate={paginate}
                />
            </div>


            <section>
                <div className="container">

                    <div className="modal fade" id="addlabpayment" role="dialog">
                        <div className="po_sc_in">

                            <div className="modal-content">
                                <div className="modal-header">
                                    {/* <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Add Lab Payment</h4> */}
                                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>{heading}</h4>
                                    <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                        ×
                                    </button>

                                </div>
                                <div className="modal-body">
                                    <form onSubmit={submitLabPayment}>

                                        <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                            <div className="col-md-6 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Company Name:</span>
                                                </label>
                                                <input name="company_name" type="text" className="form-control"
                                                    placeholder=" Name"
                                                    value={state.company_name}
                                                    // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'company_name' }); }}
                                                    onChange={(e) => {
                                                        var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                                                        if (sanitizedValue.length > 150) {
                                                            sanitizedValue = sanitizedValue.slice(0, 150);
                                                        }
                                                        const regex = /(.)\1{5,}/g;
                                                        sanitizedValue = sanitizedValue.replace(regex, '');
                                                        dispatch({ type: 'set_value', value: sanitizedValue, key: 'company_name' });
                                                    }}
                                                    required />
                                            </div>
                                            <div className="col-md-6 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Amount:</span>
                                                </label>
                                                <input name="amount" type="text" className="form-control"
                                                    placeholder="Amount"
                                                    value={state.amount}
                                                    // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'amount' }); }}
                                                    onChange={(e) => {
                                                        var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                        dispatch({ type: 'set_value', value: sanitizedValue, key: 'amount' });
                                                    }}
                                                    required />
                                            </div>
                                            <div className="col-md-6 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Invoice No:</span>
                                                </label>

                                                <input name="invoice_no" type="text" className="form-control"
                                                    placeholder="Invoice NO"
                                                    value={state.invoice_no}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'invoice_no' }); }}
                                                    // onChange={(e) => {
                                                    //     var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                    //     dispatch({ type: 'set_value', value: sanitizedValue, key: 'invoice_no' });
                                                    // }}
                                                    required />

                                            </div>
                                            <div className="col-md-6 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Bill Copy: (pdf, jpg, png, svg, documents) </span>
                                                    <p>File size must be less than 5 MB.</p>
                                                </label>
                                                <input name="file" id="fileInput" type="file"
                                                    placeholder=""
                                                    // value={state.bill_copy}
                                                    // onChange={handleFileInputChange}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.files[0], key: 'bill_copy' }); }}
                                                />
                                            </div>
                                            <div className="col-md-6 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Status</span>
                                                </label>
                                                <input name="Status" type="text" className="form-control"
                                                    placeholder="Status"
                                                    value={state.status}
                                                    // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'status' }); }}
                                                    onChange={(e) => {
                                                        var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                                                        if (sanitizedValue.length > 150) {
                                                            sanitizedValue = sanitizedValue.slice(0, 150);
                                                        }
                                                        const regex = /(.)\1{5,}/g;
                                                        sanitizedValue = sanitizedValue.replace(regex, '');
                                                        dispatch({ type: 'set_value', value: sanitizedValue, key: 'status' });
                                                    }}
                                                    required />
                                            </div>
                                        </div>
                                        {/* <button type="submit" class="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button> */}
                                        <button type="submit" class="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>{button}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}

export default LabPayment