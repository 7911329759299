import React, { createContext } from "react";
const UserContext = createContext();
const UserProvider = ({ children }) => {
  const gloabalVariables = { username: "john_doe", role: "admin", toothSelection: [], selectedTeethId: {}, dentalDiagnosis: [] }; // Example user data

  return <UserContext.Provider value={gloabalVariables}>{children}</UserContext.Provider>;
};

export default UserProvider;
export { UserContext };


export const userdata =  JSON.parse(localStorage.getItem("userdata"))

export const role = localStorage.getItem("role_id")

// console.log(role)


export const teethValues = [
  {
    'teeth_id': "1",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "2",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "3",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "4",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "5",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "6",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "7",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "8",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "9",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "10",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "11",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "12",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "13",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "14",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "15",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "16",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "17",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "18",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "19",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "20",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "21",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "22",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "23",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "24",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "25",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "26",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "27",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "28",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "29",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "30",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "31",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
  {
    'teeth_id': "32",
    'teeth_stroke': 'none',
    'selected_teeth': []
  },
]

