export const login = "postlogin"
export const DoctorList = "doctor_list"
export const StaffList = "staff_list"
export const UpcomingAppointment = "upcomming_appointments"
export const UpcomingAppointmentList = "upcoming_appointments_v2"
export const AppointmentDate = "appointment_list_v2"
export const PatientList = "patient_list"
// export const PatientList = "patient_list"
export const AddDoctor = "store_doctor"
export const DoctorDepartment = "doctor_departments"
export const StaffCategory = "staff_category_list"
export const AddStaff = "store_staff"
export const DeleteDoctor = "delete_doctor"
export const DeleteStaff = "delete_staff"
export const Deletepatient = "delete_patient"
export const PatientMedicalReport = "getPatientMedicalReport"
export const InventoryList = "inventory_list"
export const InventoryCategoryList = "inventory_category_list"
export const pharmaList = "pharmacy_list"
export const ActiveInactive = "active_inactive"
export const AddPatient = "store_patient"
export const getPharmacyCategory = "pharmacy_category_list"
export const AddPharmacy = "store_pharmacy"
export const UpdateStaff = "update_staff"
export const DeletePharmacy = "delete_pharmacy"
export const SettDiagnosList = "diagnosisList"



export const PrescriptionList = "patient_prescription_list_mapping"
export const MedicalInventoryList = "prescriptionsubCategoryList"

export const medicalCategoryList = "medicalCategoryList";

export const DiagnosisType = "diagnosistypeList"
export const AddDiagnosis = "storediagnosis"
export const DeleteDiagnosis = "deletediagnosis"
export const treatmentplanList = "treatmentList"
export const Addtreatment = "storetreatment";
export const Treaprocedure = "treatmentProcedureList"
export const AddTreProced = "storetreatmentProcedure"
export const DeleteTreProce = 'deletetreatmentProcedure'
export const AddDiagnosisType = "storediagnosistype"
export const treatmentmethodList = "treatmentmethodList"
export const DeleteDiagnosisType = "deletediagnosistype"
export const CategoryList = "category_list"
export const DeleteTreatplan = "delete_subcategory"
export const StoreTreatMethod = "storetreatmentmethod"
export const AddInventory = "store_inventory"
export const DeleteTretMeth = "deletetreatmentmethod"
export const UpdateDiagnType = "updatediagnosistype"
export const UpdateDiagnosis = "updatediagnosis"
export const Updatetreatmeplan = "updateTreatmentList"
export const UpdateTreatMethod = "updatetreatmentmethod"
export const UpdateTreatProced = "updatetreatmentProcedure"
export const InventoryCatagList = "inventory_category_list"
export const StoreInventoryCatagory = "store_inventory_category"
export const UpdateInventoryCatag = "update_inventory_category"
export const DeleteInventoryCata = "delete_inventory_category"
export const InventoryListDelete = "delete_inventory"
export const UpdateInventory = "update_inventory"
export const UpdateSalary = "update_salary"
export const DeleteSalary = "delete_salary"




export const ListPharamacyCata = "pharmacy_category_list"
export const StorePharamacyCata = "store_pharmacy_category"
export const UpdatePharamaCata = "update_pharmacy_category"
export const DeletePharaCata = "delete_pharmacy_category"
export const ListStaffCategory = "staff_category_list"
export const Addstaffcat = "store_staff_category"
export const UpdateStaffCata = "update_staff_category"
export const DeleteStaffCata = "delete_staff_category"
export const ListDoctorDepart = "doctor_departments"
// export const AddDoctorDepart = "store_doctor_department"
export const AddDoctorDepart = "doctor_department_add"
export const UpdateDoctoDep = "update_doctor_department"
export const DeleteDoctorDepar = "delete_doctor_department"
export const UpdatePharamacy = "update_pharmacy"
export const UpdateDoctor = "update_doctor"
export const UpdatePatient = "update_patient"
export const StoreprescriptioSub = "storetprescriptionsubCategory"
export const StorePrescrition = "store_patient_prescription"
export const ToothSpecificMapping = "store_treatment_plan_mapping"
export const AddMedicalReportType = "storeMedicalcategory"
export const UpdateMedicalReportType = "updateMedicalcategory"
export const ListMedicalReportType = "medicalCategoryList"
export const DeleteMedicalReportType = "deleteMedicalcategory"
export const UpdateMedicalInven = "updatetprescriptionsubCategory"
export const DeleteMedicalInven = "deletetprescriptionsubCategory"
export const UpdatePatientPrescr = "update_patient_prescription"
export const DeletePatientPrescri = "delete_patient_prescription"

export const MedicalReportStore = "savePatientMedicalReport"
export const DeleteMedicalreport = "deletePatientMedicalReport"
export const Logout = "logout"

export const appointment = "store_appointment"
export const summaryList = "treatment_plan_mapping_list"

export const GeneralMedicalRecord = "store_medical_record"
export const GeneralMedicalRecordList = "medical_record_list"

export const ForgetPassword = "forgot_password"
export const ForgetVerfiyOtp = "forgotverifyOtp"
export const ForgetChangePassword = "forgot_change_pw"
export const appointmentList = "appointment_list"

export const StorePharmacyExpenses = "store_pharmacy_account"
export const ListPharmacyExpenses = "list_pharmacy_account"
export const StoreInventoryExpenses = "store_inventory_account"
export const ListInventoryExpenses = "list_inventory_account"
export const DeletePharmacyExpenses = "pharmacy_account_delete"
export const DeleteInventoryExpenses = "inventory_account_delete"
export const StoreLabPayment = "store_lab_account"
export const StoreStationery = "store_stationery_account"
export const ListLabPayment = "list_lab_account"
export const ListStationery = "list_stationery_account"
export const DeleteLabPayment = "lab_account_delete"
export const DeleteStationery = "stationery_account_delete"
export const StoreResuduleAppoin = "reschedule_appointment"
export const AppoinmentStatusUpdate = "appointment_status_update"
export const ListPharmacyIncome = "acc_prescription_payment"
export const ListConsultantExpenses = "acc_treatment_plan_method"
export const OtpRegistration = "otp_registration"
export const VerifyOtp = "verify_otp"
// export const AccountsList = "accountslist"









// export const ListPatientClinicIncome = "acc_method_payment"
export const StoreSalary = "list_salaries"
export const staffSalary = "store_staff_salary"


export const doctorSalary = "store_doctor_salary"
export const upcomingAppointments = "upcoming_appointments"
export const listToPay = "list_payment_trans"
export const storePayment = "store_payment_trans"
export const listClinicPayment = "acc_method_payment"
export const NotificationList = "get_notificationsList"
export const NetProfitList = "accountslist"
export const changePassword = "patient_change_password"
export const changeStatus = "change_notification_status"
export const changeStatusop = "change_out_patient_enquiry_status"
export const DeleteAppoinment = "delete_treatment_plan_mapping"
export const UpdateAdmin = "admin_profile"
export const UpdatePayment = "update_payment_trans"
export const UpdateEmpoloyee = "update_employee_number"
export const StorePrescriptionPay = "store_prescription_Payments"
export const UpdatePrescriptionPay = "update_prescription_Payments"
export const UpdatePharmacyAccount = "update_pharmacy_account"
export const UpdateInventoryAccount = "update_inventory_account"
export const UpdateLabAccount = "update_lab_account"
export const UpdateStationaryAccount = "update_stationary_account"
export const UpdateTreatmentPlanMapping = "update_treatment_plan_mapping"
export const StoreConsultantPayment = "store_consultant_payment"
export const UpdateConsultantPayment = "update_consultant_payment"
export const StoreEnquiry = "store_enquiry"
export const EnquiryList = "get_enquiry_list"
export const changeEnStatus = "change_enquiry_status"
export const NextAppointment = "next_appointment_v2"
export const OpEnquiry = "get_out_patient_enquiry_list"

export const CreateEvent = "createEvent"
export const GetEvent = "getEvent"
export const UpdateEvent = "updateEvent"
export const DeleteEvent = "deleteEvent"