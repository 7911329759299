import React, { useState, useEffect } from "react";
import { useReducer } from 'react';
import * as con from '../../../repository/api_calls';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            staff_id: state.staff_id,
            doctor_id: state.doctor_id,
            salary: state.salary,
            allowance: state.allowance,
            income_tax: state.income_tax,
            otc: state.otc,
            epf: state.epf
        };
    }
    if (action.type === 'set_base64') {
        return {
            staff_id: state.staff_id,
            doctor_id: state.doctor_id,
            salary: state.salary,
            allowance: state.allowance,
            income_tax: state.income_tax,
            otc: state.otc,
            epf: state.epf
        }
    }
}

const DoctorSalary = () => {

    const submitDocSalary = (event) => {
        event.preventDefault();
        addDocSalary(state);
    }

    async function addDocSalary(salary) {
        const response = await con.DoctorSalary((JSON.stringify(salary)));

        if (response['status'] === true) {
            // let afterAdded = [...SalaryLists];
            // afterAdded.push(response['data']);
            // setSalaryList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                window.location.reload();
            }, 800);
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            })
        }
    }

    // -------------------------------  Pharmacy Expense  list API -----------

    const [SalaryLists, setSalaryList] = useState([])

    useEffect(() => {
        SalaryListing();
    }, [])

    async function SalaryListing() {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
        const response = await con.SalaryList((JSON.stringify({ month: currentMonth })));

        if (response['status'] === true) {
            setSalaryList(response['salaries']);
        }
    }

    // ---------------------------- popup form api ---------------

    const [state, dispatch] = useReducer(reducer, {
        staff_id: '',
        doctor_id: '',
        salary: '',
        allowance: '',
        income_tax: '',
        otc: '',
        epf: ''
    }
    );

    return (
        <React.Fragment>

            <section>

                <form onSubmit={submitDocSalary}>

                    <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>

                        <div className="col-md-6 mb-sm-7 mb-4">
                            <label className="form-label">
                                <span className="required">Employee Id:</span>
                            </label>
                            <input name="Employee Id" type="text" className="form-control"
                                placeholder="Employee Id"
                                value={state.doctor_id}
                                onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'doctor_id' }); }}
                                required />
                        </div>

                        <div className="col-md-6 mb-sm-7 mb-4">
                            <label className="form-label">
                                <span className="required">Basic Salary:</span>
                            </label>
                            <input name="Salary" type="text" className="form-control"
                                placeholder="Salary"
                                value={state.salary}
                                // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'salary' }); }}
                                onChange={(e) => {
                                    var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'salary' });
                                }}
                                required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                            <label className="form-label">
                                <span className="required">Allowance:</span>
                            </label>
                            <input name="Allowance" type="text" className="form-control"
                                placeholder="Allowance"
                                value={state.allowance}
                                // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'allowance' }); }}
                                onChange={(e) => {
                                    var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'allowance' });
                                }}
                                required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                            <label className="form-label">
                                <span className="required">OTC:</span>
                            </label>
                            <input name="otc" type="text" className="form-control"
                                placeholder="OTC"
                                value={state.otc}
                                // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'otc' }); }}
                                onChange={(e) => {
                                    var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'otc' });
                                }}
                                required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                            <label className="form-label">
                                <span className="required">Income tax:</span>
                            </label>
                            <input name="incometax" type="text" className="form-control"
                                placeholder="Income tax"
                                value={state.income_tax}
                                // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'income_tax' }); }}
                                onChange={(e) => {
                                    var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'income_tax' });
                                }}
                                required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                            <label className="form-label">
                                <span className="required">EPF</span>
                            </label>
                            <input name="epf" type="text" className="form-control"
                                placeholder="EPF"
                                value={state.epf}
                                // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'epf' }); }}
                                onChange={(e) => {
                                    var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'epf' });
                                }}
                                required />
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button>
                </form>

            </section>

        </React.Fragment>
    )
}

export default DoctorSalary