
import React, { useState, useEffect } from "react";
import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
import * as con from '../../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";

function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            diagnosis_type: state.diagnosis_type,
            name: state.name,
            id: state.id
        };
    }
}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};



const SettingDiagnosisList = (props) => {
    const { ...rest } = props;

    // ------------------------------- add diagnosis ------------
    const [diagnosistypeList, setDiagnosiTypeList] = useState();
    const [open, setOpen] = useState(true);

    const [state, dispatch] = useReducer(reducer, {
        diagnosis_type: (diagnosistypeList && diagnosistypeList.length > 0) ? diagnosistypeList[0].id : '', name: '', id: ''
    })


    // const submitAddDiagnosis = (event) => {
    //     event.preventDefault();
    //     addDiagnosis(state);
    // }

    const showMessage = () => {
        setDropdownOpen(false)
    }

    function submitAddDiagnosis(event) {
        event.preventDefault();
        if (state.id != '') {
            addDiagnosis(state, 'edit');
            setTimeout(showMessage, 2000);
        } else {
            addDiagnosis(state, 'add');

            setTimeout(showMessage, 2000);
        }
    }

    async function addDiagnosis(diagnosisData, type) {
        if (type == 'add') {
            const response = await con.CreateDiagnosisAPI((JSON.stringify(diagnosisData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = [...diagnosisLists];
                afterAdded.push(response['data']);
                setDiagnosisList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        } else {
            const response = await con.UpdateDiagnoAPI((JSON.stringify(diagnosisData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = diagnosisLists.map(item => {
                    if (item.id === state.id) {
                        return response.data;
                    }
                    return item;
                });
                dispatch({ type: 'set_value', value: '', key: 'id' })
                dispatch({ type: 'set_value', value: '', key: 'diagnosis_type' })
                dispatch({ type: 'set_value', value: '', key: 'name' })
                setDiagnosisList(afterAdded);
                setTimeout(showMessage, 2000);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                setTimeout(() => {
                    window.location.reload();
                }, 800);
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        }
    }


    // -----------------------------  add method for dropdown
    const [heading, setHeading] = useState('Default Heading');
    const [button, setButton] = useState('Default Heading');
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = (newHeading, newButton) => {
        setHeading(newHeading);
        setButton(newButton)
        setDropdownOpen(!isDropdownOpen);

        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'diagnosis_type' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };

    //------------------------for cancel action
    const handleCancel = () => {

        setDropdownOpen(false);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'diagnosis_type' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };


    // ---------------- for edit function -------

    function handleForEditButtonClick(newHeading, newButton, subCat) {
        setHeading(newHeading);
        setButton(newButton)
        setDropdownOpen(true);

        dispatch({ type: 'set_value', value: subCat.id, key: 'id' })
        dispatch({ type: 'set_value', value: subCat.diagnosis_type, key: 'diagnosis_type' })
        dispatch({ type: 'set_value', value: subCat.name, key: 'name' })

    };
    // -----------------------------  for edit method for dropdown

    const [isDropdownOpen2, setDropdownOpen2] = useState(false);

    const handleButtonClick2 = () => {
        setDropdownOpen2(!isDropdownOpen2);
    };


    // ------------------ drop down list ------------



    useEffect(() => {
        getDiagTyp()
    }, [])

    async function getDiagTyp() {
        const response = await con.getDiagnosisTypeList();
        console.log(response);
        if (response['status'] === true) {
            setDiagnosiTypeList(response['data']);

            // Set the default diagnosis_type value here
            if (response['data'].length > 0) {
                dispatch({ type: 'set_value', value: response['data'][0].id, key: 'diagnosis_type' });
            }
        }
    }


    // ------------------------------- Diagnosis  list API -----------

    const [diagnosisLists, setDiagnosisList] = useState([])


    useEffect(() => {
        getDiagnosisLists();
    }, [])

    async function getDiagnosisLists() {
        const response = await con.DiagnosisListAPI();
        if (response['status'] === true) {
            setDiagnosisList(response['data']);
        }
    }


    // ------------------------- Delete API --------

    async function getDiagnosisDelete(deletediagnosis) {
        const response = await con.DiagnosisDeleteAPI(deletediagnosis);
        console.log(response)
        if (response['status'] === true) {
            let afterAdded = diagnosisLists.filter(item => item.id !== deletediagnosis);
            setDiagnosisList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }


    // ------------ pagenation ------------

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentdiagnosisLists = diagnosisLists.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    //-------------------------------------- filter code    

    return (
        <React.Fragment>

            <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
                <Sidebar open={open} onClose={() => setOpen(false)} />
                <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
                    <main
                        className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
                    >
                        {/* Routes */}
                        <div className="h-full">
                            <Navbar
                                onOpenSidenav={() => setOpen(true)}
                                logoText={"Kirthika Dental Care"}
                                brandText="Diagnosis"
                                {...rest}
                            />
                            < button type="submit"
                                className="btn btn-primary my-5"
                                style={{ width: "auto", backgroundColor: "#5660d9", marginLeft: "18px", cursor: "pointer" }}
                                onClick={handleButtonClick}
                            >+ Add Diagnosis
                            </button>
                            {isDropdownOpen && (

                                <div className="card mb-5" style={{ backgroundColor: "#f5f5f5", borderRadius: "7px", boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)", margin: "10px", padding: "20px" }}>
                                    <form onSubmit={submitAddDiagnosis}>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="diagnosisType">Diagnosis Type:</label>

                                            {(diagnosistypeList != null) ?
                                                <select
                                                    className="form-select"
                                                    id="sel10"
                                                    style={{ fontSize: 14, height: 44, width: "100%" }}
                                                    required
                                                    value={state.diagnosis_type}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'diagnosis_type' }); }}
                                                >
                                                    <option value="">Select a diagnosisType......</option>
                                                    {diagnosistypeList.map((list, index) =>
                                                        <option value={list['id']}>{list['name']}</option>
                                                    )}

                                                </select>
                                                : null}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="diagnosisInput">Diagnosis Name:</label>
                                            <input type="text" id="diagnosisInput" placeholder="Enter Your diagnosis" className="form-control"
                                                value={state.name}
                                                required
                                                onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'name' }); }}
                                            // onChange={(e) => {
                                            //     var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                                            //     if (sanitizedValue.length > 150) {
                                            //         sanitizedValue = sanitizedValue.slice(0, 150);
                                            //     }
                                            //     const regex = /(.)\1{5,}/g;
                                            //     sanitizedValue = sanitizedValue.replace(regex, '');
                                            //     dispatch({ type: 'set_value', value: sanitizedValue, key: 'name' });
                                            // }}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <span> <button type="submit" className="btn btn-primary">Save</button></span>
                                            <span className="me-1"> <button type="button" className="btn btn-primary" onClick={handleCancel}>cancel</button></span>

                                        </div>
                                    </form>
                                </div>
                            )}
                            <div>
                                <table className="bordered-table">
                                    <thead className="tablehead">
                                        <tr style={{ borderRadius: "10px" }}>
                                            <th>S.No</th>
                                            <th>Diagnosis Type</th>
                                            <th>Diagnosis Name</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentdiagnosisLists.map((diagnosis, index) => {
                                            const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
                                            return (

                                                <tr key={index}>
                                                    <td>{continuousIndex}</td>
                                                    <td>{diagnosis.dname}</td>
                                                    <td>{diagnosis.name}</td>
                                                    <td>
                                                        <RiEdit2Line
                                                            className="edit_icon"
                                                            aria-hidden="true"
                                                            data-toggle="modal"
                                                            data-target="#addpatient"
                                                            onClick={() => handleForEditButtonClick('Edit Diagnosis', 'Update', diagnosis)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <RiDeleteBin2Line
                                                            className="delete_icon"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => getDiagnosisDelete(diagnosis.id)}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <Pagination
                                    itemsPerPage={itemsPerPage}
                                    totalItems={diagnosisLists.length}
                                    currentPage={currentPage}
                                    paginate={paginate}
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SettingDiagnosisList