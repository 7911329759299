import React, { useState, useEffect } from "react";
import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
import * as con from '../../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';



function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        console.log(action.value)
        return {
            // prescription_category_id: state.prescription_category_id,
            category_name: state.category_name,
            sub_name: state.sub_name,
            unit: state.unit,
            available_stock: state.available_stock,
            price: state.price,
            id: state.id
        };
    }
}

const MedicalInventory = () => {

    // ------------------------------- add Sub - Catagory ------------

    const [state, dispatch] = useReducer(reducer, {
        category_name: '', sub_name: '', unit: '', available_stock: '', price: '', id: ''
    })
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    // ------------------------------- add Sub - Catagory ------------

    const submitAddMedicalSubCatago = (event) => {
        event.preventDefault();
        if (state.id != '') {
            addSubcat(state, 'edit');
            setDropdownOpen(false)
        } else {
            addSubcat(state, 'add');
            setDropdownOpen(false)
        }
    }

    async function addSubcat(SubcaData, type) {
        if (type == 'add') {
            const response = await con.AddPrescriptSubCata((JSON.stringify(SubcaData)));
            console.log(response)

            if (response['status'] === true) {
                let afterAdded = [...medicalInventory];
                afterAdded.push(response['data']);
                setMedicalInventory(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }

        } else {
            const response = await con.UpdateMedicalInvenAPI((JSON.stringify(SubcaData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = medicalInventory.map(item => {
                    if (item.id === state.id) {
                        return response.data;
                    }
                    return item;
                });
                dispatch({ type: 'set_value', value: '', key: 'id' })
                // dispatch({ type: 'set_value', value: '', key: 'prescription_category_id' })
                dispatch({ type: 'set_value', value: '', key: 'category_name' })
                dispatch({ type: 'set_value', value: '', key: 'sub_name' })
                dispatch({ type: 'set_value', value: '', key: 'unit' })
                dispatch({ type: 'set_value', value: '', key: 'available_stock' })
                dispatch({ type: 'set_value', value: '', key: 'price' })
                setMedicalInventory(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        }
    }


    // -----------------------------  add method for dropdown


    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        // dispatch({ type: 'set_value', value: inlist.prescription_category_id, key: 'prescription_category_id' })
        dispatch({ type: 'set_value', value: '', key: 'category_name' })
        dispatch({ type: 'set_value', value: '', key: 'sub_name' })
        dispatch({ type: 'set_value', value: '', key: 'unit' })
        dispatch({ type: 'set_value', value: '', key: 'available_stock' })
        dispatch({ type: 'set_value', value: '', key: 'price' })

    };



    //------------------------for cancel action
    const handleCancel = () => {

        setDropdownOpen(false);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        // dispatch({ type: 'set_value', value: inlist.prescription_category_id, key: 'prescription_category_id' })
        dispatch({ type: 'set_value', value: '', key: 'category_name' })
        dispatch({ type: 'set_value', value: '', key: 'sub_name' })
        dispatch({ type: 'set_value', value: '', key: 'unit' })
        dispatch({ type: 'set_value', value: '', key: 'available_stock' })
        dispatch({ type: 'set_value', value: '', key: 'price' })

    };

    // ---------------- for edit function -------

    function handleForEditButtonClick(inlist) {
        setDropdownOpen(true);

        dispatch({ type: 'set_value', value: inlist.id, key: 'id' })
        // dispatch({ type: 'set_value', value: inlist.prescription_category_id, key: 'prescription_category_id' })
        dispatch({ type: 'set_value', value: inlist.category_name, key: 'category_name' })
        dispatch({ type: 'set_value', value: inlist.sub_name, key: 'sub_name' })
        dispatch({ type: 'set_value', value: inlist.unit, key: 'unit' })
        dispatch({ type: 'set_value', value: inlist.available_stock, key: 'available_stock' })
        dispatch({ type: 'set_value', value: inlist.price, key: 'price' })

    };

    // ------------------------------- Prescription Sub-Category   list API -----------

    const [medicalInventory, setMedicalInventory] = useState([])


    useEffect(() => {
        getmedicalInventoryLists();
    }, [])

    async function getmedicalInventoryLists() {
        const response = await con.MedicalInventoryListsAPI();
        if (response['status'] === true) {
            setMedicalInventory(response['data']);

        }
    }

    // ------------------------- Delete API --------

    async function getMedicalInveDelete(deleteMedicalInve) {
        const response = await con.MedicalInventorDeleteAPI(deleteMedicalInve);
        console.log(response)
        if (response['status'] === true) {
            let afterAdded = medicalInventory.filter(item => item.id !== deleteMedicalInve);
            setMedicalInventory(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }

    return (
        <React.Fragment>

            <div>
                < button type="submit"
                    className="btn btn-primary my-5"
                    style={{ width: "auto", backgroundColor: "#5660d9", marginLeft: "18px" }}
                    onClick={handleButtonClick}
                >+ Add Pharmacy Category
                </button>
                {isDropdownOpen && (

                    <div className="card mb-5" style={{
                        backgroundColor: "#f5f5f5", borderRadius: "7px",
                        boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)", margin: "10px", padding: "20px"
                    }}>
                        <form onSubmit={submitAddMedicalSubCatago}>
                            <div className="form-group col-md-6">
                                <label htmlFor="Catagory">Catagory:</label>


                                <input type="text" id="Catagory" placeholder="Enter Catagory" className="form-control"
                                    value={state.category_name}
                                    required
                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'category_name' }); }}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="CatagoryInput">Sub-Category:</label>
                                <input type="text" id="CatagoryInput" placeholder="Enter Sub-Category" className="form-control"
                                    value={state.sub_name}
                                    required
                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'sub_name' }); }}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="unit">Unit:</label>

                                <select
                                    className="form-select"
                                    id="sel10"
                                    style={{ fontSize: 14, height: 44, width: "100%" }}
                                    required
                                    value={state.unit}
                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'unit' }); }}
                                >
                                    <option >Select Unit.....</option>
                                    <option value='ML'>ML</option>
                                    <option value='MG'>MG</option>

                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="stock">Stock:</label>
                                <input type="text" id="stock" placeholder="Enter Stock" className="form-control"
                                    value={state.available_stock}
                                    required
                                    onChange={(e) => {
                                        var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                        dispatch({ type: 'set_value', value: sanitizedValue, key: 'available_stock' });
                                    }}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label htmlFor="PriceInput">Price:</label>
                                <input type="text" id="PriceInput" placeholder="Enter Price" className="form-control"
                                    value={state.price}
                                    required
                                    onChange={(e) => {
                                        var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                        dispatch({ type: 'set_value', value: sanitizedValue, key: 'price' });
                                    }}
                                />
                            </div>
                            <div className=" col-md-6">
                                <span> <button type="submit" className="btn btn-primary">Save</button></span>
                                <span className="me-1"> <button type="button" className="btn btn-primary" onClick={handleCancel}>cancel</button></span>

                            </div>
                        </form>
                    </div>

                )}

                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px" }}>
                            <th>S.No</th>
                            <th>Category</th>
                            <th>Sub-Category</th>
                            <th>unit</th>
                            <th>price</th>
                            <th>Stock Available</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {medicalInventory.map((inlist, index) => {
                            return (

                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{inlist.category_name}</td>
                                    <td>{inlist.sub_name}</td>
                                    <td>{inlist.unit}</td>
                                    <td>{inlist.price}</td>
                                    <td>{inlist.available_stock}</td>
                                    <td><RiEdit2Line className="edit_icon" onClick={() => { handleForEditButtonClick(inlist); }}
                                    /></td>
                                    <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                                        onClick={() => getMedicalInveDelete(inlist.id)} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>


        </React.Fragment>
    )
}

export default MedicalInventory