import React, { useState } from "react"
import * as con from '../../../repository/api_calls';
import { useReducer, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Doctor from "assets/img/profile/doctor.png"
import './myprofile.css'
import { FiEdit2 } from "react-icons/fi";


function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            image: state.image,
            first_name: state.first_name,
            surname: state.surname,
            email: state.email,
            dob: state.dob,
            age: state.age,
            phone: state.phone,
            gender: state.gender,
            profession: state.profession,
            primary_contact_name: state.primary_contact_name,
            relationship: state.relationship,
            contact_mobile: state.contact_mobile,
            address: state.address,
            id: state.id,
            new_password: state.new_password,
            confirm_password: state.confirm_password
        };
    }
    if (action.type === 'set_base64') {
        return {
            image: action.value,
            first_name: state.first_name,
            surname: state.surname,
            email: state.email,
            dob: state.dob,
            age: state.age,
            phone: state.phone,
            gender: state.gender,
            profession: state.profession,
            primary_contact_name: state.primary_contact_name,
            relationship: state.relationship,
            contact_mobile: state.contact_mobile,
            address: state.address,
            id: state.id,
            new_password: state.new_password,
            confirm_password: state.confirm_password
        }
    }

}


function PatientProfile(props) {
    const userRoleId = localStorage.getItem('role_id');


    const userdata = JSON.parse(localStorage.getItem("userdata"));
    console.log(userdata)

    // ------------------ staff category drop down list ------------

    useEffect(() => {
        dispatch({ type: 'set_value', value: userdata?.user?.id, key: 'id' })
        dispatch({ type: 'set_value', value: userdata?.user?.image, key: 'image' })
        dispatch({ type: 'set_value', value: userdata?.user?.first_name, key: 'first_name' })
        dispatch({ type: 'set_value', value: userdata?.user?.surname, key: 'surname' })
        dispatch({ type: 'set_value', value: userdata?.user?.email, key: 'email' })
        dispatch({ type: 'set_value', value: userdata?.user?.phone, key: 'phone' })
        dispatch({ type: 'set_value', value: userdata?.user?.dob, key: 'dob' })
        dispatch({ type: 'set_value', value: userdata?.user?.age, key: 'age' })
        dispatch({ type: 'set_value', value: userdata?.user?.gender, key: 'gender' })
        dispatch({ type: 'set_value', value: userdata?.user?.profession, key: 'profession' })
        dispatch({ type: 'set_value', value: userdata?.user?.primary_contact_name, key: 'primary_contact_name' })
        dispatch({ type: 'set_value', value: userdata?.user?.relationship, key: 'relationship' })
        dispatch({ type: 'set_value', value: userdata?.user?.contact_mobile, key: 'contact_mobile' })
        dispatch({ type: 'set_value', value: userdata?.user?.address, key: 'address' })
        dispatch({ type: 'set_value', value: userdata?.user?.new_password, key: 'new_password' })
        dispatch({ type: 'set_value', value: userdata?.user?.confirm_password, key: 'confirm_password' })


    }, [])


    const [state, dispatch] = useReducer(reducer, {
        id: '', image: '', first_name: '', surname: '', email: '', phone: '',
        dob: '', age: '', gender: '', profession: '', primary_contact_name: '', relationship: '', contact_mobile: '', address: '', new_password: '', confirm_password: ''
    })

    const submitEditPatientProfile = (event) => {
        event.preventDefault();
        UpdatePatient(state);
    }

    async function UpdatePatient(staffbodyData) {
        const response = await con.UpdatePatientAPI((JSON.stringify(staffbodyData)));
        console.log(response)
        if (response['status'] === true) {
            localStorage.setItem('userdata', JSON.stringify({ "user": response['data'] }))
            dispatch({ type: 'set_value', value: '', key: 'id' })
            dispatch({ type: 'set_value', value: '', key: 'image' })
            dispatch({ type: 'set_value', value: '', key: 'first_name' })
            dispatch({ type: 'set_value', value: '', key: 'surname' })
            dispatch({ type: 'set_value', value: '', key: 'email' })
            dispatch({ type: 'set_value', value: '', key: 'phone' })
            dispatch({ type: 'set_value', value: '', key: 'dob' })
            dispatch({ type: 'set_value', value: '', key: 'age' })
            dispatch({ type: 'set_value', value: '', key: 'gender' })
            dispatch({ type: 'set_value', value: '', key: 'profession' })
            dispatch({ type: 'set_value', value: '', key: 'primary_contact_name' })
            dispatch({ type: 'set_value', value: '', key: 'relationship' })
            dispatch({ type: 'set_value', value: '', key: 'contact_mobile' })
            dispatch({ type: 'set_value', value: '', key: 'address' })

            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                window.location.href = "/profile";
            }, 800);

        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }

    function handleChangePassword(e) {
        e.preventDefault()
        changePassword()
    }
    console.log(state.confirm_password, userdata)
    async function changePassword() {
        const responseadd = await con.changePassword({
            "patient_id": userdata.user.id,
            "id": userdata.user.id,
            "password": state.new_password,
            "confirm_password": state.confirm_password,
        });
        // console.log("return = " + responseadd)
        if (responseadd['status'] == true) {
            console.log(responseadd['status'])
            toast.success(responseadd['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            window.location.reload();
        }
        else {
            console.log(responseadd)
            toast.error(responseadd['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }


    // ------------- profile image --------

    const [image, setImage] = useState(null);
    const [isImageSelected, setIsImageSelected] = useState(false);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        let base64String = '';
        reader.onloadend = () => {
            base64String = reader.result;
            setImage(reader.result);
            setIsImageSelected(true);
            setImageValue(base64String);
            console.log(base64String)
        };
        reader.readAsDataURL(file);

    };

    function setImageValue(result) {
        const splitUps = result.split(',')
        dispatch({ type: "set_base64", value: splitUps[1] });
    }

    const handleProfileClick = () => {
        document.getElementById('profileImageInput').click();
    };

    const handleRemoveProfile = () => {
        setImage(null);
        setIsImageSelected(false);
    };
    const currentDate = new Date().toISOString().split('T')[0];




    return (


        <div className="profilediv" >
            <ToastContainer autoClose={3000} />
            <div className="row justify-content-around user-info" >
                {/* <div className="col-7 col-md-3  " >
                    <div className="mb-5 mt-3">

                        <img src={state.image === "" ? Doctor : state.image} alt="Profile" className="profileImage" style={{ width: "180px", height: "180px", borderRadius: "40%", marginLeft: "35px" }} />

                    </div>

                    <span className="lis" aria-hidden="true"
                        data-toggle="modal"
                        data-target="#profile"
                        style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginLeft: '30px', marginTop: '60px' }}>


                        <button type="button"
                            className="btn btn-primary btn-lg" >Update My Profile</button>
                    </span>
                    <span className="lis" aria-hidden="true"
                        data-toggle="modal"
                        data-target="#passwordchange"
                        style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginLeft: '30px', marginTop: '30px' }}>


                        <button className="btn btn-danger btn-lg" style={{ backgroundColor: 'red' }}>Change Password</button>
                    </span>
                </div> */}


                {/* -----------------------------------Patient  Profile----------------------------- */}

                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4') ?
                    (
                        // <div className="col-12 col-md-5" >


                        //     <div className="row" style={{ display: 'flex', fontSize: '20px', fontWeight: 'bold', marginTop: '25px' }}>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>First Name</div> <div >{userdata?.user?.first_name}</div>
                        //         </div>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}> Surname</div> <div >{userdata?.user?.surname}</div>
                        //         </div>

                        //     </div>
                        //     <div className="row" style={{ display: 'flex', fontSize: '20px', fontWeight: 'bold', marginTop: '25px' }}>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>Phone</div> <div >{userdata?.user?.phone}</div>
                        //         </div>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>D.O.B</div> <div >{userdata?.user?.dob}</div>
                        //         </div>

                        //     </div>
                        //     <div className="row" style={{ display: 'flex', fontSize: '20px', fontWeight: 'bold', marginTop: '25px' }}>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>Age</div><div >{userdata?.user?.age}</div>
                        //         </div>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>Gender</div><div>{userdata?.user?.gender}</div>
                        //         </div>

                        //     </div>
                        //     <div className="row" style={{ display: 'flex', fontSize: '20px', fontWeight: 'bold', marginTop: '25px' }}>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>Profession</div> <div >{userdata?.user?.profession}</div>
                        //         </div>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>Address</div><div >{userdata?.user?.address}</div>
                        //         </div>

                        //     </div>
                        //     <div className="row" style={{ display: 'flex', fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>Email</div><div >{userdata?.user?.email}</div>
                        //         </div>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>Relative Name</div><div>{userdata?.user?.primary_contact_name}</div>
                        //         </div>

                        //     </div>
                        //     <div className="row" style={{ display: 'flex', fontSize: '18px', fontWeight: 'bold', marginTop: '20px' }}>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>Relationship</div> <div>{userdata?.user?.relationship}</div>
                        //         </div>
                        //         <div className="col-6">
                        //             <div style={{ color: 'black' }}>Relative Contact Number</div> <div>{userdata?.user?.contact_mobile}</div>
                        //         </div>

                        //     </div>

                        // </div>
                        <div className='row ms-4' >

                            <div className="mb-5 mt-4 col-2">
                                <img src={state.image === "" ? Doctor : state.image}
                                    alt="Profile" className="profileImage  " style={{ width: "140px", height: "140px", borderRadius: "50%", marginLeft: "35px" }} />

                            </div>
                            <div className="col-7 ms-5">

                                <p className="fullname"><span>{userdata?.user?.first_name}</span><span className="surname" >{userdata?.user?.surname}</span></p>
                                <p className="job" >{userdata?.user?.staff_category_id}</p>
                                <p className="address" >{userdata?.user?.address}</p>
                            </div>
                            <div className="col-2 ms-5" >

                                <button
                                    className="edit"
                                    aria-hidden="true"
                                    data-toggle="modal"
                                    data-target="#profile"
                                >
                                    <span className="edit-span" > Edit</span>
                                    <span className="edit-icons" >< FiEdit2 /></span>

                                </button>

                            </div>
                            <div className="row">
                                <div className="col-2 " >

                                    <button
                                        className="change"
                                        aria-hidden="true"
                                        data-toggle="modal"
                                        data-target="#passwordchange"
                                    >
                                        <span className="change-span" >Change </span>
                                        <span className="change-span">Password</span>
                                        <span className="change-icons" >< FiEdit2 /></span>

                                    </button>

                                </div>
                            </div>
                            <hr className="line" />
                            <div className='row ms-5'>
                                <div className='col-12'>
                                    <p className="personal" >Personal Information</p>
                                </div>

                            </div>
                            <div className='row ms-5 info' >
                                <div className='col-3'>
                                    <div className="field" ><h3>First Name</h3></div>
                                    <div className="ans"><p>{userdata?.user?.first_name}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Surname</h3></div>
                                    <div className="ans"><p>{userdata?.user?.surname}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Phone Number</h3></div>
                                    <div className="ans"><p>{userdata?.user?.phone}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>D.O.B</h3></div>
                                    <div className="ans"><p>{userdata?.user?.dob}</p></div>
                                </div>



                            </div>

                            <div className='row ms-5 info'>

                                <div className='col-3'>
                                    <div className="field"><h3>Age</h3></div>
                                    <div className="ans"><p>{userdata?.user?.age}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Gender</h3></div>
                                    <div className="ans"><p>{userdata?.user?.gender}</p></div>
                                </div>

                                <div className='col-3'>
                                    <div className="field"><h3>Address</h3></div>
                                    <div className="ans"><p>{userdata?.user?.address}</p></div>
                                </div>
                                <div className='col-3'>
                                    <div className="field"><h3>Relative Name</h3></div>
                                    <div className="ans"><p>{userdata?.user?.primary_contact_name}</p></div>
                                </div>


                            </div>
                            <div className='row ms-5 low-row'>
                                <div className='col-3'>
                                    <div className="field"><h3>Relationship</h3></div>
                                    <div className="ans"><p>{userdata?.user?.relationship}</p></div>
                                </div>
                                {/* <div className='col-3'>
                                    <div className="field"><h3>Relative Contact Number</h3></div>
                                    <div className="ans"><p>{userdata?.user?.contact_mobile}</p></div>
                                </div> */}
                                <div className='col-3'>
                                    <div className="field"><h3>Email</h3></div>
                                    <div className="ans"><p>{userdata?.user?.email}</p></div>
                                </div>

                            </div>
                        </div >

                    ) : null}

            </div>



            {/* ---------------------------------- update Docter Profile-------------------------------------- */}

            <section>
                {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4') ?
                    (
                        <div className="container">


                            <div className="modal fade" id="profile" role="dialog">
                                <div className="po_sc_in">

                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Update My Profile</h4>
                                            <button type="button" className="close" style={{ marginRight: "-250px", color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                                ×
                                            </button>

                                        </div>
                                        <div className="modal-body">
                                            <form onSubmit={submitEditPatientProfile} >

                                                <div className="mb-5 mt-3">
                                                    <p className="text-center"><b>Profile Image</b></p>
                                                    <input
                                                        id="profileImageInput"
                                                        type="file"
                                                        onChange={handleImageUpload}
                                                        style={{ display: 'none' }}

                                                    />
                                                    <div>
                                                        <div className="profileContainer" onClick={handleProfileClick} style={{ backgroundColor: "#F4F4F4", width: "100px", height: "100px", borderRadius: "50%", cursor: "pointer", margin: "auto", display: "block" }}>
                                                            {isImageSelected ? (
                                                                <div>
                                                                    <img src={image} alt="Profile" className="profileImage" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                                                                    <button onClick={handleRemoveProfile} style={{ marginTop: "8px", marginLeft: "25px", color: "red" }}>Remove</button>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <img src={state.image === "" ? Doctor : state.image} alt="Profile" className="profileImage" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />

                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">First Name:</span>
                                                        </label>
                                                        <input name="first_name" id="first_name" type="text" className="form-control"
                                                            placeholder="First Name"
                                                            value={state.first_name}
                                                            onChange={

                                                                (e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                                    if (sanitizedValue.length > 150) {
                                                                        // Handle maximum length violation
                                                                        sanitizedValue = sanitizedValue.slice(0, 150);
                                                                    }

                                                                    // Enforce not allowing same alphabetic character consecutively three times
                                                                    const regex = /(.)\1{4,}/g;
                                                                    sanitizedValue = sanitizedValue.replace(regex, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'first_name' });
                                                                }}
                                                            required

                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Surname:</span>
                                                        </label>
                                                        <input name="last_name" id="last_name" type="text" className="form-control"
                                                            placeholder="Last Name"
                                                            value={state.surname}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                                if (sanitizedValue.length > 100) {
                                                                    // Handle maximum length violation
                                                                    sanitizedValue = sanitizedValue.slice(0, 100);
                                                                }

                                                                // Enforce not allowing same alphabetic character consecutively three times
                                                                const regex = /(.)\1{4,}/g;
                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'surname' });
                                                            }}
                                                            required />
                                                    </div>

                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Phone Number</span>
                                                        </label>
                                                        <input name="phone" id="phone" type="phone" className="form-control"
                                                            placeholder="Phone Number"
                                                            minLength="10"
                                                            maxLength="10"
                                                            value={state.phone}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'phone' });
                                                            }}
                                                            required />
                                                    </div>

                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">D.O.B</span>
                                                        </label>
                                                        <input name="dob" id="datepicker" type="date" className="form-control"
                                                            value={state.dob}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'dob' }); }}
                                                            // max={currentDate}
                                                            required />
                                                    </div>

                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Age</span>
                                                        </label>
                                                        <input name="age" id="age" type="text" className="form-control"
                                                            placeholder="Age"
                                                            value={state.age}
                                                            maxLength={3}
                                                            onChange={

                                                                (e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'age' });
                                                                }}
                                                            required />
                                                    </div>


                                                    <div className="col-md-6 mb-sm-7 mt-3">
                                                        <div className="form-group mb-5">
                                                            <label className="form-label">
                                                                <span className="required"></span>Gender
                                                            </label>
                                                            <br />
                                                            <div className="d-flex align-items-center">

                                                                <div className="form-check me-5">
                                                                    <label className="container-radio" htmlFor="male">  Male
                                                                        <input type="radio" className="form-check-input" id="male" name="gender"
                                                                            value={state.gender}
                                                                            required
                                                                            checked={state.gender === 'Male'}
                                                                            onChange={(e) => { dispatch({ type: 'set_value', value: 'Male', key: 'gender' }); }}

                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>

                                                                <div className="form-check me-10">
                                                                    <label className="container-radio" htmlFor="female">       Female
                                                                        <input type="radio" className="form-check-input" id="female" name="gender"
                                                                            value={state.gender}
                                                                            checked={state.gender === 'Female'}
                                                                            onChange={(e) => { dispatch({ type: 'set_value', value: 'Female', key: 'gender' }); }}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Profession</span>
                                                        </label>
                                                        <input name="Profession" id="Profession" type="text" className="form-control"
                                                            placeholder="Profession"
                                                            value={state.profession}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'profession' }); }}
                                                            required />
                                                    </div>

                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Address</span>
                                                        </label>
                                                        <input className="form-control" name="address"
                                                            placeholder="Address"
                                                            value={state.address}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'address' }); }}
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Email</span>
                                                        </label>
                                                        <input name="email" id="email" type="email" className="form-control"
                                                            placeholder="Email"
                                                            required
                                                            value={state.email}
                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'email' }); }}
                                                        />
                                                    </div>



                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Relative Name</span>
                                                        </label>
                                                        <input name="primary_contact_name" type="text" className="form-control"
                                                            placeholder="Relative Name" required
                                                            value={state.primary_contact_name}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                                if (sanitizedValue.length > 150) {
                                                                    // Handle maximum length violation
                                                                    sanitizedValue = sanitizedValue.slice(0, 150);
                                                                }

                                                                // Enforce not allowing same alphabetic character consecutively three times
                                                                const regex = /(.)\1{4,}/g;
                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'primary_contact_name' });
                                                            }}
                                                        // minLength={1} maxLength={15} 
                                                        />
                                                    </div>

                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Relationship</span>
                                                        </label>
                                                        <input name="relationship" type="text" className="form-control"
                                                            placeholder="Relationship" required
                                                            value={state.relationship}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z]/g, '');
                                                                if (sanitizedValue.length > 150) {
                                                                    // Handle maximum length violation
                                                                    sanitizedValue = sanitizedValue.slice(0, 150);
                                                                }

                                                                // Enforce not allowing same alphabetic character consecutively three times
                                                                const regex = /(.)\1{4,}/g;
                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'relationship' });
                                                            }}
                                                        // minLength={1} maxLength={15}
                                                        />
                                                    </div>

                                                    {/* <div className="col-md-6 mb-sm-7 mb-4">
                                                        <label className="form-label">
                                                            <span className="required">Relative Contact Number</span>
                                                        </label>
                                                        <input name="contact_mobile" type="phone" className="form-control"
                                                            placeholder="Relative Contact Number" required
                                                            minLength={10} maxLength={10}
                                                            value={state.contact_mobile}
                                                            onChange={(e) => {
                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'contact_mobile' });
                                                            }}
                                                        />
                                                    </div> */}



                                                </div>
                                                <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Update</button>
                                            </form>
                                        </div>
                                        <div className="modal-footer">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>) : null}
            </section>


            {/* -------------------------password change------------------ */}
            <section >
                <div className="container">


                    <div className="modal fade" id="passwordchange" role="dialog">
                        <div className="po_sc_in">

                            <div className="modal-content" style={{ width: '65%', marginLeft: '200px' }}>
                                <div className="modal-header">
                                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Change Password</h4>
                                    <button type="button" className="close" style={{ marginRight: "-150px", color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                        ×
                                    </button>

                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleChangePassword}>

                                        <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                            {/* <div className="col-md-12 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">Old Password</span>
                                                </label>
                                                <input name="first_name" type="text" className="form-control"
                                                    placeholder="Enter Old Password"
                                                    // value={state.first_name}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value }); }}
                                                    required
                                                />
                                            </div> */}

                                            <div className="col-md-12 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required">New Password</span>
                                                </label>
                                                <input name="first_name" type="password" className="form-control"
                                                    placeholder="Enter New Password"
                                                    value={state.new_password}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: "new_password" }); }}
                                                    required
                                                />
                                            </div>
                                            <div className="col-md-12 mb-sm-7 mb-4">
                                                <label className="form-label">
                                                    <span className="required"> Confirm New Password</span>
                                                </label>
                                                <input name="last_name" type="password" className="form-control"
                                                    placeholder="Cofirm New Password"
                                                    value={state.confirm_password}
                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'confirm_password' }); }}
                                                    required />
                                            </div>



                                        </div>
                                        <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Change </button>
                                    </form>
                                </div>
                                <div className="modal-footer">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    )
}

export default PatientProfile