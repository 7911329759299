import React, { useEffect, useState } from 'react';
import { FaCar, FaEnvelope, FaPhone } from "react-icons/fa6";
import html2canvas from 'html2canvas';
import Logo from "assets/img/logo/logo.png"
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';

const PatientInvioce = (props) => {
    const [loader, setLoader] = useState(false);

    const location = useLocation();
    const data = location.state;
    console.log("Location State:", data.plist);
    // const patient = props.pData
    const userRoleId = localStorage.getItem('role_id');
    const [service, setServices] = useState([data])
    const userdata = JSON.parse(localStorage.getItem("userdata"));

    const downloadPDF = () => {
        const capture = document.querySelector('.actual-receipt');
        setLoader(true);
        html2canvas(capture).then((canvas) => {
            const imgData = canvas.toDataURL('img/png');
            const doc = new jsPDF('p', 'mm', [300, 300]);
            const componentWidth = doc.internal.pageSize.getWidth();
            const componentHeight = doc.internal.pageSize.getHeight();
            doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
            setLoader(false);
            doc.save('receipt.pdf');
        })
    }

    console.log(data.patient)
    return (
        <section className="invoicebaground" style={{height:"100vh"}}>
            {/* receipt action */}
            <div className="receipt-actions-div">
                {/* <div className="actions-right">
                    <button
                        id="getinv"
                        className="btn btn-success"
                        onClick={downloadPDF}
                        disabled={!(loader === false)}
                    >
                        {loader ? (
                            <span>Downloading</span>
                        ) : (
                            <span>Generate Prescription</span>
                        )}

                    </button>
                </div> */}
            </div>
            <div className="wrapper">

                <div className="background-container">

                    <div className="receipt-box">

                        {/* actual receipt */}
                        <div className="actual-receipt " style={{ width: 'auto', height: '700px' }}>

                            {/* organization logo */}

                            {/* organization name */}
                            <div className="main" >

                                <div className="row">

                                    <div className="col-md-12">
                                        {/* <div className="col-md-2"></div> */}
                                        {/* 
                                        <div className="col-md-3">
                                            <img src={Logo} alt="KDC" style={{ height: "150px" }} />
                                        </div>

                                        <div className="col-md-3"></div>
                                        <div className="col-md-4">
                                            <p className="title" >கிர்த்திகா பல் மருத்துவமனை</p>
                                            <p className="title1"  >52/103 Tamil Sangam Road, Behind Anna Park</p>
                                            <p className="title1" >Shankar Nagar, Salem - 636007</p>
                                        </div> */}
                                        <div className='col-md-10'>
                                            <table className="bordered-table invoice-table" >

                                                <tbody>
                                                    <tr>
                                                        <td > <img src={Logo} alt="KDC" style={{ height: "125px" }} /></td>
                                                        <td ><span className="title">கிர்த்திகா பல் மருத்துவமனை</span> <br />52/103 Tamil Sangam Road, Behind Anna Park <br />Shankar Nagar, Salem - 636007</td>

                                                    </tr>
                                                </tbody>

                                            </table>

                                        </div>
                                        {/* <div className="col-md-2"></div> */}
                                    </div>
                                </div>



                                <div className="row" id='mtop'>
                                    {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '2' || userRoleId === '3' || userRoleId === '5') ?
                                        (
                                            <div className="col-md-12" >

                                                {/* <div className="col-md-2"></div>

                                                <div className="col-md-4">
                                                    <p className="invoice">PRESCRIPTION </p>

                                                    <p className="patient-title">Patient Name: {data.patient.first_name} </p>
                                                    <p className="address-title" >Date: {data.plist.date} </p>
                                                </div>

                                                <div className="col-md-4">
                                                    <p className=" invoice-date">Registration Number : {data.patient.registration_number}  </p>
                                                    <p className='patient-id' >Patient Id : {data.patient.id} </p>
                                                </div>
                                            </div> */}
                                                {/* <div className="col-md-2"> </div> */}
                                                <div className='col-md-10'>
                                                    <p className="invoice text-center mb-5">PRESCRIPTION </p>
                                                    <table className="bordered-table invoice-table" >

                                                        <tbody>
                                                            <tr>
                                                                <td className="patient-title" style={{ textAlign: "left" }}>Patient Name:  {data.patient.first_name}<br />Date: {data.plist.date} </td>
                                                                <td className="patient-title " style={{ textAlign: "right" }}>Registration Number : {data.patient.patient_reg_no}</td>

                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                                {/* <div className="col-md-2"> </div> */}
                                            </div>

                                        ) : null}

                                    {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4') ?
                                        (
                                            <div>
                                                <div className="col-md-12" >


                                                    {/* <div className="col-md-6">
                                                        <p className="invoice">PRESCRIPTION </p>

                                                        <p className="patient-title">Patient Name: {userdata?.user?.first_name} </p>
                                                        <p className="address-title" >Date: {data.plist.date} </p>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <p className=" invoice-date">Registration Number : {userdata?.user?.registration_number}  </p>
                                                        <p className='patient-id' >Patient Id :{userdata?.user?.id}</p>
                                                    </div> */}
                                                    {/* <div className="col-md-2"> </div> */}
                                                    <div className='col-md-10'>
                                                        <p className="invoice text-center mb-5">PRESCRIPTION </p>
                                                        <table className="bordered-table invoice-table" >

                                                            <tbody>
                                                                <tr>
                                                                    <td className="patient-title" style={{ textAlign: "left" }}>Patient Name: {userdata?.user?.first_name}<br />Date: {data.plist.date} </td>
                                                                    <td className="patient-title " style={{ textAlign: "right" }}>Registration Number : {userdata?.user?.patient_reg_no}</td>

                                                                </tr>
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                    {/* <div className="col-md-2"> </div> */}
                                                </div>
                                            </div>


                                        ) : null}
                                    {/* <div className="col-md-2"></div> */}
                                </div>


                            </div>
                            <div className="row" id="mtop">
                                <div className="col-md-12">
                                    {/* <div className="col-md-2"> </div> */}
                                    <div className="col-md-10">
                                        <table className="bordered-table invoice-table" >
                                            <thead >
                                                <tr>

                                                    <th className="tableheading">S.No</th>
                                                    <th className="tableheading">Medicine Name</th>
                                                    <th className="tableheading">Quantity</th>
                                                    <th className="tableheading">Total</th>
                                                    <th className="tableheading">Paid Amount</th>
                                                    <th className="tableheading">Due</th>
                                                    <th className="tableheading">Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* {service.map((PrescriptionList, index) => ( */}
                                                <tr>

                                                    <td >{data.plist.prescription.map((t, index) => <p key={index} className="my-3"> {index + 1} </p>)}</td>
                                                    <td >{data.plist.prescription.map((t) => <p className="my-3"> {t.sub_name}</p>)}</td>
                                                    <td >{data.plist.prescription.map((t) => <p className="my-3"> {t.quantity}</p>)}</td>
                                                    <td >{data.plist.prescription.map((t) => <p className="my-3"> {t.total_amt}</p>)}</td>
                                                    {/* <td >{data.plist.prescription.map((t) => <p className="my-3"> {t.paid_amt}</p>)}</td> */}
                                                    <td ><p className="my-3"> {data.plist.prescription[0].paid_amt}</p></td>
                                                    <td ><p className="my-3"> {data.plist.prescription[0].balance}</p></td>
                                                    <td ><p className="my-3"> {data.plist.prescription[0].status}</p></td>
                                                    {/* <td >{data.plist.prescription.map((t) => <p className="my-3"> {t.balance}</p>)}</td> */}
                                                    {/* <td >{data.plist.prescription.map((t) => <p className="my-3"> {t.status}</p>)}</td> */}




                                                    {/* <td >{data.plist.sub_name} </td> */}
                                                    {/* <td >{data.price}</td> */}
                                                    {/* <td>
                                                        <span>{data.morning ? '1' : '0'} - </span>
                                                        <span className="me-2">{data.afternoon ? '1' : '0'} - </span>
                                                        <span className="me-2">{data.evening ? '1' : '0'} - </span>
                                                        <span className="me-2">{data.night ? '1' : '0'}</span>
                                                    </td> */}
                                                    {/* <td >{data.duration}</td> */}
                                                    {/* <td >{data.total_amt}</td> */}
                                                    {/* <td><span>{data.after_food}</span>
                                                        <span>{data.before_food}</span></td> */}
                                                </tr>
                                                {/* ))} */}
                                            </tbody>

                                        </table>
                                    </div>
                                    {/* <div className="col-md-2"></div> */}
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-8" id='emptydiv' ></div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div> */}

                            <div className="row" style={{marginTop:"15rem"}}>
                                <div className="col-md-12">
                                    {/* <div className="col-md-2"></div> */}
                                    <div className="col-md-10 divider  " id="invoice-footer-sec" >
                                        <div className="col" id='invoice-footer-div'><span className="invoice-footer-span" > <FaCar className='invoice-footer-icons' />kirthikadentalcare.in</span> </div><span className="line1" ></span>
                                        <div className="col" id='invoice-footer-div'><span className="invoice-footer-span"><FaPhone className='invoice-footer-icons' />+91 8006338877</span></div><span className="line2"></span>
                                        <div className="col" id='invoice-footer-div'><span className="invoice-footer-mail" ><FaEnvelope className='invoice-footer-icons' />kirthikadentalcare@gmail.com</span></div>
                                    </div>
                                </div>
                            </div>















                        </div>
                        {/* end of actual receipt */}



                    </div>
                </div>

            </div>
        </section>

    );
}

export default PatientInvioce