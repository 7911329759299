import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import * as con from '../../repository/api_calls';
import { ToastContainer, toast } from 'react-toastify';
import { FaEllipsis } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import Sidebar from "components/sidebar";
import Navbar from "components/navbar";
import { useReducer } from 'react';
import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';
import { userdata } from "patient/exp";



function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            image: state.image,
            first_name: state.first_name,
            surname: state.surname,
            email: state.email,
            patient_reg_no: state.patient_reg_no,
            dob: state.dob,
            age: state.age,
            phone: state.phone,
            gender: state.gender,
            profession: state.profession,
            primary_contact_name: state.primary_contact_name,
            relationship: state.relationship,
            contact_mobile: state.contact_mobile,
            address: state.address,
            id: state.id,
            employee_number: state.employee_number
        };
    }
    if (action.type === 'set_base64') {
        return {
            image: action.value,
            first_name: state.first_name,
            surname: state.surname,
            email: state.email,
            patient_reg_no: state.patient_reg_no,
            dob: state.dob,
            age: state.age,
            phone: state.phone,
            gender: state.gender,
            profession: state.profession,
            primary_contact_name: state.primary_contact_name,
            relationship: state.relationship,
            contact_mobile: state.contact_mobile,
            address: state.address,
            id: state.id,
            employee_number: state.employee_number
        }
    }
}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            <ul className="pagination-list">
                {pageNumbers.map((number) => (
                    <li key={number} className="pagination-item">
                        <a
                            href="#"
                            onClick={() => paginate(number)}
                            className={`pagination-link ${currentPage === number ? 'active' : ''}`}
                        >
                            {number}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
};


const AddPatientMember = (props) => {



    // ---------------------------- popup form api ---------------

    const [state, dispatch] = useReducer(reducer, {
        id: '', image: '', first_name: '', surname: '', email: '', patient_reg_no: '', phone: '',
        dob: '', age: '', gender: '', profession: '', primary_contact_name: '', relationship: '', contact_mobile: '', address: '', employee_number: ''
    })

    const navigate = useNavigate();
    const currentDate = new Date().toISOString().split('T')[0];
    const [activeIndex, setActiveIndex] = useState(null);

    const userRoleId = localStorage.getItem('role_id');
    const patientuserdata = JSON.parse(localStorage.getItem("userdata"))
    console.log(patientuserdata);

    const location = useLocation();
    // const userData = location.state;
    const userData = {};

    const data = location.state
    console.log(data)
    console.log(location.state)
    // Assign a value based on location.state
    if (location.state) {
        Object.assign(userData, location.state);
        // Object.assign(userData, { 'gender': userData.patient_gender });
    }

    console.log(userData)
    console.log("gender = ", localStorage.getItem('gender'));

    if (userData && userData?.gender) {
        localStorage.setItem("gender", userData?.gender);
    } else {
        // Handle the case where userData or userData.gender is null or undefined
        console.error("userData or userData.gender is null or undefined");
    }



    const { ...rest } = props;
    // const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");

    // const [open, setOpen] = React.useState(true);
    // const [actTab, setActTab] = useState('Diagnosis')
    // // const [actTab, setActTab] = useState('')


    // const handleTab = (e) => {
    //   e.preventDefault()
    //   setActTab(e.target.name)
    // }

    const [patientLists, setPatientList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    // const searchedPatients = patientLists.filter((c) =>
    //   (c.first_name?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
    //   (c.phone ?? '').includes(searchTerm.toLowerCase()) ||
    //   (c.gender?.toLowerCase() === searchTerm.toLowerCase())
    // );
    const searchedPatients = patientLists.filter((c) =>
        (c.first_name?.toLowerCase() ?? '').includes(searchTerm.toLowerCase()) ||
        (c.phone ?? '').includes(searchTerm.toLowerCase()) ||
        (c.gender?.toLowerCase() === searchTerm.toLowerCase())
    ).reverse();




    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25; // Set the number of items per page

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentPatients = searchedPatients.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        patientList();
    }, [])

    async function patientList() {
        const response = await con.PatientListAPI();
        if (response['status'] === true) {
            console.log(response['data'])
            console.log(userdata)
            const filterPatient = response['data'].filter((p)=> p.user_id === userdata.user.user_id)
            localStorage.setItem("members",JSON.stringify(filterPatient))
            // const filterPatient = response['data'].filter((p)=>  p.user_id? p.user_id === userdata.id : "")
            setPatientList(filterPatient);
            // setPatientList(response['data']);
            console.log(filterPatient)
            console.log(userdata.user.id)
            console.log(response['data'])
            // console.log(response['data'].user_id?response['data'].user_id: "not available")
        }
    }

    function moveToPatientDetial(data) {
        navigate('/patient-detial', { state: data });
    }


    const [open, setOpen] = React.useState(true);
    const [actTab, setActTab] = useState(localStorage.getItem('activeTab') || 'Diagnosis');
    // const userData = {}; // Assuming you have userData defined

    useEffect(() => {
        // Save the active tab to localStorage when it changes
        localStorage.setItem('activeTab', actTab);
    }, [actTab]);

    const handleTab = (e) => {
        e.preventDefault();
        setActTab(e.target.name);
    };

    function changeTab(tabName) {
        setActTab(tabName);
    }




    const submitAddPatient = (event) => {
        event.preventDefault();

        const actionType = state.id !== '' ? 'edit' : 'add';
        addPatients(state, actionType);

        setDropdownOpen(false);
    };

    async function addPatients(Patientdata, type) {
        let response;

        try {
            Patientdata['user_type'] = "patient";
            if (type === 'add') {
                response = await con.AddPatient(JSON.stringify(Patientdata));
            } else {
                response = await con.UpdatePatientAPI(JSON.stringify(Patientdata));
            }

            console.log(response);

            if (response.status === true) {
                let afterAdded;

                if (type === 'add') {
                    afterAdded = [...patientLists, response.data];
                } else {
                    afterAdded = patientLists.map(item => (item.id === state.id ? response.data : item));
                    // Reset state values on successful update
                    resetStateValues();
                }

                setPatientList(afterAdded);

                toast.success(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success',
                });

                setTimeout(() => {
                    window.location.reload();
                }, 500);

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error',
                });
            }
        } catch (error) {
            console.error('Error in addPatients:', error);
            // Handle error appropriately, e.g., display an error toast.
        }
    }



    // ---------------- for edit function -------

    function handleForEditButtonClick(newHeading, newButton, subCat) {
        setHeading(newHeading);
        setButton(newButton)
        setDropdownOpen(true);

        dispatch({ type: 'set_value', value: subCat.id, key: 'id' })
        dispatch({ type: 'set_value', value: subCat.image, key: 'image' })
        dispatch({ type: 'set_value', value: subCat.first_name, key: 'first_name' })
        dispatch({ type: 'set_value', value: subCat.surname, key: 'surname' })
        dispatch({ type: 'set_value', value: subCat.email, key: 'email' })
        dispatch({ type: 'set_value', value: subCat.patient_reg_no, key: 'patient_reg_no' })
        dispatch({ type: 'set_value', value: subCat.phone, key: 'phone' })
        dispatch({ type: 'set_value', value: subCat.dob, key: 'dob' })
        dispatch({ type: 'set_value', value: subCat.age, key: 'age' })
        dispatch({ type: 'set_value', value: subCat.gender, key: 'gender' })
        dispatch({ type: 'set_value', value: subCat.profession, key: 'profession' })
        dispatch({ type: 'set_value', value: subCat.primary_contact_name, key: 'primary_contact_name' })
        dispatch({ type: 'set_value', value: subCat.relationship, key: 'relationship' })
        dispatch({ type: 'set_value', value: subCat.contact_mobile, key: 'contact_mobile' })
        dispatch({ type: 'set_value', value: subCat.address, key: 'address' })

    };



    // ---------------- action dropdown ----
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };



    // ----------- Change Table Heading and Button Dynamically -------------------
    const [heading, setHeading] = useState('Default Heading');
    const [button, setButton] = useState('Default Heading');
    const [addPharm, setaddPharm] = useState(false)
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = (newHeading, newButton) => {
        setHeading(newHeading);
        setButton(newButton)
        setDropdownOpen(!isDropdownOpen);
        setaddPharm(!addPharm);

        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'image' })
        dispatch({ type: 'set_value', value: '', key: 'first_name' })
        dispatch({ type: 'set_value', value: '', key: 'surname' })
        dispatch({ type: 'set_value', value: '', key: 'email' })
        dispatch({ type: 'set_value', value: '', key: 'patient_reg_no' })
        dispatch({ type: 'set_value', value: '', key: 'phone' })
        dispatch({ type: 'set_value', value: '', key: 'dob' })
        dispatch({ type: 'set_value', value: '', key: 'age' })
        dispatch({ type: 'set_value', value: '', key: 'gender' })
        dispatch({ type: 'set_value', value: '', key: ' profession' })
        dispatch({ type: 'set_value', value: '', key: 'primary_contact_name' })
        dispatch({ type: 'set_value', value: '', key: 'relationship' })
        dispatch({ type: 'set_value', value: '', key: 'contact_mobile' })
        dispatch({ type: 'set_value', value: '', key: 'address' })

    };

    // ------------- profile image --------

    const [image, setImage] = useState(null);
    const [isImageSelected, setIsImageSelected] = useState(false);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        let base64String = '';
        reader.onloadend = () => {
            base64String = reader.result;
            setImage(reader.result);
            setIsImageSelected(true);
            setImageValue(base64String);
            console.log(base64String)
        };
        reader.readAsDataURL(file);
    };

    function setImageValue(result) {
        const splitUps = result.split(',')
        dispatch({ type: "set_base64", value: splitUps[1] });
    }


    // ------------------------- Delete API --------

    async function getpatientDelete(deletepatientData) {
        try {
            const response = await con.PatientDeleteAPI(deletepatientData);
            console.log(response);

            if (response.status === true) {
                const afterDeleted = patientLists.filter(item => item.id !== deletepatientData);
                setPatientList(afterDeleted);

                toast.success(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });
            }
        } catch (error) {
            console.error('Error in getpatientDelete:', error);
            // Handle error appropriately, e.g., display an error toast.
        }
    }



    return (
        <React.Fragment>
            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1' || userRoleId === '2' || userRoleId === '3') ?
                (
                    <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
                        <Sidebar open={open} onClose={() => setOpen(false)} />
                        <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">

                            <main
                                className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
                            >
                                {/* Routes */}
                                <div className="h-full">
                                    <Navbar
                                        onOpenSidenav={() => setOpen(true)}
                                        logoText={"Kirthika Dental Care"}
                                        brandText="Family Detail"
                                        // secondary={getActiveNavbar(routes)}
                                        {...rest}
                                    />



                                    <button type="button" className="btn btn-primary my-5" aria-hidden="true"
                                        data-toggle="modal"
                                        data-target="#addpatient"
                                        onClick={() => handleButtonClick('Add A New Patient', 'Submit')}
                                        style={{ cursor: "pointer", float: "right", marginRight: "40px", backgroundColor: "#145BD9" }}
                                    ><span className="me-1" style={{ fontSize: "20px" }}>+</span>ADD MEMBER</button>


                                    <div className="tabtable" style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

                                        <input className="form-control searchBar mb-5" type="text"
                                            placeholder="Global Search"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)} />


                                        <div className="container-fluid">
                                            <div className="list-view">
                                                <table className="bordered-table">
                                                    <thead className="tablehead" >
                                                        <tr style={{ borderRadius: "10px" }}>
                                                            <th>S.No</th>
                                                            <th>Patient Name</th>
                                                            <th>Register No</th>
                                                            <th>Mobile No</th>
                                                            <th>Gender</th>
                                                            {/* <th>Status</th> */}
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentPatients.map((patientlist, index) => {
                                                            // Calculate the continuous index based on the current page
                                                            const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;

                                                            return (

                                                                <tr key={index} >
                                                                    <td>{continuousIndex}</td>
                                                                    {/* <td>{index + 1}</td> */}
                                                                    <td onClick={() => { moveToPatientDetial(patientlist); }} style={{ cursor: "pointer", color: "#145BD9" }}>{patientlist.first_name}</td>
                                                                    {/* <td style={{ display: "flex", justifyContent: "center" }}>{patientlist.employee_number} <span className="lis" aria-hidden="true"
                        data-toggle="modal"
                        data-target="#editemployeeid"
                        onClick={() => handleForEditButtonClick1(patientlist)}>
                        <RiEdit2Line className="edit_icon ms-3" />
                      </span></td> */}
                                                                    <td>{patientlist.patient_reg_no}</td>
                                                                    <td>{patientlist.phone}</td>
                                                                    <td>{patientlist.gender}</td>
                                                                    {/* <td>

                        <label className="switch_to">
                          <input className="inpu_too" type="checkbox"
                            defaultChecked={patientlist.status === 1 ? true : false}
                            onChange={() => { changeStatus(patientlist) }} />
                          <span className="slider_too round" />
                        </label>
                      </td> */}
                                                                    <td>
                                                                        <div className="dropdown">
                                                                            <div style={{ display: "flex" }}>
                                                                                <FaEllipsis onClick={toggleDropdown} className="ellipsisicon" />
                                                                            </div>
                                                                            {isOpen && (
                                                                                <div className="dropdown-content" >
                                                                                    <div className="lis" aria-hidden="true"
                                                                                        data-toggle="modal"
                                                                                        data-target="#addpatient"
                                                                                        onClick={() => handleForEditButtonClick('Edit Patient', 'Update', patientlist)}
                                                                                        style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px' }}><RiEdit2Line className="edit_icon me-2" /><span>Edit</span></div>

                                                                                    <div className="lis" style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '20px' }} onClick={() => getpatientDelete(patientlist.id)}><RiDeleteBin2Line className="edit_icon me-2" style={{ color: "BB3504" }} /><span>Delete</span></div>

                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        <hr />
                                                    </tbody>
                                                </table>
                                                <Pagination
                                                    itemsPerPage={itemsPerPage}
                                                    totalItems={searchedPatients.length}
                                                    currentPage={currentPage}
                                                    paginate={paginate}
                                                />


                                            </div>
                                        </div>
                                    </div>

                                    {/* -------------------------ADD PATIENT SECTION ---------------------- */}
                                    <section>
                                        <div className="container">


                                            <div className="modal fade" id="addpatient" role="dialog">
                                                <div className="po_sc_in">

                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>{heading}</h4>
                                                            <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                                                ×
                                                            </button>

                                                        </div>
                                                        <div className="modal-body">
                                                            <form onSubmit={submitAddPatient}>

                                                                <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">First Name:</span>
                                                                        </label>
                                                                        {/* <input name="first_name" id="first_name" type="text" className="form-control"
                          placeholder="First Name"
                          value={state.first_name}
                          onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'first_name' }); }}
                          required
                        /> */}
                                                                        <input name="first_name" id="first_name" type="text" className="form-control"
                                                                            placeholder="First Name"
                                                                            value={state.first_name}
                                                                            required
                                                                            minLength={1} maxLength={100}
                                                                            onChange={(e) => {
                                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z\s.-]/g, '');
                                                                                if (sanitizedValue.length > 100) {
                                                                                    // Handle maximum length violation
                                                                                    sanitizedValue = sanitizedValue.slice(0, 100);
                                                                                }
                                                                                // Enforce not allowing same alphabetic character consecutively three times
                                                                                const regex = /(.)\1{5,}/g;
                                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'first_name' });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">Surname:</span>
                                                                        </label>
                                                                        <input name="last_name" id="last_name" type="text" className="form-control"
                                                                            placeholder="Last Name"
                                                                            value={state.surname}
                                                                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'surname' }); }}
                                                                            onChange={(e) => {
                                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                                                                                if (sanitizedValue.length > 100) {
                                                                                    // Handle maximum length violation
                                                                                    sanitizedValue = sanitizedValue.slice(0, 100);
                                                                                }
                                                                                // Enforce not allowing same alphabetic character consecutively three times
                                                                                const regex = /(.)\1{5,}/g;
                                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'surname' });
                                                                            }}
                                                                            required />
                                                                    </div>

                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">Phone Number</span>
                                                                        </label>
                                                                        <input name="phone" id="phone" type="phone" className="form-control"
                                                                            placeholder="Phone Number"
                                                                            minLength="10"
                                                                            maxLength="10"
                                                                            value={state.phone}
                                                                            onChange={(e) => {
                                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'phone' });
                                                                            }}
                                                                            required />
                                                                    </div>

                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">D.O.B</span>
                                                                        </label>
                                                                        <input name="dob" id="datepicker" type="date" className="form-control"
                                                                            value={state.dob}
                                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'dob' }); }}
                                                                            max={currentDate}
                                                                            required />
                                                                    </div>

                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">Age</span>
                                                                        </label>
                                                                        <input name="age" id="age" type="text" className="form-control"
                                                                            placeholder="Age"
                                                                            maxLength="3"
                                                                            value={state.age}
                                                                            onChange={(e) => {
                                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'age' });
                                                                            }}
                                                                            required />
                                                                    </div>


                                                                    <div className="col-md-6 mb-sm-7 mt-3">
                                                                        <div className="form-group mb-5">
                                                                            <label className="form-label">
                                                                                <span className="required"></span>Gender
                                                                            </label>
                                                                            <br />
                                                                            <div className="d-flex align-items-center gendergrid">

                                                                                <div className="form-check me-5">
                                                                                    <label className="container-radio" htmlFor="male">  Male
                                                                                        <input type="radio" className="form-check-input" id="male" name="gender"
                                                                                            value={state.gender}
                                                                                            required
                                                                                            checked={state.gender === 'Male'}
                                                                                            onChange={(e) => { dispatch({ type: 'set_value', value: 'Male', key: 'gender' }); }}

                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                </div>

                                                                                <div className="form-check me-10 mart">
                                                                                    <label className="container-radio" htmlFor="female">       Female
                                                                                        <input type="radio" className="form-check-input" id="female" name="gender"
                                                                                            value={state.gender}
                                                                                            checked={state.gender === 'Female'}
                                                                                            onChange={(e) => { dispatch({ type: 'set_value', value: 'Female', key: 'gender' }); }}
                                                                                        />
                                                                                        <span className="checkmark"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '3') ?
                                                                        (
                                                                            <div className="col-md-6 mb-sm-7 mb-4">
                                                                                <label className="form-label">
                                                                                    <span className="required">Register No (Numbers must be unique.)</span>
                                                                                </label>
                                                                                <input name="patient_reg_no" id="patient_reg_no" type="patient_reg_no" className="form-control"
                                                                                    placeholder="Register No"
                                                                                    value={state.patient_reg_no}
                                                                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'patient_reg_no' }); }}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        )
                                                                        : null}

                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">Profession</span>
                                                                        </label>
                                                                        <input name="Profession" id="Profession" type="text" className="form-control"
                                                                            placeholder="Profession"
                                                                            value={state.profession}
                                                                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'profession' }); }}
                                                                            onChange={(e) => {
                                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                                                                                if (sanitizedValue.length > 200) {
                                                                                    // Handle maximum length violation
                                                                                    sanitizedValue = sanitizedValue.slice(0, 200);
                                                                                }
                                                                                // Enforce not allowing same alphabetic character consecutively three times
                                                                                const regex = /(.)\1{5,}/g;
                                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'profession' });
                                                                            }}

                                                                            minLength={3} maxLength={200} />
                                                                    </div>

                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">Address</span>
                                                                        </label>
                                                                        <input className="form-control" name="address"
                                                                            placeholder="Address"
                                                                            value={state.address}
                                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'address' }); }}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">Email</span>
                                                                        </label>
                                                                        <input name="email" id="email" type="email" className="form-control"
                                                                            placeholder="Email"

                                                                            // pattern="[a-zA-Z0-9.]{5,}@[a-z]+\.[a-z]{2,3}"
                                                                            value={state.email}
                                                                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'email' }); }}
                                                                        />
                                                                    </div>


                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">Relation Contact Name</span>
                                                                        </label>
                                                                        <input name="primary_contact_name" type="text" className="form-control"
                                                                            placeholder="Primary contact name"
                                                                            // required
                                                                            minLength={1} maxLength={100}
                                                                            value={state.primary_contact_name}
                                                                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'primary_contact_name' }); }}
                                                                            onChange={(e) => {
                                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z\s.]/g, '');
                                                                                if (sanitizedValue.length > 100) {
                                                                                    // Handle maximum length violation
                                                                                    sanitizedValue = sanitizedValue.slice(0, 100);
                                                                                }
                                                                                // Enforce not allowing same alphabetic character consecutively three times
                                                                                const regex = /(.)\1{5,}/g;
                                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'primary_contact_name' });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">Relationship</span>
                                                                        </label>
                                                                        <input name="relationship" type="text" className="form-control"
                                                                            placeholder="Relationship"
                                                                            //  required
                                                                            minLength={1} maxLength={100}
                                                                            value={state.relationship}
                                                                            // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'relationship' }); }}
                                                                            onChange={(e) => {
                                                                                var sanitizedValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                                                                if (sanitizedValue.length > 100) {
                                                                                    // Handle maximum length violation
                                                                                    sanitizedValue = sanitizedValue.slice(0, 100);
                                                                                }
                                                                                // Enforce not allowing same alphabetic character consecutively three times
                                                                                const regex = /(.)\1{5,}/g;
                                                                                sanitizedValue = sanitizedValue.replace(regex, '');
                                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'relationship' });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-6 mb-sm-7 mb-4">
                                                                        <label className="form-label">
                                                                            <span className="required">Contact Mobile</span>
                                                                        </label>
                                                                        <input name="contact_mobile" type="phone" className="form-control"
                                                                            placeholder="Contact mobile"
                                                                            minLength={10} maxLength={10}
                                                                            value={state.contact_mobile}
                                                                            onChange={(e) => {
                                                                                var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                                dispatch({ type: 'set_value', value: sanitizedValue, key: 'contact_mobile' });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>{button}</button>
                                                            </form>
                                                        </div>
                                                        <div className="modal-footer">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>









                                </div>





                            </main>
                        </div>
                    </div>
                ) : null}


            {/* ---------------------------------------------------------------------------------------------------------- */}


            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4') ?
                (

                    <div>






                        <button type="button" className="btn btn-primary my-5" aria-hidden="true"
                            data-toggle="modal"
                            data-target="#addpatient"
                            onClick={() => handleButtonClick('Add A New Patient', 'Submit')}
                            style={{ cursor: "pointer", float: "right", marginRight: "40px", backgroundColor: "#145BD9" }}
                        ><span className="me-1" style={{ fontSize: "20px" }}>+</span>ADD PATIENT</button>


                        <div className="tabtable" style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

                            <input className="form-control searchBar mb-5" type="text"
                                placeholder="Global Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)} />


                            <div className="container-fluid">
                                <div className="list-view">
                                    <table className="bordered-table">
                                        <thead className="tablehead" >
                                            <tr style={{ borderRadius: "10px" }}>
                                                <th>S.No</th>
                                                <th>Patient Name</th>
                                                <th>Register No</th>
                                                <th>Mobile No</th>
                                                <th>Gender</th>
                                                {/* <th>Status</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentPatients.map((patientlist, index) => {
                                                // Calculate the continuous index based on the current page
                                                const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;

                                                return (

                                                    <tr key={index} >
                                                        <td>{continuousIndex}</td>
                                                        {/* <td>{index + 1}</td> */}
                                                        <td>{patientlist.first_name}</td>
                                                        {/* <td onClick={() => { moveToPatientDetial(patientlist); }} style={{ cursor: "pointer", color: "#145BD9" }}>{patientlist.first_name}</td> */}
                                                        {/* <td style={{ display: "flex", justifyContent: "center" }}>{patientlist.employee_number} <span className="lis" aria-hidden="true"
                        data-toggle="modal"
                        data-target="#editemployeeid"
                        onClick={() => handleForEditButtonClick1(patientlist)}>
                        <RiEdit2Line className="edit_icon ms-3" />
                      </span></td> */}
                                                        <td>{patientlist.patient_reg_no}</td>
                                                        <td>{patientlist.phone}</td>
                                                        <td>{patientlist.gender}</td>
                                                        {/* <td>

                        <label className="switch_to">
                          <input className="inpu_too" type="checkbox"
                            defaultChecked={patientlist.status === 1 ? true : false}
                            onChange={() => { changeStatus(patientlist) }} />
                          <span className="slider_too round" />
                        </label>
                      </td> */}
                                                        <td>
                                                            <div className="dropdown">
                                                                <div style={{ display: "flex" }}>
                                                                    <FaEllipsis onClick={toggleDropdown} className="ellipsisicon" />
                                                                </div>
                                                                {isOpen && (
                                                                    <div className="dropdown-content" >
                                                                        <div className="lis" aria-hidden="true"
                                                                            data-toggle="modal"
                                                                            data-target="#addpatient"
                                                                            onClick={() => handleForEditButtonClick('Edit Patient', 'Update', patientlist)}
                                                                            style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px' }}><RiEdit2Line className="edit_icon me-2" /><span>Edit</span></div>

                                                                        {/* <div className="lis" style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '20px' }} onClick={() => getpatientDelete(patientlist.id)}><RiDeleteBin2Line className="edit_icon me-2" style={{ color: "BB3504" }} /><span>Delete</span></div> */}

                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            <hr />
                                        </tbody>
                                    </table>
                                    <Pagination
                                        itemsPerPage={itemsPerPage}
                                        totalItems={searchedPatients.length}
                                        currentPage={currentPage}
                                        paginate={paginate}
                                    />


                                </div>
                            </div>
                        </div>

                        {/* -------------------------ADD PATIENT SECTION ---------------------- */}
                        <section>
                            <div className="container">


                                <div className="modal fade" id="addpatient" role="dialog">
                                    <div className="po_sc_in">

                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>{heading}</h4>
                                                <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                                                    ×
                                                </button>

                                            </div>
                                            <div className="modal-body">
                                                <form onSubmit={submitAddPatient}>

                                                    <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">First Name:</span>
                                                            </label>
                                                            {/* <input name="first_name" id="first_name" type="text" className="form-control"
                          placeholder="First Name"
                          value={state.first_name}
                          onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'first_name' }); }}
                          required
                        /> */}
                                                            <input name="first_name" id="first_name" type="text" className="form-control"
                                                                placeholder="First Name"
                                                                value={state.first_name}
                                                                required
                                                                minLength={1} maxLength={100}
                                                                onChange={(e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^A-Za-z\s.-]/g, '');
                                                                    if (sanitizedValue.length > 100) {
                                                                        // Handle maximum length violation
                                                                        sanitizedValue = sanitizedValue.slice(0, 100);
                                                                    }
                                                                    // Enforce not allowing same alphabetic character consecutively three times
                                                                    const regex = /(.)\1{5,}/g;
                                                                    sanitizedValue = sanitizedValue.replace(regex, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'first_name' });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">Surname:</span>
                                                            </label>
                                                            <input name="last_name" id="last_name" type="text" className="form-control"
                                                                placeholder="Last Name"
                                                                value={state.surname}
                                                                // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'surname' }); }}
                                                                onChange={(e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                                                                    if (sanitizedValue.length > 100) {
                                                                        // Handle maximum length violation
                                                                        sanitizedValue = sanitizedValue.slice(0, 100);
                                                                    }
                                                                    // Enforce not allowing same alphabetic character consecutively three times
                                                                    const regex = /(.)\1{5,}/g;
                                                                    sanitizedValue = sanitizedValue.replace(regex, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'surname' });
                                                                }}
                                                                required />
                                                        </div>

                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">Phone Number</span>
                                                            </label>
                                                            <input name="phone" id="phone" type="phone" className="form-control"
                                                                placeholder="Phone Number"
                                                                minLength="10"
                                                                maxLength="10"
                                                                value={state.phone}
                                                                onChange={(e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'phone' });
                                                                }}
                                                                required />
                                                        </div>

                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">D.O.B</span>
                                                            </label>
                                                            <input name="dob" id="datepicker" type="date" className="form-control"
                                                                value={state.dob}
                                                                onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'dob' }); }}
                                                                max={currentDate}
                                                                required />
                                                        </div>

                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">Age</span>
                                                            </label>
                                                            <input name="age" id="age" type="text" className="form-control"
                                                                placeholder="Age"
                                                                maxLength="3"
                                                                value={state.age}
                                                                onChange={(e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'age' });
                                                                }}
                                                                required />
                                                        </div>


                                                        <div className="col-md-6 mb-sm-7 mt-3">
                                                            <div className="form-group mb-5">
                                                                <label className="form-label">
                                                                    <span className="required"></span>Gender
                                                                </label>
                                                                <br />
                                                                <div className="d-flex align-items-center gendergrid">

                                                                    <div className="form-check me-5">
                                                                        <label className="container-radio" htmlFor="male">  Male
                                                                            <input type="radio" className="form-check-input" id="male" name="gender"
                                                                                value={state.gender}
                                                                                required
                                                                                checked={state.gender === 'Male'}
                                                                                onChange={(e) => { dispatch({ type: 'set_value', value: 'Male', key: 'gender' }); }}

                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check me-10 mart">
                                                                        <label className="container-radio" htmlFor="female">       Female
                                                                            <input type="radio" className="form-check-input" id="female" name="gender"
                                                                                value={state.gender}
                                                                                checked={state.gender === 'Female'}
                                                                                onChange={(e) => { dispatch({ type: 'set_value', value: 'Female', key: 'gender' }); }}
                                                                            />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '3') ?
                                                            (
                                                                <div className="col-md-6 mb-sm-7 mb-4">
                                                                    <label className="form-label">
                                                                        <span className="required">Register No (Numbers must be unique.)</span>
                                                                    </label>
                                                                    <input name="patient_reg_no" id="patient_reg_no" type="patient_reg_no" className="form-control"
                                                                        placeholder="Register No"
                                                                        value={state.patient_reg_no}
                                                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'patient_reg_no' }); }}
                                                                        required
                                                                    />
                                                                </div>
                                                            )
                                                            : null}

                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">Profession</span>
                                                            </label>
                                                            <input name="Profession" id="Profession" type="text" className="form-control"
                                                                placeholder="Profession"
                                                                value={state.profession}
                                                                // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'profession' }); }}
                                                                onChange={(e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                                                                    if (sanitizedValue.length > 200) {
                                                                        // Handle maximum length violation
                                                                        sanitizedValue = sanitizedValue.slice(0, 200);
                                                                    }
                                                                    // Enforce not allowing same alphabetic character consecutively three times
                                                                    const regex = /(.)\1{5,}/g;
                                                                    sanitizedValue = sanitizedValue.replace(regex, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'profession' });
                                                                }}

                                                                minLength={3} maxLength={200} />
                                                        </div>

                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">Address</span>
                                                            </label>
                                                            <input className="form-control" name="address"
                                                                placeholder="Address"
                                                                value={state.address}
                                                                onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'address' }); }}
                                                            />
                                                        </div>

                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">Email</span>
                                                            </label>
                                                            <input name="email" id="email" type="email" className="form-control"
                                                                placeholder="Email"

                                                                // pattern="[a-zA-Z0-9.]{5,}@[a-z]+\.[a-z]{2,3}"
                                                                value={state.email}
                                                                onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'email' }); }}
                                                            />
                                                        </div>


                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">Relation Contact Name</span>
                                                            </label>
                                                            <input name="primary_contact_name" type="text" className="form-control"
                                                                placeholder="Primary contact name"
                                                                // required
                                                                minLength={1} maxLength={100}
                                                                value={state.primary_contact_name}
                                                                // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'primary_contact_name' }); }}
                                                                onChange={(e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^A-Za-z\s.]/g, '');
                                                                    if (sanitizedValue.length > 100) {
                                                                        // Handle maximum length violation
                                                                        sanitizedValue = sanitizedValue.slice(0, 100);
                                                                    }
                                                                    // Enforce not allowing same alphabetic character consecutively three times
                                                                    const regex = /(.)\1{5,}/g;
                                                                    sanitizedValue = sanitizedValue.replace(regex, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'primary_contact_name' });
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">Relationship</span>
                                                            </label>
                                                            <input name="relationship" type="text" className="form-control"
                                                                placeholder="Relationship"
                                                                //  required
                                                                minLength={1} maxLength={100}
                                                                value={state.relationship}
                                                                // onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'relationship' }); }}
                                                                onChange={(e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                                                    if (sanitizedValue.length > 100) {
                                                                        // Handle maximum length violation
                                                                        sanitizedValue = sanitizedValue.slice(0, 100);
                                                                    }
                                                                    // Enforce not allowing same alphabetic character consecutively three times
                                                                    const regex = /(.)\1{5,}/g;
                                                                    sanitizedValue = sanitizedValue.replace(regex, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'relationship' });
                                                                }}
                                                            />
                                                        </div>

                                                        <div className="col-md-6 mb-sm-7 mb-4">
                                                            <label className="form-label">
                                                                <span className="required">Contact Mobile</span>
                                                            </label>
                                                            <input name="contact_mobile" type="phone" className="form-control"
                                                                placeholder="Contact mobile"
                                                                minLength={10} maxLength={10}
                                                                value={state.contact_mobile}
                                                                onChange={(e) => {
                                                                    var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                                                                    dispatch({ type: 'set_value', value: sanitizedValue, key: 'contact_mobile' });
                                                                }}
                                                            />
                                                        </div>

                                                    </div>
                                                    <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>{button}</button>
                                                </form>
                                            </div>
                                            <div className="modal-footer">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>









                    </div>





                ) : null}


        </React.Fragment>

    );
};


export default AddPatientMember;