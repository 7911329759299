import React, { useState, useEffect } from "react";
import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
// import './setting.css';
import * as con from '../../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            name: state.name,
            id: state.id
        };
    }
}


const InventoryCatagory = () => {

    // ------------------------------- Add inventory catagory ------------

    const [state, dispatch] = useReducer(reducer, {
        name: '', id: ''
    })

    const submitAddInvenCata = (event) => {
        event.preventDefault();
        if (state.id != '') {
            addinventoycat(state, 'edit');
            setDropdownOpen(false)
        } else {
            addinventoycat(state, 'add');
            setDropdownOpen(false)
        }
    }

    async function addinventoycat(InvrtCatagData, type) {
        if (type == 'add') {
            const response = await con.CreateInvent_cataAPI((JSON.stringify(InvrtCatagData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = [...invencatagoList];
                afterAdded.push(response['data']);
                setInventoryCatagoList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
                // window.location.reload()
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        } else {
            const response = await con.UpdateInvenCataAPI((JSON.stringify(InvrtCatagData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = invencatagoList.map(item => {
                    if (item.id === state.id) {
                        return response.data;
                    }
                    return item;
                });
                dispatch({ type: 'set_value', value: '', key: 'id' })
                dispatch({ type: 'set_value', value: '', key: 'name' })
                setInventoryCatagoList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        }
    }


    // -----------------------------  add method for dropdown

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };

    const handleCancel = () => {

        setDropdownOpen(false);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };
    // ---------------- for edit function -------

    function handleForEditButtonClick(invencta) {
        setDropdownOpen(true);

        dispatch({ type: 'set_value', value: invencta.id, key: 'id' })
        dispatch({ type: 'set_value', value: invencta.name, key: 'name' })

    };


    // ------------------------------- Inventory Catagory  list API -----------

    const [invencatagoList, setInventoryCatagoList] = useState([])


    useEffect(() => {
        getInvCatTyp();
    }, [])

    async function getInvCatTyp() {
        const response = await con.InvenCatagAPI();
        if (response['status'] === true) {
            setInventoryCatagoList(response['data']);
        }
    }


    // ------------------------- Delete API --------

    async function getInvCatDelete(deleteInCatag) {
        const response = await con.InventCataDeleteAPI(deleteInCatag);
        console.log(response)
        if (response['status'] === true) {
            let afterAdded = invencatagoList.filter(item => item.id !== deleteInCatag);
            setInventoryCatagoList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }

    return (
        <React.Fragment>

            <div>
                < button type="submit"
                    className="btn btn-primary my-5"
                    style={{ width: "auto", backgroundColor: "#5660d9", marginLeft: "18px" }}
                    onClick={handleButtonClick}
                >+ Add Inventory Category
                </button>
                {isDropdownOpen && (

                    <div className="card mb-5" style={{ backgroundColor: "#f5f5f5", borderRadius: "7px", boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)", margin: "10px", padding: "20px" }}>
                        <form onSubmit={submitAddInvenCata}>

                            <div className="form-group">
                                <label htmlFor="diagnosisInput">Inventory Catagory:</label>
                                <input type="text" id="diagnosisInput" placeholder="Enter Your Inventory Catagory" className="form-control"
                                    value={state.name}
                                    required
                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'name' }); }}
                                // onChange={(e) => {
                                //     var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                                //     if (sanitizedValue.length > 150) {
                                //         sanitizedValue = sanitizedValue.slice(0, 150);
                                //     }
                                //     const regex = /(.)\1{5,}/g;
                                //     sanitizedValue = sanitizedValue.replace(regex, '');
                                //     dispatch({ type: 'set_value', value: sanitizedValue, key: 'name' });
                                // }}
                                />
                            </div>
                            <span> <button type="submit" className="btn btn-primary">Save</button></span>
                            <span className="me-1"> <button type="button" className="btn btn-primary" onClick={handleCancel}>cancel</button></span>

                        </form>
                    </div>

                )}

                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px" }}>
                            <th>S.No</th>
                            <th>Inventory Catagory</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invencatagoList.map((invencta, index) => {
                            return (

                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{invencta.name}</td>
                                    <td><RiEdit2Line className="edit_icon" onClick={() => { handleForEditButtonClick(invencta); }} /></td>
                                    <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                                        onClick={() => getInvCatDelete(invencta.id)} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>


        </React.Fragment>
    )
}

export default InventoryCatagory