import React, { useEffect, useState } from 'react';
import * as con from '../../repository/api_calls';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { useReducer } from 'react';


function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            date: state.date,
            enquiry: state.enquiry,
            patient_id: state.patient_id
        };
    }
}


const PatientEnquiry = () => {

    const userRoleId = localStorage.getItem('role_id');


    const userdata = JSON.parse(localStorage.getItem("userdata"));
    console.log(userdata)

    // ------------------------------- add Sub - Catagory ------------

    const [state, dispatch] = useReducer(reducer, {
        date: '', enquiry: '', patient_id: userdata.user.id,
    })



    // ---------------------------- popup form api ---------------


    const submitenqir = (event) => {
        event.preventDefault();
        StoreEnquir(state);
    }

    async function StoreEnquir(enquir) {
        const response = await con.StoreEnquiryAPI((JSON.stringify(enquir)));
        console.log(response)
        if (response['status'] === true) {

            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
            setTimeout(() => {
                window.location.reload();
            }, 800);
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });

        }
    }



    return (
        <React.Fragment>
            <ToastContainer autoClose={3000} />
            <section>
                <div className="container">


                    <div >
                        <form onSubmit={submitenqir}>
                            <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                                <div className="col-md-12 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        <span className="required">Date:</span>
                                    </label>
                                    <input name="date" type="date" className="form-control"
                                        placeholder="Date"
                                        value={state.date}
                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'date' }); }}

                                        required />
                                </div>
                                <div className="col-md-12 mb-sm-7 mb-4">
                                    <label className="form-label">
                                        <span className="required">Enquiry:</span>
                                    </label>
                                    <textarea name="enquiry" type="text" className="form-control"
                                        placeholder="Enquiry"
                                        value={state.enquiry}
                                        onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'enquiry' }); }}

                                        required />
                                </div>

                            </div>
                            <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button>
                        </form>
                    </div>

                </div>
            </section>

        </React.Fragment>
    )
}

export default PatientEnquiry