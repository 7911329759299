import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Card from "components/card";
import './setting.css';
import SettingDiagnosisList from "./TreatmentSetting/diagnosislist";
import Treatementplan from "./TreatmentSetting/treatmentplan";
import Treatmentprocedure from "./TreatmentSetting/treatmentprocedure";
import Treatmentmethod from "./TreatmentSetting/treatmentmethod";
import DiagnosisType from "./TreatmentSetting/diagnosistype";
import InventoryCatagory from "./component/inventoryCatagory";
import PharamacyCatagory from "./component/pharamacycatagory";
import StaffCatagery from "./component/staffcategory";
import DoctorDepartment from "./component/doctordepartment";
import MedicalInventory from "./component/prescriptionsubcategory";
import MedicalReportType from "./component/medicalreporttype";


const Setting = () => {

  const userRoleId = localStorage.getItem('role_id');


  const [actTab, setActTab] = useState('')
  function handleTab(e) {
    e.preventDefault()
    setActTab(e.target.name)
  }


  return (
    <React.Fragment>

      <div className="settingcrd mt-[18px]">
        <div className="row">

          <div className="col-md-3 setintablist1">

            <Card >
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1') ?
                (
                  <div>
                    <p className="menuheading mt-4">Treatment Setting</p>
                    <ul className="nav " style={{ border: 'none', display: "grid" }}>
                      <li >
                        <a href="/diagnosistype">
                          <p >Diagnosis Type</p>
                        </a>
                      </li>
                      <li >
                        <a href="/diagnosislist">
                          <p >Diagnosis</p>
                        </a>
                      </li>
                      <li >
                        <a href="/treatmentplan">
                          <p >Treatment Plan</p>
                        </a>
                      </li>
                      <li >
                        <a href="/treatmentprocedure">
                          <p >Treatment Procedure</p>
                        </a>
                      </li>
                      <li >
                        <a href="/treatmentmethod">
                          <p >Treatment Method</p>
                        </a>
                      </li>

                    </ul>
                  </div>
                )
                : null}

              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1') ?
                (
                  <div>

                    <p className="menuheading">General Setting</p>
                    <ul className="nav " style={{ border: 'none', display: "grid" }}>
                      <li className="nav-item">
                        <Link className={actTab === 'tab11' ? `nav-item actt` : 'nav-item settingtab'} name='tab11' onClick={handleTab}>Inventory Category</Link>
                      </li>
                    </ul>
                    <ul className="nav " style={{ border: 'none', display: "grid" }}>
                      <li className="nav-item">
                        <Link className={actTab === 'tab12' ? `nav-item actt` : 'nav-item settingtab'} name='tab12' onClick={handleTab}>Pharmacy Category</Link>
                      </li>
                    </ul>
                    <ul className="nav " style={{ border: 'none', display: "grid" }}>
                      <li className="nav-item">
                        <Link className={actTab === 'tab13' ? `nav-item actt` : 'nav-item settingtab'} name='tab13' onClick={handleTab}>Staff Category</Link>
                      </li>
                    </ul>
                    <ul className="nav " style={{ border: 'none', display: "grid" }}>
                      <li className="nav-item">
                        <Link className={actTab === 'tab14' ? `nav-item actt` : 'nav-item settingtab'} name='tab14' onClick={handleTab}>Doctor Department</Link>
                      </li>
                    </ul>
                    {/* <ul className="nav " style={{ border: 'none', display: "grid" }}>
                      <li className="nav-item">
                        <Link className={actTab === 'tab16' ? `nav-item actt` : 'nav-item settingtab'} name='tab16' onClick={handleTab}> Prescription Category
                        </Link>
                      </li>
                    </ul> */}
                    <ul className="nav " style={{ border: 'none', display: "grid" }}>
                      <li className="nav-item">
                        <Link className={actTab === 'tab17' ? `nav-item actt` : 'nav-item settingtab'} name='tab17' onClick={handleTab}>Medical Report Type</Link>
                      </li>
                    </ul>
                  </div>
                ) : null}

            </Card>

          </div>


          <div className="col-md-9 setintablist2">

            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1' || userRoleId === '5') ?
              (
                <div>

                  <div className={actTab === 'tab1' ? `visible` : 'hidden'}>
                    <DiagnosisType />
                  </div>

                  <div className={actTab === 'tab2' ? `visible` : 'hidden'}>
                    <SettingDiagnosisList />
                  </div>

                  <div className={actTab === 'tab5' ? `visible` : 'hidden'}>
                    <Treatementplan />
                  </div>
                  {/*======== Treatment Procedure  =========== */}

                  <div className={actTab === 'tab6' ? `visible` : 'hidden'}>
                    <Treatmentprocedure />
                  </div>

                  {/*======== Treatment Method  =========== */}

                  <div className={actTab === 'tab8' ? `visible` : 'hidden'}>
                    <Treatmentmethod />
                  </div>
                </div>
              )
              : null}

            {userRoleId !== null && userRoleId !== undefined && (userRoleId === '5' || userRoleId === '1') ?
              (
                <div>
                  <div className={actTab === 'tab11' ? `visible` : 'hidden'}>
                    <InventoryCatagory />

                  </div>
                  <div className={actTab === 'tab12' ? `visible` : 'hidden'}>
                    <PharamacyCatagory />

                  </div>
                  <div className={actTab === 'tab13' ? `visible` : 'hidden'}>
                    <StaffCatagery />
                  </div>
                  <div className={actTab === 'tab14' ? `visible` : 'hidden'}>
                    <DoctorDepartment />
                  </div>
                  <div className={actTab === 'tab16' ? `visible` : 'hidden'}>
                    <MedicalInventory />
                  </div>
                  <div className={actTab === 'tab17' ? `visible` : 'hidden'}>
                    <MedicalReportType />
                  </div>
                </div>
              ) : null}

          </div>

        </div>
      </div>
    </React.Fragment>
  )
}

export default Setting