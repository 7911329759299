import React, { useState, useEffect } from "react";
import { RiEdit2Line } from 'react-icons/ri';
import { RiDeleteBin2Line } from 'react-icons/ri';
import * as con from '../../../repository/api_calls';
import { useReducer } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';



function reducer(state, action) {
    if (action.type === 'set_value') {
        state[action.key] = action.value;
        return {
            name: state.name,
            id: state.id
        };
    }
}


const PharamacyCatagory = () => {

    // ------------------------------- Add Pharamacy catagory ------------

    const [state, dispatch] = useReducer(reducer, {
        name: '', id: ''
    })

    const submitAddPharaCata = (event) => {
        event.preventDefault();
        if (state.id != '') {
            addpharamcat(state, 'edit');
            setDropdownOpen(false)
        } else {
            addpharamcat(state, 'add');
            setDropdownOpen(false)
        }
    }

    async function addpharamcat(PharamcyCatagData, type) {
        if (type == 'add') {
            const response = await con.CreatePharamCataAPI((JSON.stringify(PharamcyCatagData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = [...PharmaccatagoList];
                afterAdded.push(response['data']);
                setPharamacyCatagoList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        } else {
            const response = await con.UpdatPharaCataAPI((JSON.stringify(PharamcyCatagData)));
            console.log(response)
            if (response['status'] === true) {
                let afterAdded = PharmaccatagoList.map(item => {
                    if (item.id === state.id) {
                        return response.data;
                    }
                    return item;
                });
                dispatch({ type: 'set_value', value: '', key: 'id' })
                dispatch({ type: 'set_value', value: '', key: 'name' })
                setPharamacyCatagoList(afterAdded);
                toast.success(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_Success'
                });
            }
            else {
                toast.error(response['message'], {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'foo_bar_log_error'
                });

            }
        }
    }


    // -----------------------------  add method for dropdown

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const handleButtonClick = () => {
        setDropdownOpen(!isDropdownOpen);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };

    //------------------------for cancel action
    const handleCancel = () => {

        setDropdownOpen(false);
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'name' })
    };


    // ---------------- for edit function -------

    function handleForEditButtonClick(pharamaCat) {
        setDropdownOpen(true);

        dispatch({ type: 'set_value', value: pharamaCat.id, key: 'id' })
        dispatch({ type: 'set_value', value: pharamaCat.name, key: 'name' })

    };


    // ------------------------------- Pharmacy Catagory  list API -----------

    const [PharmaccatagoList, setPharamacyCatagoList] = useState([])


    useEffect(() => {
        getPharmCatTyp();
    }, [])

    async function getPharmCatTyp() {
        const response = await con.PharamaCatagAPI();
        if (response['status'] === true) {
            setPharamacyCatagoList(response['data']);
        }
    }


    // ------------------------- Delete API --------

    async function getPharaMaCatDelete(deletePharCatag) {
        const response = await con.PharaCataDeleteAPI(deletePharCatag);
        console.log(response)
        if (response['status'] === true) {
            let afterAdded = PharmaccatagoList.filter(item => item.id !== deletePharCatag);
            setPharamacyCatagoList(afterAdded);
            toast.success(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_Success'
            });
        }
        else {
            toast.error(response['message'], {
                position: toast.POSITION.TOP_RIGHT,
                className: 'foo_bar_log_error'
            });
        }
    }

    return (
        <React.Fragment>

            <div>
                < button type="submit"
                    className="btn btn-primary my-5"
                    style={{ width: "auto", backgroundColor: "#5660d9", marginLeft: "18px" }}
                    onClick={handleButtonClick}
                >+ Add Pharmacy Catagory
                </button>
                {isDropdownOpen && (

                    <div className="card mb-5" style={{ backgroundColor: "#f5f5f5", borderRadius: "7px", boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.24)", margin: "10px", padding: "20px" }}>
                        <form onSubmit={submitAddPharaCata}>

                            <div className="form-group">
                                <label htmlFor="diagnosisInput">Pharmacy Catagory:</label>
                                <input type="text" id="diagnosisInput" placeholder="Enter Your Pharmacy Catagory" className="form-control"
                                    value={state.name}
                                    required
                                    onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'name' }); }}
                                />
                            </div>
                            <span> <button type="submit" className="btn btn-primary">Save</button></span>
                            <span className="me-1"> <button type="button" className="btn btn-primary" onClick={handleCancel}>cancel</button></span>

                        </form>
                    </div>
                )}

                <table className="bordered-table">
                    <thead className="tablehead" >
                        <tr style={{ borderRadius: "10px" }}>
                            <th>S.No</th>
                            <th>Pharmacy Catagory</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {PharmaccatagoList.map((pharamaCat, index) => {
                            return (

                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{pharamaCat.name}</td>
                                    <td><RiEdit2Line className="edit_icon" onClick={() => { handleForEditButtonClick(pharamaCat); }} /></td>
                                    <td> <RiDeleteBin2Line className="delete_icon" style={{ cursor: "pointer" }}
                                        onClick={() => getPharaMaCatDelete(pharamaCat.id)} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>


        </React.Fragment>
    )
}

export default PharamacyCatagory