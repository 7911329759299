import React, { useState } from "react"
import * as con from '../../repository/api_calls';
import { useReducer, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import Sidebar from "components/sidebar";
import Navbar from "components/navbar";
import StaffProfile from "./profile/staffprofile";
import DoctorProfile from "./profile/doctorprofile";
import PatientProfile from "./profile/patientprofile";
import Adminprofile from "./profile/adminprofile";



function Profile(props) {
    const userRoleId = localStorage.getItem('role_id');


    const { ...rest } = props;
    const userdata = JSON.parse(localStorage.getItem("userdata"));
    const [open, setOpen] = React.useState(true);


    return (
        <div className="flex h-full w-full" style={{ overflowX: "hidden" }}>
            <Sidebar open={open} onClose={() => setOpen(false)} />
            <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">

                <main
                    className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[215px]`}
                >
                    {/* Routes */}
                    <div className="h-full">
                        <Navbar
                            onOpenSidenav={() => setOpen(true)}
                            logoText={"Kirthika Dental Care"}
                            brandText="My Profile"
                            // secondary={getActiveNavbar(routes)}
                            {...rest}
                        />
                        {userRoleId !== null && userRoleId !== undefined && (userRoleId === '3') ?
                            (
                                <div>
                                    <StaffProfile />
                                </div>
                            ) : null}

                        {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2') ?
                            (
                                <div>
                                    <DoctorProfile />
                                </div>
                            ) : null}

                        {userRoleId !== null && userRoleId !== undefined && (userRoleId === '4') ?
                            (
                                <div>
                                    <PatientProfile />
                                </div>
                            ) : null}
                        {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                            (
                                <div>
                                    <Adminprofile />
                                </div>
                            ) : null}
                    </div>
                </main>
            </div >
        </div >

    )
}

export default Profile