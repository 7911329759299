import Setting from "views/settings/settinglist";

import React from "react";
const SettingShow = (prop) => {

  return (
    <div>
      <Setting />
    </div>

  );
};
export default SettingShow;