import React, { useEffect, useState } from "react"
import * as con from '../../repository/api_calls';
import { RiEdit2Line, RiDeleteBin2Line } from 'react-icons/ri';
import { useReducer } from 'react';
import Doctor from "assets/img/profile/doctor.png";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { FaEllipsis } from "react-icons/fa6";


function reducer(state, action) {
  if (action.type === 'set_value') {
    state[action.key] = action.value;
    return {
      image: state.image,
      first_name: state.first_name,
      surname: state.surname,
      staff_category_id: state.staff_category_id,
      phone: state.phone,
      address: state.address,
      email: state.email,
      age: state.age,
      dob: state.dob,
      gender: state.gender,
      qualification: state.qualification,
      aadhar_number: state.aadhar_number,
      password: state.password,
      confirm_password: state.confirm_password,
      id: state.id,
      employee_number: state.employee_number
    };
  }
  if (action.type === 'set_base64') {
    return {
      image: action.value,
      first_name: state.first_name,
      surname: state.surname,
      staff_category_id: state.staff_category_id,
      phone: state.phone,
      address: state.address,
      email: state.email,
      age: state.age,
      dob: state.dob,
      gender: state.gender,
      qualification: state.qualification,
      aadhar_number: state.aadhar_number,
      password: state.password,
      confirm_password: state.confirm_password,
      id: state.id,
      employee_number: state.employee_number
    }
  }

}

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination">
      <ul className="pagination-list">
        {pageNumbers.map((number) => (
          <li key={number} className="pagination-item">
            <a
              href="#"
              onClick={() => paginate(number)}
              className={`pagination-link ${currentPage === number ? 'active' : ''}`}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};



const StaffList = () => {



  const userRoleId = localStorage.getItem('role_id');

  const [staffLists, setStaffList] = useState([])
  const [searchTerm, setSearchTerm] = useState("");

  const searchedStaff = staffLists.filter((c) =>
    (c.first_name && c.first_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (c.phone && c.phone.includes(searchTerm))
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25; // Set the number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentStaff = searchedStaff.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);




  useEffect(() => {
    getStaffLists();
  }, [])

  async function getStaffLists() {
    const responselist = await con.staffListAPI();
    console.log(responselist)
    if (responselist['status'] === true) {
      setStaffList(responselist['data']);

      responselist['data'].forEach((staff) => {
        localStorage.setItem("staff_id", staff.id);

      });
    }
  }
  // ------------- profile image --------

  const [image, setImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    let base64String = '';
    reader.onloadend = () => {
      base64String = reader.result;
      setImage(reader.result);
      setIsImageSelected(true);
      setImageValue(base64String);
      console.log(base64String)
    };
    reader.readAsDataURL(file);

  };

  function setImageValue(result) {
    const splitUps = result.split(',')
    dispatch({ type: "set_base64", value: splitUps[1] });
  }

  const handleProfileClick = () => {
    document.getElementById('profileImageInput').click();
  };

  const handleRemoveProfile = () => {
    setImage(null);
    setIsImageSelected(false);
  };

  // ---------------------------- popup form api ---------------


  const [state, dispatch] = useReducer(reducer, {
    image: '', first_name: '', suranme: '', staff_category_id: '',
    phone: '', address: '', email: '', dob: '', age: '', gender: '', qualification: '', aadhar_number: '', password: '', confirm_password: '', id: '', employee_number: ''
  })

  const submitStaffPop = (event) => {
    event.preventDefault();
    if (state.id != '') {
      addStaffs(state, 'edit');
    } else {
      addStaffs(state, 'add');
    }
  }


  async function addStaffs(staffbodyData, type) {
    if (type == 'add') {
      const response = await con.CreateStaffAPI((JSON.stringify(staffbodyData)));
      console.log(response);
      if (response['status'] === true) {
        let afterAdded = [...staffLists];
        afterAdded.push(response['data']);
        setStaffList(afterAdded);
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });

        setTimeout(() => {
          window.location.reload();
        }, 800);

      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        })
      }
    } else {
      const response = await con.EditStaffAPI((JSON.stringify(staffbodyData)));
      console.log(response)
      if (response['status'] === true) {
        let afterAdded = staffLists.map(item => {
          if (item.id === state.id) {
            return response.data;
          }
          return item;
        });
        dispatch({ type: 'set_value', value: '', key: 'id' })
        dispatch({ type: 'set_value', value: '', key: 'image' })
        dispatch({ type: 'set_value', value: '', key: 'first_name' })
        dispatch({ type: 'set_value', value: '', key: 'surname' })
        dispatch({ type: 'set_value', value: '', key: 'staff_category_id' })
        dispatch({ type: 'set_value', value: '', key: 'phone' })
        dispatch({ type: 'set_value', value: '', key: 'address' })
        dispatch({ type: 'set_value', value: '', key: 'email' })
        dispatch({ type: 'set_value', value: '', key: 'dob' })
        dispatch({ type: 'set_value', value: '', key: 'age' })
        dispatch({ type: 'set_value', value: '', key: 'gender' })
        dispatch({ type: 'set_value', value: '', key: 'password' })
        dispatch({ type: 'set_value', value: '', key: 'confirm_password' })
        dispatch({ type: 'set_value', value: '', key: 'status' })
        dispatch({ type: 'set_value', value: '', key: 'qualification' })
        dispatch({ type: 'set_value', value: '', key: 'aadhar_number' })

        setStaffList(afterAdded);
        toast.success(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_Success'
        });
        setTimeout(() => {
          window.location.reload();
        }, 800);
      }
      else {
        toast.error(response['message'], {
          position: toast.POSITION.TOP_RIGHT,
          className: 'foo_bar_log_error'
        });

      }
    }
  }


  // ------------------ drop down list ------------
  const [staffcategoidList, setStaffCateidList] = React.useState();

  const categoryRef = React.useRef();

  useEffect(() => {
    getStaffCat()
  }, [])

  async function getStaffCat() {
    const response = await con.getStaffCategoryList();
    console.log(response)
    if (response['status'] === true) {
      setStaffCateidList(response['data'])
      dispatch({ type: 'set_value', value: response['data'][0]['staff_category'], key: 'staff_category_id' });
    }
  }



  // ------------------------- Delete API --------

  async function getStaffDelete(deleteStaffData) {
    const response = await con.StaffDeleteAPI(deleteStaffData);
    console.log(response)
    if (response['status'] === true) {
      let afterAdded = staffLists.filter(item => item.id !== deleteStaffData);
      setStaffList(afterAdded);
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });
    }
  }


  // ---------------- action dropdown ----
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // ---------------- toogle check API ----


  // async function changeStatus(toogle) {
  //   let jsonBody = JSON.stringify({
  //     "id": toogle.id,
  //     "status": toogle.status === 1 ? "0" : "1"
  //   })
  //   const response = await con.ActiveInactiveAPI(jsonBody);
  //   console.log(response);
  //   if (response['status'] === true) {

  //     const updatedStaffList = staffLists.map(staff => {
  //       if (staff.id === toogle.id) {
  //         return {
  //           ...staff,
  //           status: toogle.status === 1 ? 0 : 1
  //         };
  //       }
  //       return staff;
  //     });

  //     setStaffList(updatedStaffList);

  //     toast.success(response['message'], {
  //       position: toast.POSITION.TOP_RIGHT,
  //       className: 'foo_bar_log_Success'
  //     });
  //   }
  // }

  const [editmode, setEditmode] = useState(false);

  // ----------- Change Table Heading and Button Dynamically -------------------

  const [heading, setHeading] = useState('Default Heading');
  const [button, setButton] = useState('Default Heading');
  const [addPharm, setaddPharm] = useState(false)
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleButtonClick = (newHeading, newButton) => {
    setHeading(newHeading);
    setEditmode(true)
    setButton(newButton)
    setDropdownOpen(!isDropdownOpen);
    setaddPharm(!addPharm);

    dispatch({ type: 'set_value', value: '', key: 'id' })
    dispatch({ type: 'set_value', value: '', key: 'image' })
    dispatch({ type: 'set_value', value: '', key: 'first_name' })
    dispatch({ type: 'set_value', value: '', key: 'surname' })
    dispatch({ type: 'set_value', value: '', key: 'staff_category_id' })
    dispatch({ type: 'set_value', value: '', key: 'phone' })
    dispatch({ type: 'set_value', value: '', key: 'address' })
    dispatch({ type: 'set_value', value: '', key: 'email' })
    dispatch({ type: 'set_value', value: '', key: 'dob' })
    dispatch({ type: 'set_value', value: '', key: 'age' })
    dispatch({ type: 'set_value', value: '', key: 'aadhar_number' })
    dispatch({ type: 'set_value', value: '', key: 'gender' })
    dispatch({ type: 'set_value', value: '', key: 'password' })
    dispatch({ type: 'set_value', value: '', key: 'confirm_password' })

  };


  // ---------------- for edit function -------

  function handleForEditButtonClick(newHeading, newButton, subCat) {
    setHeading(newHeading);
    setButton(newButton)
    setDropdownOpen(true);
    setEditmode(false)

    dispatch({ type: 'set_value', value: subCat.id, key: 'id' })
    dispatch({ type: 'set_value', value: subCat.image, key: 'image' })
    dispatch({ type: 'set_value', value: subCat.first_name, key: 'first_name' })
    dispatch({ type: 'set_value', value: subCat.surname, key: 'surname' })
    dispatch({ type: 'set_value', value: subCat.staff_category_id, key: 'staff_category_id' })
    dispatch({ type: 'set_value', value: subCat.phone, key: 'phone' })
    dispatch({ type: 'set_value', value: subCat.address, key: 'address' })
    dispatch({ type: 'set_value', value: subCat.email, key: 'email' })
    dispatch({ type: 'set_value', value: subCat.dob, key: 'dob' })
    dispatch({ type: 'set_value', value: subCat.age, key: 'age' })
    dispatch({ type: 'set_value', value: subCat.gender, key: 'gender' })
    dispatch({ type: 'set_value', value: subCat.password, key: 'password' })
    dispatch({ type: 'set_value', value: subCat.confirm_password, key: 'confirm_password' })
    dispatch({ type: 'set_value', value: subCat.status, key: 'status' })
    dispatch({ type: 'set_value', value: subCat.qualification, key: 'qualification' })
    dispatch({ type: 'set_value', value: subCat.aadhar_number, key: 'aadhar_number' })



  };
  const currentDate = new Date().toISOString().split('T')[0];

  // ------------------------ for hide aadharNumber -----------------

  const hideAadhar_number = (aadharNumber) => {
    if (aadharNumber && aadharNumber.length === 14) {
      // const maskedNumber = 'X'.repeat(8) + aadharNumber.slice(8);
      const maskedNumber = 'X'.repeat(aadharNumber.length - 3) + aadharNumber.slice(-3);
      return maskedNumber;
    } else {
      // Handle invalid phone numbers or other cases
      return 'Invalid phone number';
    }
  };

  // ---------------------update Empoloyee id ------------

  const submitEmployPop = (event) => {
    event.preventDefault();
    updateUpdateEmpl(state);
  }

  async function updateUpdateEmpl(emplyeid) {
    const response = await con.UpdateEmpoloyeeIdAPI((JSON.stringify(emplyeid)));
    console.log(response)
    if (response['status'] === true) {

      let afterAdded = staffLists.map(item => {
        if (item.id === state.id) {
          return response.data;
        }
        return item;
      });
      dispatch({ type: 'set_value', value: '', key: 'id' })
      dispatch({ type: 'set_value', value: '', key: 'employee_number' })
      setStaffList(afterAdded);
      toast.success(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_Success'
      });
      setTimeout(() => {
        window.location.reload();
      }, 800);
    }
    else {
      toast.error(response['message'], {
        position: toast.POSITION.TOP_RIGHT,
        className: 'foo_bar_log_error'
      });

    }
  }

  // ---------------- for edit function -------

  function handleForEditButtonClick1(subCat) {
    setDropdownOpen(true);
    dispatch({ type: 'set_value', value: subCat.id, key: 'id' })
    dispatch({ type: 'set_value', value: subCat.employee_number, key: 'employee_number' })
  };


  return (
    <React.Fragment>

      <ToastContainer autoClose={3000} />
      {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
        (
          <button type="button" className="btn btn-primary my-5" aria-hidden="true"
            data-toggle="modal"
            data-target="#addstaff"
            onClick={() => handleButtonClick('Add A New Staff', 'Submit')}
            style={{ cursor: "pointer", float: "right", marginRight: "40px", backgroundColor: "#145BD9" }}
          ><span className="me-1" style={{ fontSize: "20px" }}>+</span>ADD STAFF</button>
        ) : null}

      <div className="tabtable" style={{ width: "98%", height: "auto", backgroundColor: "white", marginTop: "30px", borderRadius: "7px", padding: "10px", marginLeft: "20px" }}>

        <input className="form-control searchBar mb-5" type="text"
          placeholder="Global Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} />

        <table className="bordered-table">
          <thead className="tablehead" >
            <tr style={{ borderRadius: "10px" }}>
              <th>S.No</th>
              <th>Name</th>
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                (
                  <th>Employee Id</th>
                )
                : null}
              <th>Role</th>
              <th>Qualification</th>
              <th>Aadhar Number</th>
              <th>Phone. No</th>
              {/* {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
                (
                  <th>Status</th>
                )
                : null} */}
              {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                (
                  <th>Action</th>
                )
                : null}
            </tr>
          </thead>
          <tbody>
            {currentStaff.map((staff, index) => {
              const continuousIndex = (currentPage - 1) * itemsPerPage + index + 1;
              console.log(currentStaff)
              return (

                <tr key={index} >
                  {/* <td>{index + 1}</td> */}
                  <td>{continuousIndex}</td>
                  <td>{staff.first_name}</td>
                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                    (
                      <td style={{ display: "flex", justifyContent: "center" }}>{staff.employee_number}
                        <span className="lis" aria-hidden="true"
                          data-toggle="modal"
                          data-target="#editemployeeid"
                          onClick={() => handleForEditButtonClick1(staff)}>
                          <RiEdit2Line className="edit_icon ms-3" />
                        </span></td>
                    )
                    : null}
                  <td>{staff.staff_category}</td>
                  <td>{staff.qualification}</td>
                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                    (
                      <td>{staff.aadhar_number}</td>
                    ) : null}
                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '3') ?
                    (
                      <td>{hideAadhar_number(staff.aadhar_number)}</td>
                    ) : null}
                  <td>{staff.phone}</td>
                  {/* {userRoleId !== null && userRoleId !== undefined && (userRoleId === '2' || userRoleId === '1') ?
                    (
                      <td>
                        <label className="switch_to">
                          <input className="inpu_too" type="checkbox"
                            defaultChecked={staff.status === 1 ? true : false}
                            onChange={() => { changeStatus(staff) }} />
                          <span className="slider_too round" />
                        </label>
                      </td>
                    )
                    : null} */}

                  {userRoleId !== null && userRoleId !== undefined && (userRoleId === '1') ?
                    (
                      <td>
                        <div className="dropdown">
                          <FaEllipsis onClick={toggleDropdown} className="ellipsisicon" />
                          {isOpen && (
                            <div className="dropdown-content" >
                              <div className="lis" aria-hidden="true"
                                data-toggle="modal"
                                data-target="#addstaff"
                                onClick={() => handleForEditButtonClick('Edit Staff', 'Update', staff)}
                                style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '30px' }}><RiEdit2Line className="edit_icon me-2" /><span>Edit</span></div>

                              <div className="lis" style={{ display: "flex", cursor: "pointer", justifyContent: 'flex-start', marginRight: '20px' }} onClick={() => getStaffDelete(staff.id)}><RiDeleteBin2Line className="edit_icon me-2" style={{ color: "BB3504" }} /><span>Delete</span></div>

                            </div>
                          )}
                        </div>
                      </td>
                    ) : null}
                </tr>

              )
            })}
            <hr />
          </tbody>
        </table>


        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={searchedStaff.length}
          currentPage={currentPage}
          paginate={paginate}
        />

      </div>

      <div>

        <section>
          <div className="container">

            <div className="modal fade" id="addstaff" role="dialog">
              <div className="po_sc_in">

                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>{heading}</h4>
                    <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                      ×
                    </button>

                  </div>
                  <div className="modal-body">
                    <form onSubmit={submitStaffPop}>

                      <div className="mb-5 mt-3">
                        <p className="text-center"><b>Profile Image</b></p>
                        <input
                          id="profileImageInput"
                          type="file"
                          onChange={handleImageUpload}
                          style={{ display: 'none' }}

                        />
                        <div>
                          <div className="profileContainer" onClick={handleProfileClick} style={{ backgroundColor: "#F4F4F4", width: "100px", height: "100px", borderRadius: "50%", cursor: "pointer", margin: "auto", display: "block" }}>
                            {isImageSelected ? (
                              <div>
                                <img src={image} alt="Profile" className="profileImage" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                                <button onClick={handleRemoveProfile} style={{ marginTop: "8px", marginLeft: "25px", color: "red" }}>Remove</button>
                              </div>
                            ) : (
                              <div>
                                <img src={Doctor} alt="Profile" className="profileImage" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Name:</span>
                          </label>
                          <input name="first_name" type="text" className="form-control"
                            placeholder=" Name"
                            minLength={1} maxLength={100}
                            value={state.first_name}
                            onChange={(e) => {
                              var sanitizedValue = e.target.value.replace(/[^A-Za-z\s.]/g, '');
                              if (sanitizedValue.length > 100) {
                                sanitizedValue = sanitizedValue.slice(0, 100);
                              }
                              const regex = /(.)\1{5,}/g;
                              sanitizedValue = sanitizedValue.replace(regex, '');
                              dispatch({ type: 'set_value', value: sanitizedValue, key: 'first_name' });
                            }}
                            required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Surname:</span>
                          </label>
                          <input name="surname" type="text" className="form-control"
                            placeholder="Surname"
                            minLength={1} maxLength={100}
                            value={state.surname}
                            onChange={(e) => {
                              var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                              if (sanitizedValue.length > 100) {
                                sanitizedValue = sanitizedValue.slice(0, 100);
                              }
                              const regex = /(.)\1{5,}/g;
                              sanitizedValue = sanitizedValue.replace(regex, '');
                              dispatch({ type: 'set_value', value: sanitizedValue, key: 'surname' });
                            }}
                            required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Category:</span>
                          </label>

                          {(staffcategoidList != null) ?
                            <select
                              className="form-select"
                              id="sel10"
                              style={{ fontSize: 14, height: 44, width: "100%" }}
                              required
                              ref={categoryRef}
                              value={state.staff_category_id}
                              onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'staff_category_id' }); }}
                            >
                              <option>Select Category........</option>
                              {staffcategoidList.map((list, index) =>
                                <option className="taboption" value={list['id']}>{list['name']}</option>
                              )}
                            </select>
                            : null}

                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Phone. No</span>
                          </label>
                          <input name="phone" type="text" className="form-control"
                            placeholder="Phone. No"
                            minLength={10} maxLength={10}
                            value={state.phone}
                            onChange={(e) => {
                              var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                              dispatch({ type: 'set_value', value: sanitizedValue, key: 'phone' });
                            }}
                            required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Address</span>
                          </label>
                          <input name="address" type="address" className="form-control"
                            placeholder="Address"
                            value={state.address}
                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'address' }); }}
                            required />
                        </div>

                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Email</span>
                          </label>
                          <input name="email" type="email" className="form-control"
                            placeholder="Email"
                            value={state.email}
                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'email' }); }}
                            required
                          // pattern="[a-zA-Z0-9.]{5,}@[a-z]+\.[a-z]{2,3}"
                          />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Qualification</span>
                          </label>
                          <input name="Qualification" type="text" className="form-control"
                            placeholder="Qualification"
                            minLength={2} maxLength={150}
                            value={state.qualification}
                            onChange={(e) => {
                              var sanitizedValue = e.target.value.replace(/[^A-Za-z\s-,/.()'"]/g, '');
                              if (sanitizedValue.length > 150) {
                                sanitizedValue = sanitizedValue.slice(0, 150);
                              }
                              const regex = /(.)\1{5,}/g;
                              sanitizedValue = sanitizedValue.replace(regex, '');
                              dispatch({ type: 'set_value', value: sanitizedValue, key: 'qualification' });
                            }}
                            required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Aadhar Number</span>
                          </label>
                          <input name="Aadhar Number" type="text" className="form-control"
                            placeholder="Aadhar Number"
                            minLength={12} maxLength={14}
                            value={state.aadhar_number}
                            onChange={(e) => {
                              var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                              sanitizedValue = sanitizedValue.replace(/(\d{4})/g, '$1 ').trim();
                              dispatch({ type: 'set_value', value: sanitizedValue, key: 'aadhar_number' });
                            }} required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">Age</span>
                          </label>
                          <input name="age" type="text" className="form-control"
                            placeholder="Age"
                            minLength={1} maxLength={3}
                            value={state.age}
                            onChange={(e) => {
                              var sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
                              dispatch({ type: 'set_value', value: sanitizedValue, key: 'age' });
                            }}
                            required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mb-4">
                          <label className="form-label">
                            <span className="required">D.O.B</span>
                          </label>
                          <input name="dob" type="date" className="form-control"
                            placeholder=""
                            value={state.dob}
                            max={currentDate}
                            onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'dob' }); }}
                            required />
                        </div>
                        <div className="col-md-6 mb-sm-7 mt-3">
                          <div className="form-group mb-5">
                            <label className="form-label">
                              <span className="required"></span>Gender
                            </label>
                            <br />
                            <div className="d-flex align-items-center gendergrid">

                              <div className="form-check me-5">
                                <label className="container-radio" htmlFor="male">  Male
                                  <input type="radio" id="male" name="gender"
                                    value={state.gender}
                                    required
                                    checked={state.gender === 'Male'}
                                    onChange={(e) => { dispatch({ type: 'set_value', value: 'Male', key: 'gender' }); }}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>

                              <div className="form-check me-10 mart">
                                <label className="container-radio" htmlFor="female">       Female
                                  <input type="radio" id="female" name="gender"
                                    value={state.gender}
                                    checked={state.gender === 'Female'}
                                    onChange={(e) => { dispatch({ type: 'set_value', value: 'Female', key: 'gender' }); }}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {editmode && (<>
                          <div className="col-md-6 mb-sm-7 mb-4">
                            <label className="form-label">
                              <span className="required">Password</span>
                            </label>
                            <input name="password1" type="password" className="form-control"
                              placeholder="Password"
                              minLength={6} maxLength={50}
                              value={state.password}
                              onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'password' }); }}
                              required />
                          </div>

                          <div className="col-md-6 mb-sm-7 mb-4">
                            <label className="form-label">
                              <span className="required">Confirm Password</span>
                            </label>
                            <input name="confirm_password" type="password" className="form-control"
                              placeholder="Confirm Password"
                              minLength={6} maxLength={50}
                              value={state.confirm_password}
                              onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'confirm_password' }); }}
                              required />
                          </div>
                        </>
                        )}

                      </div>
                      <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>{button}</button>
                    </form>
                  </div>
                  <div className="modal-footer">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* --------------------------------- employee_number edit popup ---------------- */}

      <section>
        <div className="container">
          <div className="modal fade" id="editemployeeid" role="dialog">
            <div className="po_sc_in">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title" style={{ fontSize: "20px", fontWeight: "600" }}>Edit Employee Id</h4>
                  <button type="button" className="close popclose" style={{ color: "blue", fontSize: "30px" }} data-dismiss="modal">
                    ×
                  </button>
                </div>

                <div className="modal-body">
                  <form onSubmit={submitEmployPop}>
                    <div className="row">
                      <div className="col-md-3">

                      </div>
                      <div className="col-md-6 mb-sm-7 mb-4">
                        <label className="form-label">
                          <span className="required">Employee Number</span>
                        </label>
                        <input name="employee_number" type="text" className="form-control"
                          placeholder="Employee Number"
                          value={state.employee_number}
                          onChange={(e) => { dispatch({ type: 'set_value', value: e.target.value, key: 'employee_number' }); }}
                          required />
                      </div>
                      <div className="col-md-3">

                      </div>

                    </div>
                    <button type="submit" className="btn btn-primary " style={{ width: "150px", margin: "auto", display: "block" }}>Submit</button>
                  </form>
                </div>
                <div className="modal-footer">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </React.Fragment>
  )
}

export default StaffList